/**
 *
 * Login
 *
 */

import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { LoginForm } from './Form';
import { AuthenticationForm } from './AuthenticationForm';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { AuthPageContainer } from 'app/components/AuthPageContainer';
import { useParams } from 'react-router';
import { useLogUserAction } from 'app/providers/LoggingProvider/useLogUserAction';
import { useHasChanged } from 'utils/usePrevious';
import { AuthConfirmationScreen } from 'app/components/AuthConfirmationScreen';
import { useQuery } from 'utils/useQuery';
import { Alert, Box, Stack } from '@mui/material';
import useHandleApiResponse from '../../../../utils/useHandleApiResponse';

interface Props {}

export const Login = memo((props: Props) => {
  const [email, setEmail] = useState<string>('');
  const { login, authenticate2fa, resendCode, authenticated } =
    useSelector(selectAuthProvider);

  // get query parameter from url - invitation

  const { query } = useQuery();

  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [autologinAttempted, setAutologinAttempted] = useState<boolean>(false);
  const params = useParams();
  const { logUserAction } = useLogUserAction();
  const loginLoadingChanged = useHasChanged(login.loading);
  const authenticate2faLoadingChanged = useHasChanged(authenticate2fa.loading);

  const [displayScreen, setDisplayScreen] = useState<boolean>(false);

  const authenticatedChanged = useHasChanged(authenticated);
  useEffect(() => {
    if (authenticatedChanged && authenticated) {
      setDisplayScreen(true);
    }
  });

  useEffect(() => {
    dispatch(authProviderActions.userLoginInit());
  }, [dispatch]);

  const content = useMemo(() => {
    switch (true) {
      case displayScreen:
        return (
          <AuthConfirmationScreen
            onFinished={() => {
              dispatch(authProviderActions.userComplete2fa());
            }}
          />
        );
      case !!login.data.validation_token:
        return (
          <AuthenticationForm
            reset={() => dispatch(authProviderActions.userLoginInit())}
            resendCode={() =>
              dispatch(
                authProviderActions.resendCodeRequest({
                  validation_token: login.data.validation_token,
                }),
              )
            }
            resendLoading={resendCode.loading}
            contact={login.data.contact}
            method={login.data.method}
            loading={authenticate2fa.loading}
            validation_token={login.data.validation_token}
            userAuthenticate2faRequest={payload =>
              dispatch(
                authProviderActions.userAuthenticate2faRequest({
                  ...payload,
                }),
              )
            }
          />
        );
      default:
        return (
          <LoginForm
            email={query.email || ''}
            data={login}
            loginRequest={data => {
              setEmail(data.email);
              dispatch(
                authProviderActions.userLoginRequest({
                  ...data,
                }),
              );
            }}
          />
        );
    }
  }, [authenticated, login, authenticate2fa, dispatch, displayScreen]);

  useHandleApiResponse(login, null, {
    errorMessage: 'Login unsuccessful with these credentials',
    onError: () => {
      logUserAction({
        category: 'auth',
        action: 'login-failed-credentials',
        params: email,
      });
    },
    onSuccess: () => {
      closeSnackbar();
    },
  });

  useHandleApiResponse(authenticate2fa, null, {
    errorMessage: 'Error - could not verify your code.',
    onError: () => {
      logUserAction({
        category: 'auth',
        action: 'login-failed-2fa',
        params: email,
      });
      if (authenticate2fa.error.code === 410) {
        dispatch(authProviderActions.userLoginInit());
      }
    },
    onSuccess: () => {
      closeSnackbar();
    },
  });

  if (params.key) {
    if (!autologinAttempted) {
      dispatch(authProviderActions.autoLoginRequest({ key: params.key }));
      setAutologinAttempted(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Login to the app" />
      </Helmet>

      <AuthPageContainer>
        <Stack spacing={4}>
          {query.invitation && (
            <Alert severity={'info'}>
              Please sign-in to accept your invitation to join another Board
              using Governance360.
            </Alert>
          )}
          {content}
        </Stack>
      </AuthPageContainer>
    </>
  );
});
