import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { IconItem } from './IconItem';
import { RadioButton } from 'app/components/RadioButton';
import { useFormikContext } from 'formik';
import { FormValues } from './index';
import { VideoBlock } from 'app/components/VideoBlock';
import { noop } from 'utils/noop';
import { WizardDownloadButton } from './WizardDownloadButton';
import key from 'weak-key';
import { RiMailFill, RiMailLine, RiQuestionFill } from 'react-icons/ri';

interface Props {
  previous: () => void;
  confirm: () => void;
  downloads: Array<any>;
}

export function ReadyToGo(props: Props) {
  const { confirm, previous, downloads } = props;
  const { setFieldValue, values } = useFormikContext<FormValues>();

  return (
    <Stack spacing={6}>
      <Typography variant={'h1'}>You are ready to go</Typography>
      <Box>
        <Stack spacing={4}>
          <Typography component={'p'}>
            Congratulations – you have completed the key first steps in setting
            up your Board process on Governance360.
          </Typography>
          <Typography component={'p'}>
            Before you proceed to run your first meeting, just a quick reminder
            that we are at your side every step of the way. If you get stuck,
            have any queries or simply want to remind yourself how features on
            the platform work, please don’t hesitate to:
          </Typography>
        </Stack>
      </Box>

      <Stack spacing={4}>
        <Box>
          <WizardDownloadButton
            title={''}
            icon={<RiMailLine size={40} />}
            description="Drop us a line at support@governance360.com"
            buttonProps={{
              onClick: () => window.open('mailto:support@governance360.com'),
            }}
          />
          <WizardDownloadButton
            title={''}
            icon={<RiQuestionFill size={40} />}
            description="Use our KnowledgeBase 24 hours a day to look up articles and
            guides to help you resolve your query"
            buttonProps={{
              onClick: () =>
                window.open(
                  'https://knowledge.governance360.com/',
                  '_blank',
                  'noopener',
                ),
            }}
          />
          <WizardDownloadButton
            title=""
            description="Download our quick FAQ on the set-up process for Governance360"
            buttonProps={{
              onClick: () =>
                window.open(
                  'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
                  '_blank',
                ),
            }}
          />
        </Box>
      </Stack>
      <Box>
        <Typography component={'p'}>
          Thank you for using Governance360 – we look forward to helping you
          save time, reduce risk and improve your effectiveness.
        </Typography>
      </Box>

      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant={'outlined'} onClick={() => previous()}>
              Previous: Schedule a meeting
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth onClick={() => confirm()} type={'submit'}>
              I'm ready to start using my board
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
