/**
 *
 * Activate
 *
 */
import * as React from 'react';
import { useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { features, IOrganisation } from 'types/types';
import { Helmet } from 'react-helmet-async';
import { useReloadCurrentOrganisation } from '../OrganisationPage/useReloadCurrentOrganisation';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { Board } from './Board';
import { Container, Typography } from '@mui/material';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useParams } from 'react-router';
import { useRefreshUser } from 'app/providers/AuthProvider/useRefreshUser';

interface Props {
  organisation: IOrganisation;
}

export function Activate(props: Props) {
  const { organisation } = props;

  const { code } = useParams<{
    code: string;
  }>();
  const navigate = useNavigate();
  const { reloadOrganisation } = useReloadCurrentOrganisation();
  const { refreshUser } = useRefreshUser();

  const { hasPermission } = useHasPermission();

  const canManageFeatures = hasPermission(
    'manage-features',
    'organisation',
    organisation.id,
  );

  const content = useMemo(() => {
    switch (code) {
      case 'board':
        return (
          <Board
            organisation={organisation}
            onComplete={() => {
              reloadOrganisation();
              setTimeout(() => {
                refreshUser();
              }, 2000);
            }}
          />
        );
      default:
        return <Typography>Invalid feature code</Typography>;
    }
  }, [code, organisation]);

  if (!canManageFeatures) {
    return <Navigate to={`/`} />;
  }

  if (!features[code]) {
    return <Typography>Invalid feature code</Typography>;
  }

  if (!organisation) return <LoadingIndicator />;

  if (organisation.features[code].status === 'not-allowed') {
    return (
      <Typography>
        This feature is not available with your current plan
      </Typography>
    );
  }

  if (organisation.features[code].activated) {
    switch (code) {
      case 'board':
        if (hasPermission('view-meetings', 'organisation', organisation.id)) {
          return <Navigate to={`/organisation/${organisation.id}/meetings`} />;
        }
        break;
      default:
        return <Typography>This feature is already activated</Typography>;
    }
  }

  return (
    <>
      <Helmet title={'Activate'}>
        <meta
          name="description"
          content="Activate features of the organisation"
        />
      </Helmet>
      <Container>
        {content}
      </Container>
    </>
  );
}
