import { CheckboxWithLabel, CheckboxWithLabelProps } from 'formik-mui';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

export function CheckboxWithLabelExtended(
  props: Omit<CheckboxWithLabelProps, 'Label'> & {
    Label: CheckboxWithLabelProps['Label'] & { description?: string };
  },
) {
  const { Label, ...other } = props;
  return (
    <CheckboxWithLabel
      {...other}
      sx={{ marginTop: -1, ...other.sx }}
      Label={{
        sx: { alignItems: 'flex-start' },
        label: (
          <Stack spacing={0.5}>
            <Typography component={Box}>{Label.label}</Typography>
            <Typography component={Box} variant={'body2'}>
              {Label.description}
            </Typography>
          </Stack>
        ),
      }}
    />
  );
}
