import {
  call,
  cancelled,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { apiRequest, getToken, setToken } from 'utils/request';
import { authProviderActions as actions } from '.';
import { loggingProviderActions } from 'app/providers/LoggingProvider/slice';
import { createRequestSaga } from '../../../../utils/saga';

const ignoreActionTypes = () => [
  actions.userTokenRefreshRequest.type,
  actions.userLogoutRequest.type,
];

function monitorableAction(action: { type: string }) {
  return (
    action.type.includes('Request') &&
    ignoreActionTypes().every(fragment => !action.type.includes(fragment))
  );
}

function identifyAction(action) {
  return action.type.slice(0, -7);
}

function getSuccessType(action) {
  return `${identifyAction(action)}Success`;
}

function getFailType(action) {
  return `${identifyAction(action)}Failed`;
}

function* monitor(monitoredAction) {
  const { fail } = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction)),
  });

  if (fail && fail.payload && +fail.payload.code === 403) {
    // yield put(actions.userLogoutRequest());
  }

  if (fail && fail.payload && +fail.payload.code === 401) {
    if (fail.payload.message === 'Token not provided') {
      yield put(actions.userLogoutRequest());
    }
    if (getToken()) {
      yield put(actions.userTokenRefreshRequest());
      const { success } = yield race({
        success: take(action => actions.userTokenRefreshSuccess.match(action)),
        fail: take(action => actions.userTokenRefreshFailed.match(action)),
      });

      if (success) {
        yield put(monitoredAction);
      } else {
        yield put(actions.userLogoutRequest());
      }
    }
  }
}

function* login(action: {
  type: string;
  payload: { email: string; password: string; redirect?: string };
}) {
  try {
    const { email, password, redirect } = action.payload;
    const payload = yield call(apiRequest, {
      url: 'auth/login',
      method: 'post',
      data: {
        email,
        password,
        redirect,
      },
    });

    if (payload.data && payload.data.token) {
      yield call(setToken, payload.data.token);
      yield put(actions.loadUserDataRequest());
      yield put(actions.userLoginSuccess(payload));
      yield put(
        loggingProviderActions.pushActionToQueue({
          category: 'auth',
          action: 'login-success',
        }),
      );
    } else {
      yield put(actions.userLoginSuccess(payload));
    }
  } catch (error) {
    yield put(actions.userLoginFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* authenticate2fa(action) {
  const {
    payload: { validationToken, code },
  } = action;

  let response;
  try {
    // try to call to our loginApi() function.  Redux Saga
    // will pause here until we either are successful or
    // receive an error
    response = yield call(apiRequest, {
      url: 'auth/verify',
      method: 'post',
      data: {
        validation_token: validationToken,
        code,
      },
    });

    if (response.data && response.data.token) {
      // if token is set here - log user in
      yield call(setToken, response.data.token);
      yield put(actions.loadUserDataRequest());

      // trigger success action and set authenticated to true
      yield put(actions.userAuthenticateSuccess());
      yield put(
        loggingProviderActions.pushActionToQueue({
          category: 'auth',
          action: 'login-success',
        }),
      );
    } else {
      throw new Error('MFA did not return token');
    }
  } catch (error) {
    yield put(actions.userAuthenticate2faFailed(error.payload));
  } finally {
    if (yield cancelled()) {
      // TODO - if needed
    }
  }
}

function* resendCode(action) {
  const {
    payload: { validation_token },
  } = action;

  try {
    yield call(apiRequest, {
      url: 'auth/resend-code',
      method: 'post',
      data: {
        validation_token,
      },
    });

    yield put(actions.resendCodeSuccess());
  } catch (error) {
    yield put(actions.resendCodeFailed(error.payload));
  } finally {
    if (yield cancelled()) {
      // TODO - if needed
    }
  }
}

function* autoLogin(action: { type: string; payload: { key: string } }) {
  try {
    const { key } = action.payload;
    const payload = yield call(apiRequest, {
      url: 'auth/autologin',
      method: 'post',
      data: {
        key,
      },
    });

    if (payload.token) {
      yield call(setToken, payload.token);
    }
    yield put(actions.loadUserDataRequest());
    yield put(actions.autoLoginSuccess({}));
  } catch (error) {
    yield put(actions.autoLoginFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* logout(action) {
  try {
    yield call(apiRequest, {
      url: 'auth/logout',
      method: 'post',
    });
    yield call(setToken, undefined);
    yield put(actions.userLogoutSuccess());
  } catch (error) {
    yield put(actions.userLogoutFailed(error.payload));
    yield call(setToken, undefined);
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* forgotPassword(action: { type: string; payload: { email: string } }) {
  try {
    const { email } = action.payload;
    const payload = yield call(apiRequest, {
      url: 'auth/recovery',
      method: 'post',
      data: {
        email,
      },
    });

    yield put(actions.forgotPasswordSuccess(payload));
  } catch (error) {
    yield put(actions.forgotPasswordFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* resetPassword(action: {
  type: string;
  payload: {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
  };
}) {
  try {
    const { email, token, password, password_confirmation } = action.payload;
    const payload = yield call(apiRequest, {
      url: 'auth/reset',
      method: 'post',
      data: {
        email,
        token,
        password,
        password_confirmation,
      },
    });
    if (payload.token) {
      yield call(setToken, payload.token);
      yield put(actions.loadUserDataRequest());
    }

    yield put(actions.resetPasswordSuccess(payload));
  } catch (error) {
    yield put(actions.resetPasswordFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadUserData(action) {
  try {
    const payload = yield call(apiRequest, {
      url: 'auth/me',
      method: 'get',
    });
    yield put(actions.loadUserDataSuccess(payload));
  } catch (error) {
    yield put(actions.loadUserDataFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* tokenRefresh(action) {
  try {
    const payload = yield call(apiRequest, {
      url: 'auth/refresh',
      method: 'post',
    });
    if (payload.token) {
      yield call(setToken, payload.token);
    }
    yield put(actions.userTokenRefreshSuccess());
  } catch (error) {
    yield put(actions.userTokenRefreshFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

const sendEmailVerification = createRequestSaga({
  actionType: actions.sendEmailVerificationRequest.type,
  url: () => `send-email-verification`,
  data: d => d,
  method: 'post',
  successAction: actions.sendEmailVerificationSuccess,
  failureAction: actions.sendEmailVerificationFailed,
});

const verifyEmail = createRequestSaga({
  actionType: actions.verifyEmailRequest.type,
  url: () => `verify-email`,
  data: d => d,
  method: 'post',
  successAction: actions.verifyEmailSuccess,
  failureAction: actions.verifyEmailFailed,
});

export function* authProviderSaga() {
  yield takeEvery(monitorableAction, monitor);
  yield takeLeading(actions.userLoginRequest.type, login);
  yield takeLeading(actions.autoLoginRequest.type, autoLogin);
  yield takeLeading(actions.userTokenRefreshRequest.type, tokenRefresh);
  yield takeLeading(actions.userAuthenticate2faRequest.type, authenticate2fa);
  yield takeLatest(actions.userLogoutRequest.type, logout);
  yield takeLeading(actions.loadUserDataRequest.type, loadUserData);
  yield takeLatest(actions.forgotPasswordRequest.type, forgotPassword);
  yield takeLatest(actions.resetPasswordRequest.type, resetPassword);
  yield takeLatest(actions.resendCodeRequest.type, resendCode);

  yield takeLatest(
    actions.sendEmailVerificationRequest.type,
    sendEmailVerification,
  );
  yield takeLatest(actions.verifyEmailRequest.type, verifyEmail);
}
