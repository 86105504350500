import { IconButton, IconButtonProps } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = IconButtonProps & {
  active: boolean;
  onToggle?: () => void;
  activeIcon: ReactNode;
  inactiveIcon: ReactNode;
};

export function ToggleButton(props: Props) {
  const {
    active,
    onToggle = () => {},
    activeIcon,
    inactiveIcon,
    ...rProps
  } = props;

  return (
    <IconButton onClick={onToggle} {...rProps}>
      {active ? activeIcon : inactiveIcon}
    </IconButton>
  );
}
