/**
 *
 * Signup
 *
 */
import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface Props {
  open: boolean;
  loading: boolean;
  onDismiss: () => void;
  onAccept: () => void;

  code: string;
}

export function SignupDialog(props: Props) {
  const { open, loading, onDismiss, onAccept, code } = props;

  return (
    <>
      <Dialog open={open} fullWidth maxWidth={'md'}>
        <DialogTitle title={'Governance360 Terms'}>
          You are about to use the Governance360 portal
        </DialogTitle>
        <DialogContent>
          <Typography
            component={'div'}
            sx={{
              '& > *:not(last-child)': {
                mb: 2,
              },
            }}
          >
            <p>Just to let you know... IT'S YOUR DATA</p>
            <p>
              ALL data you upload onto the Governance360 platform, part of
              Governance360, from now on is yours. We will never share or make
              your data or information available to anyone without your explicit
              permission.
            </p>

            <Typography variant={'h3'}>
              NO LEGAL, PROFESSIONAL OR FINANCIAL ADVICE
            </Typography>

            <p>
              Governance360 is a platform that helps you strengthen your
              processes and run your company and governance more effectively. We
              are not lawyers, nor do we profess to offer legal, professional or
              financial advice.
            </p>

            <p>
              You and your colleagues need to make your own judgements on the
              suitability of the processes we provide, and any documents we have
              created to help you - always obtain your own legal or professional
              advice before acting further.
            </p>

            <p>
              For full details, visit our &nbsp;
              <a href="https://www.governance360.com/legal/terms-of-use" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
              &nbsp; and &nbsp;
              <a href="https://www.governance360.com/legal/privacy-gdpr" target="_blank" rel="noopener noreferrer">
                Privacy policy
              </a>
              . You are deemed to understand and accept these by clicking ACCEPT
              below.
            </p>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} variant="outlined" onClick={onDismiss}>
            Decline
          </Button>
          <Button disabled={loading} onClick={onAccept}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
