/**
 *
 * MemberForm
 *
 */
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { MemberForm } from './MemberForm';
import { useMemberFormWidgetSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectMemberFormWidget } from './slice/selectors';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { IMember } from 'types/types';
import { useSnackbar } from 'notistack';
import { useHasChanged } from 'utils/usePrevious';
import { useEffectOnce } from 'utils/useEffectOnce';
import { noop } from 'utils/noop';

interface Props {
  onChange?: (payload: Partial<IMember>) => void;
  onDelete?: (payload: IMember) => void;
}

export function MemberFormWidget(props: Props) {
  const { onChange = noop, onDelete } = props;
  const { actions } = useMemberFormWidgetSlice();
  const dispatch = useDispatch();
  const {
    form: { open, loading, id, roles, committees, organisation_id },
    loadMember,
    saveMember,
  } = useSelector(selectMemberFormWidget);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const load = useCallback(() => {
    if (id) {
      dispatch(actions.loadMemberRequest({ id }));
    }
  }, [id]);

  const snackbar = useSnackbar();
  const savingChanged = useHasChanged(saveMember.loading);

  useEffect(() => {
    if (savingChanged && !saveMember.loading) {
      if (!saveMember.loading && saveMember.error) {
        snackbar.enqueueSnackbar('Error saving.', { variant: 'error' });
      } else {
        onChange(saveMember.data);
        snackbar.enqueueSnackbar('Saved.', { variant: 'success' });
        close();
      }
    }
  });

  useEffect(() => {
    if (open && id) load();
  }, [open]);

  useEffect(
    () => () => {
      dispatch(actions.close());
    },
    [dispatch],
  );

  useEffectOnce(() => {
    snackbar.closeSnackbar();
    return () => {
      snackbar.closeSnackbar();
    };
  });

  const close = () => dispatch(actions.close());

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      fullWidth
      fullScreen={fullScreen}
      scroll={'body'}
      onClose={close}
    >
      {loading || loadMember.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <MemberForm
            onDelete={onDelete}
            onClose={close}
            member={loadMember.data}
            roles={roles}
            committees={committees}
            onSave={payload =>
              dispatch(
                actions.saveMemberRequest({ organisation_id, ...payload }),
              )
            }
            saving={saveMember.loading}
          />
        </>
      )}
    </Dialog>
  );
}

export function useMemberFormWidget() {
  const { actions } = useMemberFormWidgetSlice();
  const dispatch = useDispatch();
  const memberForm = useSelector(selectMemberFormWidget);

  const open = payload => {
    dispatch(actions.openRequest(payload));
  };
  const close = () => dispatch(actions.close());

  return { open, close, isOpen: memberForm.form.open };
}
