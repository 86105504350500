/**
 *
 * CommitteeForm
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  RiCheckFill,
  RiCloseLine,
  RiDeleteBackLine,
  RiInformationLine,
  RiNewspaperLine,
} from 'react-icons/ri';

import { IconTitledContent } from 'app/components/IconTitledContent';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { ICommittee } from 'types/types';
import * as Yup from 'yup';
import { CancelAndSaveButtons } from 'app/components/CancelAndSaveButtons';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';

interface Props {
  onClose: () => void;
  committee: ICommittee | undefined;
  onSave: (payload: Partial<ICommittee>) => void;
  onDelete?: (payload: ICommittee) => void;
  saving: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  description: Yup.string(),
});

export function CommitteeForm(props: Props) {
  const { committee, onSave, onClose, onDelete, saving } = props;

  return (
    <Formik
      validationSchema={schema}
      initialValues={
        committee ||
        ({
          id: undefined,
          name: '',
          description: '',
        } as Partial<ICommittee>)
      }
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave(values);
      }}
    >
      {formik => {
        const { setSubmitting, errors, isSubmitting } = formik;
        return (
          <Form>
            <DialogTitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>
                  {committee ? (
                    <> Editing {committee.name}</>
                  ) : (
                    'Create committee'
                  )}
                </Box>

                <IconButton onClick={onClose} edge={'end'} size={'large'}>
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Stack spacing={3} sx={{ mb: 3 }}>
                  <Box>
                    <Typography variant={'h3'}>Committee details</Typography>
                    <RequiredFieldIndicator />
                  </Box>
                  <IconTitledContent
                    width={3}
                    icon={<RiNewspaperLine />}
                    title={'Committee name*'}
                  >
                    <Field
                      component={TextField}
                      id="name"
                      name="name"
                      label="Committee name"
                      fullWidth
                    />
                  </IconTitledContent>
                  <IconTitledContent
                    width={3}
                    icon={<RiInformationLine />}
                    title={'Description'}
                  >
                    <Field
                      component={TextField}
                      id="description"
                      name="description"
                      multiline
                      rows={4}
                      label="Description"
                      fullWidth
                    />
                  </IconTitledContent>
                </Stack>
              </Box>
            </DialogContent>

            <DialogActions sx={{}}>
              {!!(committee && onDelete) && (
                <Button
                  variant={'text'}
                  color={'error'}
                  startIcon={<RiDeleteBackLine />}
                  disabled={saving || isSubmitting}
                  sx={{ mr: 'auto' }}
                  onClick={() => onDelete(committee)}
                >
                  Delete committee
                </Button>
              )}
              <CancelAndSaveButtons
                disabled={saving || isSubmitting}
                onCancel={onClose}
                saveIcon={<RiCheckFill />}
              />
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
