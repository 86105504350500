import {
  Avatar,
  Box,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { getInitials } from 'utils/initials';
import { IconTitledContent } from 'app/components/IconTitledContent';
import { SecureLinkify } from 'app/components/SecureLinkify';
import React, { useState } from 'react';
import { IOrganisation, IPartner } from 'types/types';
import { SectionHeader } from 'app/components/SectionHeader';

interface Props {
  partner: IPartner;
  organisation: Partial<IOrganisation>;
}

export function PartnerDetailsBlock(props: Props) {
  const { partner, organisation } = props;

  return (
    <>
      <SectionHeader title="Partner details" />
      <Container>
        <Box sx={{ my: 4 }}>
          <Stack spacing={4}>
            <Typography>Governance360 provided by</Typography>

            <Stack direction={'row'} spacing={3} alignItems={'center'}>
              <Box>
                <Avatar
                  variant="rounded"
                  sx={{
                    color: 'white',
                    backgroundColor: partner.logo_image
                      ? 'white'
                      : 'secondary.main',
                    width: 80,
                    height: 80,
                  }}
                  src={partner.logo_image}
                  alt={partner.name}
                >
                  {getInitials(partner.name)}
                </Avatar>
              </Box>
              <Typography variant={'h3'}> {partner.name}</Typography>
            </Stack>

            <Typography>Current plan: {organisation.plan_name}</Typography>

            <Divider />

            {!!partner.contact_details && (
              <Box sx={{ typography: 'body1', minHeight: '300px' }}>
                <Stack spacing={3}>
                  <Typography>
                    Get in touch with {partner.name} using the information
                    below.
                  </Typography>
                  <IconTitledContent title={'Email'} sx={{ my: 2 }}>
                    <Stack direction={'row'}>
                      <Typography variant={'body1'}>
                        {partner.contact_details.contact_name} |
                        <SecureLinkify>
                          {'  '} {partner.contact_details.contact_email}
                        </SecureLinkify>
                      </Typography>
                    </Stack>
                  </IconTitledContent>
                  <IconTitledContent
                    title={'Telephone'}
                    sx={{ my: 2 }}
                    visible={!!partner.contact_details.telephone}
                  >
                    <Stack direction={'row'}>
                      <Typography variant={'body1'}>
                        {partner.contact_details.telephone} {'  '}
                      </Typography>
                    </Stack>
                  </IconTitledContent>
                  <IconTitledContent
                    title={'Website'}
                    sx={{ my: 2 }}
                    visible={!!partner.contact_details.website}
                  >
                    <Stack direction={'row'}>
                      <Typography variant={'body1'}>
                        <SecureLinkify>
                          {partner.contact_details.website}
                        </SecureLinkify>
                      </Typography>
                    </Stack>
                  </IconTitledContent>
                  <IconTitledContent title={'Address'} sx={{ my: 2 }}>
                    <Stack direction={'column'} spacing={1}>
                      <Typography variant={'body1'}>
                        {[
                          partner.contact_details.premises,
                          partner.contact_details.address_line_1,
                          partner.contact_details.address_line_2,
                          partner.contact_details.locality,
                          partner.contact_details.region,
                          partner.contact_details.country,
                          partner.contact_details.postal_code,
                        ]
                          .filter(a => !!a)
                          .join(', ')}
                      </Typography>
                    </Stack>
                  </IconTitledContent>
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>
      </Container>
    </>
  );
}
