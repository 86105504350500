import { IconButton, Menu, MenuItem, MenuItemProps } from '@mui/material';
import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import key from 'weak-key';

export const RolloverMenu = (props: { options: MenuItemProps[] }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { options } = props;

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      onMouseLeave={() => {
        handleClose();
      }}
    >
      <IconButton
        aria-label="See more options"
        aria-controls="context-menu"
        aria-haspopup="true"
        onClick={handleIconClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="context-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {options.map(o => (
          <MenuItem {...o} key={key(o)} />
        ))}
      </Menu>
    </div>
  );
};
