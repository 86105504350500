import React from 'react';
import { Field } from 'formik';
import { Autocomplete, TextField } from 'formik-mui';
import {
  AutocompleteProps,
  Box,
  Typography,
  TextFieldProps,
} from '@mui/material';
import key from 'weak-key';

type Props = (
  | ({ type?: 'textfield' } & Partial<TextFieldProps>)
  | ({ type?: 'autocomplete' } & Partial<AutocompleteProps<any, any, any, any>>)
) & {
  isEdit: boolean;
  text: string;
  name: string;
  bold?: boolean;
};

export function EditableField(props: Props) {
  const {
    type = 'textfield',
    isEdit,
    name,
    text,
    bold = false,
    sx = {},
    ...rProps
  } = props;
  return (
    <Box sx={{ py: 1 }}>
      {isEdit ? (
        <Field
          fullWidth
          component={type === 'textfield' ? TextField : Autocomplete}
          name={name}
          variant="outlined"
          sx={{ background: 'white', ...sx }}
          {...rProps}
        />
      ) : (
        <Box sx={{ py: 1 }}>
          <Typography
            fontWeight={bold && 'bold'}
            variant="body2"
            sx={{ mb: 0 }}
          >
            {(text || '').split('\n').map((item, i) => (
              <React.Fragment key={key({ item, i })}>
                {item}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
