import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.documents || initialState;

export const selectDocuments = createSelector([selectSlice], state => state);
export const selectLoadDocuments = createSelector(
  [selectSlice],
  state => state.loadDocuments,
);
