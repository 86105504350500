import { Chip } from '@mui/material';
import React from 'react';
import { Feature } from 'types/types';

export const StatusChip = ({ feature }: { feature: Feature }) => {
  let color: 'success' | 'warning' | 'error' = 'success';
  let text: string = 'Included in plan';

  if (feature.activated) text = 'Active';

  if (feature.status === 'trial') {
    if (feature.trial_settings.status === 'expired') {
      color = 'error';
      text = 'Trial expired';
    } else {
      if (feature.trial_settings.status === 'unused') {
        color = 'warning';
        text = `${feature.trial_settings.length} day trial available`;
      } else {
        color = 'warning';
        text = `Trial - ${feature.trial_settings.time_remaining} days left`;
      }
    }
  }
  return <Chip color={color} label={text} />;
};
