/**
 *
 * SetupMfa
 *
 */
import React, { useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useEffectOnce } from 'utils/useEffectOnce';
import { LoadingIndicator } from '../LoadingIndicator';
import { RiMailFill } from 'react-icons/ri';
import { hideEmail } from 'utils/hideChars';
import { ResendPasscode } from '../ResendPasscode';
import { BottomLinks } from './BottomLinks';
import { AuthInputField } from '../AuthInputField';

interface Props {
  getSetupData: () => void;
  setupLoading: boolean;
  setupLoaded: boolean;
  onConfirm: (code: string) => void;
  email: string;
}

export function EmailForm(props: Props) {
  const { getSetupData, onConfirm, email, setupLoaded, setupLoading } = props;
  const [code, setCode] = useState<string>('');

  useEffectOnce(() => {
    getSetupData();
  });

  if (setupLoading) {
    return <LoadingIndicator minHeight={200} />;
  }

  if (!setupLoaded) return null;

  return (
    <Box>
      <Stack alignItems={'center'} textAlign={'center'} spacing={3}>
        <Box color={'primary.main'}>
          <RiMailFill color="primary.main" fontSize={30} />
        </Box>
        <Typography variant="h3" color={'primary'}>
          Enable 2-factor authentication
        </Typography>
        <Typography variant="body1">
          To enable 2-factor authentication via your email address, enter the
          passcode sent to:{' '}
          <Typography fontWeight={'bold'} display={'inline'} component={'span'}>
            {hideEmail(email)}
          </Typography>
        </Typography>
        <Box width={'100%'}>
          <AuthInputField
            codeValue={code}
            onChange={v => setCode(v)}
            onEnter={() => onConfirm(code)}
          />
        </Box>

        <Button
          fullWidth
          disabled={code.length < 6}
          onClick={() => onConfirm(code)}
        >
          Verify passcode
        </Button>
        <BottomLinks />
        <ResendPasscode
          message={`Send via email to ${hideEmail(email)}`}
          onChange={() => getSetupData()}
          loading={setupLoading}
        />
      </Stack>
    </Box>
  );
}
