import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { documentFormWidgetSaga } from './saga';
import { DocumentFormWidgetState } from './types';
import {
  getApiCallInitialState,
  uploadApiCallInitialState,
} from 'types/ApiCall';
import { DocumentParentEntity, IDocument } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: DocumentFormWidgetState = {
  form: {
    open: false,
    loading: false,
  },
  loadDocument: getApiCallInitialState<IDocument>(),
  saveDocument: uploadApiCallInitialState,
  deleteDocument: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'documentFormWidget',
  initialState,
  reducers: {
    openRequest(
      state,
      action: PayloadAction<{
        id?: number;
        parent_entity_id?: number | string;
        parent_entity_type?: DocumentParentEntity;
      }>,
    ) {
      state.form.id = action.payload.id;
      state.form.open = true;
      state.form.loading = true;
      state.form.parent_entity_id = action.payload.parent_entity_id;
      state.form.parent_entity_type = action.payload.parent_entity_type;
    },
    openSuccess(state, action: PayloadAction<{}>) {
      state.form.loading = false;
    },
    openFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.form = { ...initialState.form, error: action.payload };
    },
    close(state, action: PayloadAction<void>) {
      state.form = initialState.form;
      state.loadDocument = initialState.loadDocument;
      state.saveDocument = initialState.saveDocument;
    },
    loadDocumentRequest(state, action: PayloadAction<{ id: number | string }>) {
      state.loadDocument.loading = true;
      state.loadDocument.error = undefined;
    },
    loadDocumentSuccess(state, action: PayloadAction<{ data: IDocument }>) {
      state.loadDocument.loading = false;
      state.loadDocument.error = undefined;
      state.loadDocument.data = action.payload.data;
    },
    loadDocumentFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadDocument.loading = false;
      state.loadDocument.error = action.payload;
    },
    saveDocumentRequest(state, action: PayloadAction<Partial<IDocument>>) {
      state.saveDocument.loading = true;
      state.saveDocument.error = undefined;
    },
    saveDocumentProgress(state, action: PayloadAction<{ progress: number }>) {
      state.saveDocument.progress = action.payload.progress;
    },
    saveDocumentSuccess(state, action: PayloadAction<{ data: IDocument }>) {
      state.saveDocument.loading = false;
      state.saveDocument.error = undefined;
      state.saveDocument.data = action.payload.data;
    },
    saveDocumentFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.saveDocument.loading = false;
      state.saveDocument.error = action.payload;
    },
    deleteDocumentRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.deleteDocument.loading = true;
      state.deleteDocument.error = undefined;
    },
    deleteDocumentSuccess(state, action: PayloadAction<any>) {
      state.deleteDocument.loading = false;
      state.deleteDocument.error = undefined;
      state.deleteDocument.data = action.payload.data;
    },
    deleteDocumentFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.deleteDocument.loading = false;
      state.deleteDocument.error = action.payload;
    },
  },
});

export const { actions: documentFormWidgetActions } = slice;
