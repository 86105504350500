import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { trainingRecordSaga } from './saga';
import { TrainingRecordState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import { ITrainingRecordEntry } from 'types/types';

export const initialState: TrainingRecordState = {
  trainingRecord: getApiCallInitialState<ITrainingRecordEntry>('list'),
};

const slice = createSlice({
  name: 'trainingRecord',
  initialState,
  reducers: {
    loadRecordRequest(state, action: PayloadAction<void>) {
      state.trainingRecord = {
        ...state.trainingRecord,
        loading: true,
        error: undefined,
      };
    },
    loadRecordSuccess(state, action: PayloadAction<any>) {
      state.trainingRecord.data = action.payload;
      state.trainingRecord.error = undefined;
      state.trainingRecord.loading = false;
    },
    loadRecordFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.trainingRecord.loading = false;
      state.trainingRecord.error = action.payload;
    },
  },
});

export const { actions: trainingRecordActions } = slice;

export const useTrainingRecordSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: trainingRecordSaga });
  return { actions: slice.actions };
};
