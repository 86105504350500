/**
 *
 * useHasPermission
 *
 */

import { useSelector } from 'react-redux';
import { selectAuthProvider } from './slice/selectors';
import { AccessCommittee } from 'types/types';

export function useHasPermission() {
  const { loadUser } = useSelector(selectAuthProvider);

  const hasPermission = (
    code: string,
    context: 'root' | 'organisation' | 'committee' = 'root',
    context_identifier: number | string = null,
  ) => {
    if (!loadUser.data) return false;
    const { access_organisations, permissions } = loadUser.data;

    if (context === 'root') {
      return permissions.indexOf(code) > -1;
    }

    if (context === 'organisation') {
      const organisation = access_organisations.find(
        o => +o.id === +context_identifier,
      );
      if (!organisation) return false;

      return organisation.permissions.indexOf(code) > -1;
    }

    if (context === 'committee') {
      const committee = access_organisations.reduce((acc, organisation) => {
        if (!acc) {
          return organisation.committees.find(
            c => +c.id === +context_identifier,
          );
        }
        return acc;
      }, null as AccessCommittee | null);
      if (!committee) return false;
      return committee.permissions.indexOf(code) > -1;
    }
  };

  return { hasPermission };
}
