/**
 *
 * Committees
 *
 */
import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { List } from './List';
import { Item } from './Item';
import { IOrganisation } from 'types/types';
import { Helmet } from 'react-helmet-async';
import { SectionHeader } from 'app/components/SectionHeader';
import { Container } from '@mui/material';
import { SectionMenu } from 'app/components/SectionMenu';

interface Props {
  organisation: IOrganisation;
}

export function Committees(props: Props) {
  const { organisation } = props;
  const location = useLocation();
  if (!organisation) return null;
  return (
    <>
      <Helmet title={'My committees'}>
        <meta name="description" content="Committees within the organisation" />
      </Helmet>
      <SectionHeader title="Committees" />
      <Container>
        <Routes>
          <Route path={''} element={<List organisation={organisation} />} />
          <Route
            path={':id/*'}
            element={<Item organisation={organisation} />}
          />
        </Routes>
      </Container>
    </>
  );
}
