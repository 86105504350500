import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ReportsPageState, ReportTable } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';

const reportInitialState: ReportTable = {
  columns: [],
  rows: [],
};

export const initialState: ReportsPageState = {
  loadDocuments: getApiCallInitialState<ReportTable>(
    'item',
    reportInitialState,
  ),
  loadConflicts: getApiCallInitialState<ReportTable>(
    'item',
    reportInitialState,
  ),
  loadMembers: getApiCallInitialState<ReportTable>('item', reportInitialState),
  loadMemberUsersSecurity: getApiCallInitialState<ReportTable>(
    'item',
    reportInitialState,
  ),
  loadMembersInCommittees: getApiCallInitialState<ReportTable>(
    'item',
    reportInitialState,
  ),
};

export const slice = createSlice({
  name: 'reportsPage',
  initialState,
  reducers: {
    loadDocumentsRequest(
      state,
      action: PayloadAction<{ organisation_id: number; [key: string]: any }>,
    ) {
      state.loadDocuments.loading = true;
    },
    loadDocumentsSuccess(state, action: PayloadAction<{ data: ReportTable }>) {
      state.loadDocuments.loading = false;
      state.loadDocuments.data = action.payload.data;
    },
    loadDocumentsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadDocuments.loading = false;
      state.loadDocuments.error = action.payload;
    },
    loadConflictsRequest(
      state,
      action: PayloadAction<{ organisation_id: number; [key: string]: any }>,
    ) {
      state.loadConflicts.loading = true;
    },
    loadConflictsSuccess(state, action: PayloadAction<{ data: ReportTable }>) {
      state.loadConflicts.loading = false;
      state.loadConflicts.data = action.payload.data;
    },
    loadConflictsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadConflicts.loading = false;
      state.loadConflicts.error = action.payload;
    },
    loadMembersRequest(
      state,
      action: PayloadAction<{ organisation_id: number; [key: string]: any }>,
    ) {
      state.loadMembers.loading = true;
    },
    loadMembersSuccess(state, action: PayloadAction<{ data: ReportTable }>) {
      state.loadMembers.loading = false;
      state.loadMembers.data = action.payload.data;
    },
    loadMembersFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadMembers.loading = false;
      state.loadMembers.error = action.payload;
    },
    loadMemberUsersSecurityRequest(
      state,
      action: PayloadAction<{ organisation_id: number; [key: string]: any }>,
    ) {
      state.loadMemberUsersSecurity.loading = true;
    },
    loadMemberUsersSecuritySuccess(
      state,
      action: PayloadAction<{ data: ReportTable }>,
    ) {
      state.loadMemberUsersSecurity.loading = false;
      state.loadMemberUsersSecurity.data = action.payload.data;
    },
    loadMemberUsersSecurityFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadMemberUsersSecurity.loading = false;
      state.loadMemberUsersSecurity.error = action.payload;
    },
    loadMembersInCommitteesRequest(
      state,
      action: PayloadAction<{ organisation_id: number; [key: string]: any }>,
    ) {
      state.loadMembersInCommittees.loading = true;
    },
    loadMembersInCommitteesSuccess(
      state,
      action: PayloadAction<{ data: ReportTable }>,
    ) {
      state.loadMembersInCommittees.loading = false;
      state.loadMembersInCommittees.data = action.payload.data;
    },
    loadMembersInCommitteesFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadMembersInCommittees.loading = false;
      state.loadMembersInCommittees.error = action.payload;
    },
  },
});

export const { actions: reportsPageActions } = slice;
