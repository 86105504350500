/**
 *
 * VerifyEmail
 *
 */
import * as React from 'react';
import { Button, Container, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useVerifyEmailSlice } from './slice/hook';
import { IOrganisation } from 'types/types';
import { selectVerifyEmail } from './slice/selectors';
import { Helmet } from 'react-helmet-async';
import { SectionHeader } from 'app/components/SectionHeader';
import { useParams } from 'react-router';
import { useQuery } from '../../../utils/useQuery';
import { useEffect, useMemo } from 'react';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import { AuthPageContainer } from '../../components/AuthPageContainer';
import { Link } from 'react-router-dom';

interface Props {}

export function VerifyEmail(props: Props) {
  const { verify } = useSelector(selectVerifyEmail);
  const { actions } = useVerifyEmailSlice();
  const dispatch = useDispatch();

  const {
    query: { token, id },
  } = useQuery();

  useEffect(() => {
    if (token && id && !verify.touched)
      dispatch(actions.verifyRequest({ token, id: +id }));
  }, [token, id]);

  useHandleApiResponse(verify, null, { errorMessage: null });

  const content = useMemo(() => {
    if (verify.loading) return <LoadingIndicator />;
    if (verify.data)
      return (
        <Typography>Thank you for verifying your email address</Typography>
      );
    return <Typography color={'error'}>Something went wrong</Typography>;
  }, [verify]);
  return (
    <>
      <Helmet title={'Verify email address'}>
        <meta name="description" content="Verify email" />
      </Helmet>
      <AuthPageContainer>
        <Stack spacing={4} textAlign={'center'}>
          <Typography variant="h4">Verify email address</Typography>
          {content}
          <Button component={Link} to={'/'} variant={'text'}>
            Click here to return to the portal
          </Button>
        </Stack>
      </AuthPageContainer>
    </>
  );
}
