import { Box, FormControl, Grid, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { TitledField } from '../TitledField';
import { RequiredFieldIndicator } from '../RequiredFieldIndicator';
import FileUpload from '../FileUpload';
import { Field, FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';

interface Props {
  title?: string;
  fileError?: ReactNode;
  touchedFile: any;
  errorFile: any;
}

export function DocumentUploadAndTitleContent(props: Props) {
  const {
    title = 'Document details',
    fileError,
    errorFile,
    touchedFile,
  } = props;

  const { touched, errors } = useFormikContext();

  return (
    <>
      <TitledField
        variant="h3"
        title={title}
        helperText="Uploading a document will
                            automatically fill in the name field. However, you can change to your own if desired"
      >
        <RequiredFieldIndicator />
      </TitledField>
      <Box>
        <Grid container>
          <Grid item xs={12} md={6}>
            <TitledField title="Document upload *">
              <FormControl>
                <FileUpload
                  name={'file'}
                  label="Select a document to upload"
                  allowRemove={false}
                />
                {touchedFile && errorFile && (
                  <Typography
                    color="error"
                    variant="body2"
                    sx={{ pl: 1, fontSize: 12 }}
                  >
                    {String(errorFile)}
                  </Typography>
                )}
              </FormControl>
            </TitledField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TitledField title="Document name *">
              <Field component={TextField} name="name" label="Name" fullWidth />
            </TitledField>
          </Grid>
        </Grid>
      </Box>

      <TitledField title="Description">
        <Field
          component={TextField}
          name="description"
          label="Description"
          fullWidth
        />
      </TitledField>
    </>
  );
}
