import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { RiskLevelSelector } from '../../RiskLevelSelector';
import { HelpModal } from 'app/components/HelpModal';

export function Likelihood() {
  return (
    <>
      <Box>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography variant="h1">Likelihood</Typography>
          <HelpModal
            iconTitle="Find more about risk likelihood"
            name="score_likelihood"
          />
        </Stack>
      </Box>
      <Box>
        <Stack spacing={2}>
          <Typography variant="body2">
            Likelihood (sometimes called probability) is a risk that an event
            "may" occur. There are many ways to estimate likelihood – in our
            feature, we suggest you ‘score’ the likelihood of it occurring from
            1 (remote) to 5 (highly probable).
          </Typography>
          <RiskLevelSelector name="score_likelihood" fullWidth={true} />
        </Stack>
      </Box>
    </>
  );
}
