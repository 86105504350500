import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

export function useQuery(): {
  query: { [key: string]: any };
  setQuery: Function;
} {
  const location = useLocation();
  const navigate = useNavigate();
  const query = queryString.parse(location.search);

  Object.keys(query || {}).forEach(k => {
    let q = query[k];
    try {
      // @ts-ignore
      q = JSON.parse(q || '{}');
    } catch (e) {}
    query[k] = q;
  });

  const setQuery = (key, value) => {
    query[key] = value;

    Object.keys(query || {}).forEach(k => {
      let q = query[k];
      try {
        if (typeof q === 'object' && (Object.keys(q).length || q.length)) {
          q = JSON.stringify(q || {});
        } else q = null;
      } catch (e) {}
      if (q) {
        query[k] = q;
      } else {
        delete query[k];
      }
    });

    const search = !Object.keys(query).length
      ? ''
      : '?' +
        queryString.stringify(query, {
          encode: false,
          strict: false,
          arrayFormat: 'none',
        });

    navigate(
      {
        pathname: location.pathname,
        search,
      },
      { replace: true },
    );
  };

  return { query, setQuery };
}
