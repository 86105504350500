import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.conflictFormWidget || initialState;

export const selectConflictFormWidget = createSelector(
  [selectSlice],
  state => state,
);

export const selectConflictFormWidgetSettings = createSelector(
  [selectSlice],
  state => state.form,
);
