import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { DateFieldWrapper } from 'app/components/DateFieldWrapper';
import { FormikMultiSelectCheckboxes } from 'app/components/FormikMultiSelectCheckboxes';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import { OrganisationPicker } from 'types/types';

interface Props {
  previous: () => void;
  proceed: () => void;
  organisations?: OrganisationPicker[];
}

export function SummaryOfInterest(props: Props) {
  const { previous, proceed, organisations } = props;

  const { isValid } = useFormikContext();

  return (
    <>
      <Box>
        <Stack spacing={4}>
          <Box>
            <Stack>
              <Box>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography fontWeight={'bold'}>
                    What is the Declaration of Interest you wish to add to your
                    record?
                  </Typography>
                </Stack>
              </Box>
              <Field
                name="description"
                component={TextField}
                label="Enter your Declaration"
                multiline
                rows={4}
              />
            </Stack>
          </Box>
          <Box>
            <Stack spacing={1}>
              <Box>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography fontWeight={'bold'}>
                    Please confirm which of the Boards using Governance360 this
                    Declaration should apply to (you may only see one choice
                    here for reference)
                  </Typography>
                </Stack>
              </Box>
              <Field
                id="organisation_ids"
                fullWidth
                component={FormikMultiSelectCheckboxes}
                name="organisation_ids"
                label="Organisations"
                multiple
                options={organisations.map(o => ({
                  ...o,
                  label: o.name,
                }))}
              />
            </Stack>
          </Box>
          <Box>
            <Stack>
              <Box>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography fontWeight={'bold'}>
                    When did the Interest commence?
                  </Typography>
                </Stack>
                <Typography variant="body2">
                  Ideally this lists the moment that the proposed or actual
                  transaction occurred (for example you bought shares, etc). Or
                  the date you joined the Organisation. For guidance, we suggest
                  you refer to your Board Administrator or Board Chair for more.
                </Typography>
              </Box>
              <DateFieldWrapper name="date_start" format={'DD/MM/YYYY'} />
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              startIcon="<"
              variant="outlined"
              fullWidth
              onClick={() => previous()}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              endIcon=">"
              fullWidth
              onClick={() => proceed()}
              disabled={!isValid}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
