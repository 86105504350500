import React from 'react';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';
import { IActionSubtaskWithStatus } from 'types/types';
import { OnClickAction } from 'utils/useOnlickActions';

interface Props {
  subtasks: IActionSubtaskWithStatus[];
  onCheck: (subtask_id: number, checked: boolean) => void;
  onclickAction: (OnClickAction) => void;
}

export function DashboardActionSubtasks(props: Props) {
  const theme = useTheme();
  const { subtasks, onCheck, onclickAction } = props;

  return (
    <Box>
      <Stack>
        {subtasks.map(item => (
          <Box key={item.id}>
            <Stack direction={'row'} spacing={1}>
              <IconButton
                onClick={() => onCheck(item.id, item.status === 'pending')}
                size={'small'}
                sx={{ alignItems: 'start', p: 0 }}
              >
                {item.status === 'completed' ? (
                  <RiCheckboxFill color={theme.palette.primary.main} />
                ) : (
                  <RiCheckboxBlankLine color={theme.palette.grey['500']} />
                )}
              </IconButton>
              {!!item.onclick ? (
                <>
                  <Button
                    variant="text"
                    sx={{ p: 0, textAlign: 'start' }}
                    onClick={() =>
                      onclickAction({
                        id: item.onclick,
                        params: item.onclick_parameters,
                      } as OnClickAction)
                    }
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Button>
                </>
              ) : (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {item.name}
                  </Typography>
                </>
              )}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
