import { takeLatest } from 'redux-saga/effects';
import { riskRegisterSectionActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadOrganisationRiskRegister = createRequestSaga({
  actionType: actions.loadOrganisationRiskRegisterRequest.type,
  url: () => 'risk-registers/organisation-risk-register',
  data: d => d,
  successAction: actions.loadOrganisationRiskRegisterSuccess,
  failureAction: actions.loadOrganisationRiskRegisterFailed,
});

const getRiskSuggestions = createRequestSaga({
  actionType: actions.getRiskSuggestionsRequest.type,
  url: () => 'risk-registers/risk-suggestions',
  data: d => d,
  successAction: actions.getRiskSuggestionsSuccess,
  failureAction: actions.getRiskSuggestionsFailed,
});

const getMemberNames = createRequestSaga({
  actionType: actions.getMemberNamesRequest.type,
  url: () => 'risk-registers/member-names',
  data: d => d,
  successAction: actions.getMemberNamesSuccess,
  failureAction: actions.getMemberNamesFailed,
});

const upsertRiskRegisterItem = createRequestSaga({
  actionType: actions.upsertRiskRegisterItemRequest.type,
  url: () => 'risk-registers/risk-register-item',
  method: 'post',
  data: d => d,
  successAction: actions.upsertRiskRegisterItemSuccess,
  failureAction: actions.upsertRiskRegisterItemFailed,
});

const updateOrganisationRiskRegister = createRequestSaga({
  actionType: actions.updateOrganisationRiskRegisterRequest.type,
  url: () => 'risk-registers/organisation-risk-register',
  method: 'post',
  data: d => d,
  successAction: actions.updateOrganisationRiskRegisterSuccess,
  failureAction: actions.updateOrganisationRiskRegisterFailed,
});

export function* riskRegisterSectionSaga() {
  yield takeLatest(
    actions.loadOrganisationRiskRegisterRequest.type,
    loadOrganisationRiskRegister,
  );
  yield takeLatest(actions.getRiskSuggestionsRequest.type, getRiskSuggestions);
  yield takeLatest(actions.getMemberNamesRequest.type, getMemberNames);
  yield takeLatest(
    actions.upsertRiskRegisterItemRequest.type,
    upsertRiskRegisterItem,
  );
  yield takeLatest(
    actions.updateOrganisationRiskRegisterRequest.type,
    updateOrganisationRiskRegister,
  );
}
