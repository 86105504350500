import { takeLatest } from 'redux-saga/effects';
import { committeesActions as actions } from './';
import { createRequestSaga } from 'utils/saga';

const loadCommittees = createRequestSaga({
  actionType: actions.loadCommitteesRequest.type,
  url: `committees`,
  data: d => d,
  successAction: actions.loadCommitteesSuccess,
  failureAction: actions.loadCommitteesFailed,
});

const loadCommittee = createRequestSaga({
  actionType: actions.loadCommitteeRequest.type,
  url: p => `committees/${p.id}`,
  successAction: actions.loadCommitteeSuccess,
  failureAction: actions.loadCommitteeFailed,
});

const deleteCommittee = createRequestSaga({
  actionType: actions.deleteCommitteeRequest.type,
  url: p => `committees/${p.id}`,
  method: 'delete',
  successAction: actions.deleteCommitteeSuccess,
  failureAction: actions.deleteCommitteeFailed,
});

export function* committeesSaga() {
  yield takeLatest(actions.loadCommitteesRequest.type, loadCommittees);
  yield takeLatest(actions.loadCommitteeRequest.type, loadCommittee);
  yield takeLatest(actions.deleteCommitteeRequest.type, deleteCommittee);
}
