import { Stack, Typography, Button, Box } from '@mui/material';
import React, { useState } from 'react';
import { PhoneAuthIcon } from '../assets/PhoneAuthIcon';
import { RiLockUnlockLine, RiMailFill, RiMessage2Fill } from 'react-icons/ri';
import { RadioButton } from '../RadioButton';
import { Link } from 'react-router-dom';

type MFAMethod = 'sms' | 'auth-app' | 'email' | 'remove';

interface Props {
  onConfirm: (method: MFAMethod) => void;
  mfaEnabled: boolean;
  cancelPath?: string;
  helpPath?: string;
}

export function MFAOptions(props: Props) {
  const { onConfirm, mfaEnabled, cancelPath = '/', helpPath = '/' } = props;
  const [method, setMethod] = useState<MFAMethod | undefined>(undefined);

  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      spacing={3}
      justifyContent={'center'}
      textAlign={'center'}
    >
      <Typography variant="h3" color={'primary.main'}>
        {mfaEnabled ? 'Change or remove' : 'Enable'} your 2-factor
        authentication
      </Typography>
      <Typography variant="body1" textAlign={'center'}>
        Choose a different method for your 2-factor authentication. This should
        be something you will always have with you when you log in to Governance
        360.
      </Typography>
      {mfaEnabled && (
        <Typography variant="body1" textAlign={'center'}>
          Alternatively, you can choose to remove 2FA.
        </Typography>
      )}

      <Stack spacing={2} width={'100%'}>
        <RadioButton
          onChange={() => {
            setMethod('auth-app');
          }}
          checked={method === 'auth-app'}
          label="Authenticator App"
          icon={<PhoneAuthIcon />}
        />
        <RadioButton
          onChange={() => {
            setMethod('email');
          }}
          checked={method === 'email'}
          label="Email"
          icon={<RiMailFill />}
        />
        <RadioButton
          onChange={() => {
            setMethod('sms');
          }}
          checked={method === 'sms'}
          label="SMS / Text message"
          icon={<RiMessage2Fill />}
        />
        {mfaEnabled && (
          <Stack marginTop={3} spacing={1}>
            <Typography variant="body1">
              To remove 2-factor authentication, select the below option:
            </Typography>
            <RadioButton
              checked={method === 'remove'}
              onChange={() => setMethod('remove')}
              icon={<RiLockUnlockLine />}
              label="Remove 2-factor authentication"
              color="error.main"
            />
          </Stack>
        )}
      </Stack>
      <Button
        fullWidth
        size="medium"
        disabled={!method}
        onClick={() => onConfirm(method)}
      >
        Continue
      </Button>

      <Button component={Link} size="small" variant="text" to={cancelPath}>
        Cancel 2-factor authentication changes
      </Button>
      {/* <Button component={Link} variant="text" to={'../'}>
        Get help
      </Button> */}
    </Stack>
  );
}
