import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { TitleAndParagraph } from '../TitleAndParagraph';

export function RiskTips() {
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Stack spacing={4}>
          <TitleAndParagraph
            title={`How many risks should be on a risk register?`}
            paragraph={`    It is all too easy to get overwhelmed when thinking about whether to
            include a new risk. Don’t be! Concentrate on those with the greatest
            potential impact to you and your Organisation. There are no hard or
            fast rules as to how many is ‘good’, but in our experience, if you
            have more than twenty (20) or so key, strategic risks, then you be
            stretched to find a way to manage them all. Clearly this will depend
            on how large, complex or risk averse you and your Organisation are,
            but if in doubt focus on those with largest potential impact.`}
          />
          <TitleAndParagraph
            title={`Keep the description as simple as possible`}
            paragraph={`The risk description should make it easy for anyone to understand
            the most critical details of each risk. Be as thorough as possible,
            but try to keep the risk description limited to only the essentials
            as you see them. That will help prevent the risk seem overwhelming,
            and hence drive better thinking about the controls you will need to
            manage it.`}
          />
          <TitleAndParagraph
            title={`Don’t overthink the risk and its description or rating`}
            paragraph={`If in doubt, start... View your register as something living and
            dynamic. While you do not want quick responses that lack meaning,
            you also do not want to be agonising over each part trying to get it
            perfect. Your risk register can and should be updated as things
            change or you have new ideas about what might work better. Update
            with your best judgements, but keep your mind open and on the
            lookout for updates and additional information that will improve and
            develop the register in future.`}
          />
        </Stack>
      </Box>
    </>
  );
}
