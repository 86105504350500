import { takeLatest } from 'redux-saga/effects';
import { academySeatAllocationsActions as actions } from './';
import { createRequestSaga } from 'utils/saga';

const loadList = createRequestSaga({
  actionType: actions.loadListRequest.type,
  url: 'academy-seat-allocations',
  data: d => d,
  successAction: actions.loadListSuccess,
  failureAction: actions.loadListFailed,
});

const allocateSeat = createRequestSaga({
  actionType: actions.allocateSeatRequest.type,
  url: `academy-seat-allocations`,
  data: d => d,
  method: 'post',
  successAction: actions.allocateSeatSuccess,
  failureAction: actions.allocateSeatFailed,
});

export function* membersSaga() {
  yield takeLatest(actions.loadListRequest.type, loadList);
  yield takeLatest(actions.allocateSeatRequest.type, allocateSeat);
}
