/**
 *
 * OrganisationDetails
 *
 */
import * as React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { IconTitledContent } from 'app/components/IconTitledContent';
import { RiBuilding2Line, RiHome5Line } from 'react-icons/ri';
import { IAbridgedOrganisation } from 'types/types';

interface Props {
  organisation: IAbridgedOrganisation;
}

export function OrganisationDetails(props: Props) {
  const { organisation } = props;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stack>
            <Box>
              <Typography>
                Basic information about the Organisation you are a member of.
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <IconTitledContent
            title={'Name'}
            icon={<RiBuilding2Line />}
            sx={{ my: 2 }}
          >
            <Stack direction={'row'}>
              <Typography variant={'body1'}>
                <Typography variant={'body1'}>{organisation.name}</Typography>
              </Typography>
            </Stack>
          </IconTitledContent>

          <IconTitledContent
            title={'Address'}
            icon={<RiHome5Line />}
            sx={{ my: 2 }}
          >
            <Stack direction={'row'}>
              <Typography variant={'body1'}>
                {[
                  organisation.premises,
                  organisation.address_line_1,
                  organisation.address_line_2,
                  organisation.locality,
                  organisation.region,
                  organisation.country,
                  organisation.postal_code,
                ]
                  .filter(a => !!a)
                  .join(', ')}
              </Typography>
            </Stack>
          </IconTitledContent>
        </Grid>
      </Grid>
    </>
  );
}
