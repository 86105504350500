/**
 *
 * InvitationsTable
 *
 */
import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { RiMore2Line } from 'react-icons/ri';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { IInvitation } from 'types/types';
import moment from 'moment-timezone';
import { Circle } from '@mui/icons-material';
import { getDateWIthFallbackString } from 'utils/getDateWIthFallbackString';
import { calculateDataTableHeight } from '../../../styles/theme';

interface Props {
  invitations: IInvitation[];
  pageSize?: number;
  editable?: boolean;
  emptyText?: ReactNode;
  onResend?: (id: number | string) => void;
  onRemove?: (id: number | string) => void;
}

export function InvitationsTable(props: Props) {
  const {
    invitations,
    pageSize = 25,
    editable = false,
    emptyText = 'No invitations attached',
    onResend = r => {},
    onRemove = r => {},
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentId, setCurrentId] = React.useState<null | number>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const showFooter = useMemo(() => {
    return invitations.length > pageSize;
  }, [pageSize, invitations]);

  const rows = useMemo(() => {
    return invitations.map(m => ({
      ...m,
      name: `${m.first_name} ${m.last_name}`,
    }));
  }, [invitations]);

  if (!invitations.length) {
    return (
      <Box>
        <Typography variant={'body1'}>{emptyText}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: calculateDataTableHeight(invitations.length, showFooter),
        maxHeight: '40vh',
        width: '100%',
        mb: 4,
      }}
    >
      <DataGridPro
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
            pinnable: false,
            hideable: false,
            renderCell: ({ row }) => (
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Tooltip title={'Pending'}>
                  <Circle color={'warning'} />
                </Tooltip>
                <Box>{row.name}</Box>
              </Stack>
            ),
          },
          {
            field: 'job_title',
            headerName: 'Title',
            minWidth: 200,
            flex: 1,
            pinnable: false,
            hideable: false,
          },
          {
            field: 'role_name',
            headerName: 'Access role',
            minWidth: 150,
            flex: 1,
            pinnable: false,
            hideable: false,
          },
          {
            field: 'created_at',
            minWidth: 200,
            headerName: 'Date invited',
            type: 'dateTime',
            valueGetter: ({ row }) => getDateWIthFallbackString(row.created_at),
            pinnable: false,
            hideable: false,
          },

          ...(editable
            ? [
                {
                  field: 'actions',
                  headerName: '',
                  sortable: false,
                  pinnable: false,
                  hideable: false,
                  resizable: false,
                  width: 50,
                  disableColumnMenu: true,
                  renderCell: ({ row, colDef }) => {
                    return (
                      <IconButton
                        edge={false}
                        size={'small'}
                        onClick={ev => handleClick(ev, row.id)}
                      >
                        <RiMore2Line aria-label="See more options" />
                      </IconButton>
                    );
                  },
                },
              ]
            : []),
        ]}
        disableRowSelectionOnClick
        disableColumnSelector
        hideFooter={!showFooter}
        paginationModel={{ page: 1, pageSize }}
        pagination={showFooter}
        rows={rows}
        pinnedColumns={{
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          right: ['actions'],
        }}
      />
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            onResend(currentId!);
            handleClose();
          }}
        >
          Edit / resend
        </MenuItem>
        <MenuItem
          onClick={() => {
            onRemove(currentId!);
            handleClose();
          }}
        >
          Remove
        </MenuItem>
      </Menu>
    </Box>
  );
}
