import { MenuItem } from '@mui/material';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export function MyProfileLinks() {
  const { loadUser } = useSelector(selectAuthProvider);

  return (
    <>
      <MenuItem component={Link} to={'/profile'}>
        Personal details
      </MenuItem>
      <MenuItem component={Link} to={'/profile/security'}>
        Security & passwords
      </MenuItem>
      {!!loadUser.data && !!loadUser.data.thinkific_enabled && (
        <MenuItem component={Link} to={'/profile/academy-record'}>
          Academy record
        </MenuItem>
      )}
      <MenuItem component={Link} to={'/profile/declarations'}>
        Declarations of Interest
      </MenuItem>
    </>
  );
}
