import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { IRiskRegister } from 'types/types';
import { useQuery } from 'utils/useQuery';

interface Props {
  risk_register: IRiskRegister;
  return_path?: string;
  add_new_path?: string;
}

export function AddSuccess(props: Props) {
  const {
    risk_register,
    add_new_path = '../add',
    return_path = '../active',
  } = props;

  const {
    query: { risk_area_code = null },
  } = useQuery();

  if (!risk_register) {
    return null;
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#F9F9F9', p: 4, borderRadius: 3 }}>
        <Box sx={{ mt: 2, mb: 4 }}>
          <Stack spacing={4}>
            <Typography variant={'h1'}>New risk completed</Typography>
            <Box>
              <Typography>
                Well done - you have added another item to your Organisation’s
                Risk Register. Risk management is a never-ending journey – but
                you are on the right track, good work.
              </Typography>
            </Box>
            <Typography>
              You can now choose to add another risk or return to the summary of
              your Risk Register.
            </Typography>
            <Box>
              <Stack>
                <Button component={Link} to={add_new_path}>
                  Add another risk
                </Button>
                <Button variant={'outlined'} component={Link} to={return_path}>
                  Return to active risks
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
