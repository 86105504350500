/**
 *
 * Expire / Expiring Documents
 *
 */
import * as React from 'react';
import { useCallback } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { DocumentsTable } from 'app/components/DocumentsTable';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentsSlice } from './slice/hook';
import { IOrganisation } from 'types/types';
import { selectDocuments } from './slice/selectors';
import { useEffectOnce } from 'utils/useEffectOnce';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Helmet } from 'react-helmet-async';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useConfirm } from 'utils/useConfirm';
import { useDocumentFormWidget } from 'app/widgets/DocumentFormWidget/useDocumentFormWidget';
import { documentFormWidgetActions } from '../../widgets/DocumentFormWidget/slice';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import { selectDocumentFormWidget } from '../../widgets/DocumentFormWidget/slice/selectors';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';
import { DocumentFormWidget } from '../../widgets/DocumentFormWidget';

interface Props {
  organisation: IOrganisation;
}
const MenuButton = ({ sx = null, ...rProps }) => (
  <Button
    fullWidth
    {...rProps}
    variant="outlined"
    sx={{
      ...(sx ? sx : {}),
    }}
  />
);

export function Expiring(props: Props) {
  const { organisation } = props;
  const { loadExpiringDocuments, loadDocumentCategories } =
    useSelector(selectDocuments);
  const { deleteDocument, saveDocument } = useSelector(
    selectDocumentFormWidget,
  );
  const { actions } = useDocumentsSlice();
  const dispatch = useDispatch();

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 20,
  });

  const { open } = useDocumentFormWidget();
  const confirm = useConfirm();

  const { hasPermission } = useHasPermission();

  const canView = hasPermission(
    'view-expiring-documents',
    'organisation',
    organisation.id,
  );

  const canManage = hasPermission(
    'manage-organisation-documents',
    'organisation',
    organisation.id,
  );

  useEffectOnce(() => {
    if (organisation) load();
  });

  useEffectOnChange(() => {
    load();
  }, paginationModel);

  const load = useCallback(() => {
    if (canView) {
      dispatch(
        actions.loadExpiringDocumentsRequest({
          parent_entity_id: organisation.id,
          parent_entity_type: 'organisation',
          'page[size]': paginationModel.pageSize,
          'page[number]': paginationModel.page,
        }),
      );
    } else {
    }
  }, [organisation]);

  const downloadable = hasPermission(
    'download-organisation-documents',
    'organisation',
    organisation.id,
  );

  useHandleApiResponse(deleteDocument, undefined, {
    onSuccess: () => {
      load();
    },
  });
  useHandleApiResponse(saveDocument, null, {
    onSuccess: () => {
      load();
    },
  });

  if (!loadExpiringDocuments.data.length && loadExpiringDocuments.loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet title={'Organisation documents (expired/expiring)'}>
        <meta
          name="description"
          content="Template documents for the organisation"
        />
      </Helmet>

      <Stack mt={4} spacing={4}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Typography variant={'h3'}>Expired / expiring</Typography>
            <Typography>
              View all documents that have expired or will expire in the next 60
              days.
            </Typography>
          </Stack>

          {!!canView && !!loadExpiringDocuments.data.length ? (
            <DocumentsTable
              meta={loadExpiringDocuments.meta}
              onPaginationModelChange={({ page, pageSize }) => {
                setPaginationModel({ page: page + 1, pageSize });
              }}
              documents={loadExpiringDocuments.data}
              pageSize={10}
              downloadable={downloadable}
              editable={canManage}
              onRemove={id => {
                confirm({
                  title: 'Delete document',
                  description: 'Are you sure you want to delete this document?',
                })
                  .then(() =>
                    dispatch(
                      documentFormWidgetActions.deleteDocumentRequest({
                        id,
                      }),
                    ),
                  )
                  .catch(() => {});
              }}
              onEdit={id => {
                open({
                  parent_entity_id: organisation.id,
                  parent_entity_type: 'organisation',
                  id,
                });
              }}
            />
          ) : (
            <Typography>No expired / expiring documents attached</Typography>
          )}
        </Stack>
      </Stack>
      <DocumentFormWidget
        categories={loadDocumentCategories.data}
        defaultCategory={'reference'}
      />
    </>
  );
}
