import { Button, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface SectionMenuProps {
  title: string;
  isActive: boolean;
  path: string;
  icon?: ReactNode;
  hidden?: boolean;
}

export function SectionMenu(props: SectionMenuProps) {
  const { title, isActive, path, hidden = false } = props;

  const theme = useTheme();
  const isActiveStyles = isActive && {
    color: 'common.midnight',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.common.white,
      color: theme.palette.common.midnight,
      borderTop: `1px solid ${theme.palette.primary.main}`,
    },
  };

  return (
    <>
      {!hidden && (
        <Button
          variant="text"
          component={Link}
          to={path}
          sx={{
            borderRadius: 0,
            textAlign: 'center',
            ...isActiveStyles,
          }}
        >
          {title}
        </Button>
      )}
    </>
  );
}
