import React from 'react';

export function ColumnsIcon() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2820_22523)">
          <g clipPath="url(#clip1_2820_22523)">
            <path
              d="M2 12H8V20H2V12ZM9 2H15V20H9V2ZM16 7H22V20H16V7Z"
              fill="#09121F"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2820_22523">
            <rect width="24" height="24" fill="white" />
          </clipPath>
          <clipPath id="clip1_2820_22523">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0 -1)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
