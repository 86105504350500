import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { RadioButton } from 'app/components/RadioButton';
import { Field, getIn, useFormikContext } from 'formik';
import { FormValues } from './index';
import { TextField } from 'formik-mui';
import moment from 'moment-timezone';
import { DateTimePickerWrapper } from 'app/components/DateTimePickerWrapper';

interface Props {
  previous: () => void;
  next: () => void;
}

export function ScheduleMeeting(props: Props) {
  const { next, previous } = props;
  const { setFieldValue, values, errors, setFieldTouched, touched, isValid } =
    useFormikContext<FormValues>();

  return (
    <Stack spacing={6}>
      <Typography variant={'h1'}>Schedule a meeting</Typography>
      <Box>
        <Stack spacing={4}>
          <Typography component={'p'}>
            Now your first board and members are set up you can schedule your
            first meeting of that Board and send out an invite to the members on
            that Board. If you are not ready to do this now (for example you
            want to add more papers and standing documents first), you can skip
            this step.
          </Typography>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <RadioButton
            label={
              <Stack sx={{ textAlign: 'left', pl: 2 }}>
                <Typography color={'primary'} variant={'h4'}>
                  Schedule a meeting now
                </Typography>
              </Stack>
            }
            onChange={() => setFieldValue('schedule_meeting', true)}
            checked={values.schedule_meeting === true}
          />

          {!!values.schedule_meeting && (
            <Box sx={{ py: 4 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name={'meeting.name'}
                    label={'Meeting name'}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name={'meeting.description'}
                    label={'Description'}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimePickerWrapper
                    name={'meeting.date_start'}
                    sx={{ width: '100%' }}
                    format={'DD/MM/YYYY hh:mm a'}
                    minutesStep={15}
                    onChange={(date, context) => {
                      if (date) {
                        // add length in hours to start date
                        const newDate = date
                          .clone()
                          .add(values.meeting.length, 'hours');
                        setFieldValue('meeting.date_end', newDate);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    id="length"
                    name="meeting.length"
                    label="Length (hours)"
                    type="number"
                    InputProps={{ inputProps: { min: 0.5, step: 0.5 } }}
                    fullWidth
                    onChange={e => {
                      const length = parseFloat(e.target.value); // Parse the input value to a float
                      // set date_end to be date_start + length as moment object
                      const newDate = moment(values.meeting.date_start)
                        .clone()
                        .add(length, 'hours');
                      setFieldValue('meeting.date_end', newDate);
                      setFieldValue('meeting.length', length);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name={'meeting.location'}
                    label={'Location / URL'}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          <RadioButton
            label={
              <Stack sx={{ textAlign: 'left', pl: 2 }}>
                <Typography color={'primary'} variant={'h4'}>
                  Skip this step for now
                </Typography>

                <Stack spacing={1}>
                  <Typography
                    color={'grey.700'}
                    sx={{ textDecoration: 'none' }}
                  >
                    You can schedule meetings and send reminders at any time by
                    visiting Boards in the menu
                  </Typography>
                </Stack>
              </Stack>
            }
            onChange={() => setFieldValue('schedule_meeting', false)}
            checked={values.schedule_meeting === false}
          />
        </Stack>
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant={'outlined'} onClick={() => previous()}>
              Previous: Board info
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              onClick={() => next()}
              disabled={!isValid}
              type={'submit'}
            >
              Next: Ready to go
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
