import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PersonalDetailsState } from './types';
import { apiCallInitialState } from 'types/ApiCall';
import { IUser } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: PersonalDetailsState = {
  loadPersonalDetails: apiCallInitialState,
  savePersonalDetails: apiCallInitialState,
};

export const slice = createSlice({
  name: 'personalDetails',
  initialState,
  reducers: {
    loadPersonalDetailsRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadPersonalDetails.loading = true;
      state.loadPersonalDetails.error = undefined;
    },
    loadPersonalDetailsSuccess(state, action: PayloadAction<{ data: IUser }>) {
      state.loadPersonalDetails.loading = false;
      state.loadPersonalDetails.error = undefined;
      state.loadPersonalDetails.data = action.payload.data;
    },
    loadPersonalDetailsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadPersonalDetails.loading = false;
      state.loadPersonalDetails.error = action.payload;
    },
    savePersonalDetailsRequest(state, action: PayloadAction<Partial<IUser>>) {
      state.savePersonalDetails.loading = true;
      state.savePersonalDetails.error = undefined;
    },
    savePersonalDetailsSuccess(
      state,
      action: PayloadAction<{ data: Partial<IUser> }>,
    ) {
      state.savePersonalDetails.loading = false;
      state.savePersonalDetails.error = undefined;
      state.savePersonalDetails.data = action.payload.data;
    },
    savePersonalDetailsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.savePersonalDetails.loading = false;
      state.savePersonalDetails.error = action.payload;
    },
  },
});

export const { actions: personalDetailsActions } = slice;
