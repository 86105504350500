import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material';

import * as React from 'react';

interface Props {
  proceed: () => void;
  cancel: () => void;
}

export function Intro(props: Props) {
  const { proceed, cancel } = props;
  return (
    <Stack spacing={6}>
      <Typography variant={'h1'}>Welcome to Boards by Governance360</Typography>
      <Box>
        <Stack spacing={4}>
          <Stack>
            <Typography component={'p'}>
              Congratulations - you are about to activate the Boards feature
              created by Governance360. This will help you run board meetings
              more quickly, save you time and effort in circulating papers to
              your board members and reduce your risk in doing so (no more
              email, no more insecure links to storage systems, no more paper!)
            </Typography>
          </Stack>
          <Typography variant={'h2'}>
            Generally, most new Board administrators proceed as follows:
          </Typography>

          <Box>
            <Stack direction={'row'} spacing={2}>
              <Typography
                component={'span'}
                sx={{
                  fontWeight: 'bold',
                }}
              >
                1.
              </Typography>
              <Typography component={'p'}>
                They set up a meeting due in the future (your next Board meeting
                for example) and add some documents (usually the Agenda) already
                agreed for that meeting
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Stack direction={'row'} spacing={2}>
              <Typography
                component={'span'}
                sx={{
                  fontWeight: 'bold',
                }}
              >
                2.
              </Typography>
              <Typography component={'p'}>
                Add one or two previous meetings of the Board, along with the
                documents that go with that meeting (in exactly the same way as
                you create a future meeting, simply using our date and time
                feature to add dates that have already passed)
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Stack direction={'row'} spacing={2}>
              <Typography
                component={'span'}
                sx={{
                  fontWeight: 'bold',
                }}
              >
                3.
              </Typography>
              <Typography component={'p'}>
                Invite Board members to join Governance360 at that point – so
                that when they join they can see the next meeting ready to go
                (and with some papers to digest), along with the last few
                meetings for context.
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Typography component={'p'}>
              Most administrators usually take 10 to 15 minutes only therefore
              to get up and running with Boards. Please let us know if you need
              any help either via{' '}
              <Link
                sx={{ display: 'inline' }}
                href="mailto:support@governance360.com"
              >
                support@governance360.com
              </Link>{' '}
              or browse our{' '}
              <Link
                href="https://knowledge.governance360.com"
                sx={{ display: 'inline' }}
                target="_blank"
                rel="noopener"
              >
                Knowledge Base
              </Link>{' '}
              for additional help.
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant={'outlined'}
              onClick={() => cancel()}
              sx={{ textTransform: 'uppercase' }}
            >
              I'm not ready to proceed just yet
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              onClick={() => proceed()}
              sx={{ textTransform: 'uppercase' }}
            >
              I'm ready to start
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
