export const getInitials = (nameString: string) => {
  if (nameString.length <= 3) return nameString.toUpperCase();

  const fullName = nameString.split(/[\s,\&]+/);
  return fullName
    .map(s => (s ? s.trim().charAt(0) : ''))
    .filter(s => s.match(/^[a-z0-9]+$/i) !== null)
    .join('')
    .toUpperCase();
};
