import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { manageMfaSaga } from './saga';
import { ManageAuthState } from './types';
import { apiCallInitialState, getApiCallInitialState } from 'types/ApiCall';
import { IUser, MfaMethod } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';
import moment from 'moment-timezone';

export const initialState: ManageAuthState = {
  confirmLogin: getApiCallInitialState('item', {
    confirmationToken: '',
    confirmationTokenExpires: moment().toISOString(),
  }),
  getSetupData: getApiCallInitialState('item', { loaded: false }),
  confirmSetup: getApiCallInitialState(),
  changePassword: getApiCallInitialState(),
  changeEmail: getApiCallInitialState(),
  requestCodes: getApiCallInitialState('item', {
    codes: [],
    cleared: false,
  }),
};

const slice = createSlice({
  name: 'manageMfa',
  initialState,
  reducers: {
    init(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.confirmLogin = initialState.confirmLogin;
      }
      state.getSetupData = initialState.getSetupData;
      state.confirmSetup = initialState.confirmSetup;
    },
    confirmLoginRequest(
      state,
      action: PayloadAction<{ email: string; password: string }>,
    ) {
      state.confirmLogin = { ...initialState.confirmLogin, loading: true };
    },
    confirmLoginSuccess(
      state,
      action: PayloadAction<{
        confirmationToken: string;
        confirmationTokenExpires: string;
      }>,
    ) {
      state.confirmLogin = {
        ...initialState.confirmLogin,
        data: action.payload,
      };
    },
    confirmLoginFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.confirmLogin = {
        ...initialState.confirmLogin,
        error: action.payload,
      };
    },
    getSetupDataRequest(
      state,
      action: PayloadAction<{
        confirmationToken: string;
        method: MfaMethod | 'remove';
        telephone_mobile?: string;
      }>,
    ) {
      state.getSetupData = { ...initialState.getSetupData, loading: true };
    },
    getSetupDataSuccess(
      state,
      action: PayloadAction<{
        data:
          | {
              qrcode_image: string;
              secret: string;
            }
          | undefined;
      }>,
    ) {
      state.getSetupData = {
        ...initialState.getSetupData,
        data: { ...(action.payload.data || {}), loaded: true },
      };
    },
    getSetupDataFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.getSetupData = {
        ...initialState.getSetupData,
        error: action.payload,
      };
    },
    confirmSetupRequest(
      state,
      action: PayloadAction<{
        method: MfaMethod | 'remove';
        confirmationToken: string;
        code: string;
        secret?: string;
      }>,
    ) {
      state.confirmSetup = { ...initialState.confirmSetup, loading: true };
    },
    confirmSetupSuccess(
      state,
      action: PayloadAction<{ data: { enabled: boolean } }>,
    ) {
      state.confirmSetup = {
        ...initialState.confirmSetup,
        data: action.payload.data,
      };
    },
    confirmSetupFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.confirmSetup = {
        ...initialState.confirmSetup,
        error: action.payload,
      };
    },

    changePasswordRequest(
      state,
      action: PayloadAction<{
        confirmationToken: string;
        new_password: string;
      }>,
    ) {
      state.changePassword.loading = true;
      state.changePassword.error = undefined;
    },
    changePasswordSuccess(state, action: PayloadAction<any>) {
      state.changePassword.loading = false;
      state.changePassword.error = undefined;
      state.changePassword.data = action.payload.data;
    },
    changePasswordFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.changePassword.loading = false;
      state.changePassword.error = action.payload;
    },

    changeEmailRequest(
      state,
      action: PayloadAction<{ email: string; confirmationToken: string }>,
    ) {
      state.changeEmail.loading = true;
      state.changeEmail.error = undefined;
    },
    changeEmailSuccess(state, action: PayloadAction<{ data: Partial<IUser> }>) {
      state.changeEmail.loading = false;
      state.changeEmail.error = undefined;
      state.changeEmail.data = action.payload.data;
    },
    changeEmailFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.changeEmail.loading = false;
      state.changeEmail.error = action.payload;
    },

    requestCodesInit(
      state,
      action: PayloadAction<{
        confirmationToken: string;
        new_email: string;
      }>,
    ) {
      state.requestCodes = initialState.requestCodes;
    },
    requestCodesRequest(
      state,
      action: PayloadAction<{
        clear_all?: boolean;
      }>,
    ) {
      state.requestCodes.loading = true;
      state.requestCodes.error = undefined;
    },
    requestCodesSuccess(
      state,
      action: PayloadAction<{ data: { codes: string[]; cleared?: boolean } }>,
    ) {
      state.requestCodes.loading = false;
      state.requestCodes.error = undefined;
      state.requestCodes.data = action.payload.data;
    },
    requestCodesFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.requestCodes.loading = false;
      state.requestCodes.error = action.payload;
    },
  },
});

export const { actions: manageMfaActions } = slice;

export const useManageAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: manageMfaSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useManageAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
