import {
  Box,
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import key from 'weak-key';
import * as React from 'react';
import { YourPlan } from './YourPlan';
import { GettingStarted } from './GettingStarted';
import { Form, Formik, FormikProps } from 'formik';
import { BoardInfo } from './BoardInfo';
import { ScheduleMeeting } from './ScheduleMeeting';
import { ReadyToGo } from './ReadyToGo';
import * as yup from 'yup';
import { useEffect } from 'react';
import { FormValues, WizardState } from './index';
import { noop } from 'utils/noop';
import { BoardActivationData } from '../slice/types';
import moment from 'moment-timezone';

interface Props {
  state: WizardState;
  activationData: BoardActivationData;
  setState: (state: WizardState) => void;
  onSubmit: (values: FormValues) => void;
  cancel: () => void;
}

const meetingSchema = yup.object().shape({
  name: yup.string().required('Meeting name is required'),
  date_start: yup
    .date()
    .typeError('Start date and time are required')
    .required('Start time is required'),
  date_end: yup
    .date()
    .typeError('End date and time are required')
    .required('End time is required'),
});

const schema = [
  {},
  { committee_name: yup.string().required('Committee name is required') },
  {
    schedule_meeting: yup.boolean().required(),
    meeting: yup.object().when('schedule_meeting', {
      is: true,
      then: meetingSchema, // Apply the meeting schema when schedule_meeting is true
      otherwise: yup.object().nullable(), // If schedule_meeting is false, the meeting object is not validated
    }),
  },
  {},
];

export function Wizard(props: Props) {
  const { state, setState, activationData, onSubmit, cancel } = props;

  const setStep = (step: number) => {
    setState({ ...state, step });
  };

  const updateValues = (values: FormValues) => {
    setState({ ...state, values });
  };

  const { step, played, values } = state;

  const setPlayed = index => {
    if (!played.includes(index)) {
      setState({ ...state, played: [...state.played, index] });
    }
  };

  const steps = [
    {
      label: 'Your plan',
      component: (
        <YourPlan
          previous={() => setStep(0)}
          next={() => setStep(2)}
          {...activationData.plan}
        />
      ),
    },
    {
      label: 'Board info',
      component: (
        <BoardInfo previous={() => setStep(1)} next={() => setStep(3)} />
      ),
    },
    {
      label: 'Schedule a meeting',
      component: (
        <ScheduleMeeting previous={() => setStep(2)} next={() => setStep(4)} />
      ),
    },
    {
      label: 'Ready to go',
      component: (
        <ReadyToGo
          previous={() => setStep(3)}
          confirm={() => onSubmit(values)}
          downloads={activationData.more_info.downloads}
        />
      ),
    },
  ];

  const currentValidationSchema = yup
    .object()
    .shape(Object.assign({}, ...schema.slice(0, step)));
  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  useEffect(() => {
    formikRef.current
      ?.validateForm()
      .then(errors => {
        updateValues(formikRef.current.values);
      })
      .catch(noop);
  }, [step]);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        ...values,
        meeting: {
          ...values.meeting,
          date_start: moment(values.meeting.date_start),
          date_end: moment(values.meeting.date_end),
        },
      }}
      onSubmit={values => {}}
      validationSchema={currentValidationSchema}
    >
      {f => (
        <Form>
          <Stack spacing={4}>
            {isMd && (
              <Stepper>
                {steps.map((s, i) => (
                  <Step key={key(s)} active={step === i + 1}>
                    <StepLabel>{s.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}

            <Box>{steps[step - 1].component}</Box>

            <Box color={'grey.700'}>
              <Button
                variant={'text'}
                onClick={() => cancel()}
                color={'inherit'}
              >
                Cancel setting up Boards
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
