import {
  Avatar,
  Box,
  Grid,
  Stack,
  TextField as MuiTextField,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';

import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';
import { getInitials } from 'utils/initials';
import { ActionRegisterCommittee } from '../slice/types';
import { ActionRegisterFormType } from './index';

interface Props {
  onClose: () => void;
  previous: () => void;
  next: () => void;
  committees: ActionRegisterCommittee[];
}

export function AssignOwner(props: Props) {
  const { onClose, next, previous, committees } = props;

  const { setFieldValue, isValid, values } =
    useFormikContext<ActionRegisterFormType>();

  const members = useMemo(() => {
    return committees.find(c => c.id === values.committee_id)?.members || [];
  }, [committees, values.committee_id]);

  const filteredMembers = useMemo(() => {
    return members.filter(m => !values.member_ids.includes(m.member_id));
  }, [members, values.member_ids]);

  const [searchValue, setSearchValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleAddMember = member => {
    const newMembers = [...values.member_ids, member.id];
    setFieldValue('member_ids', newMembers);
    setSearchValue(null);
    setInputValue('');
  };

  const handleRemoveMember = memberId => {
    const filteredIds = values.member_ids.filter(id => id !== memberId);
    setFieldValue('member_ids', filteredIds);
  };

  return (
    <>
      <Typography variant="h2">Assign an owner</Typography>
      <Typography variant="body2">
        Assign an owner who is directly involved in or responsible for the
        action previously outlined.
      </Typography>
      <Box>
        <Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography variant="h5">
              Find the team members or type a name, business or team
            </Typography>
          </Stack>
          <Box sx={{ mb: { xs: 4 } }}>
            <Grid container alignItems={'center'}>
              <Grid item xs={12} md={8}>
                <Autocomplete
                  options={filteredMembers.map(m => ({
                    id: m.member_id,
                    first_name: m.first_name,
                    last_name: m.last_name,
                  }))}
                  getOptionLabel={option =>
                    `${option.first_name} ${option.last_name}`
                  }
                  renderInput={params => (
                    <TextField {...params} label="Member name" />
                  )}
                  value={searchValue}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleAddMember(newValue);
                    }
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Typography variant="h5">Assignees</Typography>
          <Box>
            <Grid container>
              <Grid item xs={12} md={6} pr={2}>
                {values.member_ids.length ? (
                  <Stack spacing={1}>
                    {values.member_ids.map(id => {
                      const member = members.find(m => m.member_id === id);
                      if (!member) return null;
                      return (
                        <Stack
                          key={id}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack direction="row" alignItems="center">
                            <Avatar
                              sx={{
                                backgroundColor: 'common.beige',
                                color: 'common.black',
                                width: 30,
                                height: 30,
                              }}
                            >
                              {getInitials(
                                `${member.first_name} ${member.last_name}`,
                              )}
                            </Avatar>
                            <Typography fontWeight="bold" variant="body2">
                              {`${member.first_name} ${member.last_name}`}
                            </Typography>
                          </Stack>
                          <IconButton
                            sx={{ color: 'error.main' }}
                            onClick={() => handleRemoveMember(member.member_id)}
                          >
                            <RiCloseCircleLine />
                          </IconButton>
                        </Stack>
                      );
                    })}
                  </Stack>
                ) : (
                  <Typography>No members assigned</Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography variant="body2">
                    We'd recommend assigning one owner to most actions.
                  </Typography>
                  <Typography variant="body2">
                    If you have an action that genuinely needs more than one
                    owner, you can assign up to 5 owners.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant="outlined" onClick={previous}>
              Previous
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth onClick={next} disabled={!isValid}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
    </>
  );
}
