export const hideEmail = email => {
  try {
    const index = email.indexOf('@');
    if (index > 5) {
      return (
        '*'.repeat(index - 3) +
        email
          .split('')
          .slice(index - 3)
          .join('')
      );
    } else {
      return '*'.repeat(index) + email.split('').slice(index).join('');
    }
  } catch (e) {
    return '';
  }
};

export const hidePhoneNo = phone => {
  try {
    return (
      '*'.repeat(phone.split('').length - 3) +
      phone.split('').slice(-3).join('')
    );
  } catch (e) {
    return '';
  }
};
