import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApiCallInitialState } from 'types/ApiCall';
import { IAbridgedOrganisation } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';
import { OrganisationProfileState } from './types';

export const initialState: OrganisationProfileState = {
  loadOrganisationProfile: getApiCallInitialState<IAbridgedOrganisation>(),
  saveOrganisationProfile: getApiCallInitialState<IAbridgedOrganisation>(),
  sendContactForm: getApiCallInitialState(),
  // savingContactForm: apiCallInitialState,
};

export const slice = createSlice({
  name: 'organisationProfile',
  initialState,
  reducers: {
    loadOrganisationProfileInit(state, action: PayloadAction<void>) {
      state.loadOrganisationProfile = initialState.loadOrganisationProfile;
    },
    loadOrganisationProfileRequest(
      state,
      action: PayloadAction<{ id: string | number }>,
    ) {
      state.loadOrganisationProfile.loading = true;
      state.loadOrganisationProfile.error = undefined;
    },
    loadOrganisationProfileSuccess(
      state,
      action: PayloadAction<{ data: IAbridgedOrganisation }>,
    ) {
      state.loadOrganisationProfile.loading = false;
      state.loadOrganisationProfile.error = undefined;
      state.loadOrganisationProfile.data = action.payload.data;
    },
    loadOrganisationProfileFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadOrganisationProfile.loading = false;
      state.loadOrganisationProfile.error = action.payload;
    },
    saveOrganisationProfileRequest(
      state,
      action: PayloadAction<Partial<IAbridgedOrganisation>>,
    ) {
      state.saveOrganisationProfile.loading = true;
      state.saveOrganisationProfile.error = undefined;
    },
    saveOrganisationProfileSuccess(
      state,
      action: PayloadAction<{ data: IAbridgedOrganisation }>,
    ) {
      state.saveOrganisationProfile.loading = false;
      state.saveOrganisationProfile.error = undefined;
      state.saveOrganisationProfile.data = action.payload.data;
    },
    saveOrganisationProfileFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.saveOrganisationProfile.loading = false;
      state.saveOrganisationProfile.error = action.payload;
    },

    sendContactFormRequest(state, action: PayloadAction<any>) {
      state.sendContactForm.loading = true;
      state.sendContactForm.error = undefined;
    },
    sendContactFormSuccess(state, action: PayloadAction<any>) {
      state.sendContactForm.loading = false;
      state.sendContactForm.error = undefined;
    },
    sendContactFormFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.sendContactForm.loading = false;
      state.sendContactForm.error = action.payload;
    },
  },
});

export const { actions: organisationProfileActions } = slice;
