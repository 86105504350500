/**
 *
 * ConflictForm
 *
 */
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { ConflictForm } from './ConflictForm';
import { useConflictFormWidgetSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectConflictFormWidget } from './slice/selectors';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { IConflict } from 'types/types';
import { noop } from 'utils/noop';
import useHandleApiResponse from 'utils/useHandleApiResponse';

interface Props {
  onChange?: (payload: IConflict) => void;
  onDelete?: (payload: IConflict) => void;
}

export function ConflictFormWidget(props: Props) {
  const { onChange = noop, onDelete = noop } = props;
  const { actions } = useConflictFormWidgetSlice();
  const dispatch = useDispatch();
  const {
    form: { open, loading, id, organisations, organisation_id },
    loadConflict,
    saveConflict,
  } = useSelector(selectConflictFormWidget);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const load = useCallback(() => {
    if (id) {
      dispatch(actions.loadConflictRequest({ id }));
    }
  }, [id]);

  useHandleApiResponse(saveConflict, 'Declaration updated', {
    errorMessage: 'Unable to update your Declaration.',
    onSuccess: () => {
      onChange();
      close();
    },
  });

  useEffect(() => {
    if (open && id) load();
  }, [open]);

  useEffect(
    () => () => {
      dispatch(actions.close());
    },
    [dispatch],
  );

  const close = () => dispatch(actions.close());

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      fullWidth
      fullScreen={fullScreen}
      scroll={'body'}
      onClose={close}
    >
      {loading || loadConflict.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ConflictForm
            saving={saveConflict.loading}
            onClose={close}
            onDelete={() => onDelete(loadConflict.data)}
            conflict={loadConflict.data}
            organisations={organisations}
            onSave={payload =>
              dispatch(
                actions.saveConflictRequest({ organisation_id, ...payload }),
              )
            }
          />
        </>
      )}
    </Dialog>
  );
}

export function useConflictFormWidget() {
  const { actions } = useConflictFormWidgetSlice();
  const dispatch = useDispatch();
  const conflictForm = useSelector(selectConflictFormWidget);

  const open = payload => {
    dispatch(actions.openRequest(payload));
  };
  const close = () => dispatch(actions.close());

  return { open, close, isOpen: conflictForm.form.open };
}
