import { useDocumentFormWidgetSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocumentFormWidget } from './slice/selectors';

export function useDocumentFormWidget() {
  const { actions } = useDocumentFormWidgetSlice();
  const dispatch = useDispatch();
  const documentForm = useSelector(selectDocumentFormWidget);

  const remove = (payload: { id: number | string }) => {
    dispatch(actions.deleteDocumentRequest(payload));
  };

  const open = payload => {
    dispatch(actions.openRequest(payload));
  };
  const close = () => dispatch(actions.close());

  return { open, close, remove, isOpen: documentForm.form.open };
}
