import { useRef, useEffect } from 'react';
import { isEqual } from 'lodash';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useHasChanged = (val, logDiff = false) => {
  const prevVal = usePrevious(val);

  if (!isEqual(prevVal, val) && prevVal !== undefined && logDiff) {
    console.log(prevVal, val);
  }

  return !isEqual(prevVal, val) && prevVal !== undefined;
};
