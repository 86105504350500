import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as Timer } from './assets/timer.svg';
import React from 'react';

export const TimerSubtitle = ({ title }) => {
  return (
    <Stack direction={'row'} spacing={1}>
      <Box sx={{ width: 15, height: 15 }}>
        <Timer />
      </Box>
      <Typography variant="body2" color={'grey.700'} sx={{ pt: 0.5 }}>
        {title}
      </Typography>
    </Stack>
  );
};
