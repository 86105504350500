import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Button,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { RiInformationFill, RiShareForward2Line } from 'react-icons/ri';
import { IRiskRegister } from 'types/types';
import { useRiskColourName } from 'utils/useRiskColourName';
import { Link } from 'react-router-dom';

interface Props {
  riskRegister: IRiskRegister;
  urlPrefix?: string;
}

export function RiskMatrix(props: Props) {
  const { riskRegister, urlPrefix = './' } = props;

  const getRiskColourData = useRiskColourName();
  const GetMitigatedRiskColour = val => {
    const { colour } = getRiskColourData(val, 'mitigated');
    return colour;
  };

  const items = useMemo(() => {
    if (!riskRegister) return [];

    return (riskRegister.items || []).filter(item => !item.is_archived);
  }, [riskRegister]);

  const impactLevels = [
    'Insignificant',
    'Minor',
    'Moderate',
    'Major',
    'Extreme',
  ];
  const likelihoodLevels = [
    'Highly Probable',
    'Probable',
    'Possible',
    'Unlikely',
    'Remote',
  ];

  const getTextColour = val => (val >= 20 ? 'white' : 'common.black');
  const theme = useTheme();
  const mdViewUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Stack>
        <Box>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <RiInformationFill size={20} />
            {!!items.length ? (
              <>
                <Typography>
                  Click a box on the grid to see those risks and browse to them.
                </Typography>
              </>
            ) : (
              <>
                <Typography>
                  Currently the risk matrix is empty, as you haven't added any
                  risks yet.
                </Typography>
              </>
            )}
          </Stack>
        </Box>
        <Stack direction={'row'} justifyContent={'flex-start'}>
          <TableContainer>
            <Table
              sx={{
                borderCollapse: 'separate',
                borderSpacing: mdViewUp ? 5 : 2,
                width: '100%',
                textAlign: 'left',
              }}
            >
              {!!mdViewUp && (
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: 'none' }} />
                    {impactLevels.map((impact, index) => (
                      <TableCell
                        key={impact}
                        align="center"
                        sx={{
                          border: 'none',
                          width: 100,
                          pb: 3,
                        }}
                      >
                        <Typography>{impact}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {likelihoodLevels.map((likelihood, rowIndex) => (
                  <TableRow key={likelihood}>
                    {!!mdViewUp && (
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          textAlign: 'end',
                          border: 'none',
                          pr: 3,
                          width: 50,
                        }}
                      >
                        <Typography>{likelihood}</Typography>
                      </TableCell>
                    )}
                    {impactLevels.map((_, colIndex) => {
                      const itemsInCell = items.filter(
                        item =>
                          item.score_likelihood === 5 - rowIndex &&
                          item.score_impact_mitigated === 1 + colIndex,
                      );
                      const score = Math.max(
                        ((5 - rowIndex) * (colIndex + 1)) / 5,
                        1,
                      );
                      return (
                        <TableCell
                          key={colIndex}
                          sx={{
                            border: 'none',
                            width: `${80 / impactLevels.length}%`,
                            position: 'relative',
                            backgroundColor: !!itemsInCell.length
                              ? GetMitigatedRiskColour(score)
                              : '#E8E8E8',
                            borderRadius: 1,
                            '&::before': {
                              content: "''",
                              display: 'block',
                              paddingTop: '40%',
                            },
                          }}
                        >
                          {!!itemsInCell.length && (
                            <Button
                              variant="text"
                              component={Link}
                              to={`${urlPrefix}${itemsInCell[0].score_likelihood}/${itemsInCell[0].score_impact_mitigated}`}
                              sx={{
                                color: getTextColour(
                                  itemsInCell[0].score_likelihood *
                                    itemsInCell[0].score_impact_mitigated,
                                ),
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                '&:hover': {
                                  color: getTextColour(
                                    itemsInCell[0].score_likelihood *
                                      itemsInCell[0].score_impact_mitigated,
                                  ),
                                },
                              }}
                            >
                              <Stack alignItems={'center'} spacing={0.5}>
                                <Typography variant={mdViewUp ? 'h3' : 'body1'}>
                                  {itemsInCell.length}
                                </Typography>
                                <Typography
                                  fontWeight={mdViewUp && 'bold'}
                                  variant={mdViewUp ? 'body1' : 'body2'}
                                >
                                  {itemsInCell.length === 1 ? 'risk' : 'risks'}
                                </Typography>
                              </Stack>
                            </Button>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        {/* <Box sx={{ py: 2 }}>
          <Stack spacing={4}>
            <Box>
              <Stack spacing={2}>
                <Typography variant="h3">
                  Something about risk matrix
                </Typography>
                <Typography>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Nulla possimus similique, cumque excepturi hic architecto
                  nostrum vero ad unde dolorum repellendus, sit ducimus dolores
                  deserunt aliquid labore exercitationem, rerum et!
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Stack spacing={2}>
                <Typography variant="h3">
                  Another thing about the risk matrix
                </Typography>
                <Typography>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Nulla possimus similique, cumque excepturi hic architecto
                  nostrum vero ad unde dolorum repellendus, sit ducimus dolores
                  deserunt aliquid labore exercitationem, rerum et!
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Box> */}
      </Stack>
    </>
  );
}
