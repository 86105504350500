import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';

interface Props {
  text?: string;
}

export function RequiredFieldIndicator(props: Props) {
  const { text = '* Required fields' } = props;
  return (
    <Stack alignItems={'end'}>
      <Typography variant="body2" color="textSecondary">
        {text}
      </Typography>
    </Stack>
  );
}
