import { Button, Stack } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export function BottomLinks() {
  return (
    <Stack>
      <Button
        component={Link}
        to={'/auth/manage/setup'}
        size="small"
        variant="text"
        sx={{ mt: 1, textAlign: 'center' }}
      >
        Set up a different method of 2-factor authentication
      </Button>
      <Button
        component={Link}
        to={'/profile/security'}
        size="small"
        variant="text"
        sx={{ mt: 1 }}
      >
        Cancel setting up 2-factor authentication
      </Button>
    </Stack>
  );
}
