import { takeLatest } from 'redux-saga/effects';
import { personalDetailsActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const savePersonalDetails = createRequestSaga({
  actionType: actions.savePersonalDetailsRequest.type,
  url: `profile/personal-details`,
  method: 'put',
  data: d => d,
  successAction: actions.savePersonalDetailsSuccess,
  failureAction: actions.savePersonalDetailsFailed,
});

export function* personalDetailsSaga() {
  yield takeLatest(
    actions.savePersonalDetailsRequest.type,
    savePersonalDetails,
  );
}
