import { ResponseErrorPayload } from 'utils/request';
import { NestedObject } from 'types/types';

export type ApiCallSingle<T, E> = {
  loading: boolean;
  error?: ResponseErrorPayload;
  data: T;
  touched?: boolean;
} & E;

export type ApiCallList<T, E> = {
  loading: boolean;
  error?: ResponseErrorPayload;
  data: T[];
  meta: ResultMetaData;
  touched?: boolean;
} & E;

export type ApiCall<T = any, E = {}> = T extends any[]
  ? ApiCallList<T[number], E>
  : ApiCallSingle<T, E>;

export interface ApiCallRequestParameters extends NestedObject {
  'page[number]'?: number;
  'page[size]'?: number;
  sort?: string;
  filter?: NestedObject;
}

export const apiCallInitialState: ApiCall = {
  loading: false,
  error: undefined,
  data: undefined,
  touched: false,
};

export const resultMetaDataInitialState: ResultMetaData = {
  current_page: 0,
  from: 0,
  last_page: 0,
  per_page: 0,
  to: 0,
  total: 0,
};

function isArrayType<T>(val: T): val is T & any[] {
  return Array.isArray(val);
}

export type GetApiReturnType<T> = Omit<T, 'loading' | 'error' | 'touched'>;

type ApiCallType = 'list' | 'item';

export function getApiCallInitialState<T = any, E = any>(
  type?: ApiCallType,
  initialValue?: T,
  extra?: E,
): ApiCall<T, E> {
  if (type === 'list') {
    return {
      ...apiCallInitialState,
      data: initialValue || [],
      meta: resultMetaDataInitialState,
      ...extra,
    } as unknown as ApiCall<T, E>;
  } else {
    return {
      ...apiCallInitialState,
      data: initialValue || (undefined as T),
      ...extra,
    } as unknown as ApiCall<T, E>;
  }
}

export type UploadApiCall<T = any> = ApiCall<T> & {
  progress: number;
};

export const uploadApiCallInitialState: UploadApiCall = {
  ...apiCallInitialState,
  progress: 0,
};

export interface ResultMetaData {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
  [key: string]: any;
}
