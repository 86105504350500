import React, { useMemo, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { RiArrowLeftCircleFill } from 'react-icons/ri';
import { IRiskRegister } from 'types/types';
import { useRiskColourName } from 'utils/useRiskColourName';
import { RiskRegisterItem } from 'app/components/RiskRegisterItem';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { RiskSubheader } from 'app/components/RiskSubheader';
import key from 'weak-key';

interface Props {
  riskRegister: IRiskRegister;
  saving?: boolean;
  onSave?: (values: any) => void;
  canManage?: boolean;
  memberNames?: string[];
}

export function RiskMatrixList(props: Props) {
  const { riskRegister, saving, canManage, memberNames, onSave } = props;
  const [openItemId, setOpenItemId] = useState<number>();

  const getRiskColourData = useRiskColourName();
  const getName = (val, field) => {
    const { levelName } = getRiskColourData(val, field);
    return levelName;
  };

  const { impact, likelihood } = useParams<{
    impact: string;
    likelihood: string;
  }>();

  const items = useMemo(() => {
    if (!riskRegister) return [];

    return (riskRegister.items || [])
      .filter(item => !item.is_archived)
      .filter(
        item =>
          item.score_impact_mitigated === +impact &&
          item.score_likelihood === +likelihood,
      );
  }, [riskRegister, impact, likelihood]);

  return (
    <>
      <Stack sx={{ my: 4 }} spacing={3}>
        {items.length ? (
          <>
            <Box>
              <Button
                startIcon={<RiArrowLeftCircleFill />}
                variant="text"
                component={Link}
                to={'../risk-matrix'}
              >
                Back to risk matrix
              </Button>
            </Box>
            <Box>
              <Stack spacing={3}>
                <Typography variant="h2">
                  {getName(items[0]?.score_likelihood, 'score_likelihood')}{' '}
                  likelihood +{' '}
                  {getName(
                    items[0]?.score_impact_mitigated,
                    'score_impact_mitigated',
                  )}{' '}
                  impact
                </Typography>
                <Typography>
                  {items.length} risks with likelihood and impact
                </Typography>
              </Stack>
            </Box>
            <RiskSubheader subheader="active" />

            {items.map(item => (
              <RiskRegisterItem
                key={key(item)}
                risk_areas={riskRegister.risk_areas}
                item={item}
                toggleOpen={() =>
                  setOpenItemId(openId => (openId === item.id ? null : item.id))
                }
                open={openItemId === item.id}
                memberNames={memberNames}
                canManage={canManage}
                saving={saving}
                onSave={onSave}
              />
            ))}
          </>
        ) : (
          <>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Button
                startIcon={<RiArrowLeftCircleFill />}
                variant="text"
                component={Link}
                to={'../active'}
              >
                Back to risk-register
              </Button>
              <Button variant="outlined" component={Link} to={'../add'}>
                Add a new risk
              </Button>
            </Stack>
            <Box>
              <Typography>You haven't got any active risks</Typography>
            </Box>
          </>
        )}
      </Stack>
    </>
  );
}
