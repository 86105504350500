import React from 'react';

export function YahooIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
        width={15}
        height={15}
      >
        <path
          style={{ fill: '#8921b4' }}
          d="M15 7.5c0 4.14-3.36 7.5-7.5 7.5C3.36 15 0 11.64 0 7.5 0 3.36 3.36 0 7.5 0 11.64 0 15 3.36 15 7.5z"
        />
        <path
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: '#fdfdfd',
          }}
          d="M12.01 5.09c0-.03-.01-.04-.04-.04-.2 0-.4 0-.6.01-.94.01-1.87.02-2.8.04-.19 0-.39.01-.58.01-.03 0-.04.01-.04.04v.55c0 .02 0 .04.03.04.12 0 .24.01.36.02.35.01.71.02 1.06.04h.05c-.02.03-.04.04-.06.06-.62.53-1.23 1.05-1.85 1.57-.05.05-.05.05-.09 0-.54-.66-1.07-1.32-1.6-1.98-.12-.15-.25-.3-.37-.46.01 0 .02-.01.03-.01.2-.02.4-.04.61-.05.21-.01.43-.03.64-.04.03 0 .04-.01.04-.04.01-.19.03-.37.05-.56.01-.04 0-.04-.04-.04H2.04c-.03 0-.04.01-.04.04v.54c0 .06 0 .06.06.06.45.03.89.07 1.35.1.03 0 .05.01.07.04.4.47.78.94 1.18 1.41.51.63 1.02 1.25 1.54 1.88.02.02.03.04.03.07v1.65c0 .02 0 .04-.03.04-.22.02-.45.03-.67.05l-.81.06c-.03 0-.05.01-.05.05v.47c0 .06-.01.06.05.06h4.71c.03 0 .04-.01.04-.03v-.5c0-.02-.01-.03-.03-.03-.07 0-.14-.01-.21-.01-.42-.02-.84-.04-1.26-.04-.04 0-.05-.01-.05-.05 0-.61.01-1.2.03-1.79 0-.02.01-.04.03-.05.2-.19.41-.37.61-.55.67-.59 1.33-1.19 2-1.79a.09.09 0 0 1 .07-.03c.1 0 .19-.01.29-.02.34-.02.68-.05 1.02-.08.03 0 .04-.01.04-.04v-.67zM12.99 6.54l-.03.12-.3 1.24-.33 1.39c0 .01-.01.01-.02.01-.17-.02-.33-.04-.49-.05h-.03c-.01 0-.01 0-.01-.01v-.33c0-.16.01-.32.01-.48 0-.17.01-.35.01-.52 0-.17.01-.35.01-.52 0-.17 0-.35.01-.52 0-.12.01-.25.01-.37 0-.01 0-.02.01-.02.1.01.2.01.3.02.06 0 .13.01.19.01.12.01.24.01.36.02.08 0 .16.01.24.01.02-.01.04-.01.06 0zM12.05 10.24h-.31c-.01 0-.02 0-.02-.02l-.03-.3-.03-.29c0-.01 0-.01.01-.01.05 0 .11.01.16.01.07.01.15.01.22.02.09.01.17.01.26.02h.05c.01 0 .01 0 .01.01v.53c0 .01-.01.01-.01.01-.09.01-.2.02-.31.02z"
        />
      </svg>
    </>
  );
}
