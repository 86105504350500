import { call, takeLatest, cancelled, put, select } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { conflictsActions as actions } from './';
import { createRequestSaga } from 'utils/saga';
import { selectConflictFormWidgetSettings } from '../../../widgets/ConflictFormWidget/slice/selectors';

const init = createRequestSaga({
  actionType: actions.openRequest.type,
  url: `organisations`,
  data: d => ({ is_member: true }),
  successAction: actions.openSuccess,
  failureAction: actions.openFailed,
});

const loadConflicts = createRequestSaga({
  actionType: actions.loadConflictsRequest.type,
  url: `conflicts/my-conflicts`,
  data: d => d,
  successAction: actions.loadConflictsSuccess,
  failureAction: actions.loadConflictsFailed,
});

const deleteConflict = createRequestSaga({
  actionType: actions.deleteConflictRequest.type,
  url: p => `conflicts/${p.id}`,
  method: 'delete',
  successAction: actions.deleteConflictSuccess,
  failureAction: actions.deleteConflictFailed,
});

const closeOffConflict = createRequestSaga({
  actionType: actions.closeOffConflictRequest.type,
  url: p => `conflicts/${p.id}/close`,
  method: 'post',
  successAction: actions.closeOffConflictSuccess,
  failureAction: actions.closeOffConflictFailed,
});

const saveConflict = createRequestSaga({
  actionType: actions.saveConflictRequest.type,
  url: p => `conflicts/${p.id || ''}`,
  method: p => (p.id ? 'put' : 'post'),
  data: d => d,
  successAction: actions.saveConflictSuccess,
  failureAction: actions.saveConflictFailed,
});

export function* conflictsSaga() {
  yield takeLatest(actions.loadConflictsRequest.type, loadConflicts);
  yield takeLatest(actions.deleteConflictRequest.type, deleteConflict);
  yield takeLatest(actions.closeOffConflictRequest.type, closeOffConflict);
  yield takeLatest(actions.saveConflictRequest.type, saveConflict);
  yield takeLatest(actions.openRequest.type, init);
}
