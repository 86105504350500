import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useMeetingsSlice } from './slice/hook';
import { selectLoadMeetings } from './slice/selectors';
import { IOrganisation } from 'types/types';
import { useEffectOnce } from 'utils/useEffectOnce';
import { useCanCreateMeetings } from './useCanCreateMeetings';
import { MeetingsCarousel } from './MeetingsCarousel';
import { MeetingCard } from './MeetingCard';
import key from 'weak-key';
import moment from 'moment-timezone';
import { Link, useNavigate } from 'react-router-dom';
import {
  MeetingFormDialog,
  useMeetingFormWidget,
} from '../../widgets/MeetingFormWidget';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';

interface Props {
  organisation: IOrganisation;
}

export function Upcoming(props: Props) {
  const [, setInitialised] = useState(false);
  const { organisation } = props;
  const { actions } = useMeetingsSlice();
  const loadMeetings = useSelector(selectLoadMeetings);
  const dispatch = useDispatch();
  const { open, close, isOpen } = useMeetingFormWidget();

  const navigate = useNavigate();
  const load = useCallback(() => {
    dispatch(actions.loadMeetingsRequest({ organisation_id: organisation.id }));
  }, [organisation]);

  useHandleApiResponse(loadMeetings, null, {
    onError: () => {
      if (loadMeetings.error?.code === 401) {
        // todo - redirect away from page (and log?)
        return;
      }
    },
    onSuccess: () => {
      setInitialised(true);
    },
  });

  useEffectOnChange(() => {
    load();
  }, organisation.id);

  useEffectOnce(() => {
    load();
  });

  const canCreateMeetings = useCanCreateMeetings(organisation);

  const futureAndCurrentMeetings = useMemo(() => {
    return [
      ...loadMeetings.data
        .filter(m => m.historical_status === 'future')
        .slice(-1000),
      ...loadMeetings.data
        .filter(m => m.historical_status === 'current')
        .slice(-1000),
    ];
  }, [loadMeetings.data]);

  const recentMeetings = useMemo(() => {
    return loadMeetings.data
      .filter(meeting => {
        const numberOfMinutesAgo = moment(meeting.date_end).diff(
          moment(),
          'minutes',
        );
        return numberOfMinutesAgo < 0 && numberOfMinutesAgo > -(124 * 60 * 24);
      })
      .sort((a, b) => {
        const diffA = moment(a.date_end).diff(moment(), 'minutes');
        const diffB = moment(b.date_end).diff(moment(), 'minutes');
        return diffA - diffB;
      });
  }, [loadMeetings.data]);

  return (
    <>
      {canCreateMeetings && (
        <>
          <MeetingFormDialog
            open={isOpen}
            onClose={close}
            onChange={meeting =>
              navigate(
                `/organisation/${organisation.id}/meetings/${meeting.id}`,
              )
            }
          />
        </>
      )}
      <Box
        sx={{
          py: 4,
        }}
      >
        <Stack spacing={6}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack>
              <Typography variant="h2">Upcoming & recent</Typography>
              {!futureAndCurrentMeetings.length && (
                <Typography>
                  You have no upcoming meetings.{' '}
                  {canCreateMeetings && (
                    <Box
                      component={'a'}
                      sx={{
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() => open({ organisation_id: organisation.id })}
                    >
                      Create one
                    </Box>
                  )}
                </Typography>
              )}
            </Stack>
          </Stack>
          {!!futureAndCurrentMeetings.length && (
            <MeetingsCarousel meetings={futureAndCurrentMeetings} />
          )}

          {!!recentMeetings.length && (
            <Box>
              <Stack spacing={4}>
                <Typography variant="h2">Latest meetings</Typography>
                <Typography variant="body2">
                  These meetings have passed, but minutes, transcriptions and
                  documents may still be of current use to you.
                </Typography>
                <Box>
                  <Grid container spacing={4}>
                    {recentMeetings.reverse().map(meeting => (
                      <Grid item key={key(meeting)} xs={12} md={6} lg={4}>
                        <MeetingCard meeting={meeting} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Stack>
            </Box>
          )}
          <Button
            component={Link}
            variant="text"
            to={'../past'}
            sx={{ p: 0, justifyContent: 'start' }}
          >
            Browse all past meetings
          </Button>
        </Stack>
      </Box>
    </>
  );
}
