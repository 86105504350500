import React from 'react';

interface LinkRendererProps {
  location: string;
}

const LinkRenderer: React.FC<LinkRendererProps> = ({ location }) => {
  // Regex to check if the string is a URL
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  const getDisplayText = (url: string): string => {
    return url.replace(/^https?:\/\//, ''); // Removes the protocol
  };

  // Function to determine if the location is a link and which type
  const getLinkDetails = (location: string) => {
    if (urlPattern.test(location)) {
      const displayText = getDisplayText(location);
      if (location.includes('meet.google.com'))
        return { isLink: true, text: 'Join Google Meet', href: location };
      if (location.includes('zoom.us'))
        return { isLink: true, text: 'Join Zoom', href: location };
      if (location.includes('teams.microsoft'))
        return { isLink: true, text: 'Join Teams', href: location };
      return { isLink: true, text: displayText, href: location }; // Generic link
    }
    return { isLink: false, text: location }; // Not a link
  };

  const { isLink, text, href } = getLinkDetails(location);

  // Render based on the type of location
  return (
    <span>
      {isLink ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ) : (
        <span>{text}</span>
      )}
    </span>
  );
};

export default LinkRenderer;
