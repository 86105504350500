import { createSlice } from 'utils/@reduxjs/toolkit';
import { DashboardActionsWidgetState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { PayloadAction } from '@reduxjs/toolkit';
import { ResponseErrorPayload } from 'utils/request';
import { ActionStatus, IAction } from 'types/types';

export const initialState: DashboardActionsWidgetState = {
  loadList: getApiCallInitialState<IAction[]>('list'),
  updateAction: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'dashboardActionsWidget',
  initialState,
  reducers: {
    loadListInit(state, action: PayloadAction) {
      state.loadList = initialState.loadList;
    },
    loadListRequest(
      state,
      action: PayloadAction<{ organisation_id: number | string }>,
    ) {
      state.loadList.loading = true;
    },
    loadListSuccess(state, action: PayloadAction<{ data: IAction[] }>) {
      state.loadList.loading = false;
      state.loadList.touched = true;
      state.loadList.data = action.payload.data;
    },
    loadListFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadList.loading = false;
      state.loadList.error = action.payload;
    },
    updateActionRequest(
      state,
      action: PayloadAction<{
        action_id: number | string;
        subtask_id?: number | string;
        clearSubtasks?: boolean;
        status: ActionStatus;
      }>,
    ) {
      state.updateAction.loading = true;
    },
    updateActionSuccess(state, action: PayloadAction<{ data: IAction }>) {
      state.updateAction.loading = false;
      state.updateAction.touched = true;
      state.updateAction.data = action.payload.data;
    },
    updateActionFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.updateAction.loading = false;
      state.updateAction.error = action.payload;
    },
  },
});

export const { actions: dashboardActionsWidgetActions } = slice;
