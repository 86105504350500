import { put, takeLatest } from 'redux-saga/effects';
import { homePageActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadHomePage = createRequestSaga({
  actionType: actions.loadHomePageRequest.type,
  url: `organisations`,
  onSuccess: function* (response: any, dataSent) {
    yield put(actions.loadHomePageSuccess({ organisations: response.data }));
  },
  failureAction: actions.loadHomePageFailed,
});

export function* homePageSaga() {
  yield takeLatest(actions.loadHomePageRequest.type, loadHomePage);
}
