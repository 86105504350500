import { takeLatest, call, cancelled, put } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { trainingRecordActions as actions } from '.';

function* loadTrainingRecord(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `training-record`,
      method: 'get',
      data: action.payload,
    });

    yield put(actions.loadRecordSuccess(payload.data));
  } catch (e) {
    yield put(actions.loadRecordFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* trainingRecordSaga() {
  yield takeLatest(actions.loadRecordRequest.type, loadTrainingRecord);
}
