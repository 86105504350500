import React, { ReactNode } from 'react';
import {
  FormControl,
  OutlinedSelectProps,
  Select,
  Typography,
} from '@mui/material';
import { RiArrowDropDownLine } from 'react-icons/ri';

interface Props extends OutlinedSelectProps {
  status: string;
  children: ReactNode;
  label: string;
}

export function ActionStatusSelect(props: Props) {
  const { status, children, label, ...selectProps } = props;
  const statusColor = status === 'open' ? 'secondary' : 'success';
  return (
    <FormControl fullWidth>
      <Select
        value={status}
        displayEmpty
        IconComponent={RiArrowDropDownLine}
        renderValue={() => (
          <Typography variant="body2" fontWeight="bold">
            {label}
          </Typography>
        )}
        {...selectProps}
        sx={{
          height: 45,
          p: 0 + ' !important',
          justifyContent: 'center',
          pointerEvents: 'auto',
          '.MuiOutlinedInput-notchedOutline': { border: 'none' },
          backgroundColor: `${statusColor}.light`,
          color: `${statusColor}.main`,
          '&:hover, &.isActive': {
            backgroundColor: `${statusColor}.light`,
            color: `${statusColor}.main`,
          },
          '.MuiSelect-icon': {
            color: `${statusColor}.main`,
            fontSize: 25,
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
}
