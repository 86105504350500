import { Alert, BoxProps, Stack, Typography } from '@mui/material';
import React, { ComponentProps } from 'react';

interface Props extends BoxProps {
  alertProps?: ComponentProps<typeof Alert>;
}

export function PasswordCriteriaInfo(props: Props) {
  const { alertProps } = props;
  return (
    <Alert variant="outlined" severity="info" {...alertProps}>
      <Typography variant="body2">
        Your password must meet the following criteria:
      </Typography>
      <Stack
        typography={'body2'}
        component={'ul'}
        spacing={0.5}
        sx={{ marginTop: '10px !important', paddingLeft: 2 }}
      >
        <Typography variant="body2" component={'li'}>
          At least 10 characters long
        </Typography>
        <Typography variant="body2" component={'li'}>
          Contains at least one number
        </Typography>
        <Typography variant="body2" component={'li'}>
          Contains at least one lowercase letter
        </Typography>
        <Typography variant="body2" component={'li'}>
          Contains at least one uppercase letter
        </Typography>
        <Typography variant="body2" component={'li'}>
          Contains at least one special character (!?.@#$%^&*)
        </Typography>
      </Stack>
    </Alert>
  );
}
