import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useEffectOnChange } from 'utils/useEffectOnChange';

type Options = {
  successMessage?: string;
  errorMessage?: string;
  onSuccess?: () => void;
  onError?: () => void;
};

function useHandleApiResponse(
  response: { loading: boolean; error?: { message: string } },
  successMessage = 'Operation successful',
  { errorMessage, onSuccess, onError }: Options = {},
) {
  const snackbar = useSnackbar();

  useEffectOnChange(
    () => {
      if (response.error?.message === 'Token has expired') {
        return;
      }
      if (response.error && errorMessage !== null) {
        snackbar.enqueueSnackbar(errorMessage || response.error.message, {
          variant: 'error',
        });
        if (onError) onError();
      } else {
        if (successMessage) {
          snackbar.enqueueSnackbar(successMessage, {
            variant: 'success',
          });
        }
        if (onSuccess) onSuccess();
      }
    },
    response.loading,
    false,
  );
}

export default useHandleApiResponse;
