import React from 'react';

export function PhoneLockLine() {
  return (
    <>
      <svg
        width="137"
        height="137"
        viewBox="0 0 137 137"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1915_47253)">
          <path
            d="M102.75 11.418C104.264 11.418 105.716 12.0194 106.787 13.0899C107.857 14.1604 108.459 15.6124 108.459 17.1263V57.0846H97.042V22.8346H39.9587V114.168H68.5003V125.585H34.2503C32.7364 125.585 31.2844 124.983 30.2139 123.913C29.1434 122.842 28.542 121.39 28.542 119.876V17.1263C28.542 15.6124 29.1434 14.1604 30.2139 13.0899C31.2844 12.0194 32.7364 11.418 34.2503 11.418H102.75ZM102.75 68.5013C107.292 68.5013 111.648 70.3055 114.86 73.5171C118.071 76.7287 119.875 81.0845 119.875 85.6263V91.3346H125.584V119.876C125.584 121.39 124.982 122.842 123.912 123.913C122.841 124.983 121.389 125.585 119.875 125.585H85.6253C84.1114 125.585 82.6594 124.983 81.5889 123.913C80.5184 122.842 79.917 121.39 79.917 119.876V91.3346H85.6253V85.6263C85.6253 81.0845 87.4296 76.7287 90.6411 73.5171C93.8527 70.3055 98.2085 68.5013 102.75 68.5013ZM114.167 102.751H91.3337V114.168H114.167V102.751ZM102.75 79.918C99.8505 79.918 97.042 82.4867 97.042 85.6263V91.3346H108.459V85.6263C108.459 84.1124 107.857 82.6604 106.787 81.5899C105.716 80.5194 104.264 79.918 102.75 79.918Z"
            fill="#F0B04F"
          />
        </g>
        <defs>
          <clipPath id="clip0_1915_47253">
            <rect width="137" height="137" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
