/**
 *
 * ProfilePage
 *
 */
import * as React from 'react';
import { Container } from '@mui/material';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PersonalDetails } from '../PersonalDetails';
import { Conflicts } from '../Conflicts';
import { TrainingRecord } from '../TrainingRecord';
import { useSelector } from 'react-redux';
import { SecurityAndPasswords } from '../SecurityAndPasswords';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { SectionHeader } from 'app/components/SectionHeader';
import { SectionMenu } from 'app/components/SectionMenu';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useRoutedTabs } from 'app/providers/AuthProvider/useRoutedTabs';
import { AddDeclarationForm } from '../Conflicts/AddDeclarationForm';

interface Props {}

export function ProfilePage(props: Props) {
  const { loadUser } = useSelector(selectAuthProvider);
  const location = useLocation();

  // const { RoutedTabs } = useRoutedTabs(
  //   [
  //     { label: 'Personal details', to: './', route: './' },
  //     { label: 'Security & passwords', to: 'security' },
  //     { label: 'Declarations of interest', to: 'declarations' },
  //     {
  //       label: 'Academy record',
  //       to: 'academy-record',
  //       hidden: !loadUser.data || !loadUser.data.thinkific_enabled,
  //     },
  //     { label: 'Skills', to: 'skills', hidden: true },

  //     {
  //       label: 'Onboarding actions',
  //       to: 'actions',
  //       disabled: true,
  //       hidden: true,
  //     },
  //   ],
  //   `/profile`,
  // );

  if (!loadUser.data) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <SectionHeader
        title="My profile"
        sectionMenu={
          <>
            <SectionMenu
              title="Personal details"
              path="./personal-details"
              isActive={location.pathname === '/profile/personal-details'}
            />

            <SectionMenu
              title="Security & passwords"
              path="./security"
              isActive={location.pathname === '/profile/security'}
            />

            <SectionMenu
              title="Declarations of Interest"
              path="./declarations"
              isActive={location.pathname === '/profile/declarations'}
            />
            <SectionMenu
              title="Academy record"
              path="./academy-record"
              isActive={location.pathname === '/profile/academy-record'}
              hidden={!loadUser.data || !loadUser.data.thinkific_enabled}
            />
            <SectionMenu
              title="Onboarding actions"
              path="./actions"
              isActive={location.pathname === '/profile/actions'}
              hidden={true}
            />
          </>
        }
      />
      <Container>
        <Routes>
          <Route
            path={'personal-details'}
            element={
              <>
                <PersonalDetails />
              </>
            }
          />
          <Route
            path={'security/*'}
            element={
              <>
                <SecurityAndPasswords />
              </>
            }
          />
          <Route
            path={'declarations/*'}
            element={
              <>
                <Conflicts />
              </>
            }
          />
          {/* <Route path={'skills/*'} element={<Skills />} /> */}
          <Route
            path={'academy-record/*'}
            element={
              <>
                <TrainingRecord />
              </>
            }
          />

          <Route
            path={'*'}
            element={<Navigate to={'personal-details'} replace />}
          />
        </Routes>
      </Container>
    </>
  );
}
