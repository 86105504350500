import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import RiskHeaderMenu from '../RiskHeaderMenu';
import { ColumnsIcon } from '../assets/ColumnsIcon';
import { ReactComponent as IconFinancial } from './risk-icon-financial.svg';
import { ReactComponent as IconGovernance } from './risk-icon-governance.svg';
import { ReactComponent as IconEnvironmental } from './risk-icon-environmental.svg';
import { RiskAreaAction, RiskHeaderIcon } from 'types/types';

interface Props {
  title: string;
  icon?: RiskHeaderIcon;
  numberOfRisks: number;
  onRiskAreaAction: (action: RiskAreaAction) => void;
  onRename: () => void;
  canManage?: boolean;
}

export function RiskHeader(props: Props) {
  const {
    title,
    icon = 'default',
    numberOfRisks,
    onRiskAreaAction,
    onRename,
    canManage = false,
  } = props;

  const headerIcon = useMemo(() => {
    switch (icon) {
      case 'financial':
        return <IconFinancial />;
      case 'environmental-external':
        return <IconEnvironmental />;
      case 'governance':
        return <IconGovernance />;
      case 'operational':
      case 'legal-regulatory-compliance':
      default:
        return <ColumnsIcon />;
    }
  }, [icon]);

  const getNumberOfRisksMessage = () => {
    if (numberOfRisks) {
      return numberOfRisks === 1
        ? `${numberOfRisks} risk`
        : `${numberOfRisks} risks`;
    } else {
      return 'No risks';
    }
  };

  return (
    <Box sx={{ py: 1, backgroundColor: 'grey.200' }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box>
          <Stack direction={'row'} spacing={0} alignItems={'center'}>
            <Stack width={55} justifyContent={'center'} alignItems={'center'}>
              {headerIcon}
            </Stack>
            <Typography variant="h5">{title}</Typography>
            <Typography
              sx={{
                '&::before': {
                  content: '"\\2022"',
                  mx: 2,
                },
              }}
              variant="body2"
            >
              {getNumberOfRisksMessage()}
            </Typography>
          </Stack>
        </Box>
        {!!canManage && (
          <RiskHeaderMenu
            canDelete={numberOfRisks === 0}
            onRename={onRename}
            onRiskAreaAction={onRiskAreaAction}
          />
        )}
      </Stack>
    </Box>
  );
}
