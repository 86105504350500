/**
 *  a context provider that allows the user to set an array of menu items to be displayed in the app bar
 */

import React, { createContext, ReactNode, useContext, useState } from 'react';
import { RoutedTabProps } from '../AuthProvider/useRoutedTabs';

interface RoutedTabsContextProps {
  menuLinks: RoutedTabProps[];
  setMenuLinks: (newLinks: RoutedTabProps[]) => void;
}

const RoutedTabsContext = createContext<RoutedTabsContextProps | undefined>(
  undefined,
);

interface NavigationProviderProps {
  children: ReactNode;
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
}) => {
  const [menuLinks, setMenuLinks] = useState<RoutedTabProps[]>([]);

  return (
    <RoutedTabsContext.Provider value={{ menuLinks, setMenuLinks }}>
      {children}
    </RoutedTabsContext.Provider>
  );
};

export const useNavigationProvider = (): RoutedTabsContextProps => {
  const context = useContext(RoutedTabsContext);
  if (context === undefined) {
    throw new Error('useRoutedTabs must be used within a NavigationProvider');
  }
  return context;
};
