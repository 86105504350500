import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import { RiCloseLine, RiQuestionFill } from 'react-icons/ri';
import { theme } from 'styles/theme';
import { TitleAndParagraph } from '../TitleAndParagraph';

interface Props {
  name: string;
  buttonText?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg';
  iconTitle?: string;
}

export function HelpModal(props: Props) {
  const { name, buttonText, maxWidth = 'md', iconTitle } = props;

  const impactTableRows = [
    ['Insignificant', '1', 'No impact on service or reputation'],
    ['Minor', '2', 'Little impact on service or reputation'],
    [
      'Moderate',
      '3',
      'Some service disruption, complaints or litigation probable',
    ],
    ['Major', '4', 'Service disrupted, complaints or litigation probable'],
    [
      'Extreme/Catastrophic',
      '5',
      'Service disrupted, major litigation or reputational damage likely, loss of confidence in Organisation',
    ],
  ];
  const likelihoodTableRows = [
    ['Remote', 1, 'May only occur in exceptional circumstances'],
    ['Unlikely', 2, 'Expected to occur in a few circumstances'],
    ['Possible', 3, 'Expected to occur in some circumstances'],
    ['Probable', 4, 'Expected to occur in many circumstances'],
    [
      'Highly probable',
      5,
      'Expected to occur frequently and in most circumstances',
    ],
  ];

  const content = () => {
    switch (name) {
      case 'risk_name':
        return (
          <>
            <DialogTitle sx={{ py: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>What is the risk?</Box>

                <IconButton
                  onClick={() => setOpen(false)}
                  edge={'end'}
                  size={'large'}
                >
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>

            <DialogContent>
              <Box>
                <Stack spacing={2}>
                  <Typography variant={'body2'} lineHeight={1.8}>
                    The first step in the risk management process is to identify
                    the risk itself. Risk is something uncertain. It may happen.
                    It may not. But either way, it is important because it will
                    have an impact on your organisation and its objectives.
                    These may be positive, negative or neutral.
                  </Typography>
                </Stack>
              </Box>
            </DialogContent>
          </>
        );
      case 'risk_area':
        return (
          <>
            <DialogTitle sx={{ py: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>Risk category</Box>

                <IconButton
                  onClick={() => setOpen(false)}
                  edge={'end'}
                  size={'large'}
                >
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>

            <DialogContent>
              <Box>
                <Stack spacing={2}>
                  <Typography variant={'body2'} lineHeight={1.8}>
                    This is where you should seek to categorise the risk you
                    have identified into a key headline area – is the risk an
                    example of a governance risk, a financial risk or an
                    environmental risk for example (amongst other things).
                  </Typography>
                </Stack>
              </Box>
            </DialogContent>
          </>
        );
      case 'score_likelihood':
        return (
          <>
            <DialogTitle sx={{ py: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>How to estimate likelihood?</Box>

                <IconButton
                  onClick={() => setOpen(false)}
                  edge={'end'}
                  size={'large'}
                >
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>

            <DialogContent>
              <Box>
                <Stack spacing={4}>
                  <Stack>
                    <TitleAndParagraph
                      title={'What is likelihood?'}
                      paragraph={`Likelihood (sometimes called probability) is a risk that
                    an event "may" occur. There are many ways to estimate
                    likelihood – in our feature, we suggest you ‘score’ the
                    likelihood of it occurring from 1 (remote) to 5 (highly
                    probable.)`}
                    />
                    <TitleAndParagraph
                      title={'How to quantify likelihood?'}
                      paragraph={`Likelihood ‘should’ change very little once you have considered and appraised it.  For 
                      example, the likelihood of the sun hitting the earth ‘should’ in theory change very little once 
                      you’ve made your initial assessment of the likelihood...  However, when starting your risk 
                      journey, it can be hard to quantify what the likelihood should be. 
                      
                      \n For our Risk Register feature, we have adopted the 5x5 scoring matrix most commonly in 
                      use in private and non-profit organisations (for example, as recommended by the Charity 
                      Commission in the UK).  This suggests rating likelihood using the following thinking:`}
                    />

                    <TableContainer>
                      <Table aria-label="Impact Table">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                              Description
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: 'bold' }}
                            >
                              Score
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                              Impact on Organisation's service and reputation
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {likelihoodTableRows.map(row => (
                            <TableRow key={row[0]}>
                              <TableCell component="th" scope="row">
                                {row[0]}
                              </TableCell>
                              <TableCell align="center">{row[1]}</TableCell>
                              <TableCell align="left">{row[2]}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Typography variant="body2" lineHeight={1.8}>
                      Use this as a simple guide to quantify your Risks. It
                      isn’t a precise science – the key thing is that you are
                      able to prioritise the threats that face you and devise
                      strategies to combat them.
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </DialogContent>
          </>
        );
      case 'impact_text':
        return (
          <>
            <DialogTitle sx={{ py: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>Risk Impact</Box>

                <IconButton
                  onClick={() => setOpen(false)}
                  edge={'end'}
                  size={'large'}
                >
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>

            <DialogContent>
              <Box>
                <Stack spacing={2}>
                  <TitleAndParagraph
                    title={'What is impact?'}
                    paragraph={`Impact (sometimes called Risk Severity) is the expected harm
                    or adverse effect that may occur due to exposure to the
                    Risk. In other words, it measures how bad things could get
                    if a particular Risk occurs. Whilst you will typically focus
                    on highest impact risks, do not forget that what may be a
                    lower impact risk can change to a very high impact risk
                    because of the possible connection between it happening and
                    triggering the occurrence of other risks. One low impact
                    risk may lead to another and another so that the cumulative
                    impact becomes extreme or catastrophic.`}
                  />
                </Stack>
              </Box>
            </DialogContent>
          </>
        );
      case 'score_impact':
        return (
          <>
            <DialogTitle sx={{ py: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>Impact before controls</Box>

                <IconButton
                  onClick={() => setOpen(false)}
                  edge={'end'}
                  size={'large'}
                >
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>

            <DialogContent>
              <Box>
                <Stack spacing={2}>
                  <TitleAndParagraph
                    title={'How should we estimate impact?'}
                    paragraph={`Impact (sometimes called Risk Severity) is the expected harm
                      or adverse effect that may occur due to exposure to the
                      Risk. In other words, it measures how bad things could get
                      if a particular Risk occurs. Whilst you will typically focus
                      on highest impact risks, do not forget that what may be a
                      lower impact risk can change to a very high impact risk
                      because of the possible connection between it happening and
                      triggering the occurrence of other risks. One low impact
                      risk may lead to another and another so that the cumulative
                      impact becomes extreme or catastrophic.`}
                  />
                  <TableContainer>
                    <Table aria-label="Impact Table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            Description
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                            Score
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            Impact on Organisation's service and reputation
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {impactTableRows.map(row => (
                          <TableRow key={row[0]}>
                            <TableCell component="th" scope="row">
                              {row[0]}
                            </TableCell>
                            <TableCell align="center">{row[1]}</TableCell>
                            <TableCell align="left">{row[2]}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Typography variant="body2" lineHeight={1.8}>
                    Use this as a simple guide to quantify your Risks. It isn’t
                    a precise science – the key thing is that you are able to
                    prioritise the threats that face you and devise strategies
                    to combat them.
                  </Typography>
                </Stack>
              </Box>
            </DialogContent>
          </>
        );
      case 'control_measures_text':
        return (
          <>
            <DialogTitle sx={{ py: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>Control measures</Box>

                <IconButton
                  onClick={() => setOpen(false)}
                  edge={'end'}
                  size={'large'}
                >
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>

            <DialogContent>
              <Box>
                <Stack spacing={2}>
                  <TitleAndParagraph
                    title={'What are control measures?'}
                    paragraph={`Control measures are actions that can be taken to reduce the
                      potential of exposure to the Risk. At this stage you should
                      estimate your exposure to the Risk without you having
                      deployed any solutions or mitigations to the Risk
                      identified. This is sometimes known as ‘raw risk’. There is,
                      in theory, no limit to how many control measures you may
                      have in place to mitigate a Risk. When considering it for
                      the purposes of a risk assessment, we suggest you focus on
                      the two or three key controls in place, in particular those
                      that may have a single point of failure (for example a
                      dependence on a key individual) to highlight`}
                  />
                </Stack>
              </Box>
            </DialogContent>
          </>
        );
      case 'score_impact_mitigated':
        return (
          <>
            <DialogTitle sx={{ py: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>Impact after controls</Box>

                <IconButton
                  onClick={() => setOpen(false)}
                  edge={'end'}
                  size={'large'}
                >
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>

            <DialogContent>
              <Box>
                <Stack spacing={2}>
                  <TitleAndParagraph
                    title={'What is mitigated impact?'}
                    paragraph={` Risk mitigation is a strategy to prepare for and lessen the
                      effects of threats faced by your Organisation. In simplest
                      form, risk mitigation means “making the impact of your
                      identified risk less severe by preventing or reducing it
                      through the controls you put in place”. Your aim by
                      producing a Risk Register is to identify those key risks
                      that affect your organisation, introducing controls to
                      reduce the threat of them to your organisation (or ensuring
                      existing controls work efficiently) and then ensuring that
                      the controls you propose operate and keep your Organisation
                      safe. As part of the leadership team, you need to form a
                      view as to the acceptability of the net risk that remains
                      after the controls are put in place. You cannot reduce risk
                      entirely – your objective should be reduce the risks you
                      face to an acceptable/appropriate level.`}
                  />
                </Stack>
              </Box>
            </DialogContent>
          </>
        );
      case 'mitigated_risk_score':
        return (
          <>
            <DialogTitle sx={{ py: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>Mitigated Risk Score</Box>

                <IconButton
                  onClick={() => setOpen(false)}
                  edge={'end'}
                  size={'large'}
                >
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>

            <DialogContent>
              <Box>
                <Stack spacing={2}>
                  <TitleAndParagraph
                    title={'Mitigated Risk Overview'}
                    paragraph={`The mitigated risk is a crucial aspect of your Risk Register, as it provides a comprehensive evaluation of the potential risks that your project or endeavour might face, considering both the likelihood and impact after the implementation of control measures. This calculation empowers you to make informed decisions and prioritise your risk management strategies effectively.`}
                  />
                  <TitleAndParagraph
                    title={'Calculation Methodology'}
                    paragraph={`The mitigated risk is determined by multiplying the Likelihood and Impact After Controls values and then scaling the result using a predefined rating scale ranging from 1 to 25. This unique approach allows for a nuanced assessment that takes into account the severity of the risk along with its likelihood of occurrence.`}
                  />
                  <TitleAndParagraph
                    title={'Interpreting the Result'}
                    paragraph={`The mitigated risk is determined by multiplying the Likelihood and Impact After Controls values and then scaling the result using a predefined rating scale ranging from 1 to 25. This unique approach allows for a nuanced assessment that takes into account the severity of the risk along with its likelihood of occurrence.
                    The calculated mitigated risk value, aligned with the rating scale, helps you categorise and prioritise risks for further action. By understanding the potential consequences despite control measures, you can allocate resources appropriately and develop targeted strategies to manage these risks effectively.`}
                  />
                  <Stack>
                    <Typography variant="h5">
                      Utilising the Mitigated Risk:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        '&::before': {
                          content: '"\\2022"',
                          mx: 2,
                        },
                      }}
                    >
                      Prioritisation: Arrange risks based on their mitigated
                      risk values to focus on the most critical areas.
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        '&::before': {
                          content: '"\\2022"',
                          mx: 2,
                        },
                      }}
                    >
                      Resource Allocation: Allocate resources proportionally to
                      the level of mitigated risk.
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        '&::before': {
                          content: '"\\2022"',
                          mx: 2,
                        },
                      }}
                    >
                      Mitigation Strategies: Tailor mitigation strategies to
                      each risk's severity and likelihood.
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        '&::before': {
                          content: '"\\2022"',
                          mx: 2,
                        },
                      }}
                    >
                      Reporting and Communication: Communicate the mitigated
                      risk score to stakeholders for transparent risk management
                      discussions.
                    </Typography>
                  </Stack>
                  <Typography variant="body2" lineHeight={1.8}>
                    In sum, the mitigated risk score adds depth and precision to
                    your Risk Register by considering both the impact after
                    controls and the likelihood of occurrence. This rating
                    scale, provides a comprehensive picture of the risks you
                    face and equips you to make informed decisions that steer
                    your organisation towards success while managing potential
                    challenges effectively.
                  </Typography>
                </Stack>
              </Box>
            </DialogContent>
          </>
        );
    }
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  return (
    <>
      {buttonText ? (
        <Button onClick={() => setOpen(true)} variant={'text'}>
          <Typography color={'primary.main'} variant="body1">
            {buttonText}
          </Typography>
        </Button>
      ) : (
        <IconButton
          sx={{ p: 0, mx: 1 }}
          onClick={() => setOpen(true)}
          color="primary"
        >
          <RiQuestionFill title={iconTitle} />
        </IconButton>
      )}
      <Dialog
        open={open}
        fullWidth
        scroll={'body'}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        onClose={() => setOpen(false)}
      >
        <Box sx={{ p: 3, mb: 2 }}>
          {content()}

          <Stack alignItems={'end'} px={3}>
            <Button onClick={() => setOpen(false)} size="small">
              Close
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
}
