import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.meetingFormWidget || initialState;

export const selectMeetingFormWidget = createSelector(
  [selectSlice],
  state => state,
);

export const selectMeetingFormWidgetSettings = createSelector(
  [selectSlice],
  state => state.form,
);
