/**
 *
 * ConflictsTable
 *
 */
import * as React from 'react';
import { MutableRefObject, ReactNode, useMemo } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridApi,
} from '@mui/x-data-grid-pro';
import { IConflict } from 'types/types';
import moment from 'moment-timezone';
import { getDateWIthFallbackString } from 'utils/getDateWIthFallbackString';
import { calculateDataTableHeight } from '../../../styles/theme';

interface Props {
  conflicts: IConflict[];
  pageSize?: number;
  page?: number;
  emptyText?: ReactNode;
  context?: 'profile' | 'meeting';
  editable?: boolean;
  onEdit?: (id: number | string) => void;
  onRemove?: (id: number | string) => void;
  onCloseOff?: (id: number | string) => void;
  apiRef?: MutableRefObject<GridApi>;
}

export function ConflictsTable(props: Props) {
  const {
    conflicts,
    pageSize = 100,
    page = 1,
    emptyText = 'No Declarations of Interest',
    context = 'meeting',
    editable = false,
    onEdit = r => {},
    onRemove = r => {},
    onCloseOff = r => {},
    apiRef,
  } = props;

  const [currentId, setCurrentId] = React.useState<null | number>(null);

  const showFooter = useMemo(() => {
    return conflicts.length > pageSize;
  }, [pageSize, conflicts]);

  if (!conflicts.length) {
    return (
      <Box>
        <Typography variant="body2">{emptyText}</Typography>
      </Box>
    );
  }

  const getItem = id => conflicts.find(conflict => conflict.id === id);

  return (
    <>
      <Box
        sx={{
          height: calculateDataTableHeight(conflicts.length, showFooter, 70),
          maxHeight: '80vh',
          width: '100%',
        }}
      >
        <DataGridPro
          apiRef={apiRef}
          columns={[
            ...(context === 'meeting'
              ? [
                  {
                    flex: 0,
                    field: 'member_name',
                    headerName: 'Name',
                    minWidth: 300,
                    pinnable: false,
                    hideable: false,
                  },
                ]
              : []),
            {
              flex: 0.5,
              field: 'description',
              headerName: 'Description',
              pinnable: false,
              hideable: false,
              renderCell: params => {
                const description = params.value;
                return (
                  <>
                    <Box sx={{ fontWeight: 'bold' }}>{description}</Box>
                  </>
                );
              },
            },
            {
              field: 'date_start',
              minWidth: 160,
              headerName: 'Started',
              type: 'date',
              valueGetter: ({ row }) =>
                getDateWIthFallbackString(row.date_start),
              renderCell: params => {
                const date = params.value;
                return date ? moment(date).format('DD/MM/YYYY') : '';
              },
              pinnable: false,
              hideable: false,
            },
            {
              field: 'date_end',
              minWidth: 160,
              headerName: 'Ended',
              type: 'date',
              valueGetter: ({ row }) => getDateWIthFallbackString(row.date_end),
              renderCell: params => {
                const date = params.value;
                return (
                  <>
                    {!!date ? (
                      <Box>{moment(date).format('DD/MM/YYYY')}</Box>
                    ) : (
                      <Box sx={{ color: 'common.amber' }}>Ongoing</Box>
                    )}
                  </>
                );
              },
              pinnable: false,
              hideable: false,
            },
            ...(context === 'profile'
              ? [
                  {
                    flex: 0.2,
                    field: 'organisation_name',
                    headerName: 'Organisation Name',
                    pinnable: false,
                    hideable: false,
                  },
                ]
              : []),
            ...(editable
              ? [
                  {
                    field: 'actions',
                    headerName: '',
                    sortable: false,
                    resizable: false,
                    pinnable: false,
                    hideable: false,
                    disableColumnMenu: true,
                    renderCell: ({ row, colDef }) => {
                      return (
                        <Stack
                          direction={'row'}
                          justifyContent={'center'}
                          sx={{ width: '100%' }}
                        >
                          <Button
                            variant={'text'}
                            onClick={() => onEdit(row.id!)}
                          >
                            Edit
                          </Button>
                        </Stack>
                      );
                    },
                  },
                ]
              : []),
          ]}
          disableColumnSelector
          hideFooter={!showFooter}
          paginationModel={{ page, pageSize }}
          pagination={showFooter}
          rows={conflicts}
          pinnedColumns={{
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
            right: ['actions'],
          }}
        />
      </Box>
    </>
  );
}
