import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { invitationFormWidgetSaga } from './saga';
import { InvitationFormWidgetState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { ICommittee, IInvitation, IRole } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: InvitationFormWidgetState = {
  form: {
    open: false,
    loading: false,
    committees: [],
    roles: [],
  },
  loadInvitation: getApiCallInitialState<IInvitation>(),
  saveInvitation: getApiCallInitialState(),
};

const slice = createSlice({
  name: 'invitationFormWidget',
  initialState,
  reducers: {
    openRequest(
      state,
      action: PayloadAction<{
        id?: number;
        organisation_id: number;
        committee_id?: number;
      }>,
    ) {
      state.form.id = action.payload.id;
      state.form.open = true;
      state.form.loading = true;
      state.form.organisation_id = action.payload.organisation_id;
    },
    openSuccess(
      state,
      action: PayloadAction<{ committees: ICommittee[]; roles: IRole[] }>,
    ) {
      state.form.committees = action.payload.committees;
      state.form.roles = action.payload.roles;
      state.form.loading = false;
    },
    openFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.form = { ...initialState.form, error: action.payload };
    },
    close(state, action: PayloadAction<void>) {
      state.form = initialState.form;
      state.loadInvitation = initialState.loadInvitation;
      state.saveInvitation = initialState.saveInvitation;
    },
    loadInvitationRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadInvitation.loading = true;
      state.loadInvitation.error = undefined;
    },
    loadInvitationSuccess(state, action: PayloadAction<{ data: IInvitation }>) {
      state.loadInvitation.loading = false;
      state.loadInvitation.error = undefined;
      state.loadInvitation.data = action.payload.data;
    },
    loadInvitationFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadInvitation.loading = false;
      state.loadInvitation.error = action.payload;
    },
    saveInvitationRequest(state, action: PayloadAction<Partial<IInvitation>>) {
      state.saveInvitation.loading = true;
      state.saveInvitation.error = undefined;
    },
    saveInvitationSuccess(
      state,
      action: PayloadAction<{ data: IInvitation | IInvitation }>,
    ) {
      state.saveInvitation.loading = false;
      state.saveInvitation.error = undefined;
      state.saveInvitation.data = action.payload.data;
    },
    saveInvitationFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.saveInvitation.loading = false;
      state.saveInvitation.error = action.payload;
    },
  },
});

export const { actions: invitationFormWidgetActions } = slice;

export const useInvitationFormWidgetSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: invitationFormWidgetSaga });
  return { actions: slice.actions };
};
