/**
 *
 * SecureLinkify
 *
 */
import * as React from 'react';
import { SecureLink } from 'react-secure-link';
import Linkify from 'react-linkify';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<any> {}

export function SecureLinkify(props: Props) {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <SecureLink href={decoratedHref} key={key}>
          {decoratedText}
        </SecureLink>
      )}
    >
      {props.children}
    </Linkify>
  );
}
