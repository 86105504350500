import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SettingsContext = createContext(undefined);

export const SettingsProvider = ({ children }) => {
  const location = useLocation();
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem('settings')) || {};
    setSettings(savedSettings[location.pathname] || {});
  }, [location.pathname]);

  const setSetting = (key, value) => {
    setSettings(prevSettings => {
      const newSettings = { ...prevSettings, [key]: value };

      setSettings(newSettings);

      const allSettings = JSON.parse(localStorage.getItem('settings')) || {};
      allSettings[location.pathname] = newSettings;
      localStorage.setItem('settings', JSON.stringify(allSettings));

      return newSettings;
    });
  };

  return (
    <SettingsContext.Provider value={{ settings, setSetting }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
