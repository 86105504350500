import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.documentFormWidget || initialState;

export const selectDocumentFormWidget = createSelector(
  [selectSlice],
  state => state,
);

export const selectDocumentFormWidgetSettings = createSelector(
  [selectSlice],
  state => state.form,
);
