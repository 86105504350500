import { useDispatch } from 'react-redux';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { useEffect } from 'react';

export function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authProviderActions.userLogoutRequest());
  }, [dispatch]);

  return null;
}
