import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.invitationFormWidget || initialState;

export const selectInvitationFormWidget = createSelector(
  [selectSlice],
  state => state,
);

export const selectInvitationFormWidgetSettings = createSelector(
  [selectSlice],
  state => state.form,
);
