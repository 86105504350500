import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ActionRegisterState } from './types';
import {
  getApiCallInitialState,
  uploadApiCallInitialState,
} from 'types/ApiCall';
import { IActionRegisterItem, IOrganisation } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';
import { ActionRegisterFormType } from '../ActionRegisterForm';

export const initialState: ActionRegisterState = {
  loadActionRegisterOptions: getApiCallInitialState('item', {
    all_committees: [],
    viewable_committees: [],
    managed_committees: [],
  }),
  loadActionRegisterItemList:
    getApiCallInitialState<IActionRegisterItem[]>('list'),
  loadActionRegisterItem: getApiCallInitialState<IActionRegisterItem>(),
  saveActionRegisterItem: getApiCallInitialState(),
  deleteActionRegisterItem: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'actionRegister',
  initialState,
  reducers: {
    loadActionRegisterOptionsRequest(
      state,
      action: PayloadAction<{ id: number }>,
    ) {
      state.loadActionRegisterOptions.loading = true;
      state.loadActionRegisterOptions.error = undefined;
    },
    loadActionRegisterOptionsSuccess(
      state,
      action: PayloadAction<{ data: any }>,
    ) {
      state.loadActionRegisterOptions.loading = false;
      state.loadActionRegisterOptions.error = undefined;
      state.loadActionRegisterOptions.data = action.payload.data;
    },
    loadActionRegisterOptionsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadActionRegisterOptions.loading = false;
      state.loadActionRegisterOptions.error = action.payload;
    },
    loadActionRegisterItemListRequest(
      state,
      action: PayloadAction<{
        organisation_id: string | number;
        committee_id: string | number;
        meeting_id?: string | number;
        status?: string;
        assigned_to?: string;
      }>,
    ) {
      state.loadActionRegisterItemList.loading = true;
      state.loadActionRegisterItemList.error = undefined;
    },
    loadActionRegisterItemListSuccess(
      state,
      action: PayloadAction<{ data: IActionRegisterItem[] }>,
    ) {
      state.loadActionRegisterItemList.loading = false;
      state.loadActionRegisterItemList.error = undefined;
      state.loadActionRegisterItemList.data = action.payload.data;
    },
    loadActionRegisterItemListFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadActionRegisterItemList.loading = false;
      state.loadActionRegisterItemList.error = action.payload;
    },
    loadActionRegisterItemRequest(
      state,
      action: PayloadAction<{ id: number }>,
    ) {
      state.loadActionRegisterItem.loading = true;
      state.loadActionRegisterItem.error = undefined;
    },
    loadActionRegisterItemInit(state) {
      state.loadActionRegisterItem = initialState.loadActionRegisterItem;
    },
    loadActionRegisterItemSuccess(
      state,
      action: PayloadAction<{ data: IActionRegisterItem }>,
    ) {
      state.loadActionRegisterItem.loading = false;
      state.loadActionRegisterItem.error = undefined;
      state.loadActionRegisterItem.data = action.payload.data;
    },
    loadActionRegisterItemFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadActionRegisterItem.loading = false;
      state.loadActionRegisterItem.error = action.payload;
    },
    saveActionRegisterItemRequest(
      state,
      action: PayloadAction<Partial<ActionRegisterFormType>>,
    ) {
      state.saveActionRegisterItem.loading = true;
      state.saveActionRegisterItem.error = undefined;
    },
    saveActionRegisterItemSuccess(state, action: PayloadAction<{ data: any }>) {
      state.saveActionRegisterItem.loading = false;
      state.saveActionRegisterItem.error = undefined;
      state.saveActionRegisterItem.data = action.payload.data;
    },
    saveActionRegisterItemFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.saveActionRegisterItem.loading = false;
      state.saveActionRegisterItem.error = action.payload;
    },
    deleteActionRegisterItemRequest(
      state,
      action: PayloadAction<{ id: number }>,
    ) {
      state.deleteActionRegisterItem.loading = true;
      state.deleteActionRegisterItem.error = undefined;
    },
    deleteActionRegisterItemSuccess(
      state,
      action: PayloadAction<{ data: any }>,
    ) {
      state.deleteActionRegisterItem.loading = false;
      state.deleteActionRegisterItem.error = undefined;
      state.deleteActionRegisterItem.data = action.payload.data;
    },
    deleteActionRegisterItemFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.deleteActionRegisterItem.loading = false;
      state.deleteActionRegisterItem.error = action.payload;
    },
  },
});

export const { actions: actionActions } = slice;
