import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApiCallInitialState } from 'types/ApiCall';
import { IPartner } from 'types/types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { ResponseErrorPayload } from 'utils/request';
import { PartnerDetailsState } from './types';
import { partnerDetailsSaga } from './saga';

export const initialState: PartnerDetailsState = {
  loadPartner: getApiCallInitialState<IPartner>(),
};

export const slice = createSlice({
  name: 'partnerDetails',
  initialState,
  reducers: {
    loadPartnerInit(state, action: PayloadAction<void>) {
      state.loadPartner = initialState.loadPartner;
    },
    loadPartnerRequest(
      state,
      action: PayloadAction<{ organisation_id: string | number }>,
    ) {
      state.loadPartner.loading = true;
      state.loadPartner.error = undefined;
    },
    loadPartnerSuccess(state, action: PayloadAction<{ data: IPartner }>) {
      state.loadPartner.loading = false;
      state.loadPartner.error = undefined;
      state.loadPartner.data = action.payload.data;
    },
    loadPartnerFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadPartner.loading = false;
      state.loadPartner.error = action.payload;
    },
  },
});

export const { actions: partnerDetailsActions } = slice;

