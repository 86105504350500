import React from 'react';
import { Box, CardMedia, Stack } from '@mui/material';
import { TitleAndParagraph } from 'app/components/TitleAndParagraph';
import HelpGuidanceImage from './assets/help_guidance_img.png';

export function RiskHelpGuidance() {
  return (
    <Box sx={{ my: 4, pb: 3 }}>
      <Stack spacing={4}>
        <TitleAndParagraph
          title={`Should the Risk Register focus on strategic or operational risk?`}
          paragraph={`Strategic risks threaten an organisation’s ability to deliver
              expected outcomes, harming it’s ability to survive, grow and
              develop. (Generally) these relate to external factors, for
              example, technological change, a changing competitive landscape,
              changes in customer demands, etc. Operational risks stem from
              inadequate or failed internal procedures, employee errors,
              cybersecurity breaches, etc. There are no hard or fast rules to
              apply when deciding what types of risks to add to your
              Organisation’s Risk Register – it is, after all, your property and
              your assessment of Risk so the world ‘as you see it’! That said,
              most Organisations spend too much time focusing on small,
              potentially insignificant issues within their organisations –
              hence, if in doubt, ask yourself the question, “does this risk
              pose a real impact to my Organisation”? If it does, then it is
              probably best to consider including it in the Register, if not,
              then you have your answer!`}
        />

        <TitleAndParagraph
          title={`How many risks should be on a risk register?`}
          paragraph={`It is all too easy to get overwhelmed when thinking about whether
              to include a new risk. Don’t be! Concentrate on those with the
              greatest potential impact to you and your Organisation. There are
              no hard or fast rules as to how many is ‘good’, but in our
              experience, if you have more than twenty (20) or so key, strategic
              risks, then you be stretched to find a way to manage them all.
              Clearly this will depend on how large, complex or risk averse you
              and your Organisation are, but if in doubt focus on those with
              largest potential impact.`}
        />

        <CardMedia component={'img'} src={HelpGuidanceImage} />
        <TitleAndParagraph
          title={`Keep the description as simple as possible`}
          paragraph={`     The risk description should make it easy for anyone to understand
            the most critical details of each risk. Be as thorough as possible,
            but try to keep the risk description limited to only the essentials
            as you see them. That will help prevent the risk seem overwhelming,
            and hence drive better thinking about the controls you will need to
            manage it.`}
        />
        <TitleAndParagraph
          title={`Don’t overthink the risk and its description or rating`}
          paragraph={`If in doubt, start... View your register as something living and
            dynamic. While you do not want quick responses that lack meaning,
            you also do not want to be agonising over each part trying to get it
            perfect. Your risk register can and should be updated as things
            change or you have new ideas about what might work better. Update
            with your best judgements, but keep your mind open and on the
            lookout for updates and additional information that will improve and
            develop the register in future.`}
        />
        <TitleAndParagraph
          title={`What are some of the pitfalls of a Risk Register to avoid?`}
          paragraph={`Done well, a Risk Register quickly becomes a key management aid for
            improving and developing your Organisation. There are things to
            avoid though, for example: - Do NOT consider that the Register is a
            static tool - a list of risks, updated occasionally. Managing a list
            of what could go wrong is not the same as considering how best to
            achieve objectives, so review, add and amend things where
            appropriate regularly. - The risk ratings in a risk register (e.g.
            low, medium, high) can lack meaning. They do not address how an
            adverse event would affect the objectives of the organisation. Low
            risks do happen after all, so never rest on your laurels. A risk
            register leads to managing and mitigating individual risks in silos
            instead of considering all the things that might happen — the big
            picture — to determine the best cause of action and how much to take
            of which risks. When meeting and reviewing your Register, consider
            things in the round, not in isolation.`}
        />
      </Stack>
    </Box>
  );
}
