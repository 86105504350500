/**
 *
 * AuthSettings
 *
 */
import * as React from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { PasswordField } from 'app/components/PasswordField';
import Yup from 'utils/yup';
import { Link } from 'react-router-dom';
import { PasswordCriteriaInfo } from 'app/components/PasswordCriteriaInfo';

interface Props {
  saving?: boolean;
  onSave: (payload: { new_password: string }) => void;
  cancelPath: string;
}

const schema = Yup.object().shape({
  new_password: Yup.string().required('This field is required').checkPassword(),
  new_password_confirm: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
});

export function ChangePasswordForm(props: Props) {
  const { saving, onSave, cancelPath } = props;

  return (
    <Box>
      <Stack spacing={3} textAlign={'center'}>
        <Typography variant="h3" color={'primary.main'}>
          Change your password
        </Typography>
        <Typography variant={'body1'} textAlign={'center'}>
          Please enter your new password below. We recommend that you don't
          reuse the same password in other accounts.
        </Typography>
        <Box textAlign={'left'}>
          <Box marginBottom={1}>
            <PasswordCriteriaInfo />
          </Box>
        </Box>

        <Formik
          validationSchema={schema}
          initialValues={{
            new_password: '',
            new_password_confirm: '',
          }}
          onSubmit={values => {
            onSave(values);
          }}
        >
          {formik => {
            const { values, setSubmitting, isSubmitting, dirty, isValid } =
              formik;
            if (isSubmitting && !saving) setSubmitting(false);
            return (
              <Form>
                <Stack>
                  <Field
                    component={PasswordField}
                    id="new_password"
                    name="new_password"
                    placeholder="New password"
                    inputProps={{ autoComplete: 'new-password' }}
                    fullWidth
                  />

                  <Field
                    component={PasswordField}
                    id="new_password_confirm"
                    name="new_password_confirm"
                    placeholder="Confirm new password"
                    inputProps={{ autoComplete: 'new-password' }}
                    fullWidth
                  />
                  <Button
                    type={'submit'}
                    disabled={!dirty || saving || isSubmitting || !isValid}
                  >
                    Save changes
                  </Button>
                  <Button
                    to={cancelPath}
                    size="small"
                    component={Link}
                    variant={'text'}
                  >
                    Back to the application
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Stack>
    </Box>
  );
}
