import moment from 'moment-timezone';

export const getDateOfNextWorkingDay = (
  hours: number = 10,
  minutes: number = 0,
) => {
  let nextWorkingDay = moment();
  switch (nextWorkingDay.day()) {
    case 0: // Sunday
      nextWorkingDay.add(1, 'day'); // Move to Monday
      break;
    case 1: // Monday
    case 2: // Tuesday
    case 3: // Wednesday
    case 4: // Thursday
      nextWorkingDay.add(1, 'day'); // Next day
      break;
    case 5: // Friday
      nextWorkingDay.add(3, 'days'); // Move to Monday
      break;
    case 6: // Saturday
      nextWorkingDay.add(2, 'days'); // Move to Monday
      break;
  }
  nextWorkingDay.hours(hours).minutes(minutes).seconds(0).milliseconds(0); // Set time to 10 AM
  return nextWorkingDay;
};
