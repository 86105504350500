/**
 *
 * Login
 *
 */

import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { ForgotPasswordForm } from './Form';
import { AuthPageContainer } from 'app/components/AuthPageContainer';
import { selectForgotPassword } from 'app/providers/AuthProvider/slice/selectors';
import { useSnackbar } from 'notistack';
import { Box, Stack, Typography } from '@mui/material';

interface Props {}

export const ForgotPassword = memo((props: Props) => {
  const data = useSelector(selectForgotPassword);
  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!data.error && data.data) {
      enqueueSnackbar('An email has been sent to reset your password.', {
        variant: 'success',
      });
    } else {
      closeSnackbar();
    }
  }, [closeSnackbar, data.data, data.error, enqueueSnackbar]);

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
        <meta name="description" content="Description of forgot password" />
      </Helmet>

      <AuthPageContainer>
        <>
          <Stack spacing={3} alignItems={'center'} justifyContent={'center'}>
            <Typography variant="h3" color="primary">
              Forgot your password?
            </Typography>
            <Typography textAlign={'center'}>
              Send us your email address in the form below and we'll send you an
              email with instructions on how to reset your password.
            </Typography>
            <Box sx={{ width: '100%' }}>
              <ForgotPasswordForm
                data={data}
                forgotPasswordRequest={data => {
                  dispatch(
                    authProviderActions.forgotPasswordRequest({
                      ...data,
                    }),
                  );
                }}
              />
            </Box>
          </Stack>
        </>
      </AuthPageContainer>
    </>
  );
});
