/**
 *
 * DocumentForm
 *
 */
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { DocumentForm } from './DocumentForm';
import { useDocumentFormWidgetSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocumentFormWidget } from './slice/selectors';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { IDocument } from 'types/types';
import { useSnackbar } from 'notistack';
import { useHasChanged } from 'utils/usePrevious';
import { useEffectOnce } from 'utils/useEffectOnce';
import { noop } from 'utils/noop';

interface Props {
  onChange?: (payload: IDocument) => void;
  categories?: {
    label: string;
    value: string;
  }[];
  defaultCategory?: string;
}

export function DocumentFormWidget(props: Props) {
  const { onChange = noop, categories, defaultCategory } = props;
  const { actions } = useDocumentFormWidgetSlice();
  const dispatch = useDispatch();
  const {
    form: { open, loading, id, parent_entity_id, parent_entity_type },
    loadDocument,
    saveDocument,
    deleteDocument,
  } = useSelector(selectDocumentFormWidget);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const load = useCallback(() => {
    if (id) {
      dispatch(actions.loadDocumentRequest({ id }));
    }
  }, [id]);

  const snackbar = useSnackbar();
  const savingChanged = useHasChanged(saveDocument.loading);
  const deletingChanged = useHasChanged(deleteDocument.loading);

  useEffect(() => {
    if (savingChanged && !saveDocument.loading) {
      if (!saveDocument.loading && saveDocument.error) {
        if (saveDocument.error.code === 401) return;
        snackbar.enqueueSnackbar('Error saving.', { variant: 'error' });
      } else {
        onChange(saveDocument.data);
        snackbar.enqueueSnackbar('Saved.', { variant: 'success' });
        close();
      }
    }
    if (deletingChanged && !deleteDocument.loading) {
      if (!deleteDocument.loading && deleteDocument.error) {
        if (deleteDocument.error.code === 401) return;
        snackbar.enqueueSnackbar('Error deleting.', { variant: 'error' });
      } else {
        onChange(deleteDocument.data);
        snackbar.enqueueSnackbar('Deleted.', { variant: 'success' });
        close();
      }
    }
  });

  useEffect(() => {
    if (open && id) load();
  }, [open]);

  useEffectOnce(() => {
    snackbar.closeSnackbar();
    return () => {
      snackbar.closeSnackbar();
      close();
    };
  });

  const close = () => dispatch(actions.close());

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      fullWidth
      fullScreen={fullScreen}
      scroll={'body'}
      onClose={close}
    >
      {loading || loadDocument.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DocumentForm
            defaultCategory={defaultCategory}
            categories={categories}
            onClose={close}
            saving={saveDocument.loading}
            document={loadDocument.data}
            onSave={payload =>
              dispatch(
                actions.saveDocumentRequest({
                  parent_entity_id,
                  parent_entity_type,
                  ...payload,
                }),
              )
            }
            saveDocument={saveDocument}
          />
        </>
      )}
    </Dialog>
  );
}
