import { takeLatest } from 'redux-saga/effects';
import { checklistsActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadChecklistSettings = createRequestSaga({
  actionType: actions.loadChecklistSettingsRequest.type,
  url: `checklists/settings`,
  data: d => d,
  successAction: actions.loadChecklistSettingsSuccess,
  failureAction: actions.loadChecklistSettingsFailed,
});

const updateChecklistSettings = createRequestSaga({
  actionType: actions.updateChecklistSettingsRequest.type,
  url: `checklists/settings`,
  method: 'put',
  data: d => d,
  successAction: actions.updateChecklistSettingsSuccess,
  failureAction: actions.updateChecklistSettingsFailed,
});

const loadChecklists = createRequestSaga({
  actionType: actions.loadOrganisationChecklistsRequest.type,
  url: `checklists/organisation-checklists`,
  data: d => d,
  successAction: actions.loadOrganisationChecklistsSuccess,
  failureAction: actions.loadOrganisationChecklistsFailed,
});

const loadSubmission = createRequestSaga({
  actionType: actions.loadSubmissionRequest.type,
  url: `checklists/submissions/latest`,
  data: d => d,
  successAction: actions.loadSubmissionSuccess,
  failureAction: actions.loadSubmissionFailed,
});

const loadAllSubmissions = createRequestSaga({
  actionType: actions.loadAllSubmissionsRequest.type,
  url: `checklists/submissions/all`,
  data: d => d,
  successAction: actions.loadAllSubmissionsSuccess,
  failureAction: actions.loadAllSubmissionsFailed,
});

const saveSubmission = createRequestSaga({
  actionType: actions.saveSubmissionRequest.type,
  url: `checklists/submissions/update`,
  method: 'put',
  data: d => d,
  successAction: actions.saveSubmissionSuccess,
  failureAction: actions.saveSubmissionFailed,
});

export function* checklistsSaga() {
  yield takeLatest(
    actions.loadChecklistSettingsRequest.type,
    loadChecklistSettings,
  );
  yield takeLatest(
    actions.updateChecklistSettingsRequest.type,
    updateChecklistSettings,
  );
  yield takeLatest(
    actions.loadOrganisationChecklistsRequest.type,
    loadChecklists,
  );
  yield takeLatest(actions.loadSubmissionRequest.type, loadSubmission);
  yield takeLatest(actions.loadAllSubmissionsRequest.type, loadAllSubmissions);
  yield takeLatest(actions.saveSubmissionRequest.type, saveSubmission);
}
