import React, { useEffect } from 'react';
import { Form as DeclarationForm } from './Form';
import { useDispatch, useSelector } from 'react-redux';
import { useConflictsSlice } from '../slice/hook';
import { selectConflicts } from '../slice/selectors';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import useHandleApiResponse from '../../../../utils/useHandleApiResponse';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

export function AddDeclarationForm() {
  const dispatch = useDispatch();
  const { actions } = useConflictsSlice();
  const navigate = useNavigate();
  const { form, saveConflict } = useSelector(selectConflicts);
  const load = () => {
    dispatch(actions.openRequest({}));
  };

  useEffect(() => {
    load();
    return () => {
      dispatch(actions.close());
    };
  }, []);

  useHandleApiResponse(saveConflict, 'Declaration of Interest saved', {
    errorMessage: 'Unable to save declaration',
    onSuccess: () => {
      navigate('..');
    },
  });

  if (!form.open) {
    return <LoadingIndicator />;
  }
  return (
      <Box sx={{ mt: 4, backgroundColor: 'grey.200', p: 4, borderRadius: 3 }}>
        <Box sx={{ my: 2, mb: 4 }}>
          <DeclarationForm
            saving={saveConflict.loading}
            organisations={form.organisations}
            onSave={values => {
              dispatch(actions.saveConflictRequest(values));
            }}
            />
        </Box>
    </Box>
  );
}
