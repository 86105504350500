// react hook

import { useMemo } from 'react';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { IOrganisation, IUser } from 'types/types';
import { selectAuthProvider } from '../../providers/AuthProvider/slice/selectors';
import { useSelector } from 'react-redux';

export function useCanCreateMeetings(organisation: IOrganisation) {
  const { loadUser } = useSelector(selectAuthProvider);
  const user = loadUser.data;
  const { hasPermission } = useHasPermission();

  return useMemo(() => {
    if (!user) return false;
    return (
      (user.access_organisations.find(o => o.id === organisation.id) || {})
        .committees || []
    ).reduce((a, c) => {
      return a || hasPermission('create-meeting', 'committee', c.id);
    }, false);
  }, [user, organisation]);
}
