import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.meetings || initialState;

export const selectMeetings = createSelector([selectSlice], state => state);
export const selectLoadMeetings = createSelector(
  [selectSlice],
  state => state.loadMeetings,
);
export const selectLoadMeeting = createSelector(
  [selectSlice],
  state => state.loadMeeting,
);
