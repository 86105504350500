import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ChecklistsState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import {
  AbridgedChecklist,
  ChecklistSubmission,
  IChecklistSetting,
} from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: ChecklistsState = {
  loadOrganisationChecklists:
    getApiCallInitialState<AbridgedChecklist[]>('list'),
  loadChecklistSettings: getApiCallInitialState<IChecklistSetting[]>('list'),
  updateChecklistSettings: getApiCallInitialState(),
  loadSubmission: getApiCallInitialState<ChecklistSubmission>(),
  loadAllSubmissions: getApiCallInitialState<ChecklistSubmission[]>('list'),
  saveSubmission: getApiCallInitialState<Partial<ChecklistSubmission>>(),
};

export const slice = createSlice({
  name: 'checklists',
  initialState,
  reducers: {
    initOrganisationChecklists(state) {
      state.loadOrganisationChecklists =
        initialState.loadOrganisationChecklists;
    },
    loadOrganisationChecklistsRequest(
      state,
      action: PayloadAction<Partial<{ organisation_id: number }>>,
    ) {
      state.loadOrganisationChecklists.loading = true;
      state.loadOrganisationChecklists.error = undefined;
    },
    loadOrganisationChecklistsSuccess(
      state,
      action: PayloadAction<{ data: AbridgedChecklist[] }>,
    ) {
      state.loadOrganisationChecklists.loading = false;
      state.loadOrganisationChecklists.error = undefined;
      state.loadOrganisationChecklists.data = action.payload.data;
    },
    loadOrganisationChecklistsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadOrganisationChecklists.loading = false;
      state.loadOrganisationChecklists.error = action.payload;
    },
    updateChecklistSettingsRequest(
      state,
      action: PayloadAction<{
        organisation_id: number | string;
        checklists: IChecklistSetting[];
      }>,
    ) {
      state.updateChecklistSettings.loading = true;
      state.updateChecklistSettings.error = undefined;
    },
    updateChecklistSettingsSuccess(
      state,
      action: PayloadAction<{ data: IChecklistSetting[] }>,
    ) {
      state.updateChecklistSettings.loading = false;
      state.updateChecklistSettings.error = undefined;
      // save to load object to update view
      state.loadChecklistSettings.data = action.payload.data;
    },
    updateChecklistSettingsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.updateChecklistSettings.loading = false;
      state.updateChecklistSettings.error = action.payload;
    },
    loadChecklistSettingsRequest(
      state,
      action: PayloadAction<Partial<{ organisation_id: number }>>,
    ) {
      state.loadChecklistSettings.loading = true;
      state.loadChecklistSettings.error = undefined;
    },
    loadChecklistSettingsSuccess(
      state,
      action: PayloadAction<{ data: IChecklistSetting[] }>,
    ) {
      state.loadChecklistSettings.loading = false;
      state.loadChecklistSettings.error = undefined;
      state.loadChecklistSettings.data = action.payload.data;
    },
    loadChecklistSettingsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadChecklistSettings.loading = false;
      state.loadChecklistSettings.error = action.payload;
    },
    initLatestSubmission(state) {
      state.loadSubmission = initialState.loadSubmission;
    },
    loadSubmissionRequest(
      state,
      action: PayloadAction<Partial<ChecklistSubmission>>,
    ) {
      state.loadSubmission.loading = true;
      state.loadSubmission.error = undefined;
    },
    loadSubmissionSuccess(
      state,
      action: PayloadAction<{ data: ChecklistSubmission }>,
    ) {
      state.loadSubmission.loading = false;
      state.loadSubmission.error = undefined;
      state.loadSubmission.data = action.payload.data;
    },
    loadSubmissionFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadSubmission.loading = false;
      state.loadSubmission.error = action.payload;
    },
    loadAllSubmissionsRequest(
      state,
      action: PayloadAction<Partial<ChecklistSubmission>>,
    ) {
      state.loadAllSubmissions.loading = true;
      state.loadAllSubmissions.error = undefined;
    },
    loadAllSubmissionsSuccess(
      state,
      action: PayloadAction<{ data: Partial<ChecklistSubmission>[] }>,
    ) {
      state.loadAllSubmissions.loading = false;
      state.loadAllSubmissions.error = undefined;
      state.loadAllSubmissions.data = action.payload.data;
    },
    loadAllSubmissionsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadAllSubmissions.loading = false;
      state.loadAllSubmissions.error = action.payload;
    },
    saveSubmissionRequest(
      state,
      action: PayloadAction<
        Partial<ChecklistSubmission> & { due_date?: string; close?: boolean }
      >,
    ) {
      state.saveSubmission.loading = true;
      state.saveSubmission.error = undefined;
    },
    saveSubmissionSuccess(
      state,
      action: PayloadAction<{ data: Partial<ChecklistSubmission> }>,
    ) {
      state.saveSubmission.loading = false;
      state.saveSubmission.error = undefined;
      state.saveSubmission.data = action.payload.data;
    },
    saveSubmissionFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.saveSubmission.loading = false;
      state.saveSubmission.error = action.payload;
      state.saveSubmission.data = undefined;
    },
  },
});

export const { actions: checklistsActions } = slice;
