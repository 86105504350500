import { takeLatest, call, cancelled, put } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { reportsPageActions as actions } from '.';
import { createRequestSaga } from '../../../../utils/saga';

const loadDocuments = createRequestSaga({
  actionType: actions.loadDocumentsRequest.type,
  url: () => 'reports/organisation-documents',
  data: d => d,
  successAction: actions.loadDocumentsSuccess,
  failureAction: actions.loadDocumentsFailed,
});

const loadConflicts = createRequestSaga({
  actionType: actions.loadConflictsRequest.type,
  url: () => 'reports/organisation-conflicts',
  data: d => d,
  successAction: actions.loadConflictsSuccess,
  failureAction: actions.loadConflictsFailed,
});

const loadMembers = createRequestSaga({
  actionType: actions.loadMembersRequest.type,
  url: () => 'reports/organisation-members',
  data: d => d,
  successAction: actions.loadMembersSuccess,
  failureAction: actions.loadMembersFailed,
});

const loadMemberUsersSecurity = createRequestSaga({
  actionType: actions.loadMemberUsersSecurityRequest.type,
  url: () => 'reports/member-users-security',
  data: d => d,
  successAction: actions.loadMemberUsersSecuritySuccess,
  failureAction: actions.loadMemberUsersSecurityFailed,
});

const loadMembersInCommittees = createRequestSaga({
  actionType: actions.loadMembersInCommitteesRequest.type,
  url: () => 'reports/members-in-committees',
  data: d => d,
  successAction: actions.loadMembersInCommitteesSuccess,
  failureAction: actions.loadMembersInCommitteesFailed,
});

export function* reportsPageSaga() {
  yield takeLatest(actions.loadDocumentsRequest.type, loadDocuments);
  yield takeLatest(actions.loadConflictsRequest.type, loadConflicts);
  yield takeLatest(actions.loadMembersRequest.type, loadMembers);
  yield takeLatest(
    actions.loadMemberUsersSecurityRequest.type,
    loadMemberUsersSecurity,
  );
  yield takeLatest(
    actions.loadMembersInCommitteesRequest.type,
    loadMembersInCommittees,
  );
}
