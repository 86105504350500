/**
 *
 * TitledField
 *
 */
import * as React from 'react';
import { ReactNode } from 'react';
import {
  Box,
  Grid,
  Stack,
  StackProps,
  Typography,
  TypographyVariant,
} from '@mui/material';

interface Props extends Pick<StackProps, 'sx'> {
  title: ReactNode;
  children: ReactNode;
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl';
  width?: 1 | 2 | 3 | 4 | 5 | 6;
  visible?: boolean;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  alignTitle?: 'start' | 'center' | 'end';
  helperText?: string;
  variant?: TypographyVariant;
}

export function TitledField(props: Props) {
  const {
    title,
    children,
    breakpoint = 'lg',
    width = 2,
    visible = true,
    direction = 'column',
    alignTitle = 'center',
    sx,
    helperText,
    variant = 'h5',
    ...rProps
  } = props;

  if (!visible) return null;

  return (
    <Box {...rProps}>
      {direction === 'column' ? (
        <Grid container direction={direction}>
          <Grid item xs={12} {...{ [breakpoint]: width }}>
            <Stack spacing={1}>
              <Typography variant={variant}>{title}</Typography>
              {helperText && (
                <Typography variant="body2" color="textSecondary">
                  {helperText}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} {...{ [breakpoint]: 12 - width }}>
            {children}
          </Grid>
        </Grid>
      ) : (
        <Stack direction={direction} alignItems={alignTitle}>
          <Typography variant={variant}>{title}</Typography>

          {helperText && (
            <Typography variant="body2" color="textSecondary">
              {helperText}
            </Typography>
          )}
          <Box>{children}</Box>
        </Stack>
      )}
    </Box>
  );
}
