import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';
import { useField } from 'formik';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';

export const DateFieldWrapper = (props: Partial<DatePickerProps<any>>) => {
  const { onChange, ...rest } = props;
  const [field, meta, helpers] = useField(props.name);
  const { value } = field;

  const { error, touched } = meta;
  const { setValue } = helpers;

  const momentValue = useMemo(() => {
    return value ? moment(value) : null;
  }, [value]);

  return (
    <DatePicker
      {...field}
      {...rest}
      value={momentValue}
      onChange={(date, context) => {
        // Set the time to be 12:00:00 to avoid timezone issues
        date.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
        setValue(date);
        onChange && onChange(date, context);
      }}
      slotProps={{
        textField: {
          error: !!error && touched,
          helperText: touched && error,
        },
        actionBar: {
          actions: ['clear'],
        },
      }}
    />
  );
};
