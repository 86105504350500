import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiRequest } from './request';
import { useSnackbar } from 'notistack';

export function useOpenAcademyPage() {
  const [opening, setOpening] = useState<boolean>(false);
  const snackbar = useSnackbar();
  const getLink = useCallback(async (return_url = '') => {
    const res = await apiRequest({
      url: 'training-record/get-link',
      method: 'post',
      data: {
        return_url,
      },
    });

    return res.academy_link;
  }, []);

  const openLink = useCallback(
    async (return_url = '') => {
      setOpening(true);
      try {
        const link = await getLink(return_url);
        window.open(link, '_blank');
      } catch (e) {
        snackbar.enqueueSnackbar('Error opening academy link.', {
          variant: 'error',
        });
      } finally {
        setOpening(false);
      }
    },
    [getLink],
  );

  useEffect(() => {
    return () => {
      snackbar.closeSnackbar();
    };
  }, []);

  return { openLink, opening };
}
