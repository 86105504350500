/**
 *
 * Conflicts
 *
 */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import { SectionHeader } from '../../components/SectionHeader';
import { List } from './List';
import { AddDeclarationForm } from './AddDeclarationForm';
import { noop } from 'lodash';

interface Props {}

export function Conflicts(props: Props) {
  return (
    <>
      <Helmet title={'My Declarations of Interest'}>
        <meta name="description" content="Declarations of Interest" />
      </Helmet>
      <Container>
        <Routes>
          <Route path={''} element={<List />} />
          <Route path={'add'} element={<AddDeclarationForm />} />
        </Routes>
      </Container>
    </>
  );
}
