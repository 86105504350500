import { useDispatch, useSelector } from 'react-redux';
import { selectOrganisationSelector } from './slice/selectors';
import { useOrganisationSelectorSlice } from './slice';
import { useLocalStorage } from 'usehooks-ts';

export const useOrganisationSelector = () => {
  const dispatch = useDispatch();
  const { currentOrganisations, availableOrganisations, open } = useSelector(
    selectOrganisationSelector,
  );

  const { actions } = useOrganisationSelectorSlice();

  const [id, setLastOrganisationId] = useLocalStorage(
    'lastOrganisationId',
    null,
  );

  return {
    availableOrganisations,
    open,
    setCurrentOrganisationId: (id: number) => {
      if (!currentOrganisations.length || currentOrganisations[0] !== id) {
        dispatch(actions.setCurrentOrganisations([id]));
        setLastOrganisationId(id);
      }
    },
    currentOrganisationId: currentOrganisations.length
      ? currentOrganisations[0]
      : id,
  };
};
