/**
 *
 * CommitteeForm
 *
 */
import * as React from 'react';
import { useEffect } from 'react';
import { CommitteeForm } from './CommitteeForm';
import { useCommitteeFormWidgetSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommitteeFormWidget } from './slice/selectors';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffectOnce } from 'utils/useEffectOnce';
import { useHasChanged } from 'utils/usePrevious';
import { ICommittee } from 'types/types';

interface Props {
  onChange: (payload: ICommittee) => void;
  onDelete?: (payload: ICommittee) => void;
}

export function CommitteeFormWidget(props: Props) {
  const { onChange, onDelete } = props;
  const { actions } = useCommitteeFormWidgetSlice();
  const dispatch = useDispatch();
  const {
    form: { open, loading, id, organisation_id, error },
    loadCommittee,
    saveCommittee,
  } = useSelector(selectCommitteeFormWidget);
  const theme = useTheme();
  const snackbar = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const load = () => {
    if (id) {
      dispatch(actions.loadCommitteeRequest({ id }));
    }
  };

  const savingChanged = useHasChanged(saveCommittee.loading);

  useEffect(() => {
    if (savingChanged && !saveCommittee.loading) {
      if (!saveCommittee.loading && saveCommittee.error) {
        snackbar.enqueueSnackbar('Error saving.', { variant: 'error' });
      } else {
        onChange(saveCommittee.data);
        snackbar.enqueueSnackbar('Saved.', { variant: 'success' });
        close();
      }
    }
  });

  useEffect(() => {
    if (open && id) load();
  }, [open]);

  useEffect(
    () => () => {
      dispatch(actions.close());
    },
    [dispatch],
  );

  useEffectOnce(() => {
    snackbar.closeSnackbar();
    return () => {
      snackbar.closeSnackbar();
    };
  });

  const close = () => dispatch(actions.close());

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      fullWidth
      fullScreen={fullScreen}
      scroll={'body'}
      onClose={close}
    >
      {loading || loadCommittee.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <CommitteeForm
            onDelete={onDelete}
            onClose={close}
            committee={loadCommittee.data}
            onSave={payload =>
              dispatch(
                actions.saveCommitteeRequest({
                  organisation_id,
                  ...payload,
                }),
              )
            }
            saving={saveCommittee.loading}
          />
        </>
      )}
    </Dialog>
  );
}

export function useCommitteeFormWidget() {
  const { actions } = useCommitteeFormWidgetSlice();
  const dispatch = useDispatch();
  const committeeForm = useSelector(selectCommitteeFormWidget);

  const open = payload => {
    dispatch(actions.openRequest(payload));
  };
  const close = () => dispatch(actions.close());

  return { open, close, isOpen: committeeForm.form.open };
}
