import React, { ReactNode, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DataAccordion } from '../DataAccordion';

type IRow = {
  id?: string | number;
  [key: string]: string | number | null | undefined | ReactNode;
};

type TableColumn = {
  field: string | number | any;
  headerName: string;
  type?: 'date';
  flex?: number;
};

interface Props {
  title?: string;
  totalReports: number;
  dataAccordion: ReactNode;
}

export function ReportTableBlock(props: Props) {
  const { title, totalReports, dataAccordion } = props;

  return (
    <>
      <Box>
        <Stack spacing={2}>
          <Box sx={{ borderBottom: '1px solid black', py: 1 }}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant="h5">{title}</Typography>
              <Typography
                color={'grey'[700]}
                sx={{
                  '&::before': {
                    content: '"\\2022"',
                    mx: 2,
                  },
                }}
              >
                {totalReports} {totalReports === 1 ? 'report' : 'reports'}
              </Typography>
            </Stack>
          </Box>
          {dataAccordion}
        </Stack>
      </Box>
    </>
  );
}
