import React from 'react';

export function VerifiedIcon() {
  return (
    <>
      <svg
        width="137"
        height="137"
        viewBox="0 0 137 137"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M68.5 5.70898L115.405 16.1324C118.014 16.7147 119.875 19.0265 119.875 21.7037V78.7129C119.875 90.1638 114.15 100.861 104.622 107.209L68.5 131.292L32.3777 107.209C22.8448 100.855 17.125 90.1638 17.125 78.7186V21.7037C17.125 19.0265 18.9859 16.7147 21.5946 16.1324L68.5 5.70898ZM93.9135 46.9346L65.6573 75.1851L49.5141 59.0419L41.4425 67.1135L65.663 91.334L101.991 55.0062L93.9135 46.9346V46.9346Z"
          fill="#3AD78C"
        />
      </svg>
    </>
  );
}
