export function formatFileSize(bytes: number): string {
  if (isNaN(bytes)) {
    throw new Error('Invalid input: Input must be a number.');
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']; // Units of measurement
  const k = 1024; // Base size in bytes
  if (bytes < k) {
    return `${bytes} Bytes`; // Directly return bytes if less than one KB
  }

  let i = Math.floor(Math.log(bytes) / Math.log(k));
  let number = parseFloat((bytes / Math.pow(k, i)).toFixed(2)); // Round to two decimal places

  return `${number.toLocaleString()} ${sizes[i]}`; // Format number according to locale and append the appropriate size label
}

/*
// Usage example:
console.log(formatFileSize(1024)); // Outputs: "1 KB"
console.log(formatFileSize(1234)); // Outputs: "1.21 KB"
console.log(formatFileSize(1234567)); // Outputs: "1.18 MB"
console.log(formatFileSize(987654321)); // Outputs: "942.08 MB"
*/
