import { createSlice } from 'utils/@reduxjs/toolkit';
import { AcademySeatAllocationsState, LoadListPayload } from './types';
import { apiCallInitialState, getApiCallInitialState } from 'types/ApiCall';
import { PayloadAction } from '@reduxjs/toolkit';
import { ResponseErrorPayload } from '../../../../utils/request';

export const initialState: AcademySeatAllocationsState = {
  loadList: getApiCallInitialState<LoadListPayload>('item', {
    allocations: [],
    members: [],
    seats: 0,
    remaining: 0,
  }),
  allocateSeat: apiCallInitialState,
};

export const slice = createSlice({
  name: 'academySeatAllocations',
  initialState,
  reducers: {
    init(state, action: PayloadAction) {
      return initialState;
    },

    loadListInit(state, action: PayloadAction) {
      state.loadList = initialState.loadList;
    },
    loadListRequest(
      state,
      action: PayloadAction<{
        organisation_id: number | string;
      }>,
    ) {
      state.loadList = {
        ...initialState.loadList,
        data: state.loadList.data,
        loading: true,
        touched: true,
      };
    },
    loadListSuccess(state, action: PayloadAction<{ data: LoadListPayload }>) {
      state.loadList = {
        ...initialState.loadList,
        data: action.payload.data,
        loading: false,
      };
    },
    loadListFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadList = {
        ...initialState.loadList,
        loading: false,
        error: action.payload,
      };
    },
    allocateSeatInit(state, action: PayloadAction) {
      state.allocateSeat = initialState.allocateSeat;
    },
    allocateSeatRequest(
      state,
      action: PayloadAction<{
        organisation_id: number | string;
        member_id: number | string;
      }>,
    ) {
      state.allocateSeat = {
        ...initialState.allocateSeat,
        loading: true,
        touched: true,
      };
    },
    allocateSeatSuccess(state, action: PayloadAction) {
      state.allocateSeat = {
        ...initialState.allocateSeat,
        loading: false,
      };
    },
    allocateSeatFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.allocateSeat = {
        ...initialState.allocateSeat,
        loading: false,
        error: action.payload,
      };
    },
  },
});

export const { actions: academySeatAllocationsActions } = slice;
