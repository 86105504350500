import { useOrganisationPageSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganisationPage } from './slice/selectors';

export function useReloadCurrentOrganisation() {
  const { actions: organisationPageActions } = useOrganisationPageSlice();
  const { loadOrganisation } = useSelector(selectOrganisationPage);
  const dispatch = useDispatch();

  function reloadOrganisation() {
    if (loadOrganisation.data)
      dispatch(
        organisationPageActions.loadOrganisationRequest({
          id: +loadOrganisation.data.id,
        }),
      );
  }

  return { reloadOrganisation, loadOrganisation };
}
