/**
 *
 * IconTitledContent
 *
 */
import * as React from 'react';
import { ReactNode } from 'react';
import { Box, BoxProps, Grid, Stack, Typography } from '@mui/material';

interface Props extends Pick<BoxProps, 'sx'> {
  title: ReactNode;
  icon?: ReactNode;
  children: ReactNode;
  iconProps?: BoxProps;
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl';
  width?: 1 | 2 | 3 | 4 | 5 | 6;
  visible?: boolean;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
}

export function IconTitledContent(props: Props) {
  const {
    title,
    icon,
    children,
    breakpoint = 'md',
    width = 2,
    iconProps = { sx: { color: 'primary.main', fontSize: 28 } },
    visible = true,
    direction = 'row',
    ...rProps
  } = props;

  const { sx, ...rIProps } = iconProps;

  if (!visible) return null;

  return (
    <Box {...rProps}>
      <Grid container direction={direction}>
        <Grid item xs={12} {...{ [breakpoint]: width }}>
          <Stack direction="row" alignItems="center" gap={1} color="grey.700">
            <>
              {/* <Box
                {...rIProps}
                sx={{ display: 'inline-flex', alignItems: 'inherit', ...sx }}
              >
                {icon}
              </Box> */}
              <Typography variant="body1">{title}</Typography>
            </>
          </Stack>
        </Grid>
        <Grid item xs={12} {...{ [breakpoint]: 12 - width }}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}
