import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
  Box,
  Grid,
  IconButton,
  IconButtonProps,
  Stack,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IAbridgedMeeting } from 'types/types';
import key from 'weak-key';
import { MeetingCard } from './MeetingCard';
import Carousel, { CarouselInternalState } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

interface Props {
  meetings: IAbridgedMeeting[];
}

const CustomLeftArrow = (props: IconButtonProps) => (
  <IconButton size="large" color="primary" {...props}>
    <RiArrowLeftSLine />
  </IconButton>
);

const CustomRightArrow = (props: IconButtonProps) => (
  <IconButton size="large" color="primary" {...props}>
    <RiArrowRightSLine />
  </IconButton>
);

export function MeetingsCarousel(props: Props) {
  const { meetings } = props;
  const [state, setState] = useState<CarouselInternalState>();
  const [ref, setRef] = useState<Carousel>();

  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: theme.breakpoints.values.lg },
      items: 3,
      partialVisibilityGutter: meetings.length > 3 ? 40 : 0,
    },
    laptop: {
      breakpoint: {
        max: theme.breakpoints.values.lg,
        min: theme.breakpoints.values.md,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: theme.breakpoints.values.md,
        min: theme.breakpoints.values.sm,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: {
        max: theme.breakpoints.values.sm,
        min: theme.breakpoints.values.xs,
      },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };

  const arrowStyles: SxProps = {
    backgroundColor: 'grey.100',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    opacity: !isXl && 0.8,
  };

  const [leftArrowDisabled, rightArrowDisabled] = useMemo(() => {
    if (!state) return [true, true];
    return [
      state.currentSlide <= 0,
      state.slidesToShow + state.currentSlide >= meetings.length,
    ];
  }, [state]);

  return (
    <Box sx={{ my: 2, position: 'relative' }}>
      {!leftArrowDisabled && (
        <CustomLeftArrow
          sx={{
            left: isXl ? -60 : 0,
            ...arrowStyles,
          }}
          onClick={() => ref.previous(1)}
        />
      )}
      <Carousel
        ref={el => {
          setRef(el);
          setState(el?.state);
        }}
        swipeable
        partialVisible
        arrows={false}
        responsive={responsive}
        keyBoardControl
        afterChange={(previousSlide, state) => setState(state)}
      >
        {meetings
          .slice(0, 250)
          .reverse()
          .map(meeting => (
            <MeetingCard
              sx={{ mr: 2 }}
              key={key(meeting)}
              meeting={meeting}
              subtitleType={'organisation'}
            />
          ))}
      </Carousel>
      {!rightArrowDisabled && (
        <CustomRightArrow
          sx={{
            right: isXl ? -60 : 0,
            ...arrowStyles,
          }}
          onClick={() => ref.next(1)}
        />
      )}
    </Box>
  );
}
