import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Logo } from '../Logo';

interface Props {
  errorMessage?: string;
}

export function InvalidResponse(props: Props) {
  const { errorMessage = 'Something went wrong' } = props;

  return (
    <Container sx={{ py: 8 }}>
      <Box>
        <Stack alignItems={'center'} justifyContent={'center'} spacing={4}>
          <Logo variant="circle-blue" />
          <Typography variant="h5">{errorMessage}</Typography>
        </Stack>
      </Box>
    </Container>
  );
}
