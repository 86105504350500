import { noop } from 'utils/noop';
import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { ReactNode } from 'react';

interface Props {
  title: ReactNode;
  description?: ReactNode;
  buttonProps?: ButtonProps;
  icon?: ReactNode;
  size?: number;
}

export function WizardDownloadButton(props: Props) {
  const { title, description, buttonProps, icon, size = 48 } = props;

  return (
    <Button
      sx={{
        py: 2,
        px: { xs: 0, sm: 4 },
        gap: {
          xs: 4,
          sm: 0,
        },
        textAlign: {xs: 'center', sm: 'left'},
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        alignItems: {xs: 'flex-center', sm:'flex-start'},
      }}
      onClick={noop}
      variant={'text'}
      startIcon={
        icon ? (
          <>
            <Box height={size} width={size}>
              {icon}
            </Box>
          </>
        ) : (
          <svg
            width="47"
            height="48"
            viewBox="0 0 47 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 43.5H9V48H6C2.69063 48 0 45.3094 0 42V6C0 2.69063 2.69063 0 6 0H21.5156C23.1094 0 24.6375 0.628125 25.7625 1.75313L34.2469 10.2375C35.3719 11.3625 36 12.8906 36 14.4844V27H31.5V15H24C22.3406 15 21 13.6594 21 12V4.5H6C5.175 4.5 4.5 5.175 4.5 6V42C4.5 42.825 5.175 43.5 6 43.5ZM16.5 33H19.5C22.3969 33 24.75 35.3531 24.75 38.25C24.75 41.1469 22.3969 43.5 19.5 43.5H18V46.5C18 47.325 17.325 48 16.5 48C15.675 48 15 47.325 15 46.5V34.5C15 33.675 15.675 33 16.5 33ZM19.5 40.5C20.7469 40.5 21.75 39.4969 21.75 38.25C21.75 37.0031 20.7469 36 19.5 36H18V40.5H19.5ZM28.5 33H31.5C33.9844 33 36 35.0156 36 37.5V43.5C36 45.9844 33.9844 48 31.5 48H28.5C27.675 48 27 47.325 27 46.5V34.5C27 33.675 27.675 33 28.5 33ZM31.5 45C32.325 45 33 44.325 33 43.5V37.5C33 36.675 32.325 36 31.5 36H30V45H31.5ZM39 34.5C39 33.675 39.675 33 40.5 33H45C45.825 33 46.5 33.675 46.5 34.5C46.5 35.325 45.825 36 45 36H42V39H45C45.825 39 46.5 39.675 46.5 40.5C46.5 41.325 45.825 42 45 42H42V46.5C42 47.325 41.325 48 40.5 48C39.675 48 39 47.325 39 46.5V34.5Z"
              fill="#4091F7"
            />
          </svg>
        )
      }
      {...buttonProps}
    >
      <Box sx={{ flex: 1, px: 4 }}>
        <Stack spacing={1}>
          <Typography variant={'h4'}>{title}</Typography>
          <Typography color={'grey.700'}>
            {!!description
              ? description
              : `A short overview of the Governance360 Academy - a set of 10, CPD
              accredited modules covering the basics of being a statutory
              director, the role of a Board and everything in between. Access is
              subject to plan (please talk to us if you’d like to know more).`}
          </Typography>
        </Stack>
      </Box>
    </Button>
  );
}
