/**
 *
 * LoggingProvider
 *
 */
import * as React from 'react';
import { useEffect } from 'react';
import { useLoggingProviderSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggingProvider } from './slice/selectors';

interface Props {}

export function LoggingProvider(props: Props) {
  const { actions } = useLoggingProviderSlice();

  const { userActionQueue, logUserActions } = useSelector(
    selectLoggingProvider,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const sendUserActionLog = () => {
      if (logUserActions.loading) return;
      if (!userActionQueue.length) return;
      dispatch(actions.logUserActionsRequest());
    };
    let interval: NodeJS.Timeout;
    interval = setInterval(sendUserActionLog, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [actions, dispatch, logUserActions.loading, userActionQueue]);

  return null;
}
