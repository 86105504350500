/**
 *
 * SetupMfa
 *
 */
import * as React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { LoadingIndicator } from '../LoadingIndicator';
import { RiMessage2Fill } from 'react-icons/ri';
import { BottomLinks } from './BottomLinks';
import { ResendPasscode } from '../ResendPasscode';
import { hidePhoneNo } from 'utils/hideChars';
import { AuthInputField } from '../AuthInputField';
import { TelephoneField } from '../TelephoneField';

interface Props {
  getSetupData: (telephone_mobile: string) => void;
  setupLoading: boolean;
  setupLoaded: boolean;
  onConfirm: (code: string) => void;
  telephone_mobile: string;
}

export function SmsForm(props: Props) {
  const {
    getSetupData,
    onConfirm,
    telephone_mobile,
    setupLoading,
    setupLoaded,
  } = props;
  const [telephoneMobile, setTelephoneMobile] =
    useState<string>(telephone_mobile);
  const [code, setCode] = useState<string>('');

  if (setupLoading) {
    return <LoadingIndicator />;
  }

  if (!setupLoaded) {
    return (
      <Box>
        <Stack>
          <Stack spacing={3} alignItems={'center'} textAlign={'center'}>
            <Box color={'primary.main'}>
              <RiMessage2Fill color="primary.main" fontSize={40} />
            </Box>
            <Typography variant="h3" color={'primary'}>
              Enable 2-factor authentication
            </Typography>
            <Typography variant="body1">
              Lets confirm your phone number so we can start using SMS / text
              messages to verify your login requests
            </Typography>
          </Stack>
          <TelephoneField
            value={telephoneMobile}
            onChange={e => {
              setTelephoneMobile(e);
            }}
          />
          <Button
            disabled={setupLoading || !telephoneMobile}
            onClick={() => getSetupData(telephoneMobile)}
          >
            Send passcode
          </Button>
          <BottomLinks />
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack>
        <Stack spacing={3} alignItems={'center'} textAlign={'center'}>
          <Box color={'primary.main'}>
            <RiMessage2Fill color="primary.main" fontSize={40} />
          </Box>
          <Typography variant="h3" color={'primary'}>
            Enable 2-factor authentication
          </Typography>
          <Typography variant="body1">
            Your account has an extra layer of security enabled. To login, enter
            the passcode sent to {hidePhoneNo(telephoneMobile)}
          </Typography>
        </Stack>

        <AuthInputField
          codeValue={code}
          onChange={v => setCode(v)}
          onEnter={() => onConfirm(code)}
        />

        <Button disabled={code.length < 6} onClick={() => onConfirm(code)}>
          Verify passcode
        </Button>
        <BottomLinks />
        <ResendPasscode
          message={`Send a text message to ${hidePhoneNo(telephoneMobile)}`}
          onChange={() => getSetupData(telephoneMobile)}
          loading={setupLoading}
        />
      </Stack>
    </Box>
  );
}
