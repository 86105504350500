import { Box, BoxProps, Stack } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props extends BoxProps {
  icon: ReactNode;
  size?: number;
}

export function IconSquare(props: Props) {
  const { icon, size = 45, sx, ...rProps } = props;

  return (
    <Box
      sx={{
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '3px solid white',
        borderRadius: 1,
        ...sx,
      }}
      {...rProps}
    >
      <Stack
        sx={{ width: '50%', height: '50%' }}
        justifyContent="center"
        alignItems="center"
      >
        {icon}
      </Stack>
    </Box>
  );
}
