import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import {
  RiLockLine,
  RiLockUnlockLine,
  RiMailLine,
  RiMessage2Fill,
} from 'react-icons/ri';
import { PhoneAuthIcon } from '../assets/PhoneAuthIcon';
import { PhoneLockLine } from '../assets/PhoneLockLine';
import { Link } from 'react-router-dom';

interface Props {
  isEnabled: boolean;
  isVerifying: boolean;
  returnPath: string;
  backPath: string;
  method: string;
}

export function MFAConfirmation(props: Props) {
  const { isEnabled, isVerifying, returnPath, backPath, method } = props;
  const mfaMethodIcon = () => {
    switch (method) {
      case 'auth-app':
        return (
          <PhoneAuthIcon
            color="currentColor"
            width="40"
            height="40"
          ></PhoneAuthIcon>
        );
      case 'sms':
        return <RiMessage2Fill fontSize={40}></RiMessage2Fill>;
      case 'email':
        return <RiMailLine fontSize={40}></RiMailLine>;
    }
  };

  return (
    <Stack
      padding={1}
      direction={'column'}
      spacing={2}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {isEnabled && <Box color={'primary.main'}>{mfaMethodIcon()}</Box>}

      <Box color={'primary.main'}>
        {isVerifying ? (
          <Typography variant="h3">Verifying your authenticator app</Typography>
        ) : (
          <Stack>
            <Typography variant="h3">
              2-factor authentication has been{' '}
              {isEnabled ? 'enabled' : 'removed'}
            </Typography>
          </Stack>
        )}
      </Box>
      <Stack alignItems={'center'}>
        {!isVerifying ? (
          <Stack alignItems={'center'}>
            <Box
              sx={{
                fontSize: 150,
                color: isEnabled ? 'success.main' : 'warning.light',
              }}
            >
              {isEnabled ? <RiLockLine /> : <RiLockUnlockLine />}
            </Box>
            <Typography variant="body1" textAlign={'center'} py={2}>
              2FA has been {isEnabled ? 'enabled' : 'removed'} with immediate
              effect. Next time you log in, you will be asked to verify your
              identity.
            </Typography>
          </Stack>
        ) : (
          <Box sx={{ fontSize: 150, color: 'warning.light' }}>
            <PhoneLockLine />
          </Box>
        )}
      </Stack>

      {!isVerifying && (
        <Stack alignItems={'center'}>
          <Button component={Link} to={returnPath}>
            Return to your account settings
          </Button>
          {!isEnabled && (
            <Button variant="text" component={Link} to={backPath}>
              Re-enable 2-factor authentication
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
