import { KeyboardArrowDownSharp } from '@mui/icons-material';
import { FormControl, FormControlProps, Select } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props extends FormControlProps {
  value: string | number;
  onChange: (e) => void;
  children: ReactNode;
}

export function SelectControl(props: Props) {
  const { value, onChange, children, sx, ...rProps } = props;
  return (
    <FormControl sx={sx} {...rProps}>
      <Select
        sx={{
          height: 40,
          borderRadius: 2,
          '& .MuiSelect-icon': {
            fontSize: 30,
            color: 'primary.main',
            fontWeight: 900,
          },
        }}
        IconComponent={KeyboardArrowDownSharp}
        value={value}
        displayEmpty
        onChange={onChange}
      >
        {children}
      </Select>
    </FormControl>
  );
}
