import { call, put, takeLatest } from 'redux-saga/effects';
import { setToken } from 'utils/request';
import { signupActions as actions } from './';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { createRequestSaga } from 'utils/saga';

const getInvitation = createRequestSaga({
  actionType: actions.getInvitationRequest.type,
  url: ({ code }) => `invitations/${code}`,
  successAction: actions.getInvitationSuccess,
  failureAction: actions.getInvitationFailed,
});

const signup = createRequestSaga({
  actionType: actions.signupRequest.type,
  url: 'auth/signup',
  method: 'post',
  data: d => d,
  onSuccess: function* (response: any, dataSent) {
    if (response.token) {
      yield call(setToken, response.token);
      yield put(authProviderActions.loadUserDataRequest());
    }
    yield put(actions.signupSuccess(response));
  },
  failureAction: actions.signupFailed,
});

const acceptInvitation = createRequestSaga({
  actionType: actions.acceptInvitationRequest.type,
  url: ({ id }) => `invitations/${id}/accept`,
  method: 'post',
  successAction: actions.acceptInvitationSuccess,
  failureAction: actions.acceptInvitationFailed,
});

const declineInvitation = createRequestSaga({
  actionType: actions.declineInvitationRequest.type,
  url: ({ id }) => `invitations/${id}/decline`,
  method: 'post',
  successAction: actions.declineInvitationSuccess,
  failureAction: actions.declineInvitationFailed,
});

const createOrganisation = createRequestSaga({
  actionType: actions.createOrganisationRequest.type,
  url: `organisations`,
  method: 'post',
  successAction: actions.createOrganisationSuccess,
  failureAction: actions.createOrganisationFailed,
});

const dismissOnboarding = createRequestSaga({
  actionType: actions.dismissOnboardingRequest.type,
  url: `onboarding/dismiss`,
  method: 'post',
  successAction: actions.dismissOnboardingSuccess,
  failureAction: actions.dismissOnboardingFailed,
});

export function* signupSaga() {
  yield takeLatest(actions.getInvitationRequest.type, getInvitation);
  yield takeLatest(actions.acceptInvitationRequest.type, acceptInvitation);
  yield takeLatest(actions.declineInvitationRequest.type, declineInvitation);
  yield takeLatest(actions.signupRequest.type, signup);
  yield takeLatest(actions.createOrganisationRequest.type, createOrganisation);
  yield takeLatest(actions.dismissOnboardingRequest.type, dismissOnboarding);
}
