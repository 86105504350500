import React, { ReactNode } from 'react';
import {
  CircularProgress,
  Box,
  Stack,
  BoxProps,
  circularProgressClasses,
} from '@mui/material';

interface Props extends BoxProps {
  percentage: number;
  size?: number | string;
  thickness?: number;
  circleColours: {
    progressColour: string;
    remainderColour: string;
  };
}

export function CompletionIndicator(props: Props) {
  const {
    percentage,
    size = '100%',
    thickness = 4,
    circleColours,
    children,
  } = props;
  const { progressColour, remainderColour } = circleColours;

  const circleStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
  };
  return (
    <Box sx={{ position: 'relative', width: size, height: 0, pt: '100%' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={size}
        thickness={thickness}
        sx={{
          ...circleStyles,
          color: remainderColour,
          zIndex: 1,
          [`& ${circularProgressClasses.circle}`]: {
            stroke: remainderColour,
          },
        }}
      />
      <CircularProgress
        variant="determinate"
        value={percentage}
        size={size}
        thickness={thickness}
        sx={{
          ...circleStyles,
          color: progressColour,
          zIndex: 2,
          transform: `rotate(-90deg)`,
          transformOrigin: 'center',
          '&.MuiCircularProgress-static': {
            transition: 'none',
          },
        }}
      />
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          width: size,
          height: size,
          position: 'absolute',
          top: 0,
          left: 0,
          boxSizing: 'border-box',
          background: 'white',
          borderRadius: '50%',
        }}
      >
        <Box>{children}</Box>
      </Stack>
    </Box>
  );
}
