/**
 *
 * LoginForm
 *
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Yup from 'utils/yup';

import { PasswordField } from 'app/components/PasswordField';

const schema = Yup.object().shape({
  password: Yup.string().required('This field is required').checkPassword(),
  password_confirmation: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

interface Props {
  resetPasswordRequest: Function;
  data: {
    loading: boolean;
  };
}

export function ResetPasswordForm(props: Props) {
  const { resetPasswordRequest, data } = props;
  return (
    <Formik
      initialValues={{
        password: '',
        password_confirmation: '',
      }}
      validationSchema={schema}
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        resetPasswordRequest(values);
      }}
    >
      {({ submitForm, isSubmitting, setSubmitting }) => {
        if (isSubmitting !== data.loading) {
          setSubmitting(data.loading);
        }

        return (
          <Form>
            <Box>
              <Box sx={{ mb: 2 }}>
                <Field
                  component={PasswordField}
                  id="password"
                  name="password"
                  label="New password"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Field
                  component={PasswordField}
                  id="password_confirmation"
                  name="password_confirmation"
                  label="Confirm new password"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box>
                <Box>
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      Save new password
                    </Button>
                  </Box>
                  <Box sx={{ mt: 3, textAlign: 'center' }}>
                    <Button
                      component={Link}
                      variant="text"
                      size="small"
                      className="align-right"
                      to="/auth/login"
                    >
                      Back to login page
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}
