import { useState, useEffect } from 'react';

const useHasFocus = (): boolean => {
  const [focus, setFocus] = useState<boolean>(
    !document.hidden && document.hasFocus(),
  );

  useEffect(() => {
    const onFocus = () => {
      setFocus(true);
    };
    const onBlur = () => setFocus(false);
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setFocus(true);
      } else {
        setFocus(false);
      }
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return focus;
};

export { useHasFocus };
