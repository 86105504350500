/**
 *
 * ConflictForm
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  RiCheckFill,
  RiCloseLine,
  RiDeleteBackLine,
  RiGroupLine,
  RiInformationLine,
} from 'react-icons/ri';

import { IconTitledContent } from 'app/components/IconTitledContent';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { IConflict, IOrganisation } from 'types/types';
import * as Yup from 'yup';
import { FormikMultiSelectCheckboxes } from 'app/components/FormikMultiSelectCheckboxes';
import { CancelAndSaveButtons } from 'app/components/CancelAndSaveButtons';
import moment, { now } from 'moment';
import { getDateWIthFallbackString } from '../../../utils/getDateWIthFallbackString';
import { formatParsedISO } from '../../../utils/formatParsedISO';
import { DateFieldWrapper } from 'app/components/DateFieldWrapper';
import { useMemo } from 'react';

interface Props {
  onClose: () => void;
  onDelete: () => void;
  conflict: IConflict | undefined;
  organisations: IOrganisation[];
  onSave: (payload: Partial<IConflict>) => void;
  saving: boolean;
}

const schema = Yup.object().shape({
  description: Yup.string().required('This field is required'),
  organisation_ids: Yup.array()
    .of(Yup.number())
    .when('id', {
      is: v => !v,
      then: Yup.array()
        .of(Yup.number())
        .required('At least one selection is required')
        .min(1, 'At least one selection is required'),
    }),
  date_start: Yup.date(),
  date_end: Yup.date()
    .nullable()
    .min(Yup.ref('date_start'), 'End date cannot be before start date'),
});

export function ConflictForm(props: Props) {
  const { conflict, organisations, onSave, onClose, onDelete, saving } = props;
  const today = useMemo(() => {
    return moment();
  }, []);
  return (
    <Formik<Partial<IConflict>>
      validationSchema={schema}
      initialValues={
        conflict
          ? {
              ...conflict,
              organisation_ids: [],
            }
          : ({
              id: undefined,
              description: '',
              organisation_ids: [],
            } as Pick<IConflict, 'id' | 'description'> & {
              organisation_ids: Array<number | string>;
            })
      }
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave({
          ...values,
          date_end: values.date_end
            ? moment.utc(values.date_end).format('YYYY-MM-DD HH:mm:ss')
            : undefined,
        });
      }}
    >
      {formik => {
        const {
          errors,
          setSubmitting,
          values,
          isSubmitting,
          setFieldValue,
          dirty,
        } = formik;

        return (
          <Form>
            <DialogTitle>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{ mr: 'auto' }}>
                  {conflict ? <>Editing declaration</> : 'Create declaration'}
                </Box>

                <IconButton onClick={onClose} edge={'end'} size={'large'}>
                  <RiCloseLine />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box>
                <Typography variant={'h3'}>Declaration details</Typography>

                <Stack spacing={3} sx={{ my: 3 }}>
                  <IconTitledContent
                    width={3}
                    icon={<RiGroupLine />}
                    title={'Organisation *'}
                  >
                    {conflict ? (
                      <Typography variant={'body1'}>
                        {conflict.organisation_name}
                      </Typography>
                    ) : (
                      <Field
                        multiple
                        component={FormikMultiSelectCheckboxes}
                        id="organisation_ids"
                        name="organisation_ids"
                        label="Organisation"
                        fullWidth
                        options={organisations.map(o => ({
                          ...o,
                          label: o.name,
                        }))}
                      />
                    )}
                  </IconTitledContent>

                  <IconTitledContent
                    width={3}
                    icon={<RiInformationLine />}
                    title={'Description *'}
                  >
                    <Field
                      component={TextField}
                      id="description"
                      name="description"
                      multiline
                      rows={4}
                      label="Description"
                      fullWidth
                    />
                  </IconTitledContent>
                  <IconTitledContent
                    title={'Start date'}
                    icon={<RiInformationLine />}
                    width={3}
                  >
                    {conflict ? (
                      <Typography variant={'body1'}>
                        {formatParsedISO(conflict.date_start)}
                      </Typography>
                    ) : (
                      <Field
                        component={TextField}
                        id="date_start"
                        name="date_start"
                        label="Start date"
                        fullWidth
                      />
                    )}
                  </IconTitledContent>
                  <IconTitledContent
                    title={'End date'}
                    icon={<RiInformationLine />}
                    width={3}
                  >
                    {conflict?.date_end ? (
                      <Typography variant={'body1'}>
                        {formatParsedISO(conflict.date_end)}
                      </Typography>
                    ) : (
                      <>
                        <Stack>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!values.date_end}
                                onClick={() =>
                                  setFieldValue(
                                    'date_end',
                                    values.date_end ? undefined : today,
                                  )
                                }
                              />
                            }
                            label={'The interest is ongoing / continues'}
                          />
                          {values.date_end ? (
                            <>
                              <Stack direction={'row'} spacing={1}>
                                <DateFieldWrapper
                                  name="date_end"
                                  format={'DD/MM/YYYY'}
                                />
                              </Stack>
                            </>
                          ) : null}
                        </Stack>
                      </>
                    )}
                  </IconTitledContent>
                </Stack>
              </Box>
            </DialogContent>
            <DialogActions sx={{}}>
              {!!conflict && (
                <Button
                  onClick={onDelete}
                  variant={'text'}
                  color={'error'}
                  startIcon={<RiDeleteBackLine />}
                  disabled={saving || isSubmitting}
                  sx={{ mr: 'auto' }}
                >
                  Delete declaration
                </Button>
              )}

              <CancelAndSaveButtons
                disabled={isSubmitting || saving || !dirty}
                saveIcon={<RiCheckFill />}
                onCancel={onClose}
                saveText="Save"
              />
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
