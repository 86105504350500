import { takeLatest } from 'redux-saga/effects';
import { organisationPageActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadOrganisation = createRequestSaga({
  actionType: actions.loadOrganisationRequest.type,
  url: p => `organisations/${p.id}`,
  successAction: actions.loadOrganisationSuccess,
  failureAction: actions.loadOrganisationFailed,
});

const manageFeature = createRequestSaga({
  actionType: actions.manageFeatureRequest.type,
  url: `organisations/manage-feature`,
  method: 'post',
  successAction: actions.manageFeatureSuccess,
  failureAction: actions.manageFeatureFailed,
});

export function* organisationPageSaga() {
  yield takeLatest(actions.loadOrganisationRequest.type, loadOrganisation);
  yield takeLatest(actions.manageFeatureRequest.type, manageFeature);
}
