/**
 *
 * Checklists
 *
 */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  AbridgedChecklist,
  ChecklistSubmission,
  IChecklistSetting,
} from 'types/types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { RiCloseLine, RiDeleteBackLine } from 'react-icons/ri';
import { DatePicker } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';

interface ChecklistSubmissionOptions {
  due_date?: string;
  export?: boolean;
}

interface Props {
  open: boolean;
  onClose: () => void;
  canExport?: boolean;
  submission: ChecklistSubmission;
  onConfirm: (options: ChecklistSubmissionOptions) => void;
}

export function ConfirmSubmissionDialog(props: Props) {
  const { open, onClose, onConfirm, submission, canExport } = props;
  const [values, setValues] = useState<ChecklistSubmissionOptions | null>({
    export: false,
    due_date: null,
  });

  const date = useMemo(() => {
    if (!values.due_date) return null;

    return moment(values.due_date, 'YYYY-MM-DD HH:mm:ss');
  }, [values.due_date]);

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      open={!!submission && !!open}
      onClose={onClose}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box> 
            Submit check in
          </Box>
          <IconButton onClick={onClose} edge={'end'} size={'large'}>
            <RiCloseLine />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Box>Would you like to schedule a future check in?</Box>
          <DatePicker
            minDate={moment()}
            format="DD/MM/YYYY"
            onChange={(value, keyboardInputValue) =>
              setValues({
                ...values,
                due_date: (value as Moment).format('YYYY-MM-DD HH:mm:ss'),
              })
            }
            value={date}
            slotProps={{ textField: {} }}
          />
          {!!date && (
            <Button
              size={'small'}
              color={'error'}
              variant={'text'}
              endIcon={<RiDeleteBackLine />}
              onClick={() => setValues({ ...values, due_date: null })}
            >
              Clear scheduled date
            </Button>
          )}

          <Box>
            By confirming, the check-in will be marked as completed and will not
            be editable. Are you sure?
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box>
          {!!canExport && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.export}
                  onChange={ev =>
                    setValues({ ...values, export: !values.export })
                  }
                />
              }
              label={'Export to file?'}
            />
          )}
        </Box>
        <Button
          onClick={() => {
            onConfirm(values);
            onClose();
          }}
        >
          Confirm check in?
        </Button>
      </DialogActions>
    </Dialog>
  );
}
