/**
 *
 * LoginForm
 *
 */

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { PasswordField } from 'app/components/PasswordField';

const schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('This field is required'),
  password: Yup.string().required('This field is required'),
});

interface Props {
  email: string;
  loginRequest: Function;
  data: {
    loading: boolean;
  };
}

export function LoginForm(props: Props) {
  const { loginRequest, data, email } = props;
  return (
    <Stack spacing={3}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h3" color="primary">
          Login to the application
        </Typography>
      </Box>
      <Box>
        <Formik
          initialValues={{
            email,
            password: '',
          }}
          validationSchema={schema}
          validateOnChange
          onSubmit={values => {
            loginRequest(values);
          }}
        >
          {({ submitForm, isSubmitting, setSubmitting }) => {
            if (isSubmitting !== data.loading) {
              setSubmitting(data.loading);
            }

            return (
              <Form>
                <Box>
                  <Box sx={{ mb: 2 }}>
                    <Field
                      component={TextField}
                      inputProps={{
                        readOnly: !!email,
                      }}
                      id="email"
                      name="email"
                      type="email"
                      label="Email / Username"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Field
                      component={PasswordField}
                      id="password"
                      name="password"
                      label="Password"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      Login
                    </Button>
                  </Box>
                  <Box sx={{ mt: 3, textAlign: 'center' }}>
                    <Button
                      component={Link}
                      variant="text"
                      size="small"
                      className="align-right"
                      to="/auth/forgot-password"
                    >
                      Forgot password?
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Stack>
  );
}
