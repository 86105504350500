import React, { ReactNode } from 'react';
import {
  Box,
  Paper,
  PaperProps,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';

interface Props extends Pick<PaperProps, 'sx'> {
  title: string;
  children: ReactNode;
  sx?: SxProps;
}

export function DashboardBox(props: Props) {
  const { title, children, sx, ...rProps } = props;
  return (
    <Paper
      variant="outlined"
      square
      sx={{
        px: 2,
        pt: 2,
        pb: 4,
        ...sx,
      }}
      {...rProps}
    >
      <Stack spacing={1}>
        <Box sx={{ mb: 2 }}>
          <Typography variant={'h5'}>{title}</Typography>
        </Box>
        <>{children}</>
      </Stack>
    </Paper>
  );
}
