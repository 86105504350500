import React from 'react';
import { ThemeProps } from 'styled-components';
interface Props {
  color?: string;
  width?: string;
  height?: string;
}
export function PhoneAuthIcon(props: Props) {
  const { color = '#343459', width = '25', height = '25' } = props;

  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7083 18.7494H5.29159V6.24935H15.7083V7.29102H17.7916V3.12435C17.7916 1.97852 16.8541 1.04102 15.7083 1.04102H5.29159C4.14575 1.04102 3.20825 1.97852 3.20825 3.12435V21.8744C3.20825 23.0202 4.14575 23.9577 5.29159 23.9577H15.7083C16.8541 23.9577 17.7916 23.0202 17.7916 21.8744V17.7077H15.7083V18.7494ZM19.2291 10.0202L19.8749 11.4577L20.5208 10.0202L21.9583 9.37435L20.5208 8.72852L19.8749 7.29102L19.2291 8.72852L17.7916 9.37435L19.2291 10.0202Z"
          fill={color}
        />
        <path
          d="M16.6667 8.33398L15.3646 11.1986L12.5 12.5007L15.3646 13.8027L16.6667 16.6673L17.9688 13.8027L20.8333 12.5007L17.9688 11.1986L16.6667 8.33398ZM21.875 13.5423L21.2292 14.9798L19.7917 15.6257L21.2292 16.2715L21.875 17.709L22.5208 16.2715L23.9583 15.6257L22.5208 14.9798L21.875 13.5423Z"
          fill={color}
        />
      </svg>
    </>
  );
}
