import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.organisationPage || initialState;

export const selectOrganisationPage = createSelector(
  [selectSlice],
  state => state,
);

export const selectLoadOrganisation = createSelector(
  [selectSlice],
  state => state.loadOrganisation,
);
