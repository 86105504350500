/**
 *
 * SetupMfa
 *
 */
import React, { useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useEffectOnce } from 'utils/useEffectOnce';
import { LoadingIndicator } from '../LoadingIndicator';
import { ResendPasscode } from '../ResendPasscode';
import { Link } from 'react-router-dom';
import { hideEmail } from 'utils/hideChars';
import { BottomLinks } from './BottomLinks';
import { AuthInputField } from '../AuthInputField';

interface Props {
  getSetupData: () => void;
  setupLoading: boolean;
  setupLoaded: boolean;
  onConfirm: (code: string) => void;
  email: string;
}

export function RemoveForm(props: Props) {
  const { getSetupData, onConfirm, email, setupLoaded, setupLoading } = props;
  const [code, setCode] = useState<string>('');
  useEffectOnce(() => {
    getSetupData();
  });

  if (setupLoading) {
    return <LoadingIndicator />;
  }

  if (!setupLoaded) return null;

  return (
    <Box>
      <Stack spacing={2} alignItems={'center'} textAlign={'center'}>
        <Typography variant="h3" color={'primary'} textAlign={'center'} mb={2}>
          Remove your current 2-factor authentication
        </Typography>
        <Typography variant="body1">
          You are about to remove 2-factor authentication.
        </Typography>
        <Typography variant="body1" fontWeight={'bold'}>
          We have sent you an email with a passcode to: {hideEmail(email)}
        </Typography>
        <Typography variant="body1" fontWeight={'bold'}>
          To remove the 2-factor authentication, insert the passcode below. We
          will verify your request and you will return to this screen.
        </Typography>

        <AuthInputField
          codeValue={code}
          onChange={v => setCode(v)}
          onEnter={() => onConfirm(code)}
        />
        <Button
          fullWidth
          disabled={code.length < 6}
          onClick={() => onConfirm(code)}
        >
          Confirm
        </Button>

        {/* <Button component={Link} variant="text" to={'../'}>
          Get help
        </Button> */}
        <BottomLinks></BottomLinks>
        <ResendPasscode
          message={`Send passcode to ${hideEmail(email)}`}
          onChange={() => getSetupData()}
          loading={setupLoading}
        />
      </Stack>
    </Box>
  );
}
