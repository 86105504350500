import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  title: ReactNode;
  riskLevelSelector: ReactNode;
  riskHelpModal: ReactNode;
}

export function ResponsiveRow(props: Props) {
  const { title, riskLevelSelector, riskHelpModal } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ xs: '0.5', md: '1' }}
        alignItems={{ xs: 'flex-start' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
      >
        <Box sx={{ minWidth: 200, pt: 1 }}>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography fontWeight={'bold'}>{title}</Typography>
            {isMd && riskHelpModal}
          </Stack>
        </Box>
        {riskLevelSelector}
        {!isMd && riskHelpModal}
      </Stack>
    </Box>
  );
}
