import { Avatar, Box, Chip, Paper, Stack, Typography } from '@mui/material';
import { getInitials } from 'utils/initials';
import { RiArrowRightSLine, RiUserFill } from 'react-icons/ri';
import { ActionButton } from 'app/components/ActionButton';
import * as React from 'react';
import { IAbridgedMeeting } from 'types/types';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AddToCalendar } from 'app/components/AddToCalendar';

interface Props {
  meeting: IAbridgedMeeting;
  subtitleType?: 'committee' | 'organisation';
}

export function MeetingBar(props: Props) {
  const { meeting, subtitleType = 'committee' } = props;

  const location = useLocation();

  const isMeetingPage = location.pathname.includes('meetings');
  const path = `/organisation/${meeting.organisation_id}/meetings/${meeting.id}`;
  const sameDay =
    moment(meeting.date_start).format('YMMDD') ===
    moment(meeting.date_end).format('YMMDD');

  const color = useMemo(() => {
    switch (meeting.historical_status) {
      case 'current':
        return 'primary.main';
      case 'future':
        return 'primary.light';
    }

    return 'primary.lightest';
  }, [meeting]);

  return (
    <ActionButton variant={'contained'} fullWidth color={color} to={path}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack>
          <Avatar
            variant="rounded"
            sx={{
              backgroundColor: 'white',
              color: 'primary.main',
            }}
            alt={meeting.committee.name}
          >
            {getInitials(meeting.committee.name)}
          </Avatar>
        </Stack>
        <Stack direction={'row'} spacing={2} width={'100%'}>
          <Stack direction={'column'} spacing={0.5} width={'100%'}>
            <Stack
              direction={{ xs: 'column-reverse', sm: 'row' }}
              justifyContent={'space-between'}
              alignItems={{ xs: 'start', sm: 'center' }}
              width={'100%'}
            >
              <Box>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography variant="h3">{meeting.name}</Typography>
                  {!meeting.published_at && (
                    <Chip label={'Draft'} color={'warning'} />
                  )}
                </Stack>
              </Box>
              {!!moment(meeting.date_end).isAfter(moment()) &&
                !(meeting.historical_status === 'current') && (
                  <AddToCalendar
                    calendarView={isMeetingPage ? 'responsive' : 'dropdown'}
                    breakpoint={'lg'}
                    buttonProps={{
                      size: 'small',
                    }}
                    calendar_links={meeting.calendar_links}
                  />
                )}
            </Stack>

            <Typography variant="body1" sx={{ opacity: 0.9 }}>
              {meeting.committee.name}
            </Typography>
            {subtitleType === 'organisation' ? (
              <Stack
                direction={{ sm: 'row', xs: 'column' }}
                justifyContent={{ sm: 'space-between', xs: 'flex-start' }}
                alignItems={{ sm: 'center', xs: 'start' }}
                width={'100%'}
                spacing={0.5}
              >
                <Box>
                  <Typography variant="body1" sx={{ opacity: 0.9 }}>
                    {meeting.organisation_name}
                  </Typography>
                </Box>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={{ sm: 'end', xs: 'space-between' }}
                >
                  {meeting.historical_status === 'current' ? (
                    <Box>
                      <Paper
                        elevation={0}
                        sx={{
                          py: 1,
                          px: 2,
                          backgroundColor: 'common.white',
                          color: 'primary.main',
                        }}
                      >
                        <Stack direction={'row'} alignItems={'center'}>
                          <RiUserFill />
                          <Typography variant={'body2'}>Join now</Typography>
                        </Stack>
                      </Paper>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant={'body2'} sx={{ opacity: 0.6 }}>
                        {sameDay ? (
                          <>
                            {moment(meeting.date_start).format('ddd D MMM Y')} |{' '}
                            {moment(meeting.date_start).format('HH:mm')} -{' '}
                            {moment(meeting.date_end).format('HH:mm')}
                          </>
                        ) : (
                          <>
                            {moment(meeting.date_start).format('ddd D MMM Y')} -{' '}
                            {moment(meeting.date_end).format('ddd D MMM Y')}
                          </>
                        )}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Stack>
            ) : (
              ''
            )}
          </Stack>
        </Stack>
      </Stack>
    </ActionButton>
  );
}
