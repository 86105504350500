/**
 *
 * RiskRegisterSection
 *
 */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { IOrganisation, IRiskArea } from 'types/types';
import { useRiskRegisterSectionSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectRiskRegisterSection } from './slice/selectors';
import { useEffectOnce } from 'utils/useEffectOnce';
import { RiskRegisterListView } from './RiskRegisterListView';
import {
  Box,
  Button,
  Container,
  Dialog,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { get } from 'lodash';
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { RiskMatrix } from './RiskMatrix';
import { RiskHelpGuidance } from './RiskHelpGuidance';
import { SectionMenu } from 'app/components/SectionMenu';
import { AddNewRisk } from './AddNewRisk';
import { useEffectOnChange } from 'utils/useEffectOnChange';
import { RiskAreaForm } from './RiskAreaForm';
import { AddSuccess } from './AddSuccess';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { RiskMatrixList } from './RiskMatrixList';
import { RiskArchive } from './RiskArchive';
import { useSnackbar } from 'notistack';
import { getApiUrl, serveFile } from 'utils/request';
import { now } from 'moment';
import { organisationPageActions } from '../OrganisationPage/slice';
import { useReloadCurrentOrganisation } from '../OrganisationPage/useReloadCurrentOrganisation';
import { Helmet } from 'react-helmet-async';
import { SectionHeader } from 'app/components/SectionHeader';

interface Props {
  organisation: IOrganisation;
}

export function RiskRegisterSection(props: Props) {
  const { organisation } = props;
  const [riskAreaFormOpen, setRiskAreaFormOpen] = useState<{
    open: boolean;
    risk_area?: IRiskArea;
  }>({ open: false });

  const { reloadOrganisation } = useReloadCurrentOrganisation();

  const { hasPermission } = useHasPermission();

  const snackbar = useSnackbar();

  const canManage = hasPermission(
    'manage-risk-register',
    'organisation',
    organisation.id,
  );

  const location = useLocation();
  const navigate = useNavigate();

  const save = () => {
    serveFile(
      getApiUrl(
        `exports/risk-register?risk_register_id=${
          loadOrganisationRiskRegister.data.id
        }&nonce=${now()}`,
      ),
    ).then(res => {});
  };

  const { actions } = useRiskRegisterSectionSlice();
  const dispatch = useDispatch();
  const {
    getMemberNames,
    loadOrganisationRiskRegister,
    upsertRiskRegisterItem,
    updateOrganisationRiskRegister,
  } = useSelector(selectRiskRegisterSection);

  const theme = useTheme();
  const mdViewUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffectOnce(() => {
    dispatch(
      actions.loadOrganisationRiskRegisterRequest({
        organisation_id: organisation.id,
      }),
    );
    dispatch(
      actions.getRiskSuggestionsRequest({
        organisation_id: organisation.id,
      }),
    );
    dispatch(
      actions.getMemberNamesRequest({
        organisation_id: organisation.id,
      }),
    );
  });

  const itemsLength = useMemo(() => {
    if (!loadOrganisationRiskRegister.data) return 0;
    return (
      loadOrganisationRiskRegister.data.items.filter(i => !i.is_archived)
        .length || 0
    );
  }, [loadOrganisationRiskRegister.data]);

  // Reload organisation if items reduced to zero
  useEffectOnChange(() => {
    if (
      (!organisation.risk_register_activated && !!itemsLength) ||
      (organisation.risk_register_activated && !itemsLength)
    )
      reloadOrganisation();
  }, itemsLength);

  useEffectOnChange(
    () => {
      if (upsertRiskRegisterItem.error) {
      } else {
        dispatch(
          actions.loadOrganisationRiskRegisterRequest({
            organisation_id: organisation.id,
          }),
        );

        snackbar.enqueueSnackbar('Risk saved successfully', {
          variant: 'success',
        });
        if (upsertRiskRegisterItem.data.action === 'create') {
          navigate('./add/success');
        }
      }
    },
    upsertRiskRegisterItem.loading,
    false,
  );

  useEffectOnChange(
    () => {
      if (!updateOrganisationRiskRegister.error) {
        dispatch(
          actions.loadOrganisationRiskRegisterRequest({
            organisation_id: organisation.id,
          }),
        );
        setRiskAreaFormOpen({ open: false });
        if (
          updateOrganisationRiskRegister.data.action === 'add' ||
          updateOrganisationRiskRegister.data.action === 'update'
        ) {
          snackbar.enqueueSnackbar('Risk category saved successfully', {
            variant: 'success',
          });
        }
      }
    },
    updateOrganisationRiskRegister.loading,
    false,
  );

  if (!organisation) return null;

  return (
    <>
      <Helmet title={'Risk register'}>
        <meta name="description" content="Organisation risk register" />
      </Helmet>

      <SectionHeader
        title="Risk Register"
        sectionMenu={
          <>
            <SectionMenu
              title="Active risks"
              path={'./active'}
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/risk-register/active`
              }
            />

            <SectionMenu
              title={mdViewUp ? 'Risk matrix' : 'Matrix'}
              path={'./risk-matrix'}
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/risk-register/risk-matrix`
              }
            />

            <SectionMenu
              title={mdViewUp ? 'Archived risks' : 'Archived'}
              path={'./archived-risks'}
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/risk-register/archived-risks`
              }
            />

            <SectionMenu
              title={mdViewUp ? 'Help & guidance' : 'Help'}
              path={'./help-guidance'}
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/risk-register/help-guidance`
              }
            />
          </>
        }
      />

      <Container>
        <Box sx={{ my: 4 }}>
          <Stack spacing={4}>
            <Routes>
              <Route
                path={'active'}
                element={
                  <>
                    <RiskRegisterListView
                      canExport={hasPermission(
                        'export-risk-register',
                        'organisation',
                        organisation.id,
                      )}
                      saveExport={save}
                      memberNames={getMemberNames.data}
                      canManage={canManage}
                      openRiskAreaForm={(risk_area: IRiskArea) =>
                        setRiskAreaFormOpen({ open: true, risk_area })
                      }
                      riskRegister={loadOrganisationRiskRegister.data}
                      onSave={data =>
                        dispatch(
                          actions.upsertRiskRegisterItemRequest({
                            organisation_id: +organisation.id,
                            risk_register_item: data,
                          }),
                        )
                      }
                      saving={upsertRiskRegisterItem.loading}
                      onRiskAreaAction={action =>
                        dispatch(
                          actions.updateOrganisationRiskRegisterRequest({
                            organisation_id: +organisation.id,
                            risk_area_action: action,
                          }),
                        )
                      }
                    />
                  </>
                }
              />
              <Route
                path={'add'}
                element={
                  <>
                    <AddNewRisk
                      openRiskAreaForm={(risk_area: IRiskArea) =>
                        setRiskAreaFormOpen({ open: true, risk_area })
                      }
                      onSave={data =>
                        dispatch(
                          actions.upsertRiskRegisterItemRequest({
                            organisation_id: +organisation.id,
                            risk_register_item: data,
                          }),
                        )
                      }
                      risk_register={loadOrganisationRiskRegister.data}
                      saving={upsertRiskRegisterItem.loading}
                      memberNames={getMemberNames.data}
                      defaultMemberName={getMemberNames.data[0]}
                    />
                  </>
                }
              />{' '}
              <Route
                path={'add/success'}
                element={
                  <AddSuccess
                    risk_register={loadOrganisationRiskRegister.data}
                  />
                }
              />
              <Route
                path={'risk-matrix'}
                element={
                  <RiskMatrix
                    riskRegister={loadOrganisationRiskRegister.data}
                  />
                }
              />
              <Route
                path={'risk-matrix/:likelihood/:impact'}
                element={
                  <RiskMatrixList
                    riskRegister={loadOrganisationRiskRegister.data}
                    saving={upsertRiskRegisterItem.loading}
                    memberNames={getMemberNames.data}
                    canManage={canManage}
                    onSave={data =>
                      dispatch(
                        actions.upsertRiskRegisterItemRequest({
                          organisation_id: +organisation.id,
                          risk_register_item: data,
                        }),
                      )
                    }
                  />
                }
              />
              <Route
                path={'archived-risks/*'}
                element={
                  <RiskArchive
                    riskRegister={loadOrganisationRiskRegister.data}
                    onSave={data =>
                      dispatch(
                        actions.upsertRiskRegisterItemRequest({
                          organisation_id: +organisation.id,
                          risk_register_item: data,
                        }),
                      )
                    }
                  />
                }
              />
              <Route path={'help-guidance/*'} element={<RiskHelpGuidance />} />
              <Route path={'*'} element={<Navigate to={'active'} replace />} />
            </Routes>
          </Stack>
        </Box>
      </Container>

      <Dialog
        open={riskAreaFormOpen.open}
        maxWidth={'md'}
        fullWidth
        scroll={'body'}
        onClose={() => setRiskAreaFormOpen({ open: false })}
      >
        <>
          <RiskAreaForm
            onDelete={() => {}}
            existingRiskCategories={
              loadOrganisationRiskRegister.data?.risk_areas
            }
            saving={updateOrganisationRiskRegister.loading}
            onClose={() => setRiskAreaFormOpen({ open: false })}
            risk_area={riskAreaFormOpen.risk_area}
            onSave={payload =>
              dispatch(
                actions.updateOrganisationRiskRegisterRequest({
                  organisation_id: +organisation.id,
                  risk_area_action: {
                    action: riskAreaFormOpen.risk_area ? 'update' : 'add',
                    ...payload,
                  },
                }),
              )
            }
          />
        </>
      </Dialog>
    </>
  );
}
