import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from 'react-router-dom';

export function BillingInfo() {
  return (
    <>
      <Stack alignItems={'flex-start'}>
        <Typography variant="h3">Billing Information</Typography>
        <Typography>
          To learn about your price plan and billing info, visit the below link.
          In time, your billing information will appear on this page.
        </Typography>
        {/*
        <Button
          component={Link}
          href="https://stripe.com/gb"
          target="_blank"
          rel="noopener"
          variant="outlined"
          endIcon={<LaunchIcon />}
        >
          Click here to view your billing information
        </Button>
        */}
        <Button
          variant="text"
          component={Link}
          to="../partner-details"
          sx={{ px: 0 }}
        >
          Contact us about billing or account queries
        </Button>
      </Stack>
    </>
  );
}
