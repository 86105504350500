import { call, cancelled, put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { committeeFormWidgetActions as actions } from '.';

function* init(action) {
  try {
    //const settings = yield select(selectCommitteeFormWidgetSettings);

    yield put(actions.openSuccess({}));
  } catch (e) {
    yield put(actions.openFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadCommittee(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `committees/${action.payload.id}`,
      method: 'get',
      data: {},
    });

    yield put(actions.loadCommitteeSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.loadCommitteeFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* saveCommittee(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `committees/${action.payload.id || ''}`,
      method: action.payload.id ? 'put' : 'post',
      data: action.payload,
    });

    yield put(actions.saveCommitteeSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.saveCommitteeFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* committeeFormWidgetSaga() {
  yield takeLatest(actions.openRequest.type, init);
  yield takeLatest(actions.loadCommitteeRequest.type, loadCommittee);
  yield takeLatest(actions.saveCommitteeRequest.type, saveCommittee);
}
