import React from 'react';
import { Box, CardMedia, Stack, Typography } from '@mui/material';
import MeetingGuideImage from './meeting_guide.png';

export function Guide() {
  return (
    <Box sx={{ my: 4 }}>
      <Stack spacing={6}>
        <Typography variant="h2">Meetings Guide</Typography>
        <Box>
          <Stack spacing={3}>
            <Typography variant='h5'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </Typography>
            <Typography>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec id elit non mi porta gravida at eget metus. Donec ullamcorper nulla non metus auctor fringilla. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </Typography>
            <Typography variant='h5'>
            Maecenas faucibus mollis interdum
            </Typography>
            <Typography >
            Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas faucibus mollis interdum. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum.
            </Typography>
            <CardMedia src={MeetingGuideImage} component={'img'} />
            <Typography variant='h5'>
            Maecenas faucibus mollis interdum
            </Typography>
            <Typography>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec id elit non mi porta gravida at eget metus. Donec ullamcorper nulla non metus auctor fringilla. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </Typography>
            <Typography variant='h5'>
            Maecenas faucibus mollis interdum
            </Typography>
            <Typography >
            Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas faucibus mollis interdum. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum.
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
