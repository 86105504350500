import FileUpload from '../../components/FileUpload';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Field, Formik } from 'formik';
import * as React from 'react';
import { IDocument } from '../../../types/types';
import { MeetingDocumentFile } from './MeetingDocumentFile';
import { Delete, Edit } from '@mui/icons-material';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';
import { AutoUpdateName } from 'app/components/AutoUpdateName';
import { TitledField } from 'app/components/TitledField';
import { RiCloseLine } from 'react-icons/ri';
import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';
import { DocumentUploadAndTitleContent } from 'app/components/DocumentUploadAndTitleContent';

interface Props {
  initialDocument?: IDocument;
  onSave: (payload: any) => void;
  onDelete?: () => void;
  canManage?: boolean;
  negativeStatement?: string;
  loading?: boolean;
  title?: string;
  // onClose?: () => void;
}

const schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  description: Yup.string(),
  file: Yup.mixed()
    .test(
      'is-object-or-string',
      'A document is required', // Error message if the test fails
      value => {
        return (
          value &&
          (typeof value === 'string' ||
            (typeof value === 'object' && value !== null))
        );
      },
    )
    .required('This field is required'),
});

export function MeetingDocument(props: Props) {
  const {
    initialDocument,
    onSave,
    canManage,
    onDelete,
    // onClose,
    loading,
    title,
    negativeStatement = 'No document attached',
  } = props;
  const [editing, setEditing] = React.useState(false);

  useEffectOnChange(
    () => {
      setEditing(false);
    },
    loading,
    false,
  );

  return (
    <>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <Stack
          alignContent={'center'}
          justifyContent={'space-between'}
          direction={'row'}
        >
          {initialDocument ? (
            <>
              {' '}
              <MeetingDocumentFile document={initialDocument} />
              {canManage && (
                <Box>
                  <IconButton
                    aria-label={'Edit'}
                    onClick={() => setEditing(true)}
                  >
                    <Edit />
                  </IconButton>
                  {onDelete && (
                    <IconButton aria-label={'Delete'} onClick={onDelete}>
                      <Delete />
                    </IconButton>
                  )}
                </Box>
              )}
            </>
          ) : (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography color="textSecondary">
                {' '}
                {negativeStatement}{' '}
              </Typography>
              {canManage && (
                <Button
                  size="small"
                  variant="text"
                  onClick={() => setEditing(true)}
                >
                  Add document
                </Button>
              )}
            </Stack>
          )}
        </Stack>

        {loading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        ) : null}
      </Box>
      {!!editing && (
        <Formik<{ file: any; name: string; description: string }>
          validationSchema={schema}
          validateOnMount
          enableReinitialize
          initialValues={{
            name: initialDocument?.name || '',
            description: initialDocument?.description || '',
            file: initialDocument?.file || '',
          }}
          onSubmit={values => {
            onSave(values);
          }}
        >
          {({ values, submitForm, isValid, touched, errors }) => {
            return (
              <Dialog
                open
                fullWidth
                maxWidth={'md'}
                onClose={() => setEditing(false)}
              >
                <DialogTitle>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box sx={{ mr: 'auto' }}>
                      {!initialDocument ? 'Add' : 'Edit'} a Document
                    </Box>
                    <IconButton
                      onClick={() => setEditing(false)}
                      edge={'end'}
                      size={'large'}
                    >
                      <RiCloseLine />
                    </IconButton>
                  </Stack>
                </DialogTitle>
                <DialogContent>
                  <Stack spacing={3}>
                    <DocumentUploadAndTitleContent
                      title={title}
                      errorFile={errors.file}
                      touchedFile={touched.file}
                    />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <AutoUpdateName />
                  <Button variant={'text'} onClick={() => setEditing(false)}>
                    Cancel
                  </Button>
                  <Button onClick={submitForm}>Save</Button>
                </DialogActions>
              </Dialog>
            );
          }}
        </Formik>
      )}
    </>
  );
}
