import { Box, Button, MenuItem, Stack, Tab, Tabs } from '@mui/material';
import { useGetOrganisationMenuStructure } from 'app/pages/OrganisationPage/useGetOrganisationMenuStructure';
import { useOrganisationSelector } from 'app/providers/OrganisationSelector/useOrganisationSelector';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import key from 'weak-key';
import { OrganisationSelectLinks } from '../OrganisationSelectLinks';
interface Props {}

export function DrawerOrgMenu(props: Props) {
  const { tabs } = useGetOrganisationMenuStructure();
  const { currentOrganisationId } = useOrganisationSelector();

  return (
    <>
      <Stack spacing={0}>
        {tabs
          ?.filter(t => !t.hidden)
          .map((t, index) => (
            <Box key={index}>
              {t.links
                ? t.links.map(link => (
                    <MenuItem
                      component={Link}
                      to={`/organisation/${currentOrganisationId}/${link.to}`}
                      key={key(link)}
                    >
                      {link.label}
                    </MenuItem>
                  ))
                : !t.links && (
                    <MenuItem
                      component={Link}
                      to={`/organisation/${currentOrganisationId}/${t.to}`}
                      key={key(t)}
                    >
                      {t.label}
                    </MenuItem>
                  )}
            </Box>
          ))}
        <OrganisationSelectLinks />
      </Stack>
    </>
  );
}
