import React, { useMemo, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { IRiskRegister } from 'types/types';
import { RiskRegisterItem } from 'app/components/RiskRegisterItem';
import { RiskSubheader } from 'app/components/RiskSubheader';
import key from 'weak-key';

interface Props {
  riskRegister: IRiskRegister;
  onSave?: (values: any) => void;
}

export function RiskArchive(props: Props) {
  const { riskRegister, onSave } = props;
  const [openItemId, setOpenItemId] = useState<number>();

  const items = useMemo(() => {
    if (!riskRegister) return [];

    const archivedItems = (riskRegister.items || []).filter(
      item => item.is_archived,
    );

    const sortedItems = archivedItems.sort((a, b) => {
      return (
        new Date(b.archived_at).getTime() - new Date(a.archived_at).getTime()
      );
    });

    return sortedItems;
  }, [riskRegister]);

  return (
    <>
      <Box>
        <Stack spacing={3}>
          {!!items.length ? (
            <>
              <RiskSubheader subheader="archived" />
              {items.map(item => (
                <RiskRegisterItem
                  risk_areas={riskRegister.risk_areas}
                  key={key(item)}
                  item={item}
                  onSave={onSave}
                  toggleOpen={() =>
                    setOpenItemId(openId =>
                      openId === item.id ? null : item.id,
                    )
                  }
                  open={openItemId === item.id}
                  saving={false}
                  canManage={false}
                />
              ))}
            </>
          ) : (
            <>
              <Typography>There are no archived risks</Typography>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
}
