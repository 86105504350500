import React, { useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Typography,
  Autocomplete,
  TextField as MuiTextField,
} from '@mui/material';
import { HelpModal } from '../../HelpModal';
import { Field, useFormikContext } from 'formik';
import { Select, TextField } from 'formik-mui';
import { AddNewButton } from '../../AddNewButton';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import key from 'weak-key';
import { IRiskArea } from 'types/types';
import { useEffectOnChange } from 'utils/useEffectOnChange';

export function RiskName(props: {
  risk_areas?: Array<{ code: string; name: string }>;
  openRiskAreaForm: (risk_area?: IRiskArea) => void;
  memberNames: string[];
}) {
  const { risk_areas = [], openRiskAreaForm, memberNames } = props;

  const formikRef = useFormikContext();

  useEffectOnChange(() => {
    formikRef.setFieldValue(
      'risk_area_code',
      risk_areas[risk_areas.length - 1]?.code,
    );
  }, risk_areas);

  return (
    <>
      <Stack spacing={2}>
        <Box>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography variant="h1">What's the risk?</Typography>

            <HelpModal
              iconTitle="Find more about risk"
              name="risk_name"
              maxWidth="md"
            />
          </Stack>
        </Box>
        <Typography variant="body2">
          This is a short title, recognisable by those using the Risk Register,
          describing the Risk you have identified
        </Typography>
        <Box>
          <Field
            component={TextField}
            name="name"
            label="Enter the risk name"
            fullWidth
          />
        </Box>
      </Stack>

      <Box>
        <Stack spacing={2}>
          <Box>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography variant="h3">Risk owner</Typography>
            </Stack>
          </Box>
          <Typography variant="body2">
            The person who is responsible for managing the risk and ensuring the
            control measures are implemented and reviewed.
          </Typography>

          <Box>
            <Autocomplete
              renderInput={params => (
                <Field
                  {...params}
                  component={TextField}
                  name="owner_name"
                  label="Owner name"
                />
              )}
              options={memberNames}
              onChange={(e, val) => {
                formikRef.setFieldValue('owner_name', val);
              }}
              autoHighlight
            />
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack spacing={2}>
          <Box>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography variant="h3">Risk category</Typography>
              <HelpModal
                iconTitle="Find more about risk category"
                name="risk_area"
              />
            </Stack>
          </Box>
          <Typography variant="body2">
            A system of classification can be helpful for ensuring key areas of
            risk arising from both internal and external factors are considered
            and identified. We have provided a suggested range of top level
            categories for you to use – feel free to adapt, edit and improve as
            required by your Organisation and the type of threats it faces.
          </Typography>

          <Box>
            <Grid
              container
              spacing={0.5}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid item xs={6} sm={8}>
                <FormControl fullWidth>
                  <Field
                    name="risk_area_code"
                    component={Select}
                    IconComponent={KeyboardArrowDownSharp}
                    fullWidth
                    renderValue={value => {
                      if (!value) return '--- Select risk category ---';
                      const area = risk_areas.find(area => area.code === value);
                      return area?.name;
                    }}
                    displayEmpty
                    sx={{
                      height: 40,
                      borderRadius: 2,
                      '& .MuiInput-icon': {
                        fontSize: 30,
                        color: 'primary.main',
                        fontWeight: 900,
                      },
                    }}
                  >
                    <MenuItem value={''}>--- Select risk category ---</MenuItem>
                    {risk_areas.map(area => (
                      <MenuItem key={key(area)} value={area.code}>
                        {area.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <AddNewButton
                  addDashedBorder={false}
                  text="Add new risk category"
                  //   add function
                  onClick={() => openRiskAreaForm()}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textDecoration: 'underline',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
