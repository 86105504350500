import {
  Box,
  ButtonBase,
  Card,
  Paper,
  PaperProps,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';

interface Props extends PaperProps {
  title?: string;
  onToggle?: (str) => void;
  open?: boolean;
}

export function CustomAccordion(props: Props) {
  const { title, onToggle, open, children, ...rProps } = props;

  return (
    <Stack spacing={0}>
      <Paper {...rProps} square variant="outlined">
        <ButtonBase
          sx={{ width: '100%', justifyContent: 'start' }}
          onClick={onToggle}
        >
          <Box sx={{ p: 1 }}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Box sx={{ color: 'primary.main' }}>
                {open ? (
                  <RiArrowDropUpLine size={25} />
                ) : (
                  <RiArrowDropDownLine size={25} />
                )}
              </Box>
              <Typography variant="h5" color={'primary.main'}>
                {title}
              </Typography>
            </Stack>
          </Box>
        </ButtonBase>
      </Paper>
      {!!open && (
        <Paper variant="outlined" square sx={{ borderTop: 0, p: 2 }}>
          {children}
        </Paper>
      )}
    </Stack>
  );
}
