import { Box, Container, Stack } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { OpenAndProgress } from './OpenAndProgress';
import { IOrganisation } from 'types/types';
import { SectionHeader } from 'app/components/SectionHeader';
import { SectionMenu } from 'app/components/SectionMenu';
import { Archived } from './Archived';
import { Reports } from './Reports';
import { Guide } from './Guide';

interface Props {
  organisation: IOrganisation;
}

export function ActionRegisterSection(props: Props) {
  const { organisation } = props;

  const location = useLocation();

  return (
    <>
      <Helmet title={'Actions Register'}>
        <meta
          name="description"
          content="Manage your own actions on this page"
        />
      </Helmet>

      <SectionHeader
        title="Actions"
        sectionMenu={
          <>
            <SectionMenu
              title={'All'}
              path={'./all'}
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/actions/all`
              }
            />

            {/* <SectionMenu
              title="Archived"
              path={'./archived'}
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/actions/archived`
              }
            />
            <SectionMenu
              title="Reports"
              path={'./reports'}
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/actions/reports`
              }
            />

            <SectionMenu
              title={'Guide'}
              path={'./guide'}
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/actions/guide`
              }
            /> */}
          </>
        }
      />
      <Container>
        <Box sx={{ my: 4 }}>
          <Stack spacing={4}>
            <Routes>
              <Route
                path={'all'}
                element={<OpenAndProgress organisation={organisation} />}
              />
              {/* <Route
                path={'archived'}
                element={<Archived organisation={organisation} />}
              />
              <Route
                path={'reports'}
                element={<Reports organisation={organisation} />}
              />
              <Route
                path={'guide'}
                element={<Guide organisation={organisation} />}
              /> */}

              <Route path={'*'} element={<Navigate to={'all'} replace />} />
            </Routes>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
