import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { committeeMemberFormWidgetActions as actions } from '.';
import { selectCommitteeMemberFormWidgetSettings } from './selectors';

function* init(action) {
  try {
    const settings = yield select(selectCommitteeMemberFormWidgetSettings);
    const members = yield call(apiRequest, {
      url: `members`,
      method: 'get',
      data: {
        organisation_id: settings.organisation_id,
      },
    });

    const committee = yield call(apiRequest, {
      url: `committees/${settings.committee_id}`,
      method: 'get',
    });

    const roles = yield call(apiRequest, {
      url: `roles`,
      method: 'get',
      data: {
        context: 'committee',
      },
    });

    yield put(
      actions.openSuccess({
        committee: committee.data,
        members: members.data,
        roles: roles.data,
      }),
    );
  } catch (e) {
    yield put(actions.openFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadCommitteeMember(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `committee-members/${action.payload.id}`,
      method: 'get',
      data: {},
    });

    yield put(actions.loadCommitteeMemberSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.loadCommitteeMemberFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* saveCommitteeMember(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `committee-members/${action.payload.id || ''}`,
      method: action.payload.id ? 'put' : 'post',
      data: action.payload,
    });

    yield put(actions.saveCommitteeMemberSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.saveCommitteeMemberFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* deleteCommitteeMember(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `committee-members/${action.payload.id}`,
      method: 'delete',
      data: {},
    });

    yield put(actions.deleteCommitteeMemberSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.deleteCommitteeMemberFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* committeeMemberFormWidgetSaga() {
  yield takeLatest(actions.openRequest.type, init);
  yield takeLatest(
    actions.loadCommitteeMemberRequest.type,
    loadCommitteeMember,
  );
  yield takeLatest(
    actions.saveCommitteeMemberRequest.type,
    saveCommitteeMember,
  );
  yield takeLatest(
    actions.deleteCommitteeMemberRequest.type,
    deleteCommitteeMember,
  );
}
