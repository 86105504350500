import { takeLatest } from 'redux-saga/effects';
import { dashboardActionsWidgetActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadList = createRequestSaga({
  actionType: actions.loadListRequest.type,
  url: () => 'actions',
  data: d => d,
  successAction: actions.loadListSuccess,
  failureAction: actions.loadListFailed,
});

const updateAction = createRequestSaga({
  actionType: actions.updateActionRequest.type,
  url: ({ action_id }) => `actions/${action_id}/update-status`,
  data: d => d,
  method: 'put',
  successAction: actions.updateActionSuccess,
  failureAction: actions.updateActionFailed,
});

export function* dashboardActionsWidgetSaga() {
  yield takeLatest(actions.loadListRequest.type, loadList);
  yield takeLatest(actions.updateActionRequest.type, updateAction);
}
