import { takeLatest } from 'redux-saga/effects';
import { meetingsActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadBoardActivationData = createRequestSaga({
  actionType: actions.loadBoardActivationDataRequest.type,
  url: () => 'organisations/activation-data',
  data: d => d,
  successAction: actions.loadBoardActivationDataSuccess,
  failureAction: actions.loadBoardActivationDataFailed,
});

const activateBoard = createRequestSaga({
  actionType: actions.activateBoardRequest.type,
  url: () => 'organisations/activate-feature',
  method: 'post',
  data: d => d,
  successAction: actions.activateBoardSuccess,
  failureAction: actions.activateBoardFailed,
});

export function* activateSaga() {
  yield takeLatest(
    actions.loadBoardActivationDataRequest.type,
    loadBoardActivationData,
  );
  yield takeLatest(actions.activateBoardRequest.type, activateBoard);
}
