/**
 *
 * DocumentsTable
 *
 */
import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { RiMore2Line } from 'react-icons/ri';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { Filename } from '../Filename';
import { IDocument } from 'types/types';
import moment from 'moment-timezone';
import { getToken, openFile, serveFile } from 'utils/request';
import { getDateWIthFallbackString } from 'utils/getDateWIthFallbackString';
import { calculateDataTableHeight } from '../../../styles/theme';
import { Delete, Edit } from '@mui/icons-material';
import { ResultMetaData } from '../../../types/ApiCall';

interface Props {
  meta?: ResultMetaData;
  documents: IDocument[];
  pageSize?: number;
  page?: number;
  emptyText?: ReactNode;
  editable?: boolean;
  downloadable?: boolean;
  onEdit?: (id: number | string) => void;
  onRemove?: (id: number | string) => void;
  maxHeight?: string | number | undefined;
  onPaginationModelChange?: (pagination: {
    page: number;
    pageSize: number;
  }) => void;
}

export function DocumentsTable(props: Props) {
  const {
    onPaginationModelChange = r => {},
    documents,
    emptyText = 'No documents attached',
    editable = false,
    downloadable = false,
    onEdit = r => {},
    onRemove = r => {},
    maxHeight = 'max(30vh, 300px)',
    meta = {
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    },
  } = props;

  const showFooter = useMemo(() => {
    return meta.total > meta.per_page;
  }, [meta]);

  const rows = useMemo(() => {
    return documents.map(d => ({
      id: d.id,
      name: d.name,
      created_at: d.created_at,
      expires_at: d.expires_at,
      review_at: d.review_at,
      description: d.description,
      committee_name:
        d.parent_entity_type === 'committee' ? d.committee?.name : '',
      ...d.file_details,
    }));
  }, [documents, meta]);

  if (!meta.total) {
    return (
      <Box marginBottom={3}>
        <Typography>{emptyText}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: calculateDataTableHeight(documents.length, showFooter, 72),
          maxHeight,
          width: '100%',
          mb: 4,
        }}
      >
        <DataGridPro
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 300,
              flex: 1,
              renderCell: ({ row }) => {
                if (row.filename) {
                  return (
                    <Box>
                      <Stack spacing={1}>
                        <Filename
                          sx={{
                            cursor: downloadable ? 'pointer' : 'unset',
                            pointerEvents: row.filename ? 'auto' : 'none',
                            '& .MuiTypography-body1': {
                              fontWeight: 'bold',
                            },
                          }}
                          name={row.name || '[Name not set]'}
                          extension={row.extension || 'bin'}
                          onClick={ev => {
                            if (!downloadable) return;
                            ev.nativeEvent.stopImmediatePropagation();
                            openFile(row.url);
                          }}
                        />
                        {!!row.description && (
                          <Typography variant={'body2'}>
                            {row.description}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  );
                }
              },

              pinnable: false,
              hideable: false,
            },
            {
              field: 'committee_name',
              headerName: 'Committee',
              minWidth: 200,
              pinnable: false,
              hideable: false,
              flex: 0.3,
            },
            {
              field: 'created_at',
              minWidth: 200,
              headerName: 'Date added',
              type: 'dateTime',
              valueGetter: ({ row }) =>
                getDateWIthFallbackString(row.created_at),
              renderCell: params => {
                const date = params.value;
                return date
                  ? moment(date).format('DD/MM/YYYY HH:mm')
                  : 'Unknown';
              },
              pinnable: false,
              hideable: false,
            },
            {
              field: 'review_at',
              minWidth: 200,
              headerName: 'Review date',
              type: 'date',
              valueGetter: ({ row }) =>
                getDateWIthFallbackString(row.review_at),
              renderCell: params => {
                const date = params.value;
                return date ? moment(date).format('DD/MM/YYYY') : '';
              },
              pinnable: false,
              hideable: false,
            },
            {
              field: 'expires_at',
              minWidth: 200,
              headerName: 'Expires',
              type: 'date',
              valueGetter: ({ row }) =>
                getDateWIthFallbackString(row.expires_at),
              renderCell: params => {
                const date = params.value;
                return date ? moment(date).format('DD/MM/YYYY') : 'Never';
              },
              pinnable: false,
              hideable: false,
            },
            {
              field: 'actions',
              type: 'actions',
              headerName: '',
              sortable: false,
              pinnable: false,
              resizable: false,
              hideable: false,
              width: 80,
              disableColumnMenu: true,
              getActions: ({ row }) => {
                return [
                  <IconButton
                    edge={false}
                    size={'small'}
                    onClick={() => onEdit(row.id)}
                  >
                    <Edit />
                  </IconButton>,
                  <IconButton
                    edge={false}
                    size={'small'}
                    onClick={() => onRemove(row.id)}
                  >
                    <Delete />
                  </IconButton>,
                ];
              },
            },
          ]}
          rows={rows}
          columnVisibilityModel={{
            actions: editable,
            committee_name: rows.some(r => r.committee_name),
            review_at: rows.some(r => r.review_at),
          }}
          disableColumnSelector
          disableRowSelectionOnClick
          hideFooter={!showFooter}
          rowCount={meta.total}
          slotProps={{
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}
          paginationModel={{
            page: meta.current_page - 1,
            pageSize: meta.per_page,
          }}
          onPaginationModelChange={pagination => {
            onPaginationModelChange(pagination);
          }}
          pageSizeOptions={[2, 10, 25, 50, 100, 250, 500, 1000, 10000, 100000]}
          pagination={showFooter}
          getRowHeight={r => 'auto'}
          pinnedColumns={{
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
            right: ['actions'],
          }}
          getRowId={r => r.id}
          paginationMode={'server'}
        />
      </Box>
    </>
  );
}
