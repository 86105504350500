import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { HomePageState } from './types';
import { IOrganisation } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';
import { apiCallInitialState } from 'types/ApiCall';

export const initialState: HomePageState = {
  loadHomePage: {
    ...apiCallInitialState,
    data: {
      organisations: [],
    },
  },
};

export const slice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    init(state, action: PayloadAction<void>) {
      state.loadHomePage = initialState.loadHomePage;
    },
    loadHomePageRequest(state, action: PayloadAction<void>) {
      state.loadHomePage.loading = true;
      state.loadHomePage.error = undefined;
    },
    loadHomePageSuccess(
      state,
      action: PayloadAction<{
        organisations: IOrganisation[];
      }>,
    ) {
      state.loadHomePage.loading = false;
      state.loadHomePage.error = undefined;
      state.loadHomePage.data.organisations = action.payload.organisations;
    },
    loadHomePageFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadHomePage.loading = false;
      state.loadHomePage.error = action.payload;
    },
  },
});

export const { actions: homePageActions } = slice;
