/**
 *
 * MeetingForm
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  RiCalendarLine,
  RiCheckFill,
  RiDeleteBackLine,
  RiGroupLine,
  RiInformationLine,
  RiNewspaperLine,
} from 'react-icons/ri';

import { IconTitledContent } from 'app/components/IconTitledContent';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import { ICommittee, IMeeting } from 'types/types';
import moment from 'moment-timezone';
import key from 'weak-key';
import * as Yup from 'yup';

import { RequiredFieldIndicator } from 'app/components/RequiredFieldIndicator';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { getDateOfNextWorkingDay } from 'utils/getDateOfNextWorkingDay';
import { useMemo } from 'react';
import { DateTimePickerWrapper } from 'app/components/DateTimePickerWrapper';

interface Props {
  onClose: () => void;
  meeting: IMeeting | undefined;
  committees: ICommittee[];
  onSave: (payload: Partial<IMeeting>) => void;
  saving: boolean;
  onDelete?: (payload: IMeeting) => void;
}

const schema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  date_start: Yup.date()
    .typeError('Start date and time are required')
    .required(),
  length: Yup.number().required('This field is required'),
  date_end: Yup.date().typeError('End date and time are required').required(),
  committee_id: Yup.number().required('This field is required'),
});

export function MeetingForm(props: Props) {
  const { meeting, committees, onSave, onClose, saving, onDelete } = props;

  // Set length to 2 hours (this remains the same)
  const length = 2;

  // Set date_start to the next working day at 10 AM
  const [date_start, date_end] = useMemo(() => {
    const s = getDateOfNextWorkingDay();
    // Set date_end to the same day as date_start, but at 12 PM
    const e = moment(s).add(length, 'hours');
    return [s, e];
  }, []);

  if (!committees.length) return <LoadingIndicator height={300} />;
  return (
    <Formik
      validationSchema={schema}
      initialValues={
        meeting
          ? {
              ...meeting,
              date_start: moment(meeting.date_start),
              date_end: moment(meeting.date_end),
              length: meeting.length,
            }
          : ({
              id: undefined,
              name: '',
              description: '',
              committee_id: committees.length ? committees[0].id : undefined,
              location: '',
              date_start,
              length,
              date_end,
            } as Pick<
              IMeeting,
              'id' | 'name' | 'description' | 'committee_id' | 'location'
            > & {
              date_start: moment.Moment;
              date_end: moment.Moment;
              length: number;
            })
      }
      validateOnChange
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSave({
          ...values,
          date_start: moment
            .utc(values.date_start)
            .format('YYYY-MM-DD HH:mm:ss'),
          date_end: moment
            .utc(values.date_end)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        });
      }}
    >
      {formik => {
        const { setSubmitting, values, isSubmitting, errors, dirty } = formik;

        return (
          <Form>
            <Box>
              <Stack spacing={3}>
                <Box>
                  <Typography variant={'h3'}>Meeting details</Typography>
                  <RequiredFieldIndicator />
                </Box>
                <IconTitledContent
                  width={3}
                  icon={<RiGroupLine />}
                  title={'Committee *'}
                >
                  {meeting ? (
                    <Typography variant={'body1'}>
                      {meeting.committee.name}
                    </Typography>
                  ) : (
                    <Field
                      component={Select}
                      formControl={{ fullWidth: true }}
                      id="committee_id"
                      name="committee_id"
                      label="Committee"
                      fullWidth
                    >
                      {committees.map(c => (
                        <MenuItem value={c.id} key={key(c)}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Field>
                  )}
                </IconTitledContent>
                <IconTitledContent
                  width={3}
                  icon={<RiNewspaperLine />}
                  title={'Meeting name *'}
                >
                  <Field
                    component={TextField}
                    id="name"
                    name="name"
                    label="Meeting name"
                    fullWidth
                  />
                </IconTitledContent>
                <IconTitledContent
                  width={3}
                  icon={<RiInformationLine />}
                  title={'Description'}
                >
                  <Field
                    component={TextField}
                    id="description"
                    name="description"
                    multiline
                    rows={4}
                    label="Description"
                    fullWidth
                  />
                </IconTitledContent>
                <IconTitledContent
                  width={3}
                  icon={<RiCalendarLine />}
                  title={'Date / Time *'}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerWrapper
                        name={'date_start'}
                        onChange={(date: moment.Moment | null, context) => {
                          if (date) {
                            // add length in hours to start date
                            const newDate = (date as moment.Moment)
                              .clone()
                              .add(values.length, 'hours');
                            formik.setFieldValue('date_end', newDate);
                          }
                        }}
                        sx={{ width: '100%', mb: { xs: 2 } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        id="length"
                        name="length"
                        label="Length (hours)"
                        type="number"
                        InputProps={{ inputProps: { min: 0.5, step: 0.5 } }}
                        fullWidth
                        onChange={e => {
                          const length = parseFloat(e.target.value); // Parse the input value to a float
                          // set date_end to be date_start + length as moment object
                          const newDate = moment(values.date_start)
                            .clone()
                            .add(length, 'hours');
                          formik.setFieldValue('date_end', newDate);
                          formik.setFieldValue('length', length);
                        }}
                      />
                    </Grid>
                  </Grid>
                </IconTitledContent>
              </Stack>
              <Divider />
              <Typography variant={'h3'}>Access</Typography>

              <Stack spacing={3} sx={{ my: 3 }}>
                <IconTitledContent
                  width={3}
                  icon={<RiGroupLine />}
                  title={'Location / Link'}
                >
                  <Field
                    component={TextField}
                    id="location"
                    name="location"
                    label="Location / Link"
                    placeholder="Enter location"
                    fullWidth
                  />
                </IconTitledContent>
              </Stack>
            </Box>
            <Divider />
            <Box>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent={'flex-end'}
              >
                {!!(meeting && onDelete) && (
                  <Button
                    variant={'text'}
                    color={'error'}
                    disabled={saving || isSubmitting}
                    sx={{ mr: 'auto !important', px: 0 }}
                    onClick={() => onDelete(meeting)}
                  >
                    Delete meeting
                  </Button>
                )}

                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Button variant={'outlined'} type="reset" disabled={!dirty}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting || saving || !dirty}
                  >
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}
