import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { EditableField } from '../EditableField';
import { useFormikContext } from 'formik';

interface Props {
  title: string;
  name: string;
  onEdit: () => void;
}

export function ReviewAndSubmitEditableRow(props: Props) {
  const { title, onEdit, name } = props;

  const { values } = useFormikContext();
  const value = values[name];

  return (
    <Box>
      <Stack spacing={1}>
        <Box>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant="h3">{title}</Typography>
            <Box>
              <Stack direction={'row'} spacing={1}>
                <Button variant="outlined" size="small" onClick={onEdit}>
                  Edit
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={11}>
              <EditableField text={value} isEdit={false} name={name} />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
}
