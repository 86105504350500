import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { RiskRegisterSectionState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { ResponseErrorPayload } from 'utils/request';
import {
  IRiskRegister,
  IRiskRegisterItem,
  IRiskSuggestion,
  RiskAreaAction,
} from 'types/types';

export const initialState: RiskRegisterSectionState = {
  getRiskSuggestions: getApiCallInitialState<IRiskSuggestion[]>('list'),
  getMemberNames: getApiCallInitialState<string[]>('list'),
  loadOrganisationRiskRegister: getApiCallInitialState<IRiskRegister>(),
  updateOrganisationRiskRegister: getApiCallInitialState(),

  upsertRiskRegisterItem: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'riskRegisterSection',
  initialState,
  reducers: {
    init(state, action: PayloadAction) {
      state.getRiskSuggestions = { ...initialState.getRiskSuggestions };
      state.loadOrganisationRiskRegister = {
        ...initialState.loadOrganisationRiskRegister,
      };
      state.updateOrganisationRiskRegister = {
        ...initialState.updateOrganisationRiskRegister,
      };
      state.upsertRiskRegisterItem = { ...initialState.upsertRiskRegisterItem };
    },
    getRiskSuggestionsRequest(
      state,
      action: PayloadAction<{ organisation_id: number | string }>,
    ) {
      state.getRiskSuggestions = {
        ...initialState.getRiskSuggestions,
        loading: true,
      };
    },
    getRiskSuggestionsSuccess(
      state,
      action: PayloadAction<{ data: IRiskSuggestion[] }>,
    ) {
      state.getRiskSuggestions = {
        ...initialState.getRiskSuggestions,
        data: action.payload.data,
      };
    },
    getRiskSuggestionsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.getRiskSuggestions = {
        ...initialState.getRiskSuggestions,
        error: action.payload,
      };
    },
    getMemberNamesRequest(
      state,
      action: PayloadAction<{ organisation_id: number | string }>,
    ) {
      state.getMemberNames = {
        ...initialState.getMemberNames,
        loading: true,
      };
    },
    getMemberNamesSuccess(state, action: PayloadAction<{ data: string[] }>) {
      state.getMemberNames = {
        ...initialState.getMemberNames,
        data: action.payload.data,
      };
    },
    getMemberNamesFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.getMemberNames = {
        ...initialState.getMemberNames,
        error: action.payload,
      };
    },
    loadOrganisationRiskRegisterRequest(
      state,
      action: PayloadAction<{ organisation_id: number | string }>,
    ) {
      state.loadOrganisationRiskRegister = {
        ...initialState.loadOrganisationRiskRegister,
        data: state.loadOrganisationRiskRegister.data,
        loading: true,
        touched: true,
      };
    },
    loadOrganisationRiskRegisterSuccess(
      state,
      action: PayloadAction<{ data: IRiskRegister }>,
    ) {
      state.loadOrganisationRiskRegister = {
        ...initialState.loadOrganisationRiskRegister,
        data: action.payload.data,
      };
    },
    loadOrganisationRiskRegisterFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadOrganisationRiskRegister = {
        ...initialState.loadOrganisationRiskRegister,
        error: action.payload,
      };
    },
    updateOrganisationRiskRegisterRequest(
      state,
      action: PayloadAction<{
        organisation_id: number;
        risk_area_action?: RiskAreaAction;
        name?: string;
      }>,
    ) {
      state.updateOrganisationRiskRegister = {
        ...initialState.updateOrganisationRiskRegister,
        loading: true,
      };
    },
    updateOrganisationRiskRegisterSuccess(
      state,
      action: PayloadAction<{
        data: {
          risk_register: IRiskRegister;
          action: 'update' | 'add' | 'remove' | 'move-up' | 'move-down';
        };
      }>,
    ) {
      state.updateOrganisationRiskRegister = {
        ...initialState.updateOrganisationRiskRegister,
        data: action.payload.data,
      };
    },
    updateOrganisationRiskRegisterFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.updateOrganisationRiskRegister = {
        ...initialState.updateOrganisationRiskRegister,
        error: action.payload,
      };
    },
    upsertRiskRegisterItemRequest(
      state,
      action: PayloadAction<{
        organisation_id: number;
        risk_register_item: Partial<IRiskRegisterItem>;
      }>,
    ) {
      state.upsertRiskRegisterItem = {
        ...initialState.upsertRiskRegisterItem,
        loading: true,
      };
    },
    upsertRiskRegisterItemSuccess(
      state,
      action: PayloadAction<{
        data: {
          risk_register_item: IRiskRegisterItem;
          action: 'update' | 'create';
        };
      }>,
    ) {
      state.upsertRiskRegisterItem = {
        ...initialState.upsertRiskRegisterItem,
        data: action.payload.data,
      };
    },
    upsertRiskRegisterItemFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.upsertRiskRegisterItem = {
        ...initialState.upsertRiskRegisterItem,
        error: action.payload,
      };
    },
  },
});

export const { actions: riskRegisterSectionActions } = slice;
