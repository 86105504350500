/**
 *
 * PersonalDetails
 *
 */
import * as React from 'react';
import { Box, Container } from '@mui/material';
import { PersonalDetailsForm } from './PersonalDetailsForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from 'app/providers/AuthProvider/slice/selectors';
import { selectPersonalDetails } from './slice/selectors';
import { useEffectOnce } from 'utils/useEffectOnce';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { useSnackbar } from 'notistack';
import { useHasChanged } from 'utils/usePrevious';
import { useEffect } from 'react';
import { usePersonalDetailsSlice } from './slice/hook';
import { SectionHeader } from 'app/components/SectionHeader';
import { SectionMenu } from 'app/components/SectionMenu';
import { useLocation } from 'react-router-dom';

interface Props {}

export function PersonalDetails(props: Props) {
  const { actions } = usePersonalDetailsSlice();
  const user = useSelector(selectUserData);
  const dispatch = useDispatch();
  const { savePersonalDetails } = useSelector(selectPersonalDetails);

  const snackbar = useSnackbar();
  const savingChanged = useHasChanged(savePersonalDetails.loading);
  const location = useLocation();
  useEffect(() => {
    if (savingChanged && !savePersonalDetails.loading) {
      if (savePersonalDetails.error) {
        if (savePersonalDetails.error.code === 401) return;
        snackbar.enqueueSnackbar('Error saving.', { variant: 'error' });
      } else {
        snackbar.enqueueSnackbar('Saved.', { variant: 'success' });
        load();
      }
    }
  });

  const load = () => dispatch(authProviderActions.loadUserDataRequest());
  useEffectOnce(() => {
    load();
    return () => {
      load();
    };
  });
  return (
    <>
      <Box sx={{ my: 4 }}>
        <PersonalDetailsForm
          saving={savePersonalDetails.loading}
          user={user}
          onSave={payload =>
            dispatch(actions.savePersonalDetailsRequest(payload))
          }
        />
      </Box>
    </>
  );
}
