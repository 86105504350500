import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  paragraph: string;
}

export function TitleAndParagraph(props: Props) {
  const { title, paragraph } = props;

  return (
    <Box>
      <Stack>
        <Typography fontWeight={'bold'}>{title}</Typography>
        <Typography variant="body2" lineHeight={1.8}>
          {paragraph}
        </Typography>
      </Stack>
    </Box>
  );
}
