import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { OrganisationPageState } from './types';
import { FeatureCode, IOrganisation, ManageFeatureAction } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';
import { getApiCallInitialState } from 'types/ApiCall';

export const initialState: OrganisationPageState = {
  loadOrganisation: getApiCallInitialState<IOrganisation>(),
  manageFeature: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'organisationPage',
  initialState,
  reducers: {
    loadOrganisationRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadOrganisation.loading = true;
      state.loadOrganisation.error = undefined;
    },
    loadOrganisationSuccess(
      state,
      action: PayloadAction<{ data: IOrganisation }>,
    ) {
      state.loadOrganisation.loading = false;
      state.loadOrganisation.error = undefined;
      state.loadOrganisation.data = action.payload.data;
    },
    loadOrganisationFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadOrganisation.loading = false;
      state.loadOrganisation.error = action.payload;
    },
    manageFeatureRequest(
      state,
      action: PayloadAction<{
        id: number;
        code: FeatureCode;
        action: ManageFeatureAction;
      }>,
    ) {
      state.manageFeature.loading = true;
      state.manageFeature.error = undefined;
    },
    manageFeatureSuccess(state, action: PayloadAction<{ data: any }>) {
      state.manageFeature.loading = false;
      state.manageFeature.error = undefined;
      state.manageFeature.data = action.payload.data;
    },
    manageFeatureFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.manageFeature.loading = false;
      state.manageFeature.error = action.payload;
    },
  },
});

export const { actions: organisationPageActions } = slice;
