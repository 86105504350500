import { colors, useTheme } from '@mui/material';

type RiskLevel = number;

interface RiskColourName {
  levelName?: string;
  colour: string;
}

type FieldType =
  | 'score_impact'
  | 'score_likelihood'
  | 'score_impact_mitigated'
  | 'mitigated';

export const useRiskColourName = () => {
  const theme = useTheme();
  const { colours } = theme.palette.text.risk;
  const likelihoodNames: string[] = [
    'Remote',
    'Unlikely',
    'Possible',
    'Probable',
    'Highly probable',
  ];
  const impactNames: string[] = [
    'Insignificant',
    'Minor',
    'Moderate',
    'Major',
    'Extreme',
  ];

  return (riskLevel: RiskLevel, field: FieldType): RiskColourName => {
    switch (field) {
      case 'score_likelihood':
        return {
          levelName: likelihoodNames[riskLevel - 1],
          colour: colours[riskLevel],
        };
      case 'score_impact':
      case 'score_impact_mitigated':
        return {
          levelName: impactNames[riskLevel - 1],
          colour: colours[riskLevel],
        };
      case 'mitigated':
        let keyIndex;
        if (riskLevel < 3) {
          keyIndex = Math.round(riskLevel);
        }
        if (riskLevel >= 3) {
          keyIndex = Math.round(riskLevel);
        }
        return {
          colour: colours[keyIndex],
        };
    }
  };
};
