import React from 'react';
import { Chip, ChipProps } from '@mui/material';

interface Props extends ChipProps {
  status: string;
  title: string;
}

export function ActionStatusChip(props: Props) {
  const { status, title, ...rProps } = props;
  const statusColor = status === 'open' ? 'secondary' : 'success';
  return (
    <Chip
      label={title}
      sx={{
        width: '100%',
        backgroundColor: `${statusColor}.light`,
        color: `${statusColor}.main`,
        fontWeight: 'bold',
      }}
      {...rProps}
    />
  );
}
