import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { VerifiedIcon } from '../assets/VerifiedIcon';

interface Props {
  duration?: number;
  onFinished: () => void;
}

export function AuthConfirmationScreen(props: Props) {
  const { onFinished, duration = 1500 } = props;

  useEffect(() => {
    const redirect = setTimeout(() => {
      onFinished();
    }, duration);
    return () => clearTimeout(redirect);
  });

  return (
    <Box>
      <Stack alignItems={'center'} spacing={4}>
        <Typography variant="h3" color={'primary.main'}>
          Verify your identity
        </Typography>
        <Typography variant="h5" fontWeight={'bold'}>
          Identity confirmed
        </Typography>
        <VerifiedIcon></VerifiedIcon>
        <Typography variant="body1">
          Redirecting you to Governance360...
        </Typography>
      </Stack>
    </Box>
  );
}
