/**
 *
 * Members
 *
 */
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { IOrganisation } from 'types/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectMembers } from './slice/selectors';
import { useMembersSlice } from './slice/hook';
import { useHasChanged } from 'utils/usePrevious';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { RiAddCircleLine } from 'react-icons/ri';
import { MembersTable } from 'app/components/MembersTable';
import {
  MemberFormWidget,
  useMemberFormWidget,
} from 'app/widgets/MemberFormWidget';
import { useEffectOnce } from 'utils/useEffectOnce';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Helmet } from 'react-helmet-async';
import { InvitationsTable } from 'app/components/InvitationsTable';
import { useConfirm } from 'utils/useConfirm';
import { useSnackbar } from 'notistack';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import {
  InvitationFormWidget,
  useInvitationFormWidget,
} from 'app/widgets/InvitationFormWidget';
import { noop } from 'utils/noop';
import { useGetCurrentFeature } from '../OrganisationPage/useGetCurrentFeature';
import { SectionHeader } from 'app/components/SectionHeader';

interface Props {
  organisation: IOrganisation;
}

export function Members(props: Props) {
  const { organisation } = props;
  const {
    loadMembers,
    loadInvitations,
    resendInvitation,
    removeInvitation,
    deleteMember,
  } = useSelector(selectMembers);

  const { feature } = useGetCurrentFeature('core');

  const { actions } = useMembersSlice();
  const dispatch = useDispatch();
  const { hasPermission } = useHasPermission();

  const snackbar = useSnackbar();
  const confirm = useConfirm();

  const deleteMemberLoadingChanged = useHasChanged(deleteMember.loading);
  const loadMembersLoadingChanged = useHasChanged(loadMembers.loading);
  const resendInvitationChanged = useHasChanged(resendInvitation.loading);
  const removeInvitationChanged = useHasChanged(removeInvitation.loading);
  const organisationChanged = useHasChanged(organisation.id);

  const { open, close } = useMemberFormWidget();
  const { open: iOpen, close: iClose } = useInvitationFormWidget();

  useEffect(() => {
    if (loadMembersLoadingChanged && !loadMembers.loading) {
      if (loadMembers.error) {
        // todo - redirect away from page (and log?)
        if (loadMembers.error.code === 401) return;
      }
    }
    if (organisationChanged && organisation.id) {
      load();
    }
    if (resendInvitationChanged && !resendInvitation.loading) {
      if (resendInvitation.error) {
        if (resendInvitation.error.code === 401) return;
        snackbar.enqueueSnackbar(resendInvitation.error.message, {
          variant: 'error',
        });
      } else {
        snackbar.enqueueSnackbar('Invitation successfully resent', {
          variant: 'success',
        });
        load();
      }
    }

    if (removeInvitationChanged && !removeInvitation.loading) {
      if (removeInvitation.error) {
        if (removeInvitation.error.code === 401) return;
        snackbar.enqueueSnackbar(removeInvitation.error.message, {
          variant: 'error',
        });
      } else {
        snackbar.enqueueSnackbar('Invitation removed', {
          variant: 'success',
        });
        load();
      }
    }

    if (deleteMemberLoadingChanged && !deleteMember.loading) {
      if (deleteMember.error) {
        if (deleteMember.error.code === 401) return;
        snackbar.enqueueSnackbar(deleteMember.error.message, {
          variant: 'error',
        });
      } else {
        snackbar.enqueueSnackbar('Item deleted', {
          variant: 'success',
        });
        close();
        load();
      }
    }
  });

  const load = useCallback(() => {
    dispatch(
      actions.loadMembersRequest({
        organisation_id: organisation.id,
        skip_invites: !hasPermission(
          'view-organisation-invitations',
          'organisation',
          organisation.id,
        ),
      }),
    );
    if (canInvite) {
      dispatch(
        actions.loadInvitationsRequest({ organisation_id: organisation.id }),
      );
    }
  }, [organisation]);

  useEffect(() => {
    load();
    return () => {
      dispatch(actions.loadMembersInit());
      dispatch(actions.loadInvitationsInit());
      snackbar.closeSnackbar();
    };
  }, [load]);

  const confirmDelete = id =>
    confirm({
      title: 'Delete member',
      description: 'Are you sure?',
    })
      .then(() => dispatch(actions.deleteMemberRequest({ id })))
      .catch(noop);

  const maxMembersExceeded = useMemo(() => {
    if (!loadMembers.data) return false;
    return (
      loadMembers.data.length + loadInvitations.data.length >=
      feature.settings.max_members
    );
  }, [loadMembers.data, loadInvitations.data, feature.settings.max_members]);

  if (!loadMembers.data.length && loadMembers.loading) {
    return <LoadingIndicator />;
  }

  const canInvite = hasPermission(
    'invite-organisation-member',
    'organisation',
    organisation.id,
  );
  const canManage = hasPermission(
    'manage-organisation-members',
    'organisation',
    organisation.id,
  );
  const canView = hasPermission(
    'view-organisation-members',
    'organisation',
    organisation.id,
  );

  return (
    <>
      <Helmet title={'Members'}>
        <meta name="description" content="Members of the organisation" />
      </Helmet>
      <SectionHeader title={`Members (${loadMembers.data.length})`} />
      <Container>
        <Stack my={4} spacing={1}>
          <Box mb={0.5}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent={'space-between'}
              alignItems={{ xs: 'start', md: 'center' }}
            >
              <Box>
                <Typography>
                  A top-level overview of organisation member details.
                  {canInvite && (
                    <>
                      {' '}
                      You can invite new members to the organisation, select
                      their role, and add them to a committee.
                    </>
                  )}
                </Typography>
              </Box>
              {canInvite && (
                <Box sx={{ minWidth: 200, ml: 4 }}>
                  <Button
                    variant={'outlined'}
                    startIcon={<RiAddCircleLine />}
                    onClick={() => open({ organisation_id: organisation.id })}
                    disabled={maxMembersExceeded}
                  >
                    Invite a member
                  </Button>
                </Box>
              )}
              {(canInvite || canManage) && (
                <MemberFormWidget
                  onDelete={member => confirmDelete(member.id)}
                  onChange={() => load()}
                />
              )}
            </Stack>
          </Box>
        </Stack>
        <Box>
          {canView && (
            <MembersTable
              members={loadMembers.data}
              editable={canManage}
              onEdit={id =>
                open({
                  id,
                  organisation_id: organisation.id,
                })
              }
              onRemove={confirmDelete}
            />
          )}
        </Box>
        {!!loadInvitations.data.length && (
          <Box>
            <Stack
              sx={{ my: 3 }}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant={'h3'}>
                Pending Invitations ({loadInvitations.data.length})
              </Typography>
            </Stack>
            <InvitationFormWidget onChange={load} />
            <InvitationsTable
              invitations={loadInvitations.data}
              editable
              onResend={id => iOpen({ id, organisation_id: organisation.id })}
              onRemove={id =>
                confirm({
                  title: 'Delete invitation',
                  description: 'Are you sure?',
                  confirmationText: 'Delete',
                  confirmationButtonProps: {
                    color: 'error',
                  },
                })
                  .then(() => dispatch(actions.removeInvitationRequest({ id })))
                  .catch(noop)
              }
            />
          </Box>
        )}
      </Container>
    </>
  );
}
