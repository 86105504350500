/**
 *
 * OrganisationSelector
 *
 */
import * as React from 'react';
import { useRef } from 'react';
import { useOrganisationSelectorSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { OrganisationSelectButton as SelectButton } from 'app/components/OrganisationSelectButton';
import { selectUserData } from '../AuthProvider/slice/selectors';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SelectOrganisation } from './slice/types';
import { Person, Search } from '@mui/icons-material';
import {
  RiArrowDropDownLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleFill,
  RiCloseLine,
} from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { useHasChanged } from 'utils/usePrevious';
import { useOrganisationSelector } from './useOrganisationSelector';
import { useEffectOnChange } from 'utils/useEffectOnChange';
import { useHasPermission } from '../AuthProvider/useHasPermission';
import { OrganisationSelectLinks } from 'app/components/OrganisationSelectLinks';

interface OrganisationSelectorProps {
  children: React.ReactNode;
}

export function OrganisationSelector(props: OrganisationSelectorProps) {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const { actions } = useOrganisationSelectorSlice();

  // Clear if unmounted
  React.useEffect(() => {
    return () => {
      dispatch(actions.setAvailableOrganisations([]));
    };
  }, []);

  React.useEffect(() => {
    if (!userData) return;

    setTimeout(
      () =>
        dispatch(
          actions.setAvailableOrganisations(userData.access_organisations),
        ),
      1,
    );
  }, [userData]);

  return <>{React.Children.only(props.children)}</>;
}

interface OrganisationSelectButtonProps {
  type: 'menu' | 'items';
}

export function OrganisationSelectButton(props: OrganisationSelectButtonProps) {
  const { type } = props;
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState('');
  const theme = useTheme();

  const [openOrgMenu, setOpenOrgMenu] = React.useState<null | HTMLElement>(
    null,
  );

  const [toggleMenu, setToggleMenu] = React.useState<boolean>(false);

  const handleOrgMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (type === 'menu') {
      setToggleMenu(false);
      setOpenOrgMenu(event.currentTarget);
    } else if (type === 'items') {
      event.stopPropagation();
      setOpenOrgMenu(null);
      setToggleMenu(!toggleMenu);
    }
  };

  const ref = useRef<HTMLInputElement>(null);

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    currentOrganisationId,
    setCurrentOrganisationId,
    availableOrganisations,
    open,
  } = useOrganisationSelector();

  const selection = [currentOrganisationId];
  const setSelection = (ids: Array<number | string>) => {
    setCurrentOrganisationId(ids.length ? +ids[0] : null);
  };

  const dispatch = useDispatch();
  const { actions } = useOrganisationSelectorSlice();

  const filteredAvailableOrganisations = availableOrganisations.filter(
    (organisation: SelectOrganisation) =>
      searchText.length
        ? organisation.name.toLowerCase().indexOf(searchText.toLowerCase()) !==
          -1
        : true,
  );

  const close = (apply: boolean = true) => {
    if (apply) dispatch(actions.setCurrentOrganisations(selection));
    dispatch(actions.setOrganisationSelectOpen(false));
  };

  useEffectOnChange(() => {
    if (open) {
      close();
      navigate(`/organisation/${currentOrganisationId}`);
    }
  }, currentOrganisationId);

  useEffectOnChange(() => {
    if (open) {
      setTimeout(() => {
        ref.current.focus();
      }, 300);
    }
  }, open);

  const organisations = availableOrganisations.filter(
    c => currentOrganisationId === +c.id,
  );

  let text =
    organisations.length === 1 ? organisations[0].name : 'Go to organisation';
  if (organisations.length > 1) {
    text = `${organisations.length} organisations selected`;
  }

  const toggleOrganisation = (id: number | string) => {
    setSelection([id]);
  };
  return (
    <Box>
      {type === 'menu' && (
        <>
          <SelectButton
            text={text}
            onClick={handleOrgMenu}
            endIcon={<RiArrowDropDownLine size={25} />}
            color="inherit"
            sx={{ px: 0 }}
          />
          <Menu
            anchorEl={openOrgMenu}
            open={Boolean(openOrgMenu)}
            onClose={() => setOpenOrgMenu(null)}
            sx={{ mt: 1 }}
          >
            <OrganisationSelectLinks onClick={() => setOpenOrgMenu(null)} />
            <Divider />

            {availableOrganisations.length === 1 ? (
              <MenuItem sx={{ pointerEvents: 'none' }}>{text}</MenuItem>
            ) : (
              <Box>
                <MenuItem sx={{ pointerEvents: 'none' }}>
                  <Stack spacing={1}>
                    <Typography color="textSecondary" variant="body2">
                      Currently viewing:
                    </Typography>
                    <Typography>{text}</Typography>
                  </Stack>
                </MenuItem>
                <Divider />

                <Button
                  variant="text"
                  onClick={() => {
                    setOpenOrgMenu(null);
                    dispatch(actions.setOrganisationSelectOpen(true));
                  }}
                >
                  Switch Organisation
                </Button>
              </Box>
            )}
          </Menu>
        </>
      )}

      {type === 'items' && (
        <Box>
          {availableOrganisations.length > 1 ? (
            <>
              <MenuItem sx={{ pointerEvents: 'none' }}>
                <Stack spacing={1}>
                  <Typography color="textSecondary" variant="body2">
                    Currently viewing:
                  </Typography>
                  <Typography>{text}</Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                sx={{ fontWeight: 'bold' }}
                onClick={() => {
                  setOpenOrgMenu(null);
                  dispatch(actions.setOrganisationSelectOpen(true));
                }}
              >
                Switch Organisation
              </MenuItem>
            </>
          ) : (
            <MenuItem sx={{ pointerEvents: 'none' }}>
              <Typography>{text}</Typography>
            </MenuItem>
          )}
        </Box>
      )}

      <Dialog
        open={open}
        fullWidth
        fullScreen={fullScreen}
        maxWidth="sm"
        onClose={() => close(false)}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>Select organisation</Box>
            <IconButton
              onClick={() => close(false)}
              edge={'end'}
              size={'large'}
            >
              <RiCloseLine />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Box sx={{ p: 2 }}>
          <TextField
            inputRef={ref}
            autoFocus
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            type="text"
            name={'search-organisation'}
            value={searchText}
            onChange={ev => setSearchText(ev.target.value)}
          />
        </Box>
        <DialogContent dividers sx={{ px: 0 }}>
          <List>
            {filteredAvailableOrganisations.map(
              (organisation: SelectOrganisation) => (
                <ListItemButton
                  key={`organisation-${organisation.id}`}
                  title={organisation.name}
                  onClick={() => toggleOrganisation(organisation.id)}
                  sx={{
                    pl: 3,
                    pr: 0,
                    ...(selection.indexOf(organisation.id) !== -1
                      ? {
                          backgroundColor: 'primary.main',
                          color: 'primary.contrastText',
                          '&:hover': {
                            backgroundColor: 'primary.light',
                            color: 'primary.contrastText',
                          },
                        }
                      : {}),
                  }}
                >
                  <ListItemText>{organisation.name}</ListItemText>
                  <ListItemIcon>
                    {selection.indexOf(organisation.id) !== -1 ? (
                      <RiCheckboxCircleFill fontSize={20} color={'secondary'} />
                    ) : (
                      <RiCheckboxBlankCircleLine
                        fontSize={20}
                        color={'secondary'}
                      />
                    )}
                  </ListItemIcon>
                </ListItemButton>
              ),
            )}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
