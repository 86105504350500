import { createSlice } from 'utils/@reduxjs/toolkit';
import { ActivateState, BoardActivationData } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { PayloadAction } from '@reduxjs/toolkit';

export const initialState: ActivateState = {
  loadBoardActivationData: getApiCallInitialState<BoardActivationData>(),
  activateBoard: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'activate',
  initialState,
  reducers: {
    loadBoardActivationDataInit(state, action: PayloadAction) {
      state.loadBoardActivationData = initialState.loadBoardActivationData;
    },
    loadBoardActivationDataRequest(
      state,
      action: PayloadAction<{ id: number; code: string }>,
    ) {
      state.loadBoardActivationData.loading = true;
      state.loadBoardActivationData.error = undefined;
    },
    loadBoardActivationDataSuccess(
      state,
      action: PayloadAction<{ data: BoardActivationData }>,
    ) {
      state.loadBoardActivationData.loading = false;
      state.loadBoardActivationData.error = undefined;
      state.loadBoardActivationData.data = action.payload.data;
    },
    loadBoardActivationDataFailed(state, action: PayloadAction<any>) {
      state.loadBoardActivationData.loading = false;
      state.loadBoardActivationData.error = action.payload;
    },
    activateBoardInit(state, action: PayloadAction<void>) {
      state.activateBoard = initialState.activateBoard;
    },
    activateBoardRequest(state, action: PayloadAction<any>) {
      state.activateBoard.loading = true;
      state.activateBoard.error = undefined;
    },
    activateBoardSuccess(state, action: PayloadAction<any>) {
      state.activateBoard.loading = false;
      state.activateBoard.error = undefined;
      state.activateBoard.data = action.payload;
    },
    activateBoardFailed(state, action: PayloadAction<any>) {
      state.activateBoard.loading = false;
      state.activateBoard.error = action.payload;
    },
  },
});

export const { actions: meetingsActions } = slice;
