/**
 *
 * useHasPermission
 *
 */

import { useDispatch, useSelector } from 'react-redux';
import { selectAuthProvider } from './slice/selectors';
import { useAuthProviderSlice } from './slice/hook';

export function useRefreshUser() {
  const { actions } = useAuthProviderSlice();
  const { loadUser } = useSelector(selectAuthProvider);
  const dispatch = useDispatch();

  const refreshUser = () => {
    dispatch(actions.loadUserDataRequest());
  };

  return { loadUser, refreshUser };
}
