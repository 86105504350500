import { call, takeLatest, cancelled, put } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { membersActions as actions } from './';
import { createRequestSaga } from 'utils/saga';

const loadMembers = createRequestSaga({
  actionType: actions.loadMembersRequest.type,
  url: `members`,
  successAction: actions.loadMembersSuccess,
  failureAction: actions.loadMembersFailed,
});
const loadInvitations = createRequestSaga({
  actionType: actions.loadInvitationsRequest.type,
  url: `invitations`,
  successAction: actions.loadInvitationsSuccess,
  failureAction: actions.loadInvitationsFailed,
});

const removeInvitation = createRequestSaga({
  actionType: actions.removeInvitationRequest.type,
  url: ({ id }) => `invitations/${id}`,
  method: 'delete',
  successAction: actions.removeInvitationSuccess,
  failureAction: actions.removeInvitationFailed,
});

const resendInvitation = createRequestSaga({
  actionType: actions.resendInvitationRequest.type,
  url: ({ id }) => `invitations/${id}/resend`,
  method: 'post',
  successAction: actions.resendInvitationSuccess,
  failureAction: actions.resendInvitationFailed,
});

const deleteMember = createRequestSaga({
  actionType: actions.deleteMemberRequest.type,
  url: ({ id }) => `members/${id}`,
  method: 'delete',
  successAction: actions.deleteMemberSuccess,
  failureAction: actions.deleteMemberFailed,
});

export function* membersSaga() {
  yield takeLatest(actions.loadMembersRequest.type, loadMembers);
  yield takeLatest(actions.loadInvitationsRequest.type, loadInvitations);
  yield takeLatest(actions.resendInvitationRequest.type, resendInvitation);
  yield takeLatest(actions.removeInvitationRequest.type, removeInvitation);
  yield takeLatest(actions.deleteMemberRequest.type, deleteMember);
}
