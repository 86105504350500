/**
 *
 * Meetings
 *
 */
import * as React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Item } from './Item';
import { IOrganisation } from 'types/types';
import { Helmet } from 'react-helmet-async';
import { useReloadCurrentOrganisation } from '../OrganisationPage/useReloadCurrentOrganisation';
import { useSelector } from 'react-redux';
import { selectMeetings } from './slice/selectors';
import { useEffectOnChange } from 'utils/useEffectOnChange';
import { SectionHeader } from 'app/components/SectionHeader';
import { Button, Container } from '@mui/material';
import { SectionMenu } from 'app/components/SectionMenu';
import { Upcoming } from './Upcoming';
import { Past } from './Past';
import { Guide } from './Guide';
import { BoardCalendar } from './BoardCalendar';
import { RiAddCircleFill } from 'react-icons/ri';
import { useMeetingFormWidget } from 'app/widgets/MeetingFormWidget';
import { useCanCreateMeetings } from './useCanCreateMeetings';

interface Props {
  organisation: IOrganisation;
}

export function Meetings(props: Props) {
  const { organisation } = props;
  const { reloadOrganisation } = useReloadCurrentOrganisation();
  const { loadMeetings, loadMeeting } = useSelector(selectMeetings);

  const { open } = useMeetingFormWidget();

  const location = useLocation();

  const canCreateMeetings = useCanCreateMeetings(organisation);

  useEffectOnChange(() => {
    reloadOrganisation();
  }, loadMeetings.data.length);

  if (!organisation) return null;
  return (
    <>
      <Helmet title={'Meetings'}>
        <meta name="description" content="Meetings of the organisation" />
      </Helmet>
      <SectionHeader
        title={'My meetings'}
        sectionMenu={
          <>
            <SectionMenu
              title="Upcoming & recent"
              path="./upcoming"
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/meetings/upcoming`
              }
            />
            <SectionMenu
              title="Past meetings"
              path="./past"
              isActive={
                location.pathname ===
                `/organisation/${organisation.id}/meetings/past`
              }
            />
          </>
        }
        cta={
          <>
            {canCreateMeetings && (
              <>
                <Button
                  color="secondary"
                  startIcon={<RiAddCircleFill />}
                  onClick={() => {
                    open({ organisation_id: organisation.id });
                  }}
                >
                  Schedule new meeting
                </Button>
              </>
            )}
          </>
        }
      />

      <Container>
        <Routes>
          {/* <Route path={''} element={<List organisation={organisation} />} /> */}
          <Route
            path={':id/*'}
            element={<Item organisation={organisation} />}
          />
          <Route
            path={'upcoming'}
            element={<Upcoming organisation={organisation} />}
          />
          <Route path={'past'} element={<Past organisation={organisation} />} />
          <Route path={'guide'} element={<Guide />} />
          <Route path={'board-calendar'} element={<BoardCalendar />} />
          <Route path={'*'} element={<Navigate to={'upcoming'} />} />
        </Routes>
      </Container>
    </>
  );
}
