import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { organisationProfileActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadOrganisationProfile = createRequestSaga({
  actionType: actions.loadOrganisationProfileRequest.type,
  url: ({ id }) => `organisations/${id}`,
  successAction: actions.loadOrganisationProfileSuccess,
  failureAction: actions.loadOrganisationProfileFailed,
});

const saveOrganisation = createRequestSaga({
  actionType: actions.saveOrganisationProfileRequest.type,
  url: `organisations/update-details`,
  method: 'put',
  successAction: actions.saveOrganisationProfileSuccess,
  failureAction: actions.saveOrganisationProfileFailed,
});

const sendContactForm = createRequestSaga({
  actionType: actions.sendContactFormRequest.type,
  url: `comms/send-contact-form`,
  method: 'post',
  successAction: actions.sendContactFormSuccess,
  failureAction: actions.sendContactFormFailed,
});

export function* organisationProfileSaga() {
  yield takeLatest(
    actions.loadOrganisationProfileRequest.type,
    loadOrganisationProfile,
  );
  yield takeLatest(
    actions.saveOrganisationProfileRequest.type,
    saveOrganisation,
  );
  yield takeLatest(actions.sendContactFormRequest.type, sendContactForm);
}
