import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { committeeFormWidgetSaga } from './saga';
import { CommitteeFormWidgetState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { ICommittee } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: CommitteeFormWidgetState = {
  form: {
    open: false,
    loading: false,
  },
  loadCommittee: getApiCallInitialState<ICommittee>(),
  saveCommittee: getApiCallInitialState(),
};

const slice = createSlice({
  name: 'committeeFormWidget',
  initialState,
  reducers: {
    openRequest(
      state,
      action: PayloadAction<{
        id?: number;
        organisation_id: number;
      }>,
    ) {
      state.form.id = action.payload.id;
      state.form.open = true;
      state.form.loading = true;
      state.form.organisation_id = action.payload.organisation_id;
    },
    openSuccess(state, action: PayloadAction<{}>) {
      state.form.loading = false;
    },
    openFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.form = { ...initialState.form, error: action.payload };
    },
    close(state, action: PayloadAction<void>) {
      state.form = initialState.form;
      state.loadCommittee = initialState.loadCommittee;
      state.saveCommittee = initialState.saveCommittee;
    },
    loadCommitteeRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadCommittee.loading = true;
      state.loadCommittee.error = undefined;
    },
    loadCommitteeSuccess(state, action: PayloadAction<{ data: ICommittee }>) {
      state.loadCommittee.loading = false;
      state.loadCommittee.error = undefined;
      state.loadCommittee.data = action.payload.data;
    },
    loadCommitteeFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadCommittee.loading = false;
      state.loadCommittee.error = action.payload;
    },
    saveCommitteeRequest(state, action: PayloadAction<Partial<ICommittee>>) {
      state.saveCommittee.loading = true;
      state.saveCommittee.error = undefined;
    },
    saveCommitteeSuccess(state, action: PayloadAction<{ data: ICommittee }>) {
      state.saveCommittee.loading = false;
      state.saveCommittee.error = undefined;
      state.saveCommittee.data = action.payload.data;
    },
    saveCommitteeFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.saveCommittee.loading = false;
      state.saveCommittee.error = action.payload;
    },
  },
});

export const { actions: committeeFormWidgetActions } = slice;

export const useCommitteeFormWidgetSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: committeeFormWidgetSaga });
  return { actions: slice.actions };
};
