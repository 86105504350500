import React, { useState } from 'react';
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

interface Props {
  initialLogUpdates: boolean;
  initialNotifyOnUpdates: boolean;
  onCheck?: (val) => void;
  saving: boolean;
}

export function MeetingLogOptions(props: Props) {
  const { initialLogUpdates, initialNotifyOnUpdates, onCheck, saving } = props;
  const [logUpdates, setLogUpdates] = useState(initialLogUpdates);
  const [notifyOnUpdates, setNotifyOnUpdates] = useState(
    initialNotifyOnUpdates,
  );

  const handleLogUpdatesChange = event => {
    const checked = event.target.checked;
    setLogUpdates(checked);
    onCheck({ log_updates: checked, notify_on_updates: notifyOnUpdates });
  };

  const handleNotifyOnUpdatesChange = event => {
    const checked = event.target.checked;
    setNotifyOnUpdates(checked);
    onCheck({ log_updates: logUpdates, notify_on_updates: checked });
  };

  return (
    <Box>
      <Stack>
        <Typography>
          Updates to the meeting are found below. Board admins can choose what
          to log and whether to notify attendees of updates.
        </Typography>
        <Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={logUpdates}
                  onChange={handleLogUpdatesChange}
                  disabled={saving}
                />
              }
              label="Save updates to the meeting activity log"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={notifyOnUpdates}
                  onChange={handleNotifyOnUpdatesChange}
                  disabled={saving}
                />
              }
              label="Always notify attendees of meeting activity log updates"
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
