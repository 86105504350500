import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useLocalStorage } from 'usehooks-ts';
import { Intro } from './Intro';
import { Wizard } from './Wizard';
import { useNavigate } from 'react-router-dom';
import { IMeeting, IOrganisation } from 'types/types';
import { useActivateSlice } from '../slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectActivate } from '../slice/selectors';
import { useEffectOnChange } from 'utils/useEffectOnChange';
import { Activating } from '../Activating';
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import { getDateOfNextWorkingDay } from 'utils/getDateOfNextWorkingDay';

export interface FormValues {
  track?: 'learner' | 'advanced';
  committee_name?: string;
  schedule_meeting?: boolean;
  meeting?: Pick<IMeeting, 'name' | 'description' | 'location' | 'length'> & {
    date_start: moment.Moment;
    date_end: moment.Moment;
  };
}

export interface WizardState {
  step: number;
  played: number[];
  values: FormValues;
}
interface Props {
  organisation: IOrganisation;
  onComplete: () => void;
}

export function Board(props: Props) {
  
  // Set length to 2 hours (this remains the same)
  const length = 2;
  
  // Set date_start to the next working day at 10 AM
  // Set date_end to the same day as date_start, but at 12 PM
  const [date_start, date_end] = useMemo(() => {
    const s = getDateOfNextWorkingDay();
    // Set date_end to the same day as date_start, but at 12 PM
    const e = moment(s).add(length, 'hours');
    return [s, e];
  }, []);
  
  const initialValues = {
    step: 0,
    played: [],
    values: {
      committee_name: '',
      schedule_meeting: undefined,
      meeting: {
        name: '',
        description: '',
        location: '',
        date_start,
        date_end,
        length,
      },
    },
  };
  
  const { organisation, onComplete } = props;
  const [activating, setActivating] = React.useState<boolean>(false);
  const { actions } = useActivateSlice();
  const dispatch = useDispatch();
  const { loadBoardActivationData, activateBoard } =
    useSelector(selectActivate);

  const snackbar = useSnackbar();

  const navigate = useNavigate();
  const [state, setState] = useLocalStorage<WizardState>(
    `boardWizardState-${organisation.id}`,
    initialValues,
  );

  const { step, values } = state;
  const setStep = (step: number) => {
    setState({ ...state, step });
  };

  useEffectOnChange(() => {
    if (activateBoard.loading === true) {
      setActivating(true);
    } else {
      if (activateBoard.error) {
        // handle error
        snackbar.enqueueSnackbar(
          'There was an error activating the boards feature.',
          {
            variant: 'error',
          },
        );
        setActivating(false);
      } else {
        onComplete();
        setState(initialValues);
      }
    }
  }, activateBoard.loading);

  useEffect(() => {
    //setState(initialValues);
    dispatch(
      actions.loadBoardActivationDataRequest({
        id: +organisation.id,
        code: 'board',
      }),
    );
    return () => {
      dispatch(actions.loadBoardActivationDataInit());
    };
  }, []);

  const content = useMemo(() => {
    if (activating) {
      return <Activating />;
    }
    if (step === 0) {
      return <Intro proceed={() => setStep(1)} cancel={() => navigate('/')} />;
    } else {
      return (
        <Wizard
          cancel={() => setState(initialValues)}
          state={state}
          setState={setState}
          onSubmit={data =>
            dispatch(
              actions.activateBoardRequest({
                id: +organisation.id,
                code: 'board',
                ...data,
              }),
            )
          }
          activationData={loadBoardActivationData.data}
        />
      );
    }
  }, [step, values, activateBoard.loading, loadBoardActivationData.data]);

  if (!loadBoardActivationData.data) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'grey.200',
          my: 6,
          px: 4,
          py: 6,
          borderRadius: 3,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {content}
      </Box>
    </>
  );
}
