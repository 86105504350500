/**
 *
 * PasswordField
 *
 */

import React, { ReactNode } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Paper,
  Stack,
} from '@mui/material';
import { TextFieldProps, SelectProps } from 'formik-mui';
import { FieldInputProps, FieldProps } from 'formik';
import key from 'weak-key';

interface Props extends FieldProps<any> {
  options?: Array<{
    id: number | string;
    label: ReactNode;
    [key: string]: any;
  }>;
}

export function FormikMultiSelectCheckboxes(
  props: Props,
): React.ReactComponentElement<any> {
  const {
    options = [],
    field: { value = [], name, multiple = true, onBlur, onChange },
    form,
  } = props;

  const toggleValue = v => {
    if (value.indexOf(v) > -1) {
      form.setFieldValue(
        name,
        value.filter(val => v !== val),
      );
    } else {
      form.setFieldValue(name, [...value, v]);
    }
  };

  return (
    <Box>
      <FormGroup>
        <Paper sx={{ p: 2 }} variant={'outlined'}>
          <Stack spacing={1}>
            {options.map(o => (
              <Box key={key(o)}>
                <FormControlLabel
                  label={o.label}
                  control={
                    <Checkbox
                      checked={value.indexOf(o.id) > -1}
                      onClick={ev => {
                        toggleValue(o.id);
                      }}
                    />
                  }
                />
              </Box>
            ))}
          </Stack>
        </Paper>
        {!!form.errors[name] && !!form.dirty && (
          <FormHelperText variant={'outlined'} error>
            {form.errors[name] as string}
          </FormHelperText>
        )}
      </FormGroup>
    </Box>
  );
}
