/**
 *
 * ManageAuth
 *
 */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { AuthPageContainer } from 'app/components/AuthPageContainer';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthProvider } from 'app/providers/AuthProvider/slice/selectors';
import { ConfirmLoginForm } from './ConfirmLoginForm';
import { useManageAuthSlice } from './slice';
import { selectManageAuth } from './slice/selectors';
import moment from 'moment-timezone';
import { MFAOptions } from 'app/components/MFAOptions';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { GetBackupCodes } from 'app/components/GetBackupCodes';
import { SmsForm } from 'app/components/SetupMfa/SmsForm';
import { Box, Stack } from '@mui/material';
import { EmailForm } from 'app/components/SetupMfa/EmailForm';
import { AuthAppForm } from 'app/components/SetupMfa/AuthAppForm';
import { RemoveForm } from 'app/components/SetupMfa/RemoveForm';
import { MFAConfirmation } from 'app/components/MFAConfirmation';
import { useEffectOnce } from 'utils/useEffectOnce';
import { ChangeEmailForm } from './ChangeEmailForm';
import { ChangePasswordForm } from './ChangePasswordForm';
import useHandleApiResponse from 'utils/useHandleApiResponse';

interface Props {}

const managePath = '/auth/manage';

export function ManageAuth(props: Props) {
  const [authorized, setAuthorized] = useState<boolean>(false);
  const { loadUser } = useSelector(selectAuthProvider);
  const {
    confirmLogin,
    getSetupData,
    confirmSetup,
    changePassword,
    changeEmail,
    requestCodes,
  } = useSelector(selectManageAuth);
  const dispatch = useDispatch();
  const { actions } = useManageAuthSlice();
  const navigate = useNavigate();
  const location = useLocation();

  const { mfa_method, email, telephone_mobile } = loadUser.data;

  useHandleApiResponse(changePassword, 'Password changed successfully', {
    errorMessage: 'Unable to change your password',
    onSuccess: () => navigate('/profile/security'),
  });

  useHandleApiResponse(changeEmail, 'Email changed successfully', {
    errorMessage: 'Unable to change your email',
    onSuccess: () => navigate('/profile/security'),
  });
  useHandleApiResponse(confirmLogin, null, {
    errorMessage: 'Unable to confirm credentials',
  });

  useEffect(() => {
    if (
      confirmLogin.data.confirmationToken &&
      confirmLogin.data.confirmationTokenExpires > moment().toISOString()
    ) {
      setAuthorized(true);
    } else {
      setAuthorized(false);
    }
  }, [confirmLogin.data]);

  useEffect(() => {
    dispatch(authProviderActions.loadUserDataRequest());
  }, [confirmSetup.data]);
  useEffect(() => {
    if (location.pathname === `${managePath}/setup`)
      dispatch(actions.init(false));
  }, [location.pathname]);

  useEffectOnce(() => {
    dispatch(actions.init(true));
  });

  const currentAction = useMemo(() => {
    const route = location.pathname.replace(`${managePath}/`, '');
    switch (true) {
      case route.startsWith('setup'):
        return loadUser.data.mfa_method ? 'manage-mfa' : 'enable-mfa';
      default:
        return route;
    }
  }, [location, loadUser.data]);

  const content = useMemo(() => {
    if (!authorized) {
      return (
        <ConfirmLoginForm
          loginRequest={values => dispatch(actions.confirmLoginRequest(values))}
          mfaEnabled={!!loadUser.data.mfa_method}
          loading={false}
          action={currentAction}
          cancelPath={'/profile/security'}
        />
      );
    }

    const confirmationToken = confirmLogin.data.confirmationToken;

    return (
      <Routes>
        <Route
          path="get-codes"
          element={
            <GetBackupCodes
              returnPath={'/profile/security'}
              loading={requestCodes.loading}
              request={() => dispatch(actions.requestCodesRequest({}))}
              reset={() =>
                dispatch(actions.requestCodesRequest({ clear_all: true }))
              }
              init={() => dispatch(actions.requestCodesInit())}
              codes={requestCodes.data.codes}
              cleared={requestCodes.data.cleared}
            />
          }
        />
        <Route
          path={'setup/*'}
          element={
            <Box>
              <Stack>
                {confirmSetup.loading || confirmSetup.data ? (
                  <MFAConfirmation
                    returnPath={'/profile/security'}
                    isEnabled={(confirmSetup.data || {}).enabled}
                    isVerifying={confirmSetup.loading}
                    backPath={`${managePath}/setup`}
                    method={mfa_method}
                  />
                ) : (
                  <>
                    <Routes>
                      <Route
                        path="sms"
                        element={
                          <SmsForm
                            getSetupData={telephone_mobile =>
                              dispatch(
                                actions.getSetupDataRequest({
                                  method: 'sms',
                                  telephone_mobile,
                                  confirmationToken,
                                }),
                              )
                            }
                            onConfirm={code =>
                              dispatch(
                                actions.confirmSetupRequest({
                                  method: 'sms',
                                  code,
                                  confirmationToken,
                                }),
                              )
                            }
                            setupLoading={getSetupData.loading}
                            setupLoaded={getSetupData.data.loaded}
                            telephone_mobile={telephone_mobile}
                          />
                        }
                      />
                      <Route
                        path="email"
                        element={
                          <EmailForm
                            getSetupData={() =>
                              dispatch(
                                actions.getSetupDataRequest({
                                  method: 'email',
                                  confirmationToken,
                                }),
                              )
                            }
                            onConfirm={code =>
                              dispatch(
                                actions.confirmSetupRequest({
                                  method: 'email',
                                  code,
                                  confirmationToken,
                                }),
                              )
                            }
                            setupLoading={getSetupData.loading}
                            setupLoaded={getSetupData.data.loaded}
                            email={loadUser.data.email}
                          />
                        }
                      />
                      <Route
                        path="auth-app"
                        element={
                          <AuthAppForm
                            getSetupData={() =>
                              dispatch(
                                actions.getSetupDataRequest({
                                  method: 'auth-app',
                                  confirmationToken,
                                }),
                              )
                            }
                            onConfirm={(code, secret) =>
                              dispatch(
                                actions.confirmSetupRequest({
                                  method: 'auth-app',
                                  code,
                                  secret,
                                  confirmationToken,
                                }),
                              )
                            }
                            setupLoading={getSetupData.loading}
                            setupData={getSetupData.data}
                          />
                        }
                      />

                      <Route
                        path="remove"
                        element={
                          <RemoveForm
                            email={email}
                            getSetupData={() =>
                              dispatch(
                                actions.getSetupDataRequest({
                                  method: 'remove',
                                  confirmationToken,
                                }),
                              )
                            }
                            onConfirm={code =>
                              dispatch(
                                actions.confirmSetupRequest({
                                  method: 'remove',
                                  code,
                                  confirmationToken,
                                }),
                              )
                            }
                            setupLoading={getSetupData.loading}
                            setupLoaded={getSetupData.data.loaded}
                          />
                        }
                      />
                    </Routes>
                  </>
                )}
              </Stack>
            </Box>
          }
        />

        <Route
          path="change-email"
          element={
            <ChangeEmailForm
              cancelPath={'/profile/security'}
              saving={changeEmail.loading}
              onSave={values =>
                dispatch(
                  actions.changeEmailRequest({ ...values, confirmationToken }),
                )
              }
            />
          }
        />
        <Route
          path="change-password"
          element={
            <ChangePasswordForm
              cancelPath={'/profile/security'}
              saving={changePassword.loading}
              onSave={values =>
                dispatch(
                  actions.changePasswordRequest({
                    ...values,
                    confirmationToken,
                  }),
                )
              }
            />
          }
        />
        <Route
          path="setup"
          element={
            <MFAOptions
              mfaEnabled={!!mfa_method}
              onConfirm={type => {
                navigate(`${managePath}/setup/${type}`);
              }}
              cancelPath={'/profile/security'}
            />
          }
        />
      </Routes>
    );
  }, [
    authorized,
    loadUser,
    confirmSetup,
    getSetupData,
    confirmLogin,
    location,
    currentAction,
    requestCodes,
  ]);

  return (
    <AuthPageContainer>
      <>{content}</>
    </AuthPageContainer>
  );
}
