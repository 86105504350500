import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHomePageSlice } from './slice/hook';
import { selectHomePage } from './slice/selectors';
import { useEffectOnce } from 'utils/useEffectOnce';
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

export function HomePage() {
  const dispatch = useDispatch();
  const { actions } = useHomePageSlice();
  const {
    loadHomePage: {
      loading,
      error,
      data: { organisations },
    },
  } = useSelector(selectHomePage);

  const load = () => dispatch(actions.loadHomePageRequest());

  useEffectOnce(() => {
    load();
    return () => {
      dispatch(actions.init());
    };
  });

  const [lastOrganisationId] = useLocalStorage('lastOrganisationId', null);

  const organisation_id = useMemo(() => {
    if (
      lastOrganisationId &&
      organisations.find(o => o.id === +lastOrganisationId)
    ) {
      return lastOrganisationId;
    }
    if (organisations.length > 0) {
      return organisations[0].id;
    }
    return null;
  }, [lastOrganisationId, organisations]);

  if (organisation_id) {
    return <Navigate to={`/organisation/${organisation_id}`} />;
  }

  return <></>;
}
