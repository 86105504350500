import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { meetingFormWidgetSaga } from './saga';
import { MeetingFormWidgetState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { ICommittee, IMeeting } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: MeetingFormWidgetState = {
  form: {
    open: false,
    loading: false,
    committees: [],
  },
  loadMeeting: getApiCallInitialState<IMeeting>(),
  saveMeeting: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'meetingFormWidget',
  initialState,
  reducers: {
    loadFormRequest(
      state,
      action: PayloadAction<{
        id?: number;
        organisation_id: number;
        committee_id?: number;
      }>,
    ) {
      state.form.id = action.payload.id;
      state.form.loading = true;
      state.form.organisation_id = action.payload.organisation_id;
      state.form.committee_id = action.payload.committee_id;
    },
    loadFormSuccess(state, action: PayloadAction<{ data: ICommittee[] }>) {
      state.form.committees = action.payload.data;
      state.form.loading = false;
    },
    loadFormFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.form = { ...initialState.form, error: action.payload };
    },
    open(state, action: PayloadAction) {
      state.form.open = true;
    },
    close(state, action: PayloadAction<void>) {
      state.form = initialState.form;
      state.loadMeeting = initialState.loadMeeting;
      state.saveMeeting = initialState.saveMeeting;
    },
    loadMeetingRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadMeeting.loading = true;
      state.loadMeeting.error = undefined;
    },
    loadMeetingSuccess(state, action: PayloadAction<{ data: IMeeting }>) {
      state.loadMeeting.loading = false;
      state.loadMeeting.error = undefined;
      state.loadMeeting.data = action.payload.data;
    },
    loadMeetingFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadMeeting.loading = false;
      state.loadMeeting.error = action.payload;
    },
    saveMeetingRequest(state, action: PayloadAction<Partial<IMeeting>>) {
      state.saveMeeting.loading = true;
      state.saveMeeting.error = undefined;
    },
    saveMeetingSuccess(state, action: PayloadAction<{ data: IMeeting }>) {
      state.saveMeeting.loading = false;
      state.saveMeeting.error = undefined;
      state.saveMeeting.data = action.payload.data;
    },
    saveMeetingFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.saveMeeting.loading = false;
      state.saveMeeting.error = action.payload;
    },
  },
});

export const { actions: meetingFormWidgetActions } = slice;
