/**
 *
 * CreateOrganisation
 *
 */
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Select, TextField as FormikTextField } from 'formik-mui';
import { apiRequest } from 'utils/request';
import { useDebounce } from 'utils/useDebounce';
import { Field, Form, Formik, FormikProps } from 'formik';
import { IconTitledContent } from 'app/components/IconTitledContent';
import { RiEditFill } from 'react-icons/ri';
import { IOrganisation, organisationTypes } from 'types/types';
import key from 'weak-key';
import * as Yup from 'yup';
import { NewOrganisationValues } from './index';
import { useEffectOnChange } from 'utils/useEffectOnChange';

interface Props {
  organisationDetails?: NewOrganisationValues;
  onSubmit: (data: NewOrganisationValues) => void;
  edit?: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string()
    .required('This field is required')
    .test(
      'checkNameUnique',
      'An organisation already exists with this name.',
      value => {
        return apiRequest({
          url: 'organisations/check-name',
          method: 'post',
          data: {
            name: value,
          },
        })
          .then(res => !!res.data.unique)
          .catch(e => false);
      },
    ),
  number: Yup.string(),
  type: Yup.string().required('This field is required'),
});

export function NewOrganisationForm(props: Props) {
  const { onSubmit, organisationDetails } = props;

  const [searchText, setSearchText] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<any | null>(
    organisationDetails?.selectedCompany,
  );
  const [options, setOptions] = useState<
    Array<{ title: string; company_number: string; [key: string]: any }>
  >([]);

  const dbSearchText = useDebounce(searchText, 900);

  const formRef = useRef<FormikProps<any>>(null);

  const [orgSelectionMode, setOrgSelectionMode] = useState<string>(
    organisationDetails?.orgSelectionMode || 'ch',
  );

  const [confirmedMode, setConfirmedMode] = useState<boolean>(
    organisationDetails?.confirmedMode || false,
  );

  useEffectOnChange(
    () => {
      console.log('selectedCompany', selectedCompany);
      setSelectedCompany(null);
      setSearchText('');
      setOptions([]);
      formRef.current?.setFieldValue('name', '');
      formRef.current?.setFieldValue('number', '');
      formRef.current?.setFieldValue('premises', '');
      formRef.current?.setFieldValue('address_line_1', '');
      formRef.current?.setFieldValue('address_line_2', '');
      formRef.current?.setFieldValue('locality', '');
      formRef.current?.setFieldValue('region', '');
      formRef.current?.setFieldValue('country', '');
      formRef.current?.setFieldValue('postal_code', '');
      formRef.current?.setFieldValue('type', '');
    },
    confirmedMode,
    false,
  );

  useEffect(() => {
    if (selectedCompany && selectedCompany.title === dbSearchText) return;
    if (searchText.length > 3) {
      apiRequest({
        url: 'organisations/search-companies',
        data: {
          searchText,
        },
      }).then(res => {
        setOptions(res.data.items || []);
      });
    }
  }, [dbSearchText]);

  useEffect(() => {
    if (selectedCompany && formRef.current) {
      formRef.current.setFieldValue('name', selectedCompany.title || '');
      formRef.current.setFieldValue(
        'number',
        selectedCompany.company_number || '',
      );
      formRef.current.setFieldValue(
        'premises',
        selectedCompany.address.premises || '',
      );
      formRef.current.setFieldValue(
        'address_line_1',
        selectedCompany.address.address_line_1 || '',
      );
      formRef.current.setFieldValue(
        'address_line_2',
        selectedCompany.address.address_line_2 || '',
      );
      formRef.current.setFieldValue(
        'locality',
        selectedCompany.address.locality || '',
      );
      formRef.current.setFieldValue(
        'region',
        selectedCompany.address.region || '',
      );
      formRef.current.setFieldValue(
        'country',
        selectedCompany.address.country || '',
      );
      formRef.current.setFieldValue(
        'postal_code',
        selectedCompany.address.postal_code || '',
      );

      let company_type: keyof typeof organisationTypes | '' = '';
      if (selectedCompany) {
        company_type = 'other';
        switch (selectedCompany.company_type) {
          case 'ltd':
            company_type = 'ltd';
            break;
          case 'industrial-and-provident-society':
            company_type = 'industrial-and-provident-society';
            break;
          case 'llp':
            company_type = 'limited-liability-partnership';
            break;
          case 'private-limited-guarant-nsc':
            company_type = 'private-limited-by-guarantee-without-share-capital';
            break;
        }
      }

      formRef.current.setFieldValue('type', company_type);
    }
  }, [selectedCompany]);

  const handleSelectionChange = event => {
    const newValue = event.target.value;
    if (orgSelectionMode === newValue) {
      setOrgSelectionMode(null);
    } else {
      setOrgSelectionMode(newValue);
    }
  };

  const content = (err, touched) => {
    switch (orgSelectionMode) {
      case 'ch':
        return (
          <>
            <Typography>
              Type in the organisation name or company registration number below
              to retrieve information
            </Typography>
            <Autocomplete
              options={options.map(o => ({
                id: o.company_number,
                ...o,
              }))}
              value={selectedCompany}
              onChange={(ev, v, reason) => {
                setOptions(
                  v
                    ? [v, ...options.filter(o => o.title !== v.title)]
                    : options,
                );
                setSelectedCompany(v);
              }}
              getOptionLabel={(option: any) => option.title}
              onInputChange={(event, value, reason) => {
                if (reason === 'input') setSearchText(value);
              }}
              renderInput={params => (
                <>
                  <TextField
                    name="name"
                    inputProps={{ 'aria-autocomplete': 'none' }}
                    {...params}
                    value={searchText}
                    label="Find company"
                    fullWidth
                    error={err.name && touched.name}
                    helperText={err.name && touched.name && err.name}
                  />
                </>
              )}
            />
            {!!selectedCompany && (
              <>
                <Stack spacing={3}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Typography>
                      Click on edit if you would like to change any of the
                      organisation details below.
                    </Typography>
                    <Button
                      onClick={() => setOrgSelectionMode('edit')}
                      variant="outlined"
                      startIcon={<RiEditFill />}
                    >
                      Edit
                    </Button>
                  </Stack>
                  <Box>
                    <Stack>
                      <IconTitledContent title={'Organisation'} width={3}>
                        <Typography>
                          {[
                            selectedCompany.title,
                            selectedCompany.company_number,
                            selectedCompany.company_type,
                          ]
                            .filter(c => !!c)
                            .join(' | ')}
                        </Typography>
                      </IconTitledContent>
                      <IconTitledContent title={'Address'} width={3}>
                        <Typography>
                          {[
                            selectedCompany.address.premises,
                            selectedCompany.address.address_line_1,
                            selectedCompany.address.address_line_2,
                            selectedCompany.address.locality,
                            selectedCompany.address.region,
                            selectedCompany.address.country,
                            selectedCompany.address.postal_code,
                          ]
                            .filter(a => !!a)
                            .join(', ')}
                        </Typography>
                      </IconTitledContent>
                    </Stack>
                  </Box>
                </Stack>
              </>
            )}
          </>
        );
      case 'manual':
      case 'edit':
        return (
          <>
            <Box sx={{ my: 4 }}>
              <Typography>
                Please type in the details of your organisation.
              </Typography>
            </Box>

            <IconTitledContent title={'Organisation'} width={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    label={'Organisation name'}
                    name={'name'}
                    id={'name'}
                    type={'text'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    label={'Company number'}
                    name={'number'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={Select}
                    formControl={{ fullWidth: true }}
                    label={'Type'}
                    name={'type'}
                  >
                    {Object.keys(organisationTypes).map(k => (
                      <MenuItem value={k} key={key({ k })}>
                        {organisationTypes[k]}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
            </IconTitledContent>

            <IconTitledContent title={'Address'} width={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} sx={{ mb: 2 }}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    label={'Premises'}
                    name={'premises'}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mb: 2 }}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    label={'Address 1'}
                    name={'address_line_1'}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mb: 2 }}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    label={'Address 2'}
                    name={'address_line_2'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    label={'Town/City'}
                    name={'locality'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    label={'County'}
                    name={'region'}
                    id={'region'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    label={'Country'}
                    name={'country'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    label={'Postcode'}
                    name={'postal_code'}
                  />
                </Grid>
              </Grid>
            </IconTitledContent>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Formik
        validationSchema={schema}
        innerRef={formRef}
        initialValues={{
          name: organisationDetails?.name || '',
          number: organisationDetails?.number || '',
          premises: organisationDetails?.premises || '',
          address_line_1: organisationDetails?.address_line_1 || '',
          address_line_2: organisationDetails?.address_line_2 || '',
          locality: organisationDetails?.locality || '',
          region: organisationDetails?.region || '',
          country: organisationDetails?.country || '',
          postal_code: organisationDetails?.postal_code || '',
          type: organisationDetails?.type || '',
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit({
            ...values,
            orgSelectionMode,
            confirmedMode,
            selectedCompany,
          });
        }}
      >
        {formik => {
          const {
            setSubmitting,
            isSubmitting,
            isValidating,
            dirty,
            errors,
            touched,
          } = formik;
          if (isSubmitting) setSubmitting(false);

          return (
            <>
              {!confirmedMode ? (
                <>
                  <Stack spacing={4}>
                    <Typography>Choose from the following options:</Typography>
                    <RadioGroup
                      value={orgSelectionMode}
                      onChange={handleSelectionChange}
                    >
                      <Stack spacing={4}>
                        <Paper
                          sx={{
                            p: 4,
                            border: 2,
                            borderColor:
                              orgSelectionMode === 'ch'
                                ? 'primary.main'
                                : 'grey.400',
                            backgroundColor:
                              orgSelectionMode === 'ch' && 'common.beige',
                          }}
                          elevation={0}
                        >
                          <Stack>
                            <FormControlLabel
                              value="ch"
                              control={<Radio color={'primary'} />}
                              label={
                                <>
                                  <Typography variant="body1">
                                    Select your organisation from the UK
                                    Companies House register
                                  </Typography>
                                </>
                              }
                            />
                          </Stack>
                        </Paper>
                        <Paper
                          sx={{
                            p: 4,
                            border: 2,
                            borderColor:
                              orgSelectionMode === 'manual'
                                ? 'primary.main'
                                : 'grey.400',
                            backgroundColor:
                              orgSelectionMode === 'manual' && 'common.beige',
                          }}
                          elevation={0}
                        >
                          <Stack>
                            <FormControlLabel
                              value="manual"
                              control={<Radio color={'primary'} />}
                              label={
                                <>
                                  <Typography variant="body1">
                                    Add your organisation details manually
                                  </Typography>
                                </>
                              }
                            />
                          </Stack>
                        </Paper>
                      </Stack>
                    </RadioGroup>

                    <Stack direction={'row'} justifyContent={'end'}>
                      <Button
                        disabled={orgSelectionMode === 'edit'}
                        onClick={() => setConfirmedMode(true)}
                      >
                        Confirm
                      </Button>
                    </Stack>
                  </Stack>
                </>
              ) : (
                <Box>
                  <Form>
                    <Stack spacing={4}>
                      {content(errors, touched)}

                      <Divider />
                      <Stack direction={'row'} justifyContent={'flex-end'}>
                        <Button
                          onClick={() => setConfirmedMode(false)}
                          variant="text"
                          size="small"
                        >
                          Go back to options
                        </Button>
                        <Button
                          type={'submit'}
                          disabled={
                            orgSelectionMode === 'ch'
                              ? !selectedCompany
                              : isSubmitting || isValidating
                          }
                        >
                          Next: select plan
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                </Box>
              )}
            </>
          );
        }}
      </Formik>
    </Box>
  );
}
