import React, { ReactNode } from 'react';
import { Box, ButtonGroup, Button, Stack } from '@mui/material';
import {
  RiCheckboxBlankCircleFill,
  RiCheckboxBlankCircleLine,
} from 'react-icons/ri';

interface Props {
  icon?: ReactNode;
  label: ReactNode;
  onChange: (e) => void;
  checked: boolean;
  color?: 'primary.main' | 'error.main';
}

export function RadioButton(props: Props) {
  const { icon, label, checked, onChange, color = 'primary.main' } = props;

  return (
    <Box
      color={checked ? color : 'grey.700'}
      sx={{
        border: 2,
        borderRadius: 1,
      }}
    >
      <Button
        fullWidth
        onClick={onChange}
        startIcon={
          checked ? (
            <RiCheckboxBlankCircleFill
              fontSize={20}
              color={color}
              cursor={'pointer'}
            />
          ) : (
            <RiCheckboxBlankCircleLine fontSize={20} color={'secondary.main'} />
          )
        }
        color="inherit"
        variant="text"
        sx={{
          justifyContent: 'flex-start',
          alignItems: label ? 'flex-start' : 'center',
          textDecoration: 'none !important',
          p: 3,
          gap: 4,
          backgroundColor: checked ? 'common.beige' : 'white',
          '&:hover': {
            backgroundColor: checked ? 'common.beige' : 'white',
          },
        }}
      >
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {!!icon && <Box>{icon}</Box>}
          {!!label && <Box>{label}</Box>}
        </Stack>
      </Button>
    </Box>
  );
}
