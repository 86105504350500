/**
 *
 * SetupMfa
 *
 */
import React from 'react';
import { MfaMethod } from 'types/types';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useEffectOnce } from 'utils/useEffectOnce';
import { LoadingIndicator } from '../LoadingIndicator';
import { PhoneAuthIcon } from '../assets/PhoneAuthIcon';
import { BottomLinks } from './BottomLinks';
import { AuthInputField } from '../AuthInputField';

interface Props {
  getSetupData: () => void;
  setupLoading: boolean;
  setupData: {
    loaded: boolean;
    qrcode_image?: string;
    secret?: string;
  };
  onConfirm: (code: string, secret: string) => void;
}

export function AuthAppForm(props: Props) {
  const { getSetupData, onConfirm, setupData, setupLoading } = props;
  const [code, setCode] = React.useState<string>('');

  useEffectOnce(() => {
    getSetupData();
  });

  if (setupLoading) {
    return <LoadingIndicator />;
  }

  if (!setupData) return null;

  return (
    <Box>
      <Stack>
        <Stack alignItems={'center'} spacing={3} textAlign={'center'}>
          <Box sx={{ color: 'primary.main' }}>
            <PhoneAuthIcon color={'currentColor'} height="40" width="40" />
          </Box>
          <Typography variant="h3" color={'primary.main'}>
            Enable 2-factor authentication
          </Typography>
          <Typography variant="h4" fontWeight={'bold'}>
            Step 1: Add Governance360 to your app
          </Typography>

          <Typography variant="body1" >
            Launch your authenticator app on your phone and scan the below QR
            code. Governance360 supports popular authenticator apps, including
            Google Authenticator.{' '}
          </Typography>

          <Box
            sx={{
              display: 'inline-block',
            }}
            dangerouslySetInnerHTML={{
              __html: (setupData.qrcode_image || '').replace(
                /<\? xml .*\?>/,
                '',
              ),
            }}
          />

          <Typography variant="body1" textAlign={'center'}>
            If you can't scan the QR code, you can enter this passcode into your
            authenticator app instead:
          </Typography>
          <Typography variant="body1" letterSpacing={'0.3rem'} p={2}>
            {setupData.secret}
          </Typography>

          <Typography variant="h4" fontWeight={'bold'}>
            Step 2: Enter your security code
          </Typography>
          <Typography variant="body1" textAlign={'center'}>
            Your app will generate a security code.
            <br />
            Enter the generated security code below:
          </Typography>
        </Stack>

        <AuthInputField
          label="Enter app generated security code"
          codeValue={code}
          onChange={v => setCode(v)}
          onEnter={() => onConfirm(code, setupData.secret)}
        />

        <Button
          disabled={code.length < 6}
          size="large"
          fullWidth
          onClick={() => onConfirm(code, setupData.secret)}
        >
          Verify security code
        </Button>

        <BottomLinks />
      </Stack>
    </Box>
  );
}
