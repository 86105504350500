import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export const AutoUpdateName = () => {
  const { values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    if (
      values.file &&
      !values.name && // Only run when name is initially empty
      typeof values.file === 'object' &&
      values.file.name
    ) {
      setFieldValue('name', values.file.name);
    }
  }, [values.file, setFieldValue]);

  return null;
};
