/**
 *
 * useLogUserAction
 *
 */
import { useCallback } from 'react';
import { useLoggingProviderSlice } from './slice';
import { useDispatch } from 'react-redux';
import { IUserAction } from './slice/types';

export function useLogUserAction() {
  const dispatch = useDispatch();
  const { actions } = useLoggingProviderSlice();

  const logUserAction = useCallback(
    (action: IUserAction) => {
      dispatch(actions.pushActionToQueue(action));
    },
    [dispatch, actions],
  );

  return { logUserAction };
}
