/**
 *
 * TrainingRecord
 *
 */
import * as React from 'react';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { useOpenAcademyPage } from 'utils/useOpenAcademyPage';
import { useTrainingRecordSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrainingRecord } from './slice/selectors';
import { useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { RiInformationLine } from 'react-icons/ri';
import { AcademyModuleCard } from 'app/components/AcademyModuleCard';
import key from 'weak-key';
import { ReactComponent as AcademyIcon } from './assets/academy_icon.svg';
import { DashboardCard } from 'app/components/DashboardCard';
import { SectionHeader } from 'app/components/SectionHeader';

interface Props {}

export function TrainingRecord(props: Props) {
  const { openLink, opening } = useOpenAcademyPage();
  const { actions } = useTrainingRecordSlice();
  const { trainingRecord } = useSelector(selectTrainingRecord);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.loadRecordRequest());
  }, [dispatch, actions]);

  const rows = useMemo(() => {
    return trainingRecord.data.map(tr => {
      return {
        ...tr,
        percentage_completed: Math.round(+tr.percentage_completed * 100),
      };
    });
  }, [trainingRecord.data]);

  const formatDate = (d: string, fb = 'n/a') =>
    d ? moment(d).format('DD/MM/YYYY') : fb;
  if (trainingRecord.loading) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <Container>
        <Box my={4}>
          <Stack spacing={3}>
            <Box>
              <Stack>
                <Typography>
                  Here you can view the status of your academy training record.
                  You may also undertake additional training which can be found
                  in the academy dashboard.
                </Typography>
              </Stack>
            </Box>
            <Box>
              <Stack spacing={0.5}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                  py={1}
                  justifyContent={'end'}
                >
                  <RiInformationLine />
                  <Typography variant={'body2'}>
                    Please ensure that pop-ups are enabled in your browser
                  </Typography>
                </Stack>
                <DashboardCard title={'Academy'} icon={<AcademyIcon />}>
                  <Box>
                    <Grid container>
                      {rows.map(r => (
                        <Grid item xs={12} sm={6} md={4} key={key(r)}>
                          <AcademyModuleCard
                            image={`${r.course_card_image_url}`}
                            percentage={r.percentage_completed}
                            title={r.course_name}
                            onClick={() => openLink(r.link)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                  >
                    <Button
                      fullWidth
                      variant="text"
                      disabled={opening}
                      sx={{
                        p: 0,
                        justifyContent: 'start',
                      }}
                      onClick={() => openLink()}
                    >
                      Go to Academy dashboard
                    </Button>
                  </Stack>
                </DashboardCard>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
