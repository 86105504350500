import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonBase,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { DataGridPro, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { getDateWIthFallbackString } from '../../../utils/getDateWIthFallbackString';
import { formatParsedISO } from '../../../utils/formatParsedISO';
import { ReportTable } from '../../pages/ReportsPage/slice/types';
import { ApiCall } from '../../../types/ApiCall';
import { LoadingIndicator } from '../LoadingIndicator';
import { calculateDataTableHeight } from '../../../styles/theme';

interface Props {
  title: string;
  description: string;
  updated_at?: string;
  onUpdate: () => void;
  open: boolean;
  apiCall: ApiCall<ReportTable>;
  onToggle: () => void;
  pageSize?: number;
}

const makeColumns = (columns: any[]) =>
  columns
    .map(column => {
      const c: GridColDef = { ...column };
      if (column.type === 'date' || column.type === 'dateTime') {
        c.valueGetter = ({ row }) => {
          return getDateWIthFallbackString(row[column.field]);
        };
      }
      c.headerName = column.label;
      return c;
    })
    .filter(column => column.field !== 'id');

export function DataAccordion(props: Props) {
  const {
    onToggle,
    apiCall,
    title,
    description,
    updated_at,
    onUpdate,
    open,
    pageSize = 25,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const apiRef = useGridApiRef();

  const handleExportCsv = options => {
    apiRef.current.exportDataAsCsv(options);
  };

  const handleExportPrint = options => {
    apiRef.current.exportDataAsPrint(options);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const theme = useTheme();
  const toggleOpen = () => {
    if (open) {
      onToggle();
    } else {
      onUpdate();
      onToggle();
    }
  };

  const showFooter = useMemo(() => {
    return apiCall.data.rows.length > pageSize;
  }, [pageSize, apiCall.data.rows]);

  return (
    <>
      <Box>
        <ButtonBase
          onClick={toggleOpen}
          sx={{
            border: `2px solid ${theme.palette.grey[200]}`,
            backgroundColor: theme => theme.palette.grey[100],
            width: '100%',
            p: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Stack direction={'row'} alignItems={'center'}>
              {open ? (
                <RiArrowDropUpLine
                  size={40}
                  color={theme.palette.primary.main}
                />
              ) : (
                <RiArrowDropDownLine
                  size={40}
                  color={theme.palette.primary.main}
                />
              )}
              <Typography variant="h5">{title}</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack direction={'row'} alignItems={'center'}>
              <Box>
                <Typography component={'span'} color={theme.palette.grey[500]}>
                  Last updated:
                </Typography>
                <Typography
                  component={'span'}
                  color={theme.palette.grey[500]}
                  px={1}
                >
                  {formatParsedISO(updated_at, 'dd/MM/yyyy HH:mm:ss', 'N/A')}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{ fontSize: 14, py: 0.5 }}
                onClick={event => {
                  onUpdate();
                  event.stopPropagation();
                }}
              >
                Update now
              </Button>
            </Stack>
          </Box>
        </ButtonBase>
        {open && (
          <>
            <Box
              sx={{
                p: 2,
                border: `2px solid ${theme.palette.grey[200]}`,
                borderTop: 'none',
              }}
            >
              <Stack spacing={4}>
                <Box>
                  <Stack>
                    <Typography variant="h5">Description</Typography>
                    <Typography>{description}</Typography>
                  </Stack>
                </Box>
                {apiCall.data.rows.length === 0 ? (
                  <Box>
                    {apiCall.loading ? (
                      <LoadingIndicator />
                    ) : (
                      <Typography>No data available</Typography>
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Stack>
                      <Box>
                        <Stack direction={'row'}>
                          <Button
                            onClick={() => handleExportCsv({ fileName: title })}
                            variant={'outlined'}
                          >
                            Export to CSV
                          </Button>
                        </Stack>
                      </Box>
                      {/* Table starts here */}
                      <Paper
                        sx={{
                          height: calculateDataTableHeight(
                            apiCall.data.rows.length,
                            showFooter,
                            72,
                          ),
                          maxHeight: '50vh',
                          width: '100%',
                          mb: 4,
                        }}
                      >
                        <DataGridPro
                          apiRef={apiRef}
                          hideFooter={!showFooter}
                          loading={apiCall.loading}
                          rows={apiCall.data.rows}
                          columns={makeColumns(apiCall.data.columns)}
                        />
                      </Paper>
                    </Stack>
                  </Box>
                )}

                <Box>
                  <Button variant="outlined" onClick={onUpdate}>
                    Refresh
                  </Button>
                </Box>
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
