import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { OrganisationSelectorState, SelectOrganisation } from './types';

export const initialState: OrganisationSelectorState = {
  currentOrganisations: [],
  availableOrganisations: [],
  //  ...(loadState().organisationSelector || {}),
  open: false,
};

const slice = createSlice({
  name: 'organisationSelector',
  initialState,
  reducers: {
    initialiseCurrentOrganisations(state, action: PayloadAction<void>) {},
    setAvailableOrganisations(
      state,
      action: PayloadAction<Array<SelectOrganisation>>,
    ) {
      state.availableOrganisations = action.payload;
    },
    setCurrentOrganisations(
      state,
      action: PayloadAction<Array<number | string>>,
    ) {
      state.currentOrganisations = action.payload;
    },
    setOrganisationSelectOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
  },
});

export const { actions: organisationSelectorActions } = slice;

export const useOrganisationSelectorSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
