import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';

export type OnClickAction =
  | {
      id: 'open-external-link';
      params: {
        url: string;
      };
    }
  | {
      id: 'open-internal-link';
      params: {
        path: string;
      };
    }
  | {
      id: 'security-page';
    }
  | {
      id: 'declarations-page';
    }
  | {
      id: 'academy-page';
    }
  | {
      id: 'organisation-profile-page';
    }
  | {
      id: 'personal-details-page';
    };

export interface PathPlaceholders {
  [key: string]: string | number | boolean;
}

const useOnClickActions = (placeholders?: PathPlaceholders) => {
  const navigate = useNavigate();

  const replacePathPlaceholders = useCallback(
    (path: string) => {
      if (placeholders) {
        Object.keys(placeholders).forEach(key => {
          path = path.replace(`:${key}`, get(placeholders, key, '') as string);
        });
      }
      return path;
    },
    [placeholders],
  );

  const doAction = (payload: OnClickAction) => {
    switch (payload.id) {
      case 'academy-page':
        navigate('/profile/academy-record');
        break;
      case 'declarations-page':
        navigate('/profile/declarations');
        break;
      case 'security-page':
        navigate('/profile/security');
        break;
      case 'organisation-profile-page':
        doAction({
          id: 'open-internal-link',
          params: {
            path: '/organisation/:organisation_id/organisation-profile',
          },
        });
        break;
      case 'open-external-link':
        window.open(replacePathPlaceholders(payload.params.url), '_blank');
        break;
      case 'open-internal-link':
        navigate(replacePathPlaceholders(payload.params.path));
        break;
    }
  };

  return { doAction };
};

export { useOnClickActions };
