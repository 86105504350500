/**
 *
 * OrganisationSelectButton
 *
 */
import React from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';

interface Props extends ButtonProps {
  text: string;
}

export function OrganisationSelectButton(props: Props) {
  const { sx, ...rProps } = props;
  return (
    <Button
      variant="text"
      color="inherit"
      sx={{
        textDecoration: 'none',
        width: '100%',
        justifyContent: 'start',
        px: 0,
        ...sx,
      }}
      {...rProps}
    >
      <Typography
        style={{
          textTransform: 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.text}
      </Typography>
    </Button>
  );
}
