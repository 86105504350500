import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CancelAndSaveButtons } from '../CancelAndSaveButtons';
import moment from 'moment';

interface Props {
  size: 'small' | 'medium' | 'large';
  saving?: boolean;
  dirty?: boolean;
  onCancel?: () => void;
  lastSaved: string;
  valid?: boolean;
}

export function RiskSaveBar(props: Props) {
  const {
    size = 'small',
    lastSaved,
    saving = false,
    dirty = false,
    valid = true,
    onCancel,
  } = props;
  return (
    <Box>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography sx={{ color: '#71718B' }}>
          Last saved on the {moment(lastSaved).format('D MMM Y [at] HH:mm')}
        </Typography>

        <CancelAndSaveButtons
          onCancel={onCancel}
          size={size}
          saveText="Save changes"
          canCancel={!saving && dirty}
          disabled={saving || !valid || !dirty}
        />
      </Stack>
    </Box>
  );
}
