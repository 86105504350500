import { takeLatest } from 'redux-saga/effects';
import { meetingFormWidgetActions as actions } from '.';
import { createRequestSaga } from '../../../../utils/saga';

const init = createRequestSaga({
  actionType: actions.loadFormRequest.type,
  url: p => `committees`,
  data: p => ({ organisation_id: p.organisation_id }),
  successAction: actions.loadFormSuccess,
  failureAction: actions.loadFormFailed,
});

const loadMeeting = createRequestSaga({
  actionType: actions.loadMeetingRequest.type,
  url: p => `meetings/${p.id}`,
  successAction: actions.loadMeetingSuccess,
  failureAction: actions.loadMeetingFailed,
});

const saveMeeting = createRequestSaga({
  actionType: actions.saveMeetingRequest.type,
  url: p => `meetings/${p.id || ''}`,
  method: p => (p.id ? 'put' : 'post'),
  data: p => p,
  successAction: actions.saveMeetingSuccess,
  failureAction: actions.saveMeetingFailed,
});

export function* meetingFormWidgetSaga() {
  yield takeLatest(actions.loadFormRequest.type, init);
  yield takeLatest(actions.loadMeetingRequest.type, loadMeeting);
  yield takeLatest(actions.saveMeetingRequest.type, saveMeeting);
}
