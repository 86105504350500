import {
  Box,
  Button,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import key from 'weak-key';
import * as React from 'react';
import { IconItem } from './Board/IconItem';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { ReactNode } from 'react';

interface Props {
  title?: ReactNode;
  message?: ReactNode;
}

export function Activating(props: Props) {
  const { title, message = 'Redirecting to your organisation' } = props;
  return (
    <Stack spacing={6} minHeight={'60vh'}>
      {!!title && <Typography variant={'h1'}>{title}</Typography>}
      <Box>
        <Stack spacing={4}>
          <LoadingIndicator message={message} />
        </Stack>
      </Box>
    </Stack>
  );
}
