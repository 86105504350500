/**
 *
 * ProtectedRoute
 *
 */
import * as React from 'react';
import { ComponentProps } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from 'utils/useQuery';

interface Props extends ComponentProps<any> {
  isAccessible: boolean;
  redirectToPath?: string;
}

export function ProtectedRoute(props: Props) {
  const { query, setQuery } = useQuery();
  const { isAccessible, redirectToPath = '/' } = props;

  if (!isAccessible) {
    console.log(
      `Protected Route inaccessible: going to ${redirectToPath}`,
      query,
    );
  }

  return isAccessible ? (
    props.children
  ) : (
    <Navigate to={query.redirect || redirectToPath || '/'} />
  );
}
