import { cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { documentFormWidgetActions as actions } from '.';
import { selectDocumentFormWidgetSettings } from './selectors';
import {
  createRequestSaga,
  createUploadRequestSaga,
} from '../../../../utils/saga';

function* init(action) {
  try {
    const settings = yield select(selectDocumentFormWidgetSettings);

    yield put(actions.openSuccess({}));
  } catch (e) {
    yield put(actions.openFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

const loadDocument = createRequestSaga({
  actionType: actions.loadDocumentRequest.type,
  url: p => `documents/${p.id}`,
  successAction: actions.loadDocumentSuccess,
  failureAction: actions.loadDocumentFailed,
});

const saveDocument = createUploadRequestSaga({
  actionType: actions.saveDocumentRequest.type,
  getURL: payload => (payload.id ? `documents/${payload.id}` : 'documents'),
  getMethod: payload => (payload.id ? 'put' : 'post'),
  progressAction: actions.saveDocumentProgress,
  successAction: actions.saveDocumentSuccess,
  failureAction: actions.saveDocumentFailed,
});

const deleteDocument = createRequestSaga({
  actionType: actions.deleteDocumentRequest.type,
  url: p => `documents/${p.id}`,
  method: 'delete',
  successAction: actions.deleteDocumentSuccess,
  failureAction: actions.deleteDocumentFailed,
});

export function* documentFormWidgetSaga() {
  yield takeLatest(actions.openRequest.type, init);
  yield takeLatest(actions.loadDocumentRequest.type, loadDocument);
  yield takeLatest(actions.saveDocumentRequest.type, saveDocument);
  yield takeLatest(actions.deleteDocumentRequest.type, deleteDocument);
}
