import { useEffect } from 'react';
import { useHasChanged } from './usePrevious';
import { isEqual } from 'lodash';

export function useEffectOnChange<T = any>(
  callback: (prop: T) => void,
  prop: T,
  toValue?: any,
) {
  const propHasChanged = useHasChanged(prop);

  useEffect(() => {
    if (propHasChanged) {
      // if toValue is a function that returns a boolean then use that with prop as the argument
      // otherwise use the toValue as the comparison with isEqual(prop, toValue)

      if (typeof toValue === 'function') {
        if (!toValue(prop)) return;
      }

      if (toValue !== undefined && !isEqual(prop, toValue)) return;
      callback(prop);
    }
  });
}
