import { useEffect } from 'react';

const HubSpotScript: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_HUBSPOT_ID === undefined) {
      return;
    }
    // Create script element
    const script = document.createElement('script');

    // Set script source to HubSpot ID from environment variable
    script.src = `//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_ID}.js`;
    script.async = true;
    script.defer = true;

    // Append script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component does not render anything
};

export default HubSpotScript;
