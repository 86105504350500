import {
  Box,
  BoxProps,
  Button,
  ButtonBase,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { ActionStatus, IAction, IActionSubtaskWithStatus } from 'types/types';
import {
  RiAlarmFill,
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiCalendarLine,
  RiCheckboxBlankLine,
  RiCheckboxFill,
  RiCheckFill,
  RiErrorWarningFill,
  RiFireFill,
} from 'react-icons/ri';
import { CompletionIndicator } from '../CompletionIndicator';
import { IconSquare } from '../IconSquare';
import { OnClickAction } from 'utils/useOnlickActions';
import key from 'weak-key';
import { Link, useLocation } from 'react-router-dom';
import { DashboardActionSubtasks } from '../DashboardActionSubtasks';
import moment from 'moment';

interface Props extends Pick<BoxProps, 'sx'> {
  action: IAction;
  onCheck: (subtask_id?: number) => void;
  open: boolean;
  toggleOpen: () => void;
  urlPrefix: string;
  completing?: boolean;
  onclickAction?: (payload: OnClickAction) => void;
}

export function DashboardActionItem(props: Props) {
  const {
    action,
    onclickAction,
    toggleOpen,
    onCheck,
    open,
    urlPrefix,
    sx = {},
    completing,
  } = props;
  const theme = useTheme();
  const isSameDay = date =>
    moment().format('YMMDD') === moment(date).format('YMMDD');
  const isOverdue = date => moment(date).isBefore(moment()) && !isSameDay(date);
  const isWithinWeek = date =>
    isSameDay(date) ||
    (moment(date).isAfter(moment()) &&
      moment(date).isSameOrBefore(moment().add(7, 'days')));

  const getDueIcon = date => {
    if (isOverdue(date)) {
      return <RiErrorWarningFill size={20} color={theme.palette.error.main} />;
    } else if (isWithinWeek(date)) {
      return <RiAlarmFill size={20} color={theme.palette.warning.main} />;
    } else {
      return <RiCalendarLine size={20} color={theme.palette.primary.main} />;
    }
  };

  const subtasksWithStatuses: IActionSubtaskWithStatus[] =
    action.action_definition.subtasks.map(item => {
      // use subtask_statuses to assign status if set
      const status: ActionStatus =
        (action.subtask_statuses || {})[`subtask-${item.id}`] || 'pending';
      return { ...item, status };
    });

  const actionCompletion = useMemo(() => {
    if (completing) return 100;
    return Math.round(
      subtasksWithStatuses.length
        ? (subtasksWithStatuses.filter(item => item.status === 'completed')
            .length /
            subtasksWithStatuses.length) *
            100
        : 0,
    );
  }, [completing, subtasksWithStatuses]);
  const truncateChar =
    action.action_definition.name.length > 50
      ? action.action_definition.name.substring(0, 50) + '...'
      : action.action_definition.name;

  useEffect(() => {
    if (actionCompletion === 100) {
      onCheck();
    }
  }, [actionCompletion]);

  return (
    <>
      <Box sx={{ maxWidth: 300, mx: 'auto', ...sx }}>
        <Stack spacing={3} alignItems={'stretch'}>
          <CompletionIndicator
            percentage={actionCompletion}
            thickness={6}
            circleColours={{
              progressColour: 'primary.main',
              remainderColour: 'primary.light',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: '10%',
                zIndex: 15,
              }}
            >
              <Stack
                direction={'row'}
                spacing={0.5}
                sx={{ pt: 1 }}
                width={'100%'}
              >
                {actionCompletion === 100 && (
                  <IconSquare
                    sx={{
                      background: theme.palette.success.main,
                      color: 'common.white',
                      borderColor: `${theme.palette.common.white} !important`,
                    }}
                    icon={<RiCheckFill size={25} color={'common.white'} />}
                  />
                )}
                {/* Hidden until further notice... */}
                {/* {action.action_definition.priority > 5 &&
                  actionCompletion !== 100 && (
                    <IconSquare
                      sx={{
                        background: theme.palette.error.main,
                        color: 'common.white',
                        borderColor: `${theme.palette.common.white} !important`,
                      }}
                      icon={<RiFireFill size={20} />}
                    />
                )} */}

                {actionCompletion > 0 && actionCompletion !== 100 && (
                  <Stack
                    sx={{
                      backgroundColor: 'grey.500',
                      px: 1,
                      height: 45,
                      borderColor: 'common.white',
                      border: 2,
                      borderRadius: 1,
                      color: 'common.white',
                    }}
                    justifyContent={'center'}
                  >
                    <Typography variant="body2" fontWeight={700}>
                      {actionCompletion}% done
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
            <Stack
              sx={{ mx: 7, height: 240, position: 'relative', width: 200 }}
              alignItems={'center'}
              justifyContent={'center'}
              direction={'column'}
            >
              <ButtonBase
                sx={{
                  zIndex: 7,
                  width: 180,
                  textAlign: 'center',
                }}
                component={Link}
                to={`${urlPrefix}${action.id}`}
              >
                <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                  {truncateChar}
                </Typography>
              </ButtonBase>
              <Stack
                spacing={0.5}
                direction={'row'}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  textDecoration: 'none',
                  p: 1,
                  border: `1px solid ${theme.palette.grey[300]}`,
                  background: theme => theme.palette.common.white,
                  textAlign: 'center',
                  zIndex: 8,
                }}
              >
                {!!action.due_at ? (
                  <>
                    {getDueIcon(action.due_at)}
                    <Typography variant="body2" fontWeight={'bold'}>
                      {isOverdue(action.due_at) ? 'Overdue:' : 'Due by:'}
                    </Typography>
                    <Typography variant="body2">
                      {moment(action.due_at).format('D MMM YYYY')}
                    </Typography>
                  </>
                  ) : (
                  <>
                    <Typography variant="body2">
                      No date set
                    </Typography>
                  </>
                )}
              </Stack> 
            </Stack>
          </CompletionIndicator>
          {!!action.action_definition.subtasks.length ? (
            <Button
              sx={{ borderColor: theme.palette.grey.A200 }}
              startIcon={open ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
              onClick={toggleOpen}
              variant="outlined"
              fullWidth
            >
              {open
                ? `Roll actions up`
                : `See the ${action.action_definition.subtasks.length} actions`}
            </Button>
          ) : (
            <Button
              sx={{ borderColor: theme.palette.grey.A200 }}
              startIcon={
                completing ? (
                  <RiCheckboxFill />
                ) : (
                  <RiCheckboxBlankLine color={theme.palette.grey['500']} />
                )
              }
              variant="outlined"
              fullWidth
              onClick={() => onCheck()}
            >
              Completed
            </Button>
          )}

          {!!action.action_definition.subtasks.length && open && (
            <>
              <DashboardActionSubtasks
                onCheck={onCheck}
                subtasks={subtasksWithStatuses}
                onclickAction={onclickAction}
              />
            </>
          )}
        </Stack>
      </Box>
    </>
  );
}
