import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { DateFieldWrapper } from 'app/components/DateFieldWrapper';
import { RadioButton } from 'app/components/RadioButton';
import { Field, useFormikContext } from 'formik';
import { Autocomplete, TextField } from 'formik-mui';
import React from 'react';
import { RiQuestionFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { ActionRegisterCommittee } from '../slice/types';
import { ActionRegisterFormType } from './index';

interface Props {
  onClose: () => void;
  committees: ActionRegisterCommittee[];
  next: () => void;
}

export function AddAction(props: Props) {
  const { onClose, next, committees } = props;
  const { values, isValid, setFieldValue, errors, touched } =
    useFormikContext<ActionRegisterFormType>();

  return (
    <>
      <Typography variant="h2">Add an action</Typography>
      <Typography variant="body2">
        Provide a clear and concise description of the specific action to be
        undertaken. Include details such as objectives, key tasks, a deadline
        and responsible assignees to ensure clarity and accountability.
      </Typography>
      <Box>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="h5">Action</Typography>
            <Typography variant="body2">
              This description should explain how the register will be used to
              track, manage, and report on actions across the organisation or
              committee.
            </Typography>
          </Stack>

          <Field
            name="description"
            autocomplete={'off'}
            component={TextField}
            label="Enter an action"
          />
        </Stack>
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box>
              <Stack>
                <Typography variant="h5">Committee</Typography>
                <Field
                  component={Autocomplete}
                  name="committee_id"
                  fullWidth
                  getOptionLabel={option =>
                    committees.find(c => c.id === option)?.name || option
                  }
                  renderInput={params => (
                    <MuiTextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      label="Select the committee"
                      error={touched.committee_id && !!errors.committee_id}
                    />
                  )}
                  options={committees.map(c => c.id)}
                  onChange={(e, value) => {
                    setFieldValue('member_ids', []);
                    setFieldValue('committee_id', value);
                  }}
                />{' '}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Stack>
                <Typography variant="h5">Due date</Typography>
                <DateFieldWrapper name="due_at" format={'DD/MM/YYYY'} />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {!values.id ? (
        <Stack>
          <Typography variant="h5">Status</Typography>
          <Typography variant="body2">
            The action register status provides an overview of how many actions
            are 'Open' or 'Closed', helping members quickly check the ongoing
            initiatives and tasks.
          </Typography>

          <RadioButton
            onChange={() => setFieldValue('status', 'open')}
            checked={values.status === 'open'}
            label={
              <Stack spacing={2} justifyContent={'start'} textAlign={'start'}>
                <Typography color={'primary.main'} variant="h5">
                  Open
                </Typography>
                <Typography variant="body2" color={'common.black'}>
                  The action status is initially set to 'Open'. Once the action
                  is completed or no longer required, you can update the status
                  to 'Closed'.
                </Typography>
              </Stack>
            }
          />
        </Stack>
      ) : (
        <Box>
          <Stack>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <RadioButton
                    onChange={() => setFieldValue('status', 'open')}
                    checked={values.status === 'open'}
                    label={
                      <Stack
                        spacing={2}
                        justifyContent={'start'}
                        textAlign={'start'}
                      >
                        <Typography color={'primary.main'} variant="h5">
                          Open
                        </Typography>
                        <Typography variant="body2" color={'common.black'}>
                          Choose this option if this action is still in progress
                        </Typography>
                      </Stack>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RadioButton
                    onChange={() => setFieldValue('status', 'closed')}
                    checked={values.status === 'closed'}
                    label={
                      <Stack
                        spacing={2}
                        justifyContent={'start'}
                        textAlign={'start'}
                      >
                        <Typography color={'primary.main'} variant="h5">
                          Closed
                        </Typography>
                        <Typography variant="body2" color={'common.black'}>
                          Choose this option if this action has been completed
                          or dismissed
                        </Typography>
                      </Stack>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            {/*<Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <RadioButton
                    onChange={() => alert('check')}
                    checked={false}
                    label={
                      <Stack
                        spacing={4}
                        justifyContent={'start'}
                        textAlign={'start'}
                      >
                        <Typography color={'primary.main'} variant="h5">
                          Done
                        </Typography>
                        <Typography variant="body2" color={'common.black'}>
                          Lorem ipsum dolor sit amet consectetur
                        </Typography>
                      </Stack>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RadioButton
                    onChange={() => alert('check')}
                    checked={false}
                    label={
                      <Stack
                        spacing={4}
                        justifyContent={'start'}
                        textAlign={'start'}
                      >
                        <Typography color={'primary.main'} variant="h5">
                          Paused
                        </Typography>
                        <Typography variant="body2" color={'common.black'}>
                          Lorem ipsum dolor sit amet consectetur
                        </Typography>
                      </Stack>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            */}
          </Stack>
        </Box>
      )}
      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth onClick={next} disabled={!isValid}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
