import React from 'react';
import { GlobalStyles as BaseGlobalStyles } from '@mui/material';
import { useGlobalStyles, useResetStyles } from './theme';

export function GlobalStyles() {
  return (
    <BaseGlobalStyles
      styles={{
        ...useResetStyles,
        ...useGlobalStyles,
      }}
    />
  );
}
