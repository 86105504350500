/**
 *
 * ActionButton
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  getContrastRatio,
  useTheme,
  lighten,
} from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { RiArrowRightSLine } from 'react-icons/ri';

interface Props extends Omit<ButtonProps, 'color'> {
  color?: string;
  to?: string;
  arrowSize?: number;
  removeBorderRadius?: boolean;
}

export function ActionButton(props: Props) {
  const {
    color = 'primary.lightest',
    arrowSize = 25,
    to,
    removeBorderRadius,
    ...rProps
  } = props;
  const theme = useTheme();

  const c = useMemo(() => {
    return get(theme.palette, color, theme.palette.primary.main);
  }, [color, props.variant]);

  const contrast = theme.palette.getContrastText(c);

  return (
    <Button
      {...rProps}
      {...(to ? { to, component: Link } : {})}
      variant={'contained'}
      sx={theme => ({
        textDecoration: 'none',
        backgroundColor: color,
        borderRadius: removeBorderRadius && 0,
        color: contrast,
        p: 2,
        '&:hover': {
          backgroundColor: lighten(c, 0.1),
          textDecoration: 'none',
        },
      })}
    >
      <Box sx={{ width: '100%' }}>{props.children}</Box>
    </Button>
  );
}
