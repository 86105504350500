import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.organisationSelector || initialState;

export const selectOrganisationSelector = createSelector(
  [selectSlice],
  state => state,
);

export const selectCurrentOrganisations = createSelector(
  [selectSlice],
  state => state.currentOrganisations,
);

export const selectAvailableOrganisations = createSelector(
  [selectSlice],
  state => state.availableOrganisations,
);

export const selectCurrentOrganisationName = createSelector(
  [selectSlice],
  state =>
    state.currentOrganisations.length === 1
      ? (
          state.availableOrganisations.find(
            organisation => organisation.id === state.currentOrganisations[0],
          ) || {}
        ).name
      : null,
);

export const selectOpen = createSelector([selectSlice], state => state.open);
