/**
 *
 * SignupForm
 *
 */
import * as React from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { IInvitation } from 'types/types';

interface Props {
  invitation: IInvitation;
  onAccept: () => void;
  onDecline: () => void;
  saving?: boolean;
}

export function AcceptForm(props: Props) {
  const { onAccept, onDecline, saving = false, invitation } = props;
  return (
    <Box>
      <Paper
        sx={{
          p: 4,
          border: 1,
          borderColor: 'primary.main',
        }}
        elevation={0}
      >
        <Stack justifyContent={'space-between'} sx={{ minHeight: '10vh' }}>
          <Box>
            <Typography sx={{ textAlign: 'center' }}>
              Accept invitation to join the board of{' '}
              {invitation.organisation_name}?
            </Typography>
          </Box>
          <Box sx={{}}>
            <Stack
              direction="row"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Button
                variant={'outlined'}
                onClick={onDecline}
                disabled={saving}
              >
                Decline
              </Button>
              <Button onClick={onAccept} disabled={saving}>
                Accept
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
}
