import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { memberFormWidgetSaga } from './saga';
import { MemberFormWidgetState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { ICommittee, IInvitation, IMember, IRole } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: MemberFormWidgetState = {
  form: {
    open: false,
    loading: false,
    committees: [],
    roles: [],
  },
  loadMember: getApiCallInitialState<IMember>(),
  saveMember: getApiCallInitialState(),
};

const slice = createSlice({
  name: 'memberFormWidget',
  initialState,
  reducers: {
    openRequest(
      state,
      action: PayloadAction<{
        id?: number;
        organisation_id: number;
        committee_id?: number;
      }>,
    ) {
      state.form.id = action.payload.id;
      state.form.open = true;
      state.form.loading = true;
      state.form.organisation_id = action.payload.organisation_id;
    },
    openSuccess(
      state,
      action: PayloadAction<{ committees: ICommittee[]; roles: IRole[] }>,
    ) {
      state.form.committees = action.payload.committees;
      state.form.roles = action.payload.roles;
      state.form.loading = false;
    },
    openFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.form = { ...initialState.form, error: action.payload };
    },
    close(state, action: PayloadAction<void>) {
      state.form = initialState.form;
      state.loadMember = initialState.loadMember;
      state.saveMember = initialState.saveMember;
    },
    loadMemberRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadMember.loading = true;
      state.loadMember.error = undefined;
    },
    loadMemberSuccess(state, action: PayloadAction<{ data: IMember }>) {
      state.loadMember.loading = false;
      state.loadMember.error = undefined;
      state.loadMember.data = action.payload.data;
    },
    loadMemberFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadMember.loading = false;
      state.loadMember.error = action.payload;
    },
    saveMemberRequest(state, action: PayloadAction<Partial<IMember>>) {
      state.saveMember.loading = true;
      state.saveMember.error = undefined;
    },
    saveMemberSuccess(
      state,
      action: PayloadAction<{ data: IMember | IInvitation }>,
    ) {
      state.saveMember.loading = false;
      state.saveMember.error = undefined;
      state.saveMember.data = action.payload.data;
    },
    saveMemberFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.saveMember.loading = false;
      state.saveMember.error = action.payload;
    },
  },
});

export const { actions: memberFormWidgetActions } = slice;

export const useMemberFormWidgetSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: memberFormWidgetSaga });
  return { actions: slice.actions };
};
