/**
 *
 * AcademySeatAllocations
 *
 */
import React, { useEffect, useMemo, useState } from 'react';
import { IMember, IOrganisation } from 'types/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectAcademySeatAllocations } from './slice/selectors';
import { useAcademySeatAllocationsSlice } from './slice/hook';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useConfirm } from 'utils/useConfirm';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useGetCurrentFeature } from '../OrganisationPage/useGetCurrentFeature';
import { SectionHeader } from 'app/components/SectionHeader';
import { RiAddFill, RiCheckboxCircleFill } from 'react-icons/ri';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { noop } from 'lodash';
import moment from 'moment';
import { all } from 'axios';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import { SecureLinkify } from '../../components/SecureLinkify';
import { tr } from 'date-fns/locale';

interface Props {
  organisation: IOrganisation;
}

export function AcademySeatAllocations(props: Props) {
  const { organisation } = props;

  const { loadList, allocateSeat } = useSelector(selectAcademySeatAllocations);

  const { feature } = useGetCurrentFeature('academy');

  const { actions } = useAcademySeatAllocationsSlice();
  const dispatch = useDispatch();
  const { hasPermission } = useHasPermission();

  const confirm = useConfirm();
  const load = () => {
    dispatch(actions.loadListRequest({ organisation_id: organisation.id }));
  };
  const rows = useMemo(() => {
    return loadList.data.members
      .map(member => {
        // Check if the member is in the allocation list
        const allocation = loadList.data.allocations.find(
          alloc => alloc.member_id === member.id,
        );
        return {
          id: member.id,
          name: member.name,
          email: member.email,
          allocated: !!allocation,
          allocated_at: allocation?.allocated_at,
        };
      })
      .sort((a, b) => {
        if (a.allocated && b.allocated) {
          return (b.allocated_at || '').localeCompare(a.allocated_at || '');
        }
        if (a.allocated) return -1;
        if (b.allocated) return 1;
        return a.name.localeCompare(b.name);
      });
  }, [loadList.data]);

  useEffect(() => {
    load();
  }, [organisation.id]);

  useHandleApiResponse(allocateSeat, 'Seat allocated', {
    onSuccess: () => load(),
    errorMessage:
      'Unable to allocate academy seat. Please check your available licences',
  });

  const additionalLicencesClick = () => {
    confirm({
      title: 'Buy additional licences',
      description: (
        <Stack>
          <Typography>
            <SecureLinkify>
              Please contact us at support@governance360.com to request more
              academy licences.
            </SecureLinkify>
          </Typography>
        </Stack>
      ),
      hideCancelButton: true,
    })
      .then(noop)
      .catch(noop);
  };

  const onClick = (id: number) => {
    if (loadList.data.remaining) {
      confirm({
        title: 'Allocate licence?',
        description: 'Once allocated, a licence cannot be reallocated.',
      })
        .then(() => {
          dispatch(
            actions.allocateSeatRequest({
              organisation_id: organisation.id,
              member_id: id,
            }),
          );
        })
        .catch(noop);
    }
  };

  return (
    <>
      <Helmet title={'Academy Seats'}>
        <meta name="description" content="Allocate seats to your members" />
      </Helmet>
      <SectionHeader title={`Academy Seats`} />
      <Container>
        <Box sx={{ my: 4 }}>
          <Stack spacing={6}>
            <Typography variant="h3">Academy licences</Typography>
            <Box>
              <Typography>
                As part of your current subscription to Governance360, you have{' '}
                <Box component={'span'} sx={{ fontWeight: 600 }}>
                  {loadList.data.seats}
                </Box>{' '}
                Academy licences allocated as part of your plan. These can be
                allocated to members of your team here.
              </Typography>
              <Typography>
                Once activated, your Members will receive log-in instructions
                directly for the Modules available in your Plan. Once allocated,
                licences are non-assignable until the end of your current
                contracted subscription.
              </Typography>
            </Box>
            <Box>
              <Stack spacing={2}>
                <Typography variant="h3">Total licences</Typography>
                <Box sx={{ fontWeight: 'bold' }}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    {!!loadList.data.seats ? (
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={1}
                      >
                        <Box sx={{ color: 'success.main' }}>
                          <RiCheckboxCircleFill size={20} />
                        </Box>
                        <Typography>
                          {loadList.data.seats} Governance Academy licences
                          (Monthly recurring)
                        </Typography>
                        {!!loadList.data.allocations.length && (
                          <Typography color={'common.amber'}>
                            {loadList.data.allocations.length} in use
                          </Typography>
                        )}
                      </Stack>
                    ) : (
                      <Typography color={'error'}>
                        You have no licences
                      </Typography>
                    )}
                    <Box>
                      <Button
                        variant="outlined"
                        startIcon={<RiAddFill />}
                        onClick={additionalLicencesClick}
                      >
                        Buy additional licences
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>

            <Box>
              <Typography variant="h3">
                Assign licences to members of your organisation
              </Typography>
            </Box>
            <Box
              sx={{
                height: 80 + rows.length * 75,
                maxHeight: '50vh',
                width: '100%',
                rowHeight: 32,
                mb: 4,
              }}
            >
              <DataGridPro
                loading={loadList.loading || allocateSeat.loading}
                rows={rows}
                hideFooter
                columns={[
                  {
                    flex: 1,
                    field: 'name',
                    headerName: 'Name',
                    pinnable: false,
                    hideable: false,
                    minWidth: 200,
                    renderCell: params => (
                      <>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <Typography fontWeight={'bold'}>
                            {params.value}
                          </Typography>
                          {params.row.allocated && (
                            <Box sx={{ color: 'success.main' }}>
                              <RiCheckboxCircleFill size={20} />
                            </Box>
                          )}
                        </Stack>
                      </>
                    ),
                  },
                  {
                    flex: 1,
                    field: 'email',
                    headerName: 'Email',
                    pinnable: false,
                    hideable: false,
                    minWidth: 200,
                  },
                  {
                    field: 'allocated_at',
                    type: 'date',
                    headerName: 'Assigned',
                    pinnable: false,
                    hideable: false,
                    minWidth: 200,
                    valueGetter: ({ row }) => moment(row.allocated_at).toDate(),
                    renderCell: params => (
                      <>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <Typography>
                            {params.row.allocated
                              ? moment(params.value).format('DD/MM/Y')
                              : ''}
                          </Typography>
                        </Stack>
                      </>
                    ),
                  },
                  {
                    field: 'action',
                    headerName: 'Academy Access',
                    pinnable: false,
                    hideable: false,
                    minWidth: 220,
                    renderCell: params =>
                      params.row.allocated ? (
                        <Typography fontWeight={'bold'}>
                          Seat allocated
                        </Typography>
                      ) : (
                        <Button
                          disabled={!loadList.data.remaining}
                          variant="text"
                          onClick={() => onClick(params.row.id)}
                          sx={{ px: 0 }}
                        >
                          Allocate seat
                        </Button>
                      ),
                  },
                ]}
              />
            </Box>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
