/**
 *
 * Logo
 *
 */
import * as React from 'react';
import { ReactComponent as LogoFull } from './logo-full.svg';
import { ReactComponent as LogoCircle } from './logo-circle.svg';
import { ReactComponent as LogoCircleBlue } from './logo-circle-blue.svg';
import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<any> {
  variant?: 'full' | 'circle' | 'circle-blue';
  title?: string;
}

export function Logo(props: Props) {
  const { variant = 'full', title = 'Governance360 Logo', ...rest } = props;

  if (variant === 'circle') {
    return <LogoCircle title={title} {...rest} />;
  } else if (variant === 'circle-blue') {
    return <LogoCircleBlue title={title} {...rest} />;
  } else {
    return <LogoFull title={title} {...rest} />;
  }
}
