/**
 *
 * Checklists
 *
 */
import * as React from 'react';
import { ChecklistSubmission } from 'types/types';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { formatParsedISO } from 'utils/formatParsedISO';
import { RiCloseLine } from 'react-icons/ri';

interface Props {
  dialogContent: Array<Partial<ChecklistSubmission>> | null;
  onClose: () => void;
}

export function SubmissionsDialog(props: Props) {
  const { dialogContent, onClose } = props;

  return (
    <Dialog maxWidth={'sm'} fullWidth open={!!dialogContent} onClose={onClose}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box> 
            Previous submissions
          </Box>
          <IconButton onClick={onClose} edge={'end'} size={'large'}>
            <RiCloseLine />
          </IconButton>
        </Stack>
      </DialogTitle>
      
      <DialogContent>
        {!!dialogContent && (
          <Box
            sx={{
              height: 300,
              maxHeight: 300,
              width: '100%',
              mb: 4,
            }}
          >
            <DataGridPro
              columns={[
                {
                  headerName: 'ID',
                  field: 'id',
                  flex: 0.2,
                  renderCell: param => `#${param.value}`,
                },
                {
                  flex: 0.8,
                  headerName: 'Submitted',
                  field: 'submitted_at',
                  renderCell: param => (
                    <Link to={`${param.row.checklist_id}/${param.row.id}`}>
                      {formatParsedISO(param.row.submitted_at, 'd MMMM Y H:ii')}
                    </Link>
                  ),
                },
              ]}
              disableColumnSelector
              disableRowSelectionOnClick
              hideFooter
              pagination={false}
              rows={dialogContent || []}
              pinnedColumns={{
                left: [],
                right: ['actions'],
              }}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
