/**
 *
 * PartnerDetails
 *
 */

import React from 'react';
import { IOrganisation } from 'types/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartnerDetails } from './slice/selectors';
import { usePartnerDetailsSlice } from './slice/hook';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { PartnerDetailsBlock } from './PartnerDetailsBlock';
import { useEffectOnce } from 'usehooks-ts';

interface Props {
  organisation: IOrganisation;
}

export function PartnerDetails(props: Props) {
  const { organisation } = props;

  const { actions } = usePartnerDetailsSlice();
  const dispatch = useDispatch();
  const { loadPartner } = useSelector(selectPartnerDetails);
  const partner = loadPartner.data;

  useEffectOnce(() => {
    dispatch(actions.loadPartnerRequest({ organisation_id: organisation.id }));
  });

  if (!partner) return <LoadingIndicator />;
  return <PartnerDetailsBlock organisation={organisation} partner={partner} />;
}
