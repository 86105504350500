import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { RiskLevelSelector } from '../../RiskLevelSelector';
import { HelpModal } from 'app/components/HelpModal';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

export function RiskImpact() {
  return (
    <>
      <Box>
        <Stack spacing={2}>
          <Typography variant={'h1'}>Impact</Typography>
          <Typography variant="body2">
            Impact (sometimes called Risk Severity) is the expected harm or
            adverse effect that may occur due to exposure to the Risk. In other
            words, it measures how bad things could get if a particular Risk
            occurs.
          </Typography>
          <Box>
            <Field
              component={TextField}
              multiline
              label="Enter text here"
              name="impact_text"
              rows={6}
              fullWidth
            />
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack spacing={2}>
          <Box>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography variant="h3">Impact before controls</Typography>
              <Box component={'span'}>
                <HelpModal
                  iconTitle="Find more about impact before controls"
                  name="score_impact"
                />
              </Box>
            </Stack>
          </Box>

          <Typography variant="body2">
            Impact (sometimes called Risk Severity) is the expected harm or
            adverse effect that may occur due to exposure to the Risk. In other
            words, it measures how bad things could get if a particular Risk
            occurs.
          </Typography>
          <Typography variant="body2">
            Impact before controls, sometimes known as raw risk, is the impact
            of a Risk on your Organisation before you have put any controls in
            place.
          </Typography>
          <RiskLevelSelector name="score_impact" fullWidth={true} />
        </Stack>
      </Box>
    </>
  );
}
