import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { committeeMemberFormWidgetSaga } from './saga';
import { CommitteeMemberFormWidgetState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { ICommittee, ICommitteeMember, IMember, IRole } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: CommitteeMemberFormWidgetState = {
  form: {
    open: false,
    loading: false,
    members: [],
    roles: [],
  },
  loadCommitteeMember: getApiCallInitialState<ICommitteeMember>(),
  saveCommitteeMember: getApiCallInitialState(),
  deleteCommitteeMember: getApiCallInitialState(),
};

const slice = createSlice({
  name: 'committeeMemberFormWidget',
  initialState,
  reducers: {
    openRequest(
      state,
      action: PayloadAction<{
        id?: number;
        organisation_id: number;
        committee_id: number;
      }>,
    ) {
      state.form.id = action.payload.id;
      state.form.open = true;
      state.form.loading = true;
      state.form.committee_id = action.payload.committee_id;
      state.form.organisation_id = action.payload.organisation_id;
    },
    openSuccess(
      state,
      action: PayloadAction<{
        members: IMember[];
        roles: IRole[];
        committee: ICommittee;
      }>,
    ) {
      state.form.committee = action.payload.committee;
      state.form.members = action.payload.members;
      state.form.roles = action.payload.roles;
      state.form.loading = false;
    },
    openFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.form = { ...initialState.form, error: action.payload };
    },
    close(state, action: PayloadAction<void>) {
      state.form = initialState.form;
      state.loadCommitteeMember = initialState.loadCommitteeMember;
      state.saveCommitteeMember = initialState.saveCommitteeMember;
    },
    loadCommitteeMemberRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.loadCommitteeMember.loading = true;
      state.loadCommitteeMember.error = undefined;
    },
    loadCommitteeMemberSuccess(
      state,
      action: PayloadAction<{ data: ICommitteeMember }>,
    ) {
      state.loadCommitteeMember.loading = false;
      state.loadCommitteeMember.error = undefined;
      state.loadCommitteeMember.data = action.payload.data;
    },
    loadCommitteeMemberFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadCommitteeMember.loading = false;
      state.loadCommitteeMember.error = action.payload;
    },
    saveCommitteeMemberRequest(
      state,
      action: PayloadAction<Partial<ICommitteeMember>>,
    ) {
      state.saveCommitteeMember.loading = true;
      state.saveCommitteeMember.error = undefined;
    },
    saveCommitteeMemberSuccess(
      state,
      action: PayloadAction<{ data: ICommitteeMember }>,
    ) {
      state.saveCommitteeMember.loading = false;
      state.saveCommitteeMember.error = undefined;
      state.saveCommitteeMember.data = action.payload.data;
    },
    saveCommitteeMemberFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.saveCommitteeMember.loading = false;
      state.saveCommitteeMember.error = action.payload;
    },
    deleteCommitteeMemberRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.deleteCommitteeMember.loading = true;
      state.deleteCommitteeMember.error = undefined;
    },
    deleteCommitteeMemberSuccess(state, action: PayloadAction<any>) {
      state.deleteCommitteeMember.loading = false;
      state.deleteCommitteeMember.error = undefined;
      state.deleteCommitteeMember.data = action.payload.data;
    },
    deleteCommitteeMemberFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.deleteCommitteeMember.loading = false;
      state.deleteCommitteeMember.error = action.payload;
    },
  },
});

export const { actions: committeeMemberFormWidgetActions } = slice;

export const useCommitteeMemberFormWidgetSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: committeeMemberFormWidgetSaga });
  return { actions: slice.actions };
};
