import { Box, Typography } from '@mui/material';
import React from 'react';

export function BoardCalendar() {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h2">Board Calendar</Typography>
    </Box>
  );
}
