/**
 *
 * ConfirmLoginForm
 *
 */

import React, { ReactNode, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { PasswordField } from 'app/components/PasswordField';

const schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('This field is required'),
  password: Yup.string().required('This field is required'),
});

interface Props {
  loginRequest: (values) => void;
  mfaEnabled: boolean;
  loading: boolean;
  action?: string;
  cancelPath: string;
}

export function ConfirmLoginForm(props: Props) {
  const { loginRequest, loading, mfaEnabled, action, cancelPath } = props;
  const { title, body } = useMemo(() => {
    switch (action) {
      case 'manage-mfa':
        return {
          title: 'Change or remove your 2-factor authentication',
          body: (
            <>
              To change or remove 2-factor authentication, please re-confirm
              your username and password.
            </>
          ),
        };
      case 'enable-mfa':
        return {
          title: 'Enable 2-factor authentication',
          body: (
            <>
              To enable 2-factor authentication, please re-confirm your username
              and password. We will then take you through the steps to enable
              2-factor authentication (2FA).
            </>
          ),
        };
      case 'get-codes':
        return {
          title: 'Confirm your details',
          body: (
            <>
              To retrieve your backup codes, please re-confirm your username and
              password.
            </>
          ),
        };
    }
    return {
      title: 'Confirm your details',
      body: (
        <>
          To make changes to your settings, please re-confirm your username and
          password.
        </>
      ),
    };
  }, [mfaEnabled]);

  return (
    <Stack spacing={3} textAlign={'center'}>
      <Typography variant={'h3'} color={'primary.main'}>
        {title}
      </Typography>
      <Typography variant={'body1'} textAlign={'center'}>
        {body}
      </Typography>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={schema}
        validateOnChange
        onSubmit={values => {
          loginRequest(values);
        }}
      >
        {({ submitForm, isSubmitting, setSubmitting }) => {
          if (isSubmitting !== loading) {
            setSubmitting(loading);
          }

          return (
            <Form>
              <Box>
                <Box sx={{ mb: 2 }}>
                  <Field
                    component={TextField}
                    id="email"
                    name="email"
                    type="email"
                    label="Email / Username"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Field
                    component={PasswordField}
                    id="password"
                    name="password"
                    label="Password"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <Box>
                  <Stack>
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || loading}
                        fullWidth
                      >
                        Login
                      </Button>
                    </Box>
                    <Box sx={{ mt: 6, textAlign: 'center', width: '100%' }}>
                      <Button
                        component={Link}
                        variant="text"
                        size="small"
                        className="align-right"
                        to={cancelPath}
                      >
                        Back to the application
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
}
