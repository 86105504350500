/**
 *
 * Committees
 *
 */
import * as React from 'react';
import { memo, useCallback, useEffect } from 'react';
import { IOrganisation } from 'types/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoadCommittees } from './slice/selectors';
import { useCommitteesSlice } from './slice/hook';
import { useHasChanged } from 'utils/usePrevious';
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { ActionButton } from 'app/components/ActionButton';
import { getInitials } from 'utils/initials';
import key from 'weak-key';
import { RiAddCircleLine, RiArrowRightSLine } from 'react-icons/ri';
import { useEffectOnce } from 'utils/useEffectOnce';
import {
  CommitteeFormWidget,
  useCommitteeFormWidget,
} from 'app/widgets/CommitteeFormWidget';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { SectionHeader } from 'app/components/SectionHeader';

interface Props {
  organisation: IOrganisation;
}

export const List = memo(
  (props: Props) => {
    const { organisation } = props;
    const loadCommittees = useSelector(selectLoadCommittees);
    const { actions } = useCommitteesSlice();
    const dispatch = useDispatch();

    const { open, close, isOpen } = useCommitteeFormWidget();

    const { hasPermission } = useHasPermission();

    const loadCommitteesLoadingChanged = useHasChanged(loadCommittees.loading);
    const organisationChanged = useHasChanged(organisation);

    const load = useCallback(() => {
      dispatch(
        actions.loadCommitteesRequest({
          organisation_id: organisation.id,
        }),
      );
    }, [organisation.id, dispatch]);

    useEffect(() => {
      if (loadCommitteesLoadingChanged && !loadCommittees.loading) {
        if (loadCommittees.error) {
          // todo - redirect away from page (and log?)
          if (loadCommittees.error.code === 401) return;
        }
      }
      if (organisationChanged && organisation.id) {
        load();
      }
    });

    useEffectOnce(() => {
      load();
    });

    const canManageCommittees = hasPermission(
      'manage-committees',
      'organisation',
      organisation.id,
    );

    if (!loadCommittees.data.length && loadCommittees.loading) {
      return <LoadingIndicator />;
    }

    return (
      <Box sx={{ mt: 4 }}>
        <Stack spacing={3}>
          <Box>
            <Stack spacing={1}>
              <Box>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  {!!loadCommittees.data.length ? (
                    <Box>
                      <Typography>
                        {canManageCommittees
                          ? 'View an existing or create a new committee.'
                          : 'View a committee that you are part of.'}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography>
                      {canManageCommittees
                        ? "You haven't created any committees."
                        : "You haven't been assigned to a committee yet. If you think this is a mistake, please contact your board administrator."}
                    </Typography>
                  )}
                  {hasPermission(
                    'manage-committees',
                    'organisation',
                    organisation.id,
                  ) && (
                    <>
                      <Button
                        variant={'outlined'}
                        startIcon={<RiAddCircleLine />}
                        onClick={() =>
                          open({ organisation_id: organisation.id })
                        }
                      >
                        Create a committee
                      </Button>
                      <CommitteeFormWidget
                        onChange={() => {
                          load();
                          // load user data again to get committee perms
                          dispatch(authProviderActions.loadUserDataRequest());
                        }}
                      />
                    </>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Box>

          <Stack spacing={1}>
            {loadCommittees.data.map(c => (
              <ActionButton
                variant={'contained'}
                fullWidth
                to={`${c.id}`}
                key={key(c)}
              >
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  sx={{ width: '100%', textAlign: 'left' }}
                  spacing={3}
                >
                  <Box sx={{ width: 40 }}>
                    <Avatar
                      variant="rounded"
                      sx={{
                        backgroundColor: 'white',
                        color: 'primary.main',
                      }}
                      alt={c.name}
                    >
                      {getInitials(c.name)}
                    </Avatar>
                  </Box>
                  <Box>
                    <Box>
                      <Typography variant="h3">{c.name}</Typography>
                      <Typography variant="body1" sx={{ opacity: 0.9 }}>
                        {c.member_count}{' '}
                        {`member${c.member_count !== 1 ? 's' : ''}`}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              </ActionButton>
            ))}
          </Stack>
        </Stack>
      </Box>
    );
  },
  (p, n) => p.organisation.id === n.organisation.id,
);
