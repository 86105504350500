import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { memberFormWidgetActions as actions } from '.';
import { selectMemberFormWidgetSettings } from './selectors';

function* init(action) {
  try {
    const settings = yield select(selectMemberFormWidgetSettings);

    const roles = yield call(apiRequest, {
      url: `roles`,
      method: 'get',
      data: {
        context: 'organisation',
      },
    });

    const committees = yield call(apiRequest, {
      url: `committees`,
      method: 'get',
      data: {
        organisation_id: settings.organisation_id,
      },
    });

    yield put(
      actions.openSuccess({ committees: committees.data, roles: roles.data }),
    );
  } catch (e) {
    yield put(actions.openFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadMember(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `members/${action.payload.id}`,
      method: 'get',
      data: {},
    });

    yield put(actions.loadMemberSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.loadMemberFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* saveMember(action) {
  try {
    const payload = yield call(apiRequest, {
      url: action.payload.id
        ? `members/${action.payload.id}`
        : 'invitations/create',
      method: action.payload.id ? 'put' : 'post',
      data: action.payload,
    });

    yield put(actions.saveMemberSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.saveMemberFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* memberFormWidgetSaga() {
  yield takeLatest(actions.openRequest.type, init);
  yield takeLatest(actions.loadMemberRequest.type, loadMember);
  yield takeLatest(actions.saveMemberRequest.type, saveMember);
}
