import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useFormikContext, useField } from 'formik';
import { Filename } from 'app/components/Filename';

type FileData = {
  action?: 'remove' | 'replace';
  data?: string;
  name?: string;
  size?: number;
  mime?: string;
};

interface FileUploadProps {
  name: string;
  label?: string;
  type?: 'File' | 'Image';
  allowedFileTypes?: string[];
  maxFileSize?: number;
  allowRemove?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  name,
  label = 'Upload file',
  type = 'File',
  allowedFileTypes = [
    'pdf',
    'doc',
    'docx',
    'txt',
    'rtf',
    'odt',
    'xls',
    'xlsx',
    'ods',
    'ppt',
    'pptx',
    'odp',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'svg',
    'mp4',
    'mov',
    'avi',
    'mp3',
    'wav',
    'flac',
    'zip',
    'rar',
    '7z',
    'tar',
    'gz',
    'tgz',
    'bz2',
    'xz',
  ],
  maxFileSize = 5000000, // Default 5MB
  allowRemove = true,
}) => {
  const { setFieldValue } = useFormikContext<any>();
  const [field] = useField<FileData>(name);

  const getPreview = (): string | null => {
    if (!field.value) {
      return null;
    }
    if (typeof field.value === 'string') {
      return field.value;
    }
    return field.value.data!;
  };

  const [preview, setPreview] = useState<string | null>(getPreview);

  useEffect(() => {
    if (field.value && typeof field.value === 'string' && !preview) {
      setPreview(field.value);
    }
  }, [field.value, preview]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files
      ? event.currentTarget.files[0]
      : undefined;

    if (file && file.size <= maxFileSize) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const encodedData = reader.result as string;
        setPreview(encodedData);
        await setFieldValue(name, {
          action: 'replace',
          data: encodedData.split(',')[1], // Only take the base64 data after the comma
          name: file.name,
          size: file.size,
          mime: file.type,
        });
      };
      reader.readAsDataURL(file);
    } else {
      alert('File size too large or invalid file type');
    }
  };

  const handleRemove = () => {
    setPreview(null);
    setFieldValue(
      name,
      allowRemove
        ? {
            action: 'remove',
          }
        : null,
    );
  };

  const extractFilenameFromURL = (url: string): string => {
    return url.split('/').pop() || '';
  };

  const filename =
    typeof field.value === 'string'
      ? extractFilenameFromURL(field.value)
      : field.value?.name || '';

  return (
    <Box>
      {preview ? (
        type === 'Image' ? (
          <Box>
            <img
              src={preview}
              alt="preview"
              style={{ display: 'inline-block', maxHeight: 120 }}
            />
            <IconButton onClick={handleRemove}>
              <Delete />
            </IconButton>
          </Box>
        ) : (
          <Stack direction={'row'} alignItems={'center'}>
            <Filename
              name={filename}
              extension={filename.split('.').pop() || ''}
            />
            <IconButton onClick={handleRemove}>
              <Delete />
            </IconButton>
          </Stack>
        )
      ) : (
        <Button
          variant="outlined"
          component="label"
          fullWidth
          sx={{ textAlign: 'center' }}
        >
          {label}
          <input
            type="file"
            accept={allowedFileTypes.join(',')}
            style={{ display: 'none' }}
            onChange={handleChange}
          />
        </Button>
      )}
    </Box>
  );
};

export default FileUpload;
