import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { conflictFormWidgetActions as actions } from '.';
import { selectConflictFormWidgetSettings } from './selectors';

function* init(action) {
  try {
    const settings = yield select(selectConflictFormWidgetSettings);
    const organisations = yield call(apiRequest, {
      url: `organisations`,
      method: 'get',
      data: {
        is_member: true,
      },
    });

    yield put(actions.openSuccess({ organisations: organisations.data }));
  } catch (e) {
    yield put(actions.openFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadConflict(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `conflicts/${action.payload.id}`,
      method: 'get',
      data: {},
    });

    yield put(actions.loadConflictSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.loadConflictFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* saveConflict(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `conflicts/${action.payload.id || ''}`,
      method: action.payload.id ? 'put' : 'post',
      data: action.payload,
    });

    yield put(actions.saveConflictSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.saveConflictFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* conflictFormWidgetSaga() {
  yield takeLatest(actions.openRequest.type, init);
  yield takeLatest(actions.loadConflictRequest.type, loadConflict);
  yield takeLatest(actions.saveConflictRequest.type, saveConflict);
}
