import {
  take,
  call,
  put,
  cancelled,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { manageMfaActions as actions } from '.';
import moment from 'moment-timezone';
import { apiRequest } from 'utils/request';

function* confirmLogin(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `manage-mfa/confirm-login`,
      method: 'post',
      data: {
        ...action.payload,
      },
    });

    yield put(
      actions.confirmLoginSuccess({
        confirmationToken: payload.data.token,
        confirmationTokenExpires: payload.data.expires,
      }),
    );
  } catch (e) {
    yield put(actions.confirmLoginFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* getSetupData(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `manage-mfa/get-setup-data`,
      method: 'post',
      data: {
        ...action.payload,
      },
    });

    yield put(actions.getSetupDataSuccess(payload));
  } catch (e) {
    yield put(actions.getSetupDataFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}
function* confirmSetup(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `manage-mfa/verify-setup`,
      method: 'post',
      data: {
        ...action.payload,
      },
    });
    yield put(actions.confirmSetupSuccess(payload));
  } catch (e) {
    yield put(actions.confirmSetupFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* changePassword(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `users/change-password`,
      method: 'put',
      data: action.payload,
    });

    yield put(actions.changePasswordSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.changePasswordFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* changeEmail(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `users/change-email`,
      method: 'put',
      data: action.payload,
    });

    yield put(actions.changeEmailSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.changeEmailFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* requestCodes(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `users/request-mfa-backup-codes`,
      method: 'post',
      data: action.payload,
    });

    yield put(actions.requestCodesSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.requestCodesFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* manageMfaSaga() {
  yield takeLatest(actions.confirmLoginRequest.type, confirmLogin);
  yield takeLatest(actions.getSetupDataRequest.type, getSetupData);
  yield takeLatest(actions.confirmSetupRequest.type, confirmSetup);
  yield takeLatest(actions.changePasswordRequest.type, changePassword);
  yield takeLatest(actions.changeEmailRequest.type, changeEmail);
  yield takeLatest(actions.requestCodesRequest.type, requestCodes);
}
