/**
 *
 * AuthPageContainer
 *
 */
import React, { ReactNode } from 'react';
import { Logo } from 'app/components/Logo';
import {
  Box,
  Container,
  Hidden,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Linkify from 'react-linkify';

interface Props {
  children: ReactNode;
}

export function AuthPageContainer(props: Props) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const responsiveText = () => (sm ? 'body1' : 'body2');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: 'primary.main',
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            mt: 4,
            textAlign: 'center',
            ' > svg': {
              width: 240,
              height: 60,
            },
          }}
        >
          <Logo title="Governance360 App" />
        </Box>
        <Paper
          sx={{ p: [4], my: 4 }}
          elevation={5}
          style={{ backgroundColor: theme.palette.common.white }}
        >
          {props.children}
        </Paper>

        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <Stack spacing={2}>
            <Link
              href="https://www.governance360.com/legal/privacy-gdpr"
              target="_blank"
              rel="noreferrer"
              color={'common.white'}
            >
              Privacy Policy
            </Link>
            <Typography variant={responsiveText()} color="common.white">
              This website is protected by 256-bit SSL security
            </Typography>

            <Typography variant={responsiveText()} color="common.white">
              &copy; {new Date().getFullYear()} Governance360
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
