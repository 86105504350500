import React from 'react';

export function Office365Icon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 171.9 205.1"
      >
        <path
          style={{ fill: '#dc3e15' }}
          d="M120.9 179.8c.3-1.9.4-140.7.1-143.7C97.2 41.7 73.4 47.3 49.6 53v97.8c-12.3 4.8-24.5 9.5-36.7 14.2-.1 0-.1-.1-.2-.1s-.1-.1-.1-.2c0-.4-.1-.7-.1-1.1V45.1c.5-.7 1.3-.8 1.9-1 35-13 69.9-25.9 104.9-38.9 1.2-.5 2.3-.7 3.7-.3 19.2 5.5 38.4 11 57.6 16.4.3.1.6.2 1 .3v166.6c-5.5 1.5-11 3-16.4 4.5-13.9 3.8-27.9 7.6-41.8 11.4-1.4.4-2.6.3-3.9-.1-34.6-12.5-69-25-103.4-37.6-.7-.2-1.3-.5-1.9-1.1 35.5 4.8 71.1 9.7 106.7 14.5z"
          transform="matrix(1.0174 0 0 1.02675 -12.62 -4.924)"
        />
      </svg>
    </>
  );
}
