import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ISignupPayload, SignupState } from './types';
import { getApiCallInitialState } from 'types/ApiCall';
import { IInvitation, IOrganisation } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: SignupState = {
  getInvitation: getApiCallInitialState<IInvitation>(),
  acceptInvitation: getApiCallInitialState<{
    organisation_id: string | number;
  }>(),
  declineInvitation: getApiCallInitialState(),
  signup: getApiCallInitialState(),
  createOrganisation: getApiCallInitialState(),
  dismissOnboarding: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    getInvitationInit(state, action: PayloadAction<void>) {
      state.getInvitation = initialState.getInvitation;
    },
    getInvitationRequest(state, action: PayloadAction<{ code: string }>) {
      state.getInvitation.loading = true;
    },
    getInvitationSuccess(state, action: PayloadAction<{ data: IInvitation }>) {
      state.getInvitation.loading = false;
      state.getInvitation.data = action.payload.data;
    },
    getInvitationFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.getInvitation.loading = false;
      state.getInvitation.error = action.payload;
    },
    signupRequest(state, action: PayloadAction<ISignupPayload>) {
      state.signup.loading = true;
    },
    signupSuccess(state, action: PayloadAction<any>) {
      state.signup.loading = false;
      state.signup.data = action.payload;
    },
    signupFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.signup.loading = false;
      state.signup.error = action.payload;
    },
    acceptInvitationRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.acceptInvitation.loading = true;
    },
    acceptInvitationSuccess(
      state,
      action: PayloadAction<{ organisation_id: string | number }>,
    ) {
      state.acceptInvitation.loading = false;
      state.acceptInvitation.data = action.payload;
    },
    acceptInvitationFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.acceptInvitation.loading = false;
      state.acceptInvitation.error = action.payload;
    },
    declineInvitationRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.declineInvitation.loading = true;
    },
    declineInvitationSuccess(state, action: PayloadAction<any>) {
      state.declineInvitation.loading = false;
      state.declineInvitation.data = true;
    },
    declineInvitationFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.declineInvitation.loading = false;
      state.declineInvitation.error = action.payload;
    },
    createOrganisationRequest(
      state,
      action: PayloadAction<
        Partial<IOrganisation> & {
          planHash?: string;
          plan_id?: number;
        }
      >,
    ) {
      state.createOrganisation.loading = true;
    },
    createOrganisationSuccess(state, action: PayloadAction<any>) {
      state.createOrganisation.loading = false;
      state.createOrganisation.error = undefined;
      state.createOrganisation.data = action.payload.data;
    },
    createOrganisationFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.createOrganisation.loading = false;
      state.createOrganisation.error = action.payload;
    },
    dismissOnboardingRequest(state, action: PayloadAction<void>) {
      state.dismissOnboarding.loading = true;
    },
    dismissOnboardingSuccess(state, action: PayloadAction<any>) {
      state.dismissOnboarding.loading = false;
      state.dismissOnboarding.data = action.payload;
    },
    dismissOnboardingFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.dismissOnboarding.loading = false;
      state.dismissOnboarding.error = action.payload;
    },
  },
});

export const { actions: signupActions } = slice;
