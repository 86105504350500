import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Button, IconButton, Stack } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useFormikContext, useField } from 'formik';
import { Filename } from 'app/components/Filename';
import { LoadingIndicator } from '../LoadingIndicator';
import { formatFileSize } from '../../../utils/filesize';

type FileData = {
  action?: 'remove' | 'replace';
  data?: string;
  name?: string;
  size?: number;
  mime?: string;
};

interface Props extends BoxProps {
  allowedFileTypes?: string[];
  maxFileSize?: number;
  handleFileChange: (file: FileData) => void;
  loading?: boolean;
  multiple?: boolean;
  maxFiles?: number;
  onErrorEvent?: (error: string) => void;
}

const FileUploadComponent: React.FC<Props> = props => {
  const {
    allowedFileTypes = [],
    maxFileSize = 1024 * 1024 * 10, // Default 10MB
    handleFileChange,
    loading,
    multiple = false,
    maxFiles = 10,
    onErrorEvent = () => {},
    ...rProps
  } = props;
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files
      ? event.currentTarget.files
      : undefined;

    if (!files) {
      return;
    }

    if (files.length > maxFiles) {
      onErrorEvent(`You can only upload up to ${maxFiles} files at a time.`);
      event.target.value = ''; // Clear the selection
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file && file.size <= maxFileSize) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const encodedData = reader.result as string;
          handleFileChange({
            action: 'replace',
            data: encodedData.split(',')[1], // Only take the base64 data after the comma
            name: file.name,
            size: file.size,
            mime: file.type,
          });
        };
        reader.readAsDataURL(file);
      } else {
        onErrorEvent(
          `File size too large (max ${formatFileSize(
            maxFileSize,
          )}) or invalid file type`,
        );
      }
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
      {...rProps}
    >
      {rProps.children}
      <input
        type="file"
        multiple={multiple}
        accept={allowedFileTypes.join(',')}
        style={{
          cursor: 'pointer',
          opacity: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
        }}
        onChange={handleChange}
      />

      {loading ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      ) : null}
    </Box>
  );
};

export default FileUploadComponent;
