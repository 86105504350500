/**
 *
 * MeetingForm
 *
 */
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { MeetingForm } from './MeetingForm';
import { useMeetingFormWidgetSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeetingFormWidget } from './slice/selectors';
import { useEffectOnce } from 'utils/useEffectOnce';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IMeeting } from 'types/types';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { noop } from 'utils/noop';
import { useReloadCurrentOrganisation } from 'app/pages/OrganisationPage/useReloadCurrentOrganisation';
import { RiCloseLine } from 'react-icons/ri';
import useHandleApiResponse from 'utils/useHandleApiResponse';

interface Props {
  onChange?: (payload: IMeeting) => void;
  onDelete?: (payload: IMeeting) => void;
}

export function MeetingFormWidget(props: Props) {
  const { onChange = noop, onDelete } = props;
  const { actions } = useMeetingFormWidgetSlice();
  const { hasPermission } = useHasPermission();

  const dispatch = useDispatch();
  const {
    form: { open, id, committees, organisation_id },
    loadMeeting,
    saveMeeting,
  } = useSelector(selectMeetingFormWidget);

  const { loadOrganisation } = useReloadCurrentOrganisation();

  useEffectOnce(() => {
    dispatch(
      actions.loadFormRequest({
        organisation_id: +loadOrganisation.data?.id,
      }),
    );
  });

  const load = useCallback(() => {
    if (id) {
      dispatch(actions.loadMeetingRequest({ id }));
    }
  }, [id]);

  const close = () => dispatch(actions.close());

  useHandleApiResponse(saveMeeting, 'Meeting saved', {
    onSuccess: () => {
      onChange(saveMeeting.data);
      close();
    },
    errorMessage: 'Unable to create a meeting',
  });

  useEffect(() => {
    if (open && id) load();
  }, [open]);

  if (!committees) return null;

  return (
    <MeetingForm
      onDelete={onDelete}
      saving={saveMeeting.loading}
      onClose={close}
      meeting={loadMeeting.data}
      committees={committees.filter(c =>
        hasPermission(
          loadMeeting.data ? 'edit-meeting' : 'create-meeting',
          'committee',
          c.id,
        ),
      )}
      onSave={payload =>
        dispatch(actions.saveMeetingRequest({ organisation_id, ...payload }))
      }
    />
  );
}

interface MeetingFormDialogProps {
  open: boolean;
  onClose?: () => void;
  onChange: (meeting: IMeeting) => void;
  onDelete?: (meeting: IMeeting) => void;
  // Add other props as needed
}

export const MeetingFormDialog: React.FC<MeetingFormDialogProps> = ({
  open,
  onClose = noop,
  onChange,
  onDelete,
}) => {
  const { actions } = useMeetingFormWidgetSlice();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { loadMeeting } = useSelector(selectMeetingFormWidget);

  const onCloseDialog = () => {
    onClose();
    dispatch(actions.close());
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      maxWidth={'md'}
      fullWidth
      fullScreen={fullScreen}
      scroll={'body'}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ mr: 'auto' }}>
            {loadMeeting.data ? (
              <>Editing {loadMeeting.data?.name} </>
            ) : (
              'Create meeting'
            )}
          </Box>

          <IconButton onClick={onCloseDialog} edge={'end'} size={'large'}>
            <RiCloseLine />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <MeetingFormWidget onChange={onChange} onDelete={onDelete} />
      </DialogContent>
    </Dialog>
  );
};

export function useMeetingFormWidget() {
  const { actions } = useMeetingFormWidgetSlice();
  const dispatch = useDispatch();
  const meetingForm = useSelector(selectMeetingFormWidget);

  const open = payload => {
    dispatch(actions.open());
    dispatch(actions.loadFormRequest(payload));
  };
  const close = () => dispatch(actions.close());

  return {
    open,
    close,
    isOpen: meetingForm.form.open,
    form: meetingForm.form,
    loadMeeting: meetingForm.loadMeeting,
  };
}
