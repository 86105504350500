import {
  Box,
  Button,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import key from 'weak-key';
import * as React from 'react';
import { IconItem } from './IconItem';
import { FeatureStatus } from 'types/types';

interface Props {
  previous: () => void;
  next: () => void;
  organisation_name: string;
  plan_name: string;
  partner_name: string;
  partner_logo_image_url: string;
  feature_status: FeatureStatus;
}

export function YourPlan(props: Props) {
  const {
    next,
    previous,
    partner_logo_image_url,
    partner_name,
    plan_name,
    organisation_name,
    feature_status,
  } = props;
  return (
    <Stack spacing={6}>
      <Typography variant={'h1'}>Your plan</Typography>
      <Box>
        <Stack spacing={4}>
          <Stack>
            <Typography component={'p'}>
              Boards is an add-on service to the core Governance360 platform. To
              use Boards by Governance360 you will need to subscribe to it. The
              cost will depend on a few things, including whether you wish to
              include our Academy learning product or not. You may also benefit
              from a discount, or indeed free, use of the app if your
              organisation has joined Governance360 via a partner organisation,
              who have provided you with a partner code. Review the below
              information to decide if you want to start using Boards.
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <Box>
        <Grid container>
          <Grid item sm={12} md={6}>
            <Stack spacing={4}>
              <Typography variant={'h4'}>Your organisation info</Typography>
              <IconItem
                iconContainerProps={{ width: 50, mr: 2 }}
                icon={
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 14V6H4V42H44V14H24ZM12 38H8V34H12V38ZM12 30H8V26H12V30ZM12 22H8V18H12V22ZM12 14H8V10H12V14ZM20 38H16V34H20V38ZM20 30H16V26H20V30ZM20 22H16V18H20V22ZM20 14H16V10H20V14ZM40 38H24V34H28V30H24V26H28V22H24V18H40V38ZM36 22H32V26H36V22ZM36 30H32V34H36V30Z"
                      fill="#343459"
                    />
                  </svg>
                }
                title={organisation_name}
                stackProps={{ alignItems: 'center' }}
              />
            </Stack>
          </Grid>
          <Grid item sm={12} md={6} mt={{ sm: 5, md: 0 }}>
            <Stack spacing={4}>
              <Typography variant={'h4'}>Your partner organisation</Typography>

              <IconItem
                iconContainerProps={{ width: 50, mr: 2 }}
                icon={<img src={partner_logo_image_url} alt={partner_name} />}
                title={partner_name}
                stackProps={{ alignItems: 'center' }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body2'} color={'primary'}>
              Contact us if any of the above information is incorrect or out of
              date
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Stack spacing={2}>
          <Typography variant={'h4'}>Your plan</Typography>
          <Typography>
            We offer a range of plans within Governance360, depending on the
            features you wish to use. At present, our information suggests that
            your plan will include the following key terms:
          </Typography>
        </Stack>
      </Box>

      <Box>
        <Stack spacing={4}>
          <IconItem
            icon={
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4375 6.18762L15.4688 4.12508L17.5312 3.09381L15.4688 2.06254L14.4375 0L13.4062 2.06254L11.3438 3.09381L13.4062 4.12508L14.4375 6.18762ZM5.15625 10.3127L6.87457 6.87535L10.3125 5.15635L6.87457 3.43735L5.15625 0L3.43793 3.43735L0 5.15635L3.43793 6.87535L5.15625 10.3127ZM27.8438 18.5629L26.1254 22.0002L22.6875 23.7192L26.1254 25.4382L27.8438 28.8756L29.5621 25.4382L33 23.7192L29.5621 22.0002L27.8438 18.5629ZM32.3954 6.07354L26.9266 0.604582C26.5244 0.201098 25.9965 0 25.4687 0C24.9408 0 24.4129 0.201098 24.0101 0.604582L0.60457 24.0105C-0.201094 24.8162 -0.201094 26.1221 0.60457 26.9271L6.07342 32.3961C6.47625 32.7989 7.00412 33 7.53135 33C8.05922 33 8.58709 32.7989 8.98992 32.3961L32.3954 8.98945C33.2011 8.18506 33.2011 6.87857 32.3954 6.07354ZM23.1677 13.1139L19.8864 9.83252L25.468 4.25077L28.7493 7.53214L23.1677 13.1139Z"
                  fill="#343459"
                />
              </svg>
            }
            title={
              feature_status.status === 'trial'
                ? `Free for ${feature_status.trial_settings.length} days`
                : 'Boards is included in your plan'
            }
            body={
              feature_status.status === 'trial' ? (
                <>
                  Your trial will start today if you complete this setup wizard
                  and start Boards. Once your{' '}
                  {feature_status.trial_settings.length} day trial is up, you
                  will need to pay a subscription cost as noted below.
                </>
              ) : (
                <></>
              )
            }
          />
          {/*<IconItem
            icon={
              <svg
                width="37"
                height="30"
                viewBox="0 0 37 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.12 1.56675L35.434 12.8808C35.9958 13.4433 36.3114 14.2058 36.3114 15.0008C36.3114 15.7958 35.9958 16.5583 35.434 17.1208L24.122 28.4368C23.5592 28.9995 22.7959 29.3157 22 29.3157C21.2041 29.3157 20.4408 28.9995 19.878 28.4368C19.3152 27.874 18.999 27.1107 18.999 26.3148C18.999 25.5189 19.3152 24.7555 19.878 24.1928L26.07 18.0008H3C2.20435 18.0008 1.44129 17.6847 0.87868 17.1221C0.316071 16.5595 0 15.7964 0 15.0008C0 14.2051 0.316071 13.442 0.87868 12.8794C1.44129 12.3168 2.20435 12.0008 3 12.0008H14.535H26.07L19.88 5.80875C19.3175 5.24596 19.0016 4.48276 19.0017 3.68705C19.0019 2.89133 19.3182 2.12828 19.881 1.56575C20.4438 1.00323 21.207 0.687313 22.0027 0.6875C22.7984 0.687688 23.5615 1.00397 24.124 1.56675H24.12Z"
                  fill="#343459"
                />
              </svg>
            }
            title={'£40 a month, rolling'}
            body={
              <>
                No minimum duration commitment - you can cancel whenever you
                want. No limit on users for your organisation.
              </>
            }
          />*/}
        </Stack>
      </Box>

      <Box>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant={'outlined'} onClick={() => previous()}>
              Previous: Welcome to Boards
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth onClick={() => next()} type={'submit'}>
              Next: Getting started
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
