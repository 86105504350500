import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.committees || initialState;

export const selectCommittees = createSelector([selectSlice], state => state);
export const selectLoadCommittees = createSelector(
  [selectSlice],
  state => state.loadCommittees,
);
export const selectLoadCommittee = createSelector(
  [selectSlice],
  state => state.loadCommittee,
);
