import { takeLatest } from 'redux-saga/effects';
import { verifyEmailActions as actions } from './';
import { createRequestSaga } from 'utils/saga';

const verify = createRequestSaga({
  actionType: actions.verifyRequest.type,
  url: 'verify-email',
  method: 'post',
  data: data => data,
  successAction: actions.verifySuccess,
  failureAction: actions.verifyFailed,
});

export function* verifyEmailSaga() {
  yield takeLatest(actions.verifyRequest.type, verify);
}
