/**
 *
 * ChecklistQuestion
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { RiQuestionLine } from 'react-icons/ri';
import {
  ChecklistQuestionRagAnswerValue,
  ChecklistSubmissionAnswerDefinition,
  ChecklistSubmissionAnswerValues,
} from 'types/types';
import { useMemo } from 'react';

interface Props {
  definition: ChecklistSubmissionAnswerDefinition;
  status: ChecklistQuestionRagAnswerValue;
  values: ChecklistSubmissionAnswerValues;
  onChange: (o: ChecklistSubmissionAnswerValues) => void;
  editable?: boolean;
  loading?: boolean;
}

export function ChecklistSubmissionAnswer(props: Props) {
  const {
    definition: {
      title,
      help_text,
      show_comment = true,
      comment_placeholder_text = '',
      action_on = 'Y',
      description,
      resource_text,
      resource_url,
      button_intro_text,
      button_red_text = 'No',
      button_amber_text = 'Maybe',
      button_green_text = 'Yes',
    },
    status,
    values,
    onChange,
    editable = true,
    loading = false,
  } = props;

  const setResponse = response =>
    onChange({
      ...values,
      response,
    });
  const setComment = comment => onChange({ ...values, comment });
  const setRagAnswer = rag_answer => onChange({ ...values, rag_answer });

  const open = useMemo(() => {
    if (!editable) return false;
    if (values.response !== action_on) return false;
    if (values.rag_answer) return false;
    return true;
  }, [values, editable]);

  const colors = useMemo(() => {
    switch (status) {
      case 'G':
        return ['success.light', 'success.dark'];
      case 'A':
        return ['warning.light', 'warning.dark'];
      case 'R':
        return ['error.light', 'error.dark'];

      default:
        return ['white', 'primary.main'];
    }
  }, [status]);

  return (
    <Box
      sx={{
        position: 'relative',
        p: 2,
        border: 1,
        borderColor: colors[1],
        pl: 4,
        backgroundColor: colors[0],
        '&::before': {
          content: `"${status || ''}"`,
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: 24,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors[1],
          fontSize: 12,
          fontWeight: 600,
          color: 'white',
        },
      }}
    >
      <Stack>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} md={10}>
            <Box>
              <Typography component={'span'}>{title}</Typography>
              {!!help_text && (
                <Tooltip title={<Typography>{help_text}</Typography>}>
                  <Box color={'primary.main'} component={'span'} sx={{ ml: 1 }}>
                    <RiQuestionLine fontSize={24} />
                  </Box>
                </Tooltip>
              )}
              {(!editable || !open) && !!values.comment && (
                <Box>
                  <Typography variant={'subtitle2'}>
                    <b>Comment: </b> {values.comment}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={values.response}
                onChange={ev => setResponse(ev.target.value)}
                onClick={() => (editable ? setRagAnswer(null) : {})}
              >
                <FormControlLabel
                  disabled={!editable || loading}
                  value="Y"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  disabled={!editable || loading}
                  value="N"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </Box>
          </Grid>
        </Grid>
      </Stack>
      {!!open && (
        <Box sx={{ mt: 2 }}>
          <Stack>
            <Box>
              {!!description && <Typography>{description}</Typography>}
              {!!resource_url && (
                <a href={resource_url} target={'_blank'} rel="noreferrer">
                  {resource_text}
                </a>
              )}
            </Box>

            {!!show_comment && (
              <TextField
                disabled={!editable || loading}
                fullWidth
                multiline
                minRows={3}
                placeholder={comment_placeholder_text}
                value={values.comment}
                onChange={e => setComment(e.target.value)}
              ></TextField>
            )}
            <Typography>{button_intro_text}</Typography>

            <Stack direction={'row'}>
              <Button
                disabled={!editable || loading}
                onClick={() => setRagAnswer('G')}
                color={'success'}
                variant={values.rag_answer === 'G' ? 'contained' : 'outlined'}
              >
                {button_green_text}
              </Button>
              <Button
                disabled={!editable || loading}
                onClick={() => setRagAnswer('A')}
                color={'warning'}
                variant={values.rag_answer === 'A' ? 'contained' : 'outlined'}
              >
                {button_amber_text}
              </Button>
              <Button
                disabled={!editable || loading}
                onClick={() => setRagAnswer('R')}
                color={'error'}
                variant={values.rag_answer === 'R' ? 'contained' : 'outlined'}
              >
                {button_red_text}
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
