import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';

interface Props {
  message: string;
  onChange: () => void;
  loading?: boolean;
}

export function ResendPasscode(props: Props) {
  const { onChange, message, loading } = props;
  const [toggle, setToggle] = useState<boolean>(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <Stack border={1} borderRadius={1} width={'100%'} alignItems={'center'}>
      <Button
        endIcon={toggle ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
        variant="text"
        fullWidth
        onClick={handleClick}
        sx={{
          justifyContent: 'space-between',
          textDecoration: 'none',
          display: 'flex',
          '& .MuiButton-endIcon': {
            fontSize: 15,
            color: 'primary.main',
          },
        }}
      >
        Resend Passcode
      </Button>
      {toggle && (
        <Stack spacing={1} padding={2} alignItems={'start'} width="100%">
          <Typography variant="body1">{message}</Typography>
          <Button
            disabled={loading}
            onClick={onChange}
            fullWidth
            variant="outlined"
          >
            Send new passcode
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
