import React from 'react';
import {
  FormControl,
  MenuItem,
  Stack,
  Typography,
  Select,
  StackProps,
  Box,
} from '@mui/material';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import { Operation } from '../Operation';
import { Field, useField, useFormikContext } from 'formik';
import { useRiskColourName } from 'utils/useRiskColourName';
import key from 'weak-key';

type Props = StackProps & {
  fullWidth?: boolean;
  name: 'score_impact' | 'score_likelihood' | 'score_impact_mitigated';
  readOnly?: boolean;
};

export function RiskLevelSelector(props: Props) {
  const { name, fullWidth = false, readOnly, ...rProps } = props;

  const getRiskColourData = useRiskColourName();
  const riskLevels = [1, 2, 3, 4, 5];

  const { setFieldValue, values } = useFormikContext();
  const value = +values[name];

  const onIncrease = () => {
    setFieldValue(name, Math.min(value + 1, 5));
  };

  const onDecrease = () => {
    setFieldValue(name, Math.max(value - 1, 1));
  };

  const getRiskColourAndName = level => {
    const { levelName, colour } = getRiskColourData(level, name);
    return (
      <>
        <RiCheckboxBlankCircleFill color={colour} />
        <Typography component={'span'}>{levelName}</Typography>
      </>
    );
  };

  return (
    <>
      {readOnly ? (
        <Box sx={{ py: 1 }}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography component={'span'} sx={{ width: '10px' }}>
              {value}
            </Typography>
            {getRiskColourAndName(value)}
          </Stack>
        </Box>
      ) : (
        <Stack direction={'row'} alignItems={'center'} spacing={1} {...rProps}>
          <FormControl sx={{ width: '100%' }}>
            <Select
              onChange={e =>
                setFieldValue(name, parseInt(e.target.value as string, 10))
              }
              value={value}
              sx={{
                width: fullWidth ? '100%' : 230,
                height: 40,
                borderRadius: 2,
                '& .MuiSelect-icon': {
                  fontSize: 30,
                  color: 'primary.main',
                  fontWeight: 900,
                },
              }}
              renderValue={selected => (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography component={'span'} sx={{ width: '10px' }}>
                    {selected}
                  </Typography>
                  {getRiskColourAndName(selected)}
                </Stack>
              )}
              IconComponent={KeyboardArrowDownSharp}
            >
              {riskLevels.map(level => (
                <MenuItem
                  key={key({ level })}
                  value={+level}
                  sx={{ px: 2, width: '100%' }}
                  selected={value === level}
                >
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography component={'span'} sx={{ width: '10px' }}>
                      {level}
                    </Typography>
                    {getRiskColourAndName(level)}
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Operation
            type={'minus'}
            onChange={onDecrease}
            disabled={value === 1}
          />
          <Operation
            type={'plus'}
            onChange={onIncrease}
            disabled={value === 5}
          />
        </Stack>
      )}
    </>
  );
}
