/**
 *
 * Meetings List
 *
 */
import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { IAbridgedMeeting } from 'types/types';
import key from 'weak-key';
import { MeetingBar } from './MeetingBar';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

interface Props {
  meetings: IAbridgedMeeting[];
  futureLimit?: number;
  pastLimit?: number;
  currentLimit?: number;
}

export function MeetingsList(props: Props) {
  const {
    meetings = [],
    currentLimit = 1000,
    pastLimit = 1000,
    futureLimit = 1000,
  } = props;

  const futureMeetings = meetings
    .filter(m => m.historical_status === 'future')
    .slice(-futureLimit);

  const currentMeetings = meetings
    .filter(m => m.historical_status === 'current')
    .slice(-currentLimit);

  const pastMeetings = meetings
    .filter(m => m.historical_status === 'past')
    .slice(0, pastLimit);

  return (
    <>
      <Box sx={{ my: 3 }}>
        <Stack spacing={1}>
          {!!currentMeetings.length && (
            <>
              <Typography variant={'h4'} component={'div'}>
                Current
              </Typography>
              {currentMeetings.map(meeting => (
                <MeetingBar
                  key={key(meeting)}
                  meeting={meeting}
                  subtitleType={'organisation'}
                />
              ))}
            </>
          )}
          {!!futureMeetings.length && (
            <>
              <Typography variant={'h4'} component={'div'}>
                Upcoming
              </Typography>

              {futureMeetings
                .slice(-5)
                .reverse()
                .map(meeting => (
                  <MeetingBar
                    key={key(meeting)}
                    meeting={meeting}
                    subtitleType={'organisation'}
                  />
                ))}
            </>
          )}
          {!!pastMeetings.length && (
            <>
              <Typography variant={'h4'} component={'div'}>
                Previous
              </Typography>

              {pastMeetings.slice(0, 250).map(meeting => (
                <MeetingBar
                  key={key(meeting)}
                  meeting={meeting}
                  subtitleType={'organisation'}
                />
              ))}
            </>
          )}
          {!meetings.length && (
            <>
              <Typography variant={'body2'}></Typography>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
}
