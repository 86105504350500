import { IconButton } from '@mui/material';
import React from 'react';
import { PlusIcon } from '../assets/PlusIcon';
import { MinusIcon } from '../assets/MinusIcon';

interface Props {
  onChange: () => void;
  type: 'plus' | 'minus';
  disabled: boolean;
}

export function Operation(props: Props) {
  const { onChange, type, disabled } = props;
  const icon = (operation: string) => {
    switch (operation) {
      case 'plus':
        return <PlusIcon disabled={disabled} />;
      case 'minus':
        return <MinusIcon disabled={disabled} />;
      // to be added...
      // case 'multiply':
      //   return ;
      // case 'divide':
      //   return ;
    }
  };
  return (
    <IconButton onClick={onChange} disabled={disabled} sx={{ px: 0 }}>
      {icon(type)}
    </IconButton>
  );
}
