import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.members || initialState;

export const selectMembers = createSelector([selectSlice], state => state);
export const selectLoadMembers = createSelector(
  [selectSlice],
  state => state.loadMembers,
);
