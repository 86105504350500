/**
 *
 * LoginForm
 *
 */

import React, { useMemo } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { ResendPasscode } from 'app/components/ResendPasscode';
import { hideEmail, hidePhoneNo } from 'utils/hideChars';
import { AuthConfirmationScreen } from 'app/components/AuthConfirmationScreen';
import { MfaMethod } from 'types/types';
import { AuthInputField } from 'app/components/AuthInputField';

interface Props {
  userAuthenticate2faRequest: (payload) => void;
  loading: boolean;
  validation_token: string;
  onConfirm?: (code) => void;
  contact: string;
  method: MfaMethod;
  resendCode: () => void;
  resendLoading: boolean;
  reset: () => void;
}

export function AuthenticationForm(props: Props) {
  const {
    userAuthenticate2faRequest,
    validation_token,
    loading,
    contact,
    method,
    resendLoading,
    resendCode,
    reset,
  } = props;

  const [code, setCode] = React.useState('');
  const no_of_fields = 6;
  const contactRedacted = useMemo(() => {
    if (method === 'sms') return hidePhoneNo(contact);
    if (method === 'email') return hideEmail(contact);
  }, [method, contact]);

  // if (loading) return <LoadingIndicator />;

  return (
    <Box>
      <Stack spacing={3} alignItems={'center'} textAlign={'center'}>
        <Typography variant="h3" color={'primary.main'}>
          Verify your identity
        </Typography>
        <Stack spacing={3}>
          <Typography>
            Your account has an extra layer of security enabled. To login, enter
            the passcode{' '}
            {method === 'auth-app' ? (
              <>on your Authenticator App</>
            ) : (
              <>sent to {contactRedacted}</>
            )}
          </Typography>

          <AuthInputField
            codeValue={code}
            label={'Enter your Two Factor Authorisation code to continue'}
            onChange={v => setCode(v)}
            onEnter={() =>
              userAuthenticate2faRequest({
                validationToken: validation_token,
                code,
              })
            }
          />

          <Button
            disabled={code.length < no_of_fields || loading}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              userAuthenticate2faRequest({
                validationToken: validation_token,
                code,
              });
            }}
          >
            Verify passcode
          </Button>

          <Button variant="text" size="small" onClick={reset}>
            Cancel logging in
          </Button>
          {method !== 'auth-app' && (
            <ResendPasscode
              message={`Send via ${method} to ${contactRedacted}`}
              onChange={() => resendCode()}
              loading={resendLoading}
            ></ResendPasscode>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
