import { takeLatest } from 'redux-saga/effects';
import { partnerDetailsActions as actions } from '.';
import { createRequestSaga } from 'utils/saga';

const loadPartner = createRequestSaga({
  actionType: actions.loadPartnerRequest.type,
  url: () => 'partners/load-details',
  data: d => d,
  successAction: actions.loadPartnerSuccess,
  failureAction: actions.loadPartnerFailed,
});

export function* partnerDetailsSaga() {
  yield takeLatest(actions.loadPartnerRequest.type, loadPartner);
}
