import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { VerifyEmailState } from './types';
import { IDocument } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';
import { apiCallInitialState, getApiCallInitialState } from 'types/ApiCall';

export const initialState: VerifyEmailState = {
  verify: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'verifyEmail',
  initialState,
  reducers: {
    init(state, action: PayloadAction<void>) {
      state.verify = initialState.verify;
    },
    verifyRequest(state, action: PayloadAction<{ token: string; id: number }>) {
      state.verify = { ...initialState.verify, loading: true, touched: true };
    },
    verifySuccess(state, action: PayloadAction<IDocument[]>) {
      state.verify.loading = false;
      state.verify.data = action.payload;
    },
    verifyFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.verify.loading = false;
      state.verify.error = action.payload;
    },
  },
});

export const { actions: verifyEmailActions } = slice;
