import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ConflictsState } from './types';
import { apiCallInitialState, getApiCallInitialState } from 'types/ApiCall';
import { IConflict, IOrganisation } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: ConflictsState = {
  loadConflicts: { ...apiCallInitialState, data: [] },
  deleteConflict: apiCallInitialState,
  closeOffConflict: apiCallInitialState,
  form: {
    open: false,
    loading: false,
    organisations: [],
  },
  loadConflict: getApiCallInitialState<IConflict>(),
  saveConflict: getApiCallInitialState(),
};

export const slice = createSlice({
  name: 'conflicts',
  initialState,
  reducers: {
    openRequest(
      state,
      action: PayloadAction<{
        id?: number;
        organisation_id?: number;
      }>,
    ) {
      state.form.id = action.payload.id;
      state.form.open = true;
      state.form.loading = true;
      state.form.organisation_id = action.payload.organisation_id;
    },
    openSuccess(state, action: PayloadAction<{ data: IOrganisation[] }>) {
      state.form.loading = false;
      state.form.organisations = action.payload.data;
    },
    openFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.form = { ...initialState.form, error: action.payload };
    },
    close(state, action: PayloadAction<void>) {
      state.form = initialState.form;
      state.loadConflict = initialState.loadConflict;
      state.saveConflict = initialState.saveConflict;
    },
    loadConflictRequest(state, action: PayloadAction<{ id: number }>) {
      state.loadConflict.loading = true;
      state.loadConflict.error = undefined;
    },
    loadConflictSuccess(state, action: PayloadAction<{ data: IConflict }>) {
      state.loadConflict.loading = false;
      state.loadConflict.error = undefined;
      state.loadConflict.data = action.payload.data;
    },
    loadConflictFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadConflict.loading = false;
      state.loadConflict.error = action.payload;
    },
    saveConflictRequest(state, action: PayloadAction<Partial<IConflict>>) {
      state.saveConflict.loading = true;
      state.saveConflict.error = undefined;
    },
    saveConflictSuccess(state, action: PayloadAction<{ data: IConflict }>) {
      state.saveConflict.loading = false;
      state.saveConflict.error = undefined;
      state.saveConflict.data = action.payload.data;
    },
    saveConflictFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.saveConflict.loading = false;
      state.saveConflict.error = action.payload;
    },
    loadConflictsInit(state, action: PayloadAction<void>) {
      state.loadConflicts = initialState.loadConflicts;
    },
    loadConflictsRequest(state, action: PayloadAction<void>) {
      state.loadConflicts.loading = true;
      state.loadConflicts.error = undefined;
    },
    loadConflictsSuccess(state, action: PayloadAction<{ data: IConflict[] }>) {
      state.loadConflicts.loading = false;
      state.loadConflicts.error = undefined;
      state.loadConflicts.data = action.payload.data;
    },
    loadConflictsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadConflicts.loading = false;
      state.loadConflicts.error = action.payload;
    },
    deleteConflictRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.deleteConflict.loading = true;
      state.deleteConflict.error = undefined;
    },
    deleteConflictSuccess(state, action: PayloadAction<any>) {
      state.deleteConflict.loading = false;
      state.deleteConflict.error = undefined;
      state.deleteConflict.data = action.payload.data;
    },
    deleteConflictFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.deleteConflict.loading = false;
      state.deleteConflict.error = action.payload;
    },
    closeOffConflictRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.closeOffConflict.loading = true;
      state.closeOffConflict.error = undefined;
    },
    closeOffConflictSuccess(state, action: PayloadAction<any>) {
      state.closeOffConflict.loading = false;
      state.closeOffConflict.error = undefined;
      state.closeOffConflict.data = action.payload.data;
    },
    closeOffConflictFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.closeOffConflict.loading = false;
      state.closeOffConflict.error = action.payload;
    },
  },
});

export const { actions: conflictsActions } = slice;
