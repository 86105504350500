import { Chip, Stack } from '@mui/material';
import * as React from 'react';
import { IDocument } from '../../../types/types';
import { Filename } from '../../components/Filename';
import { openFile, serveFile } from '../../../utils/request';

interface Props {
  document: IDocument;
}

export function MeetingDocumentFile(props: Props) {
  const { document } = props;

  const downloadable = !!document.file_details?.url;
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Filename
        name={document.name || document.file_details?.filename}
        extension={document.file_details?.extension || 'bin'}
        onClick={ev => {
          if (!downloadable) return;

          ev.nativeEvent.stopImmediatePropagation();
          openFile(document.file_details?.url);
        }}
      />
      {/*      <Chip label={'Updated today, 9PM'} color="primary" /> */}
    </Stack>
  );
}
