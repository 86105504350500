/**
 *
 * Checklists
 *
 */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  ChecklistQuestionRagAnswerValue,
  ChecklistSubmissionAnswer as Answer,
  IOrganisation,
} from 'types/types';
import { ChecklistSubmissionAnswer } from 'app/components/ChecklistSubmissionAnswer';
import { useEffectOnce } from 'utils/useEffectOnce';
import { useDispatch, useSelector } from 'react-redux';
import { useChecklistsSlice } from './slice/hook';
import { selectChecklists } from './slice/selectors';
import { useDebounce } from 'utils/useDebounce';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useHasChanged } from 'utils/usePrevious';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import key from 'weak-key';
import { formatParsedISO } from 'utils/formatParsedISO';
import { getApiUrl, serveFile } from 'utils/request';
import { ConfirmSubmissionDialog } from './ConfirmSubmissionDialog';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';

interface Props {
  organisation: IOrganisation;
}

export function Item(props: Props) {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const { hasPermission } = useHasPermission();

  const { organisation } = props;
  const dispatch = useDispatch();
  const { actions } = useChecklistsSlice();
  const navigate = useNavigate();

  const { submission_id = '', checklist_id } = useParams<{
    checklist_id: string;
    submission_id?: string;
  }>();

  const canExport = hasPermission(
    'export-checklists',
    'organisation',
    organisation.id,
  );

  const [answers, setAnswers] = useState<Answer[]>([]);
  const snackbar = useSnackbar();

  const { loadAllSubmissions, loadSubmission, saveSubmission } =
    useSelector(selectChecklists);

  const load = (sid = '') => {
    dispatch(
      actions.loadSubmissionRequest({
        organisation_id: organisation.id,
        checklist_id: +checklist_id,
        submission_id: +submission_id,
      }),
    );
    dispatch(
      actions.loadAllSubmissionsRequest({
        organisation_id: organisation.id,
        checklist_id: +checklist_id,
      }),
    );
  };

  useEffectOnce(() => {
    load();
    return () => {
      dispatch(actions.initLatestSubmission());
      snackbar.closeSnackbar();
    };
  });

  useEffect(() => {
    if (loadSubmission.data) {
      setAnswers(loadSubmission.data.answers);
    }
  }, [loadSubmission.data]);

  const answersChangedDebounced = useDebounce(answers, 2000);

  const statuses = useMemo<ChecklistQuestionRagAnswerValue[]>(() => {
    return answers.map(a => {
      if (!!a.values.response && a.values.response !== a.definition.action_on) {
        return 'G';
      }
      return a.values.rag_answer;
    });
  }, [answers]);

  useEffect(() => {
    if (
      !organisation ||
      !loadSubmission.data ||
      !answers.length ||
      isEqual(answers, loadSubmission.data.answers)
    )
      return;

    dispatch(
      actions.saveSubmissionRequest({ ...loadSubmission.data, answers }),
    );
  }, [answersChangedDebounced]);

  const saveLoadingChanged = useHasChanged(saveSubmission.loading);

  useEffect(() => {
    if (saveLoadingChanged && !saveSubmission.loading) {
      if (saveSubmission.error) {
        snackbar.enqueueSnackbar('Save error', { variant: 'error' });
      } else if (!!saveSubmission.data.submitted_at) {
        //        load();
        navigate('../');
      }
    }
  });

  const submissionIdChanged = useHasChanged(submission_id);

  useEffect(() => {
    if (submissionIdChanged && submission_id !== null) load(submission_id);
  });

  const handleChange = (ev: SelectChangeEvent) => {
    if (ev.target.value !== submission_id) {
      navigate(
        `${!!submission_id ? '../' : ''}../${checklist_id}/${ev.target.value}`,
      );
    }
  };

  const save = () => {
    if (!canExport) return;
    serveFile(
      getApiUrl(
        `exports/checklist-submission?submission_id=${loadSubmission.data.id}`,
      ),
    ).then(res => {});
  };

  if (!loadSubmission.data) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <Box sx={{ my: 4 }}>
        <Stack>
          <Stack>
            <Typography variant={'h3'}>
              {loadSubmission.data.checklist_name}
            </Typography>
            {!!loadSubmission.data.checklist_description && (
              <Typography variant={'body1'}>
                {loadSubmission.data.checklist_description}
              </Typography>
            )}
          </Stack>
          <Box sx={{ pt: 1 }}>
            <Stack
              direction="row"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box minWidth={200}>
                <FormControl>
                  <InputLabel shrink id="submission-label">
                    Submission
                  </InputLabel>
                  <Select
                    notched
                    fullWidth
                    labelId="submission-label"
                    id="submission-select"
                    value={submission_id || ''}
                    label="Submission"
                    onChange={handleChange}
                    displayEmpty
                    sx={{ minWidth: 300 }}
                  >
                    <MenuItem value={''}>Current</MenuItem>
                    {loadAllSubmissions.data.map(s => (
                      <MenuItem value={s.id} key={key(s)}>
                        #{s.id} - {formatParsedISO(s.submitted_at)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {!!canExport && <Button onClick={save}>Export</Button>}
            </Stack>
          </Box>
          <Stack>
            {answers.map((answer, index) => (
              <ChecklistSubmissionAnswer
                editable={loadSubmission.data.status === 'open'}
                loading={loadSubmission.loading}
                key={`checklist-submission-answer-${index}`}
                status={statuses[index]}
                onChange={values => {
                  const a = [...answers];
                  a[index] = { ...answers[index], values };

                  setAnswers(a);
                }}
                {...answer}
              />
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Box>
              <Stack direction={'row'}>
                {loadSubmission.data.status === 'open' && (
                  <Button
                    disabled={statuses.findIndex(s => !s) !== -1}
                    variant={'outlined'}
                    onClick={() => setConfirmDialogOpen(true)}
                  >
                    Submit
                  </Button>
                )}
              </Stack>
            </Box>
          </Stack>
        </Stack>

        {!!loadSubmission.data && (
          <ConfirmSubmissionDialog
            canExport={canExport}
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
            submission={loadSubmission.data}
            onConfirm={options => {
              if (options.export) {
                save();
              }

              dispatch(
                actions.saveSubmissionRequest({
                  ...loadSubmission.data,
                  due_date: options.due_date,
                  close: true,
                }),
              );
            }}
          />
        )}
      </Box>
    </>
  );
}
