import {
  alpha,
  Box,
  Button,
  CardMedia,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { RiPlayFill } from 'react-icons/ri';
import { CompletionIndicator } from '../CompletionIndicator';

interface Props {
  image: string;
  title: string;
  percentage: number;
  onClick?: () => void;
}

export function AcademyModuleCard(props: Props) {
  const { image, title, percentage = 50, onClick = () => {} } = props;
  const courseAction = () => {
    if (percentage > 0 && percentage < 100) {
      return 'Resume';
    } else if (percentage === 100) {
      return 'Review';
    } else {
      return 'Start';
    }
  };

  const theme = useTheme();

  return (
    <Box onClick={onClick} sx={{ border: 1, borderColor: 'common.grey' }}>
      <Stack spacing={1}>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <CardMedia
            image={image}
            sx={{
              pt: '50%',
              height: 0,
              width: '100%',
              objectFit: 'contain',
              position: 'relative',
            }}
          />
          {!!percentage && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <CompletionIndicator
                thickness={8}
                size={80}
                circleColours={{
                  progressColour: alpha(theme.palette.common.midnight, 0.8),
                  remainderColour: alpha(theme.palette.common.purple.main, 0.6),
                }}
                percentage={80}
              >
                <Box>
                  <Typography fontWeight={'bold'}>
                    {Math.round(percentage)}%
                  </Typography>
                </Box>
              </CompletionIndicator>
            </Box>
          )}
        </Box>

        <Stack alignItems={'center'}>
          <Box>
            <Typography
              color={'primary.main'}
              fontWeight={'bold'}
              variant="body2"
              p={1}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="text"
              sx={{
                textDecoration: 'none',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                '& .MuiButton-startIcon': {
                  display: 'flex',
                  px: 0,
                  mx: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                  '& svg': {
                    width: '30px',
                    height: '30px',
                  },
                },
              }}
              startIcon={<RiPlayFill />}
            >
              {courseAction()} course
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
