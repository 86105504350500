/**
 *
 * useRoutedTabs
 *
 */

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Tab,
  TabProps,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import key from 'weak-key';
import { kebabCase } from 'lodash';
import { RiArrowDropDownLine, RiArrowDropRightLine } from 'react-icons/ri';
import { useHasChanged } from 'utils/usePrevious';

export type RoutedTabProps = TabProps<typeof Link> & {
  route?: string;
  links?: RoutedTabProps[];
};

const DropdownMenu = ({ links, label, basePath = '' }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const buttonRef = React.useRef(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuId = `${kebabCase(label)}-dd-menu`;
  const id = `${kebabCase(label)}-dd-button`;
  const location = useLocation();

  useEffect(() => {
    setAnchorEl(null);
  }, [location.pathname]);

  return (
    <>
      <Tab
        id={id}
        label={label}
        aria-controls={menuId}
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
        icon={<RiArrowDropDownLine fontSize={25} />}
        iconPosition={'end'}
        sx={{
          p: 0,
        }}
      />

      <Menu
        id={menuId}
        anchorEl={document.getElementById(id)}
        open={!!anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              minWidth: buttonRef.current
                ? buttonRef.current.offsetWidth
                : undefined,
              maxWidth: 300,
            },
          },
        }}
      >
        {links
          .filter(item => !item.hidden)
          .map((item, index) => (
            <MenuItem
              key={key(item)}
              component={item.component ? item.component : Link}
              {...item}
              to={`${basePath}/${item.to}`}
            >
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export function useRoutedTabs(tabs: RoutedTabProps[] = [], basePath = '') {
  const location = useLocation();
  const theme = useTheme();

  const currentTabIndex = useMemo(() => {
    let index = 0;
    tabs
      .filter(t => !t.hidden)
      .forEach((t, i) => {
        const testArray = [t, ...(t.links || [])];

        testArray.forEach(ti => {
          const route = `${basePath}/${t.route || `${ti.to}/*`}`;
          if (matchPath(route, location.pathname)) {
            index = i;
          }
        });
      });
    return index;
  }, [location, tabs]);

  const tabsHasChanged = useHasChanged(tabs);

  const RoutedTabs = useCallback(
    () => (
      <Tabs
        variant={'scrollable'}
        value={currentTabIndex}
        sx={{
          '& .MuiTabs-flexContainer': {
            gap: 4,
            height: '100%',
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        {tabs
          .filter(t => !t.hidden)
          .map((t, index) => {
            const menuId = `${kebabCase(t.label.toString())}-dd-menu`;
            const id = `${kebabCase(t.label.toString())}-dd-button`;

            return t.links ? (
              <DropdownMenu
                links={t.links}
                label={t.label}
                key={'dd-menu-' + index}
                basePath={basePath}
              />
            ) : (
              <Tab
                key={key(t)}
                component={Link}
                {...t}
                to={`${basePath}/${t.to}`}
                sx={{
                  color: theme.palette.common.white + ' !important',
                  p: 0,
                }}
              />
            );
          })}
      </Tabs>
    ),
    [tabsHasChanged, currentTabIndex, basePath],
  );

  return { currentTabIndex, tabs, RoutedTabs };
}
