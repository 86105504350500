import { Box, Button, Stack } from '@mui/material';
import * as React from 'react';

interface Props {
  disabled?: boolean;
  canCancel?: boolean;
  onCancel: () => void;
  onSave?: () => void;
  saveIcon?: React.ReactNode;
  directionProp?: string;
  saveText?: string;
  cancelText?: string;
  size?: 'small' | 'medium' | 'large';
}

export function CancelAndSaveButtons(props: Props) {
  const {
    disabled,
    canCancel = true,
    onCancel,
    saveIcon,
    size = 'medium',
    cancelText = 'Cancel',
    saveText = 'Confirm',
    onSave,
  } = props;
  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Button
          size={size}
          variant={'outlined'}
          sx={{ mr: 'auto' }}
          onClick={onCancel}
          disabled={!canCancel}
        >
          {cancelText}
        </Button>
        <Button
          size={size}
          type={onSave ? 'button' : 'submit'}
          variant="contained"
          startIcon={saveIcon}
          onClick={onSave}
          disabled={disabled}
        >
          {saveText}
        </Button>
      </Stack>
    </>
  );
}
