/**
 *
 * DashboardActions
 *
 */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDashboardActionsWidgetSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboardActionsWidget } from './slice/selectors';
import { ActionStatus, IOrganisation } from 'types/types';
import { DashboardActionItem } from 'app/components/DashboardActionItem';
import {
  Box,
  Button,
  Fade,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DashboardCard } from 'app/components/DashboardCard';
import { ReactComponent as BoardIcon } from './assets/actions_icon.svg';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import key from 'weak-key';
import { OnClickAction, useOnClickActions } from 'utils/useOnlickActions';
import { RiCheckboxBlankLine } from 'react-icons/ri';
import { StatusChip } from 'app/pages/Dashboard/StatusChip';
import { RolloverMenu } from 'app/pages/Dashboard/RolloverMenu';
import { Link } from 'react-router-dom';

const completingDuration = 3000;

interface Props {
  organisation: IOrganisation;
  chip?: React.ReactNode;
}

export function DashboardActionsWidget(props: Props) {
  const { organisation, chip } = props;
  const [open, setOpen] = useState<string[]>([]);
  const { actions } = useDashboardActionsWidgetSlice();
  const { loadList } = useSelector(selectDashboardActionsWidget);
  const dispatch = useDispatch();

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const [allActions, setAllActions] = useState([]);
  const [completingActions, setCompletingActions] = useState([]);

  const { doAction } = useOnClickActions({ organisation_id: organisation.id });

  useEffect(() => {
    setAllActions(loadList.data || []);
  }, [loadList.data]);

  const handleOpen = (val) => {
    const newArr = [...open];
    const index = newArr.indexOf(val); 
  
    if (index !== -1) {
      newArr.splice(index, 1);
    } else if (newArr.length === 4) {
      newArr.shift();
    }
    newArr.push(val);
  
    setOpen(newArr);
  }

  const handleClose = (val) => {
    const newArr = [...open];
    const index = newArr.indexOf(val);
  
    if (index !== -1) {
      newArr.splice(index, 1);
      setOpen(newArr);
    }
  }
  
  
  const handleCheck = (id: number, subtask_id?: number) => {
    let status: ActionStatus = 'completed';
    if (subtask_id) {
      setAllActions(
        allActions.map(a => {
          if (a.id !== id) return a;
          const subtask_statuses = { ...(a.subtask_statuses || {}) };
          status =
            subtask_statuses[`subtask-${subtask_id}`] === 'completed'
              ? 'pending'
              : 'completed';
          subtask_statuses[`subtask-${subtask_id}`] = status;
          return { ...a, subtask_statuses };
        }),
      );

      dispatch(
        actions.updateActionRequest({
          action_id: id,
          subtask_id,
          status,
        }),
      );
      return;
    }

    // Mark as completing
    setCompletingActions(prev => [...prev, id]);
    dispatch(
      actions.updateActionRequest({ action_id: id, status: 'completed' }),
    );

    // Wait for animation to complete, then mark as complete
    setTimeout(() => {
      setAllActions(prevActions =>
        prevActions.map(a =>
          a.id === id ? { ...a, status: 'completed', completing: false } : a,
        ),
      );
      setCompletingActions(prev => prev.filter(actionId => actionId !== id));
    }, completingDuration);
  };

  const visibleActions = useMemo(() => {
    const itemsToShow = isXl ? 4 : isLg ? 3 : isSm ? 2 : 1;

    return allActions
      .filter(a => a.status !== 'completed')
      .slice(0, itemsToShow);
  }, [allActions, isSm, isMd, isLg, isXl]);

  const restorableActions = useMemo(() => {
    const itemsToShow = isXl ? 4 : isLg ? 3 : isSm ? 2 : 1;

    return allActions
      .filter(a => a.status === 'completed')
      .slice(0, itemsToShow);
  }, [allActions, isSm, isMd, isLg, isXl]);

  const handleRestore = id => {
    setAllActions(prevActions =>
      prevActions.map(a =>
        a.id === id ? { ...a, status: 'pending', subtask_statuses: {} } : a,
      ),
    );
    dispatch(
      actions.updateActionRequest({
        action_id: id,
        status: 'pending',
        clearSubtasks: true,
      }),
    );
  };

  const load = () =>
    dispatch(actions.loadListRequest({ organisation_id: organisation.id }));
  useEffect(() => {
    load();
  }, [actions, dispatch, organisation.id]);

  return (
    <DashboardCard icon={<BoardIcon />} title={'Actions'} chip={chip}>
      <LoadingIndicator showContent={loadList.touched} minHeight={300}>
        <Stack spacing={4}>
          <Typography>
            {!!visibleActions.length
              ? `This section keeps all of your key Actions organised so that you can achieve your goals - large and small.  To see the entire list of current Actions click below on "Browse all Actions".  There is also guidance on setting up new Actions and much more.`
              : 'You have no actions to complete'}
          </Typography>
          <Box>
            <Stack direction={'row'}>
              {visibleActions.map((a, i) => (
                <Fade
                  in={!completingActions.includes(a.id)}
                  timeout={{
                    appear: 1000 * i,
                    enter: 1000 * i,
                    exit: completingDuration * 0.8,
                  }}
                  key={`dashboard-widget-${a.id}`}
                >
                  <Box
                    sx={{
                      flexGrow: 0,
                      flexBasis: {
                        xs: '100%', // Takes full width on extra-small devices
                        sm: '50%', // Takes half width on small devices
                        lg: '33.33%', // Takes one-third on medium devices
                        xl: '25%', // Takes one-fourth on large devices
                      },
                    }}
                  >
                    <DashboardActionItem
                      action={a}
                      onCheck={subtask_id => handleCheck(a.id, subtask_id)}
                      open={open.some(id => id === a.id)}
                      toggleOpen={() => {
                        if(open.some(id => id === a.id)) {
                          return handleClose(a.id);
                        } else {
                          return handleOpen(a.id)
                        }
                      }}
                      completing={completingActions.includes(a.id)}
                      urlPrefix={'./actions/active/'}
                      onclickAction={doAction}
                    />
                  </Box>
                </Fade>
              ))}
            </Stack>
          </Box>
          <Box>
            <Stack>
              {restorableActions.map(a => (
                <Typography variant={'body2'}>
                  Completed {a.action_definition.name} -{' '}
                  <Box
                    sx={{
                      textDecoration: 'underline',
                      color: 'primary.main',
                      cursor: 'pointer',
                    }}
                    component={'span'}
                    onClick={() => handleRestore(a.id)}
                  >
                    Restore
                  </Box>
                </Typography>
              ))}
            </Stack>
          </Box>
          <Box>
            <Button variant="text" component={Link} to={`./actions/active`} sx={{px: 0}}>Browse all Actions</Button>
          </Box>
        </Stack>
      </LoadingIndicator>
    </DashboardCard>
  );
}
