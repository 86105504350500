import { Box, Button, Checkbox, Grid, Stack, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import React, { useState } from 'react';

interface Props {
  proceed: () => void;
}

export function BeforeYouBegin(props: Props) {
  const { proceed } = props;

  const { isValid } = useFormikContext();

  return (
    <>
      <Stack spacing={4}>
        <Typography>
          Before you begin to fill in this section, just to remind you that it
          is your duty as a Director of an Organisation to declare your own
          Interests. No one else can, or should take, responsibility for you.
          This is why this section for example is kept in 'My Profile' to help
          remind you of your duty to keep it, and your use of it, fresh in your
          mind. Things change, after all...!
        </Typography>
        <Typography>
          Second, we suggest you follow the guidance set out in your
          Organisation's policy on this topic. Your Administrator may well have
          added the relevant policy to Governance360 already, if not, then
          perhaps this might be something you suggest is added shortly to help
          you, and those that follow you?
        </Typography>
        <Typography>
          Now, with this guidance in mind, proceed to log your Declarations of
          Interest one by one. They will be captured, not only in Meeting pages
          within Governance360 moving ahead, but also by your Administrator when
          they report all declarations as a group.
        </Typography>

        {/*}
        <Box>
          <Stack spacing={2}>
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
              <Field
                name={'agreement1'}
                type={'checkbox'}
                component={CheckboxWithLabel}
                Label={{
                  label: (
                    <Typography fontWeight={'bold'}>
                      I understand ipsum totum fulcrum Declaration of Interest
                    </Typography>
                  ),
                }}
              />
            </Stack>
            <Stack spacing={1} direction={'row'} alignItems={'center'}>
              <Field
                name={'agreement2'}
                type={'checkbox'}
                component={CheckboxWithLabel}
                Label={{
                  label: (
                    <Typography fontWeight={'bold'}>
                      I understand my board chair will epsium epicanthus
                    </Typography>
                  ),
                }}
              />
            </Stack>
          </Stack>
        </Box>
        */}
      </Stack>
      <Box>
        <Grid container justifyContent={'flex-end'}>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              fullWidth
              endIcon={'>'}
              onClick={proceed}
              disabled={!isValid}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
