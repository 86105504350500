import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import { invitationFormWidgetActions as actions } from '.';
import { selectInvitationFormWidgetSettings } from './selectors';

function* init(action) {
  try {
    const settings = yield select(selectInvitationFormWidgetSettings);

    const roles = yield call(apiRequest, {
      url: `roles`,
      method: 'get',
      data: {
        context: 'organisation',
      },
    });

    const committees = yield call(apiRequest, {
      url: `committees`,
      method: 'get',
      data: {
        organisation_id: settings.organisation_id,
      },
    });

    yield put(
      actions.openSuccess({ committees: committees.data, roles: roles.data }),
    );
  } catch (e) {
    yield put(actions.openFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* loadInvitation(action) {
  try {
    const payload = yield call(apiRequest, {
      url: `invitations/${action.payload.id}/show`,
      method: 'get',
      data: {},
    });

    yield put(actions.loadInvitationSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.loadInvitationFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* saveInvitation(action) {
  try {
    const payload = yield call(apiRequest, {
      url: action.payload.id
        ? `invitations/${action.payload.id}/resend`
        : 'invitations/create',
      method: 'post',
      data: action.payload,
    });

    yield put(actions.saveInvitationSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.saveInvitationFailed(e.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* invitationFormWidgetSaga() {
  yield takeLatest(actions.openRequest.type, init);
  yield takeLatest(actions.loadInvitationRequest.type, loadInvitation);
  yield takeLatest(actions.saveInvitationRequest.type, saveInvitation);
}
