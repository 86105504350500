/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { throttle } from 'lodash';
import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license-pro';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { GlobalStyles } from './styles/GlobalStyles';
import { AuthProvider } from './app/providers/AuthProvider';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';
import { theme } from './styles/theme';
import { saveState } from './store/localStorage';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { LoggingProvider } from './app/providers/LoggingProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import HubspotScript from './app/widgets/HubspotScript';

const store = configureAppStore();

store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveState({});
  }, 2500),
);

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const router = createBrowserRouter(
  createRoutesFromElements(<Route path={'*'} element={<App />} />),
);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
};

if (!!tagManagerArgs.gtmId) TagManager.initialize(tagManagerArgs);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

ReactDOMClient.createRoot(MOUNT_NODE!).render(
  <Provider store={store}>
    <HubspotScript />
    <MuiThemeProvider theme={theme}>
      <ConfirmProvider>
        <GlobalStyles />
        <AuthProvider>
          <SnackbarProvider>
            <HelmetProvider>
              <React.StrictMode>
                <RouterProvider router={router} />
                <LoggingProvider />
              </React.StrictMode>
            </HelmetProvider>
          </SnackbarProvider>
        </AuthProvider>
      </ConfirmProvider>
    </MuiThemeProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
