import React, { useEffect } from 'react';
import { TextField } from '@mui/material';

interface Props {
  codeValue: string;
  onChange: (val: string) => void;
  onEnter: () => void;
  label?: string;
  title?: string;
  autoFocus?: boolean;
}

export function AuthInputField(props: Props) {
  const {
    codeValue = '',
    onChange,
    onEnter,
    title,
    label = 'Enter your passcode',
    autoFocus = true,
  } = props;

  const [value, setValue] = React.useState(codeValue);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && codeValue.length >= 6) {
      onEnter();
    }
  };

  const handleChange = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <TextField
      autoFocus={autoFocus}
      value={codeValue}
      title={title}
      sx={{
        my: '2',
        mx: '0 auto',
        '& input': {
          letterSpacing: '8px',
          fontSize: 18,
          fontWeight: 600,
        },
      }}
      onKeyDown={handleKeyDown}
      label={label}
      onChange={handleChange}
    />
  );
}
