import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import * as Yup from 'yup';
import { IconTitledContent } from 'app/components/IconTitledContent';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { ReactNode, useEffect } from 'react';
import { RiInformationLine, RiNewspaperLine } from 'react-icons/ri';

interface Props {
  onSubmit: (values) => void;
  sending?: boolean;
  reset?: boolean;
  title: ReactNode;
  width: 1 | 2 | 3 | 4 | 5 | 6;
  subtext: ReactNode;
}
const schema = Yup.object().shape({
  subject: Yup.string().required('This field is required'),
  description: Yup.string().required('This field is required'),
});

export function ContactForm(props: Props) {
  const { onSubmit, sending, reset, title, subtext, width } = props;

  const formikRef = React.useRef(null);
  useEffect(() => {
    if (reset && formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [reset]);
  return (
    <>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        initialValues={{ subject: '', description: '' }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(values);
        }}
        validateOnChange
      >
        {formik => {
          const { setSubmitting, isSubmitting, dirty } = formik;
          if (isSubmitting) setSubmitting(false);
          return (
            <Form>
              <Stack>
                <Typography variant="h3">{title}</Typography>

                <Typography>{subtext}</Typography>

                <Box>
                  <Stack spacing={3} sx={{ my: 3 }}>
                    <IconTitledContent
                      width={width}
                      icon={<RiNewspaperLine />}
                      title={'Subject'}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            id="subject"
                            name="subject"
                            label="Subject"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </IconTitledContent>
                    <IconTitledContent
                      width={width}
                      icon={<RiInformationLine />}
                      title={'Description'}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            id="description"
                            name="description"
                            multiline
                            rows={4}
                            label="Description"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </IconTitledContent>
                  </Stack>

                  <Stack
                    direction={'row'}
                    justifyContent={'flex-end'}
                    width={'100%'}
                  >
                    <Button
                      variant={'contained'}
                      type={'submit'}
                      disabled={!dirty || isSubmitting || sending}
                    >
                      Send
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
