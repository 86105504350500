import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import { loggingProviderActions as actions } from '.';
import { apiRequest } from 'utils/request';
import { selectLoggingProvider } from './selectors';

function* logUserActions() {
  try {
    const { userActionQueue } = yield select(selectLoggingProvider);
    const payload = yield call(apiRequest, {
      url: `log/user-actions`,
      method: 'post',
      data: {
        actions: userActionQueue.map(userAction => ({
          ...userAction,
        })),
      },
    });

    yield put(actions.logUserActionsSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.logUserActionsFailed(error.payload));
  } finally {
    if (yield cancelled()) {
    }
  }
}

export function* loggingProviderSaga() {
  yield takeLatest(actions.logUserActionsRequest.type, logUserActions);
}
