import {
  Box,
  BoxProps,
  Button,
  Grid,
  Stack,
  StackProps,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import key from 'weak-key';
import * as React from 'react';
import { ReactNode } from 'react';

interface Props {
  icon?: ReactNode;
  title: ReactNode;
  body?: ReactNode;
  stackProps?: StackProps;
  iconContainerProps?: BoxProps;
}

export function IconItem(props: Props) {
  const {
    icon,
    title,
    body,
    stackProps = {},
    iconContainerProps = {
      minWidth: 90,
      textAlign: 'center',
    },
  } = props;

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} {...stackProps}>
      <Box sx={{ ...iconContainerProps }}>{!!icon && icon}</Box>
      <Box>
        <Stack spacing={1}>
          {!!title && <Typography variant={'h2'}>{title}</Typography>}
          {!!body && <Typography component={'p'}>{body}</Typography>}
        </Stack>
      </Box>
    </Stack>
  );
}
