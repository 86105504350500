import moment from 'moment-timezone';

export function getDateWIthFallbackString(dateString: string, fallback = '') {
  try {
    const date = moment(dateString);
    if (!date.isValid()) {
      throw new Error('Invalid date');
    }
    return date.toDate();
  } catch (e) {
    return fallback;
  }
}
