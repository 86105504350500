/**
 *
 * RiskRegisterListView
 *
 */
import * as React from 'react';
import { useMemo, useState } from 'react';
import { IRiskArea, IRiskRegister, RiskAreaAction } from 'types/types';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { RiskAreaItem } from 'app/components/RiskAreaItem';
import { Box, Button, Stack } from '@mui/material';
import key from 'weak-key';
import { RiAddCircleFill, RiShareForward2Line } from 'react-icons/ri';
import { noop } from 'utils/noop';
import { useConfirm } from 'utils/useConfirm';

interface Props {
  riskRegister?: IRiskRegister;
  onRiskAreaAction: (action: RiskAreaAction) => void;
  saving?: boolean;
  onSave?: (values: any) => void;
  openRiskAreaForm: (risk_area?: IRiskArea) => void;
  canManage?: boolean;
  canExport?: boolean;
  memberNames?: string[];
  saveExport?: () => void;
}

export function RiskRegisterListView(props: Props) {
  const {
    riskRegister,
    saving = false,
    onSave,
    onRiskAreaAction,
    openRiskAreaForm,
    canManage,
    canExport,
    memberNames = [],
    saveExport = noop,
  } = props;
  const [openItemId, setOpenItemId] = useState<number>();

  const navigate = useNavigate();

  const confirm = useConfirm();

  const areas: Array<IRiskArea> = useMemo(() => {
    if (!riskRegister) return [];

    return riskRegister.risk_areas.map(area => {
      const items = riskRegister.items
        .filter(item => !item.is_archived)
        .filter(item => item.risk_area_code === area.code)
        .map(item => (item.id === openItemId ? { ...item, open: true } : item))
        .sort((a, b) => {
          // Compare pinned status
          if (a.pinned && !b.pinned) return -1;
          if (!a.pinned && b.pinned) return 1;

          // Calculate the scores
          const scoreA = a.score_impact_mitigated * a.score_likelihood;
          const scoreB = b.score_impact_mitigated * b.score_likelihood;

          // Compare scores
          return scoreB - scoreA;
        });
      return {
        ...area,
        items,
      };
    });
    // .filter(area => area.items.length > 0);
  }, [riskRegister, openItemId]);

  if (!riskRegister) return null;

  return (
    <Stack>
      {!!canManage && (
        <Box>
          <Stack direction={{xs:'column', sm: 'row'}} spacing={1} justifyContent={'end'}>
            {/* <Button
              variant="outlined"
              startIcon={<RiAddCircleFill />}
              onClick={() => openRiskAreaForm()}
            >
              Add new category
            </Button> */}
            {canManage && (
              <Button
                startIcon={<RiAddCircleFill />}
                variant="outlined"
                component={Link}
                to={'../add'}
              >
                Add new risk
              </Button>
            )}
            {!!canExport && (
              <Button
                variant="outlined"
                startIcon={<RiShareForward2Line />}
                disabled={!areas.some(a => a.items.length)}
                onClick={() =>
                  confirm({
                    title: 'Export risk register to Excel',
                    allowClose: true,
                  })
                    .then(saveExport)
                    .catch(noop)
                }
              >
                Export, share & print
              </Button>
            )}
            {/*
             */}
          </Stack>
        </Box>
      )}
      <Box>
        <Stack spacing={6}>
          {areas.map(a => (
            <Box key={key(a)}>
              <RiskAreaItem
                risk_areas={riskRegister.risk_areas}
                onRename={() => openRiskAreaForm(a)}
                onRiskAreaAction={onRiskAreaAction}
                memberNames={memberNames}
                canManage={canManage}
                saving={saving}
                onSave={onSave}
                riskArea={a}
                toggleOpen={id =>
                  setOpenItemId(openId => (openId === id ? null : id))
                }
                openItems={openItemId ? [openItemId] : []}
                onAddNew={() =>
                  navigate({
                    pathname: `../add`,
                    search: createSearchParams({
                      risk_area_code: a.code,
                    }).toString(),
                  })
                }
              />
            </Box>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}
