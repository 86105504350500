import { useSelector } from 'react-redux';
import { selectOrganisationPage } from './slice/selectors';
import { Feature, FeatureCode } from 'types/types';
import { useMemo } from 'react';

export function useGetCurrentFeature<T extends FeatureCode>(
  code: T,
): { feature: Feature<T> | undefined } {
  const { loadOrganisation } = useSelector(selectOrganisationPage);

  const feature = useMemo(() => {
    if (!loadOrganisation.data) return undefined;
    return loadOrganisation.data.features[code] as Feature<T>;
  }, [loadOrganisation.data]);

  return { feature };
}
