import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { DocumentsState } from './types';
import { IDocument } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';
import {
  apiCallInitialState,
  getApiCallInitialState,
  ResultMetaData,
} from 'types/ApiCall';

export const initialState: DocumentsState = {
  loadDocuments: getApiCallInitialState<IDocument[]>('list'),
  loadTemplates: getApiCallInitialState<IDocument[]>('list'),
  loadExpiringDocuments: getApiCallInitialState<IDocument[]>('list'),
  loadDocumentCategories: getApiCallInitialState<
    {
      label: string;
      value: string;
    }[]
  >('list'),
};

export const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    init(state, action: PayloadAction<void>) {
      state.loadDocuments = initialState.loadDocuments;
      state.loadTemplates = initialState.loadTemplates;
    },
    loadDocumentsRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.loadDocuments.loading = true;
      state.loadDocuments.error = undefined;
      state.loadDocuments.data = [];
    },
    loadDocumentsSuccess(
      state,
      action: PayloadAction<{ data: IDocument[]; meta: ResultMetaData }>,
    ) {
      state.loadDocuments.loading = false;
      state.loadDocuments.error = undefined;
      state.loadDocuments.data = action.payload.data;
      state.loadDocuments.meta = action.payload.meta;
    },
    loadDocumentsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadDocuments.loading = false;
      state.loadDocuments.error = action.payload;
    },
    loadTemplatesRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.loadTemplates.loading = true;
      state.loadTemplates.error = undefined;
    },
    loadTemplatesSuccess(state, action: PayloadAction<{ data: IDocument[] }>) {
      state.loadTemplates.loading = false;
      state.loadTemplates.error = undefined;
      state.loadTemplates.data = action.payload.data;
    },
    loadTemplatesFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadTemplates.loading = false;
      state.loadTemplates.error = action.payload;
    },

    loadDocumentCategoriesRequest(
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) {
      state.loadDocumentCategories.loading = true;
      state.loadDocumentCategories.error = undefined;
    },
    loadDocumentCategoriesSuccess(
      state,
      action: PayloadAction<{
        data: { label: string; value: string }[];
      }>,
    ) {
      state.loadDocumentCategories.loading = false;
      state.loadDocumentCategories.error = undefined;
      state.loadDocumentCategories.data = action.payload.data;
    },
    loadDocumentCategoriesFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadDocumentCategories.loading = false;
      state.loadDocumentCategories.error = action.payload;
    },
    loadExpiringDocumentsRequest(
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) {
      state.loadExpiringDocuments.loading = true;
      state.loadExpiringDocuments.error = undefined;
    },
    loadExpiringDocumentsSuccess(
      state,
      action: PayloadAction<{ data: IDocument[]; meta: ResultMetaData }>,
    ) {
      state.loadExpiringDocuments.loading = false;
      state.loadExpiringDocuments.error = undefined;
      state.loadExpiringDocuments.data = action.payload.data;
      state.loadExpiringDocuments.meta = action.payload.meta;
    },
    loadExpiringDocumentsFailed(
      state,
      action: PayloadAction<ResponseErrorPayload>,
    ) {
      state.loadExpiringDocuments.loading = false;
      state.loadExpiringDocuments.error = action.payload;
    },
  },
});

export const { actions: documentsActions } = slice;
