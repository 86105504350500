/**
 *
 * Past Meetings
 *
 */
import React, { useCallback, useState } from 'react';
import {
  Box,
  ButtonBase,
  Card,
  Chip,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  MeetingFormDialog,
  useMeetingFormWidget,
} from 'app/widgets/MeetingFormWidget';
import { useDispatch, useSelector } from 'react-redux';
import { useMeetingsSlice } from './slice/hook';
import { selectLoadMeetings } from './slice/selectors';
import { IOrganisation } from 'types/types';
import { useEffectOnce } from 'utils/useEffectOnce';
import { Link, useNavigate } from 'react-router-dom';
import key from 'weak-key';
import moment from 'moment-timezone';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';

interface Props {
  organisation: IOrganisation;
}

export function Past(props: Props) {
  const [, setInitialised] = useState(false);
  const { organisation } = props;
  const { actions } = useMeetingsSlice();
  const loadMeetings = useSelector(selectLoadMeetings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { close, isOpen } = useMeetingFormWidget();

  const load = useCallback(() => {
    dispatch(actions.loadMeetingsRequest({ organisation_id: organisation.id }));
  }, [organisation]);

  useHandleApiResponse(loadMeetings, null, {
    onError: () => {
      if (loadMeetings.error?.code === 401) {
        // todo - redirect away from page (and log?)
        return;
      }
    },
    onSuccess: () => {
      setInitialised(true);
    },
  });

  useEffectOnChange(() => {
    load();
  }, organisation.id);

  useEffectOnce(() => {
    load();
  });

  const canCreateMeetings = true;

  const pastMeetings = loadMeetings.data.filter(meeting =>
    moment(meeting.date_start).isBefore(moment()),
  );

  return (
    <>
      {canCreateMeetings && (
        <>
          <MeetingFormDialog
            open={isOpen}
            onClose={close}
            onChange={meeting =>
              navigate(
                `/organisation/${organisation.id}/meetings/${meeting.id}`,
              )
            }
          />
        </>
      )}
      <Box
        sx={{
          my: 4,
        }}
      >
        <Stack>
          <Typography variant="h2">Past Meetings</Typography>
          {!pastMeetings.length && (
            <Typography>You have no past meetings.</Typography>
          )}
        </Stack>

        {!!pastMeetings.length && (
          <Stack spacing={6}>
            <Box>
              <Grid container spacing={4}>
                {pastMeetings.map(meeting => (
                  <Grid key={key(meeting)} item xs={12} md={6} lg={4}>
                    <ButtonBase
                      component={Link}
                      to={`/organisation/${meeting.organisation_id}/meetings/${meeting.id}`}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Card
                        variant="outlined"
                        sx={{ p: 2, minHeight: 180, width: '100%' }}
                        square
                      >
                        <Stack
                          spacing={2}
                          height={'100%'}
                          justifyContent={'space-between'}
                        >
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={2}
                          >
                            <Typography fontWeight={'bold'}>
                              {moment(meeting.date_start).format(
                                'ddd DD MMM YYYY',
                              )}
                            </Typography>
                            {!meeting.published_at && (
                              <Chip color="warning" label="Draft" />
                            )}
                          </Stack>
                          <Typography fontWeight={'bold'}>
                            {moment(meeting.date_start).format('HH:mm')} -{' '}
                            {moment(meeting.date_end).format('HH:mm')}
                          </Typography>
                          <Typography fontWeight={'bold'} variant="h3">
                            {meeting.name}
                          </Typography>
                          <Typography color={'primary.main'} variant="body2">
                            See meeting details
                          </Typography>
                        </Stack>
                      </Card>
                    </ButtonBase>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        )}
      </Box>
    </>
  );
}
