/**
 *
 * CommitteeMemberForm
 *
 */
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { CommitteeMemberForm } from './CommitteeMemberForm';
import { useCommitteeMemberFormWidgetSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommitteeMemberFormWidget } from './slice/selectors';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { ICommitteeMember } from 'types/types';
import { useHasChanged } from 'utils/usePrevious';
import { useSnackbar } from 'notistack';
import { useEffectOnce } from 'utils/useEffectOnce';
import { noop } from 'utils/noop';

interface Props {
  onChange?: (payload: ICommitteeMember) => void;
}

export function CommitteeMemberFormWidget(props: Props) {
  const { onChange = noop } = props;
  const { actions } = useCommitteeMemberFormWidgetSlice();
  const dispatch = useDispatch();
  const {
    form: {
      open,
      loading,
      id,
      members,
      roles,
      committee,
      organisation_id,
      committee_id,
    },
    loadCommitteeMember,
    saveCommitteeMember,
    deleteCommitteeMember,
  } = useSelector(selectCommitteeMemberFormWidget);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const load = useCallback(() => {
    if (id) {
      dispatch(actions.loadCommitteeMemberRequest({ id }));
    }
  }, [id]);

  const snackbar = useSnackbar();
  const savingChanged = useHasChanged(saveCommitteeMember.loading);
  const deletingChanged = useHasChanged(deleteCommitteeMember.loading);

  useEffect(() => {
    if (savingChanged && !saveCommitteeMember.loading) {
      if (saveCommitteeMember.error) {
        if (saveCommitteeMember.error.code === 401) return;
        snackbar.enqueueSnackbar('Error saving.', { variant: 'error' });
      } else {
        onChange(saveCommitteeMember.data);
        snackbar.enqueueSnackbar('Saved.', { variant: 'success' });
        close();
      }
    }

    if (deletingChanged && !deleteCommitteeMember.loading) {
      if (deleteCommitteeMember.error) {
        if (deleteCommitteeMember.error.code === 401) return;
        snackbar.enqueueSnackbar('Error deleting.', { variant: 'error' });
      } else {
        onChange(deleteCommitteeMember.data);
        snackbar.enqueueSnackbar('Deleted.', { variant: 'success' });
        close();
      }
    }
  });

  useEffect(() => {
    if (open && id) load();
  }, [open]);

  useEffect(
    () => () => {
      dispatch(actions.close());
    },
    [dispatch],
  );

  useEffectOnce(() => {
    snackbar.closeSnackbar();
    return () => {
      snackbar.closeSnackbar();
    };
  });

  const close = () => dispatch(actions.close());

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      fullWidth
      fullScreen={fullScreen}
      scroll={'body'}
      onClose={close}
    >
      {loading || loadCommitteeMember.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <CommitteeMemberForm
            onClose={close}
            committeeMember={loadCommitteeMember.data}
            roles={roles}
            members={members}
            committee={committee}
            onSave={payload =>
              dispatch(
                actions.saveCommitteeMemberRequest({
                  organisation_id,
                  committee_id,
                  ...payload,
                }),
              )
            }
            saving={saveCommitteeMember.loading}
          />
        </>
      )}
    </Dialog>
  );
}

export function useCommitteeMemberFormWidget() {
  const { actions } = useCommitteeMemberFormWidgetSlice();
  const dispatch = useDispatch();
  const committeeMemberForm = useSelector(selectCommitteeMemberFormWidget);

  const remove = (payload: { id: number | string }) =>
    dispatch(actions.deleteCommitteeMemberRequest(payload));

  const open = payload => {
    dispatch(actions.openRequest(payload));
  };
  const close = () => dispatch(actions.close());

  return { open, close, remove, isOpen: committeeMemberForm.form.open };
}
