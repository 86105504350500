import React from 'react';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

interface Props extends Omit<MuiTelInputProps, 'onChange'> {
  label?: string;
  onChange: (value: string) => void;
}

export const TelephoneField = (props: Props) => {
  const { onChange, label = 'Enter phone number', ...rProps } = props;

  return (
    <MuiTelInput
      forceCallingCode
      focusOnSelectCountry
      defaultCountry={'GB'}
      onChange={(v, i) => {
        let value = v;

        // Check for a double zero directly after the first space
        if (v.match(/^(\S+ )00/) && i.numberValue.length > 8) {
          value = value
            .replace(/^(\S+ )00/, '$1+') // Replace "00" after the first space with a "+"
            .replace(/.*\+/, '+'); // Remove any characters before the new "+" sign
        }

        // Check for a single zero directly after the first space
        if (value.match(/^(\S+ )0/) && i.numberValue.length > 8) {
          value = value
            .replace(/^(\S+ )0/, '$1') // Remove "0" after the first space
            .replace(/.*\+/, '+'); // Remove any characters before the "+" if it exists
        }

        onChange(value);
      }}
      label={label}
      fullWidth
      {...rProps}
    />
  );
};
