/**
 *
 * Documents
 *
 */
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentsSlice } from './slice/hook';
import { IOrganisation } from 'types/types';
import { selectDocuments } from './slice/selectors';
import { useEffectOnce } from 'utils/useEffectOnce';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Helmet } from 'react-helmet-async';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useConfirm } from 'utils/useConfirm';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Filename } from '../../components/Filename';
import { openFile } from '../../../utils/request';

interface Props {
  organisation: IOrganisation;
}
const MenuButton = ({ sx = null, ...rProps }) => (
  <Button
    fullWidth
    {...rProps}
    variant="outlined"
    sx={{
      ...(sx ? sx : {}),
    }}
  />
);

export function Templates(props: Props) {
  const { organisation } = props;
  const { loadTemplates } = useSelector(selectDocuments);
  const { actions } = useDocumentsSlice();
  const dispatch = useDispatch();

  const confirm = useConfirm();

  const { hasPermission } = useHasPermission();

  const canViewTemplates = hasPermission(
    'view-organisation-plan-templates',
    'organisation',
    organisation.id,
  );

  const buttonWidth = 150;

  const canManage = hasPermission(
    'manage-organisation-documents',
    'organisation',
    organisation.id,
  );

  useEffectOnce(() => {
    if (organisation) load();
  });

  const load = useCallback(() => {
    if (canViewTemplates) {
      dispatch(
        actions.loadTemplatesRequest({
          organisation_id: organisation.id,
        }),
      );
    } else {
      alert('no');
    }
  }, [organisation]);

  const rows = useMemo(() => {
    return loadTemplates.data.map(d => ({
      id: d.id,
      name: d.name,
      description: d.description,
      ...d.file_details,
    }));
  }, [loadTemplates.data]);

  const downloadable = hasPermission(
    'download-organisation-documents',
    'organisation',
    organisation.id,
  );

  if (!loadTemplates.data.length && loadTemplates.loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet title={'Organisation templates'}>
        <meta
          name="description"
          content="Template documents for the organisation"
        />
      </Helmet>

      <Stack mt={4} spacing={4}>
        {!!canViewTemplates && !!loadTemplates.data.length && (
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography variant={'h3'}>Templates</Typography>
              <Typography>
                This section holds sample Templates provided to you as an
                Administrator (board members without Admin rights do not see
                this section). Documents are provided for your use (feel free to
                download and adopt them if they are useful to you), and vary
                depending on the plan you are on.
              </Typography>
            </Stack>

            {loadTemplates.data?.length ? (
              <DataGridPro
                autoHeight
                getRowHeight={() => 80}
                slots={{
                  columnHeaders: React.forwardRef((props, ref) => (
                    <Box ref={ref} />
                  )),
                  noRowsOverlay: () => (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                        }}
                      >
                        <Typography>No documents attached</Typography>
                      </Box>
                    </>
                  ),
                }}
                hideFooter
                columns={[
                  {
                    field: 'name',
                    headerName: 'Name',
                    minWidth: 200,
                    flex: 0.8,
                    sortable: false,
                    renderCell: ({ row }) => (
                      <Box>
                        <Stack spacing={1}>
                          <Filename
                            sx={{
                              cursor: downloadable ? 'pointer' : 'unset',
                              pointerEvents: row.filename ? 'auto' : 'none',
                              '& .MuiTypography-body1': {
                                fontWeight: 'bold',
                              },
                            }}
                            name={row.name || '[Name not set]'}
                            extension={row.extension || 'bin'}
                            onClick={ev => {
                              if (!downloadable) return;
                              ev.nativeEvent.stopImmediatePropagation();
                              openFile(row.url);
                            }}
                          />
                          {!!row.description && (
                            <Typography variant={'body2'}>
                              {row.description}
                            </Typography>
                          )}
                        </Stack>
                      </Box>
                    ),
                  },
                ]}
                rows={rows}
              />
            ) : (
              <></>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
}

/*
<Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={2}>
            <Button variant="text" startIcon={<RiFile3Line />}>
              All
            </Button>
            <Button variant="text" startIcon={<RiBookOpenLine />}>
              Reference
            </Button>
            <Button
              variant="text"
              startIcon={<RiFileSearchLine />}
              component={Link}
              to="../policies"
            >
              Policies
            </Button>
            <Button variant="text" startIcon={<HandShakeIcon />}>
              Induction
            </Button>
          </Stack>
          <Hidden lgDown>
            <Stack direction={'row'} spacing={2}>
              <Button variant="text">Most recent</Button>
              <Button variant="text">Most Downloaded</Button>
              <Button variant="text">By Type</Button>
              <Button
                variant="outlined"
                startIcon={<RiSearchLine />}
                size={'small'}
                onClick={() => alert('Search')}
                sx={{ '& .MuiButton-startIcon': { mx: 0 } }}
              />
            </Stack>
          </Hidden>
        </Stack>
        <Hidden lgUp>
          <Stack direction={'row'}>
            <MenuButton
              onClick={handleClick}
              startIcon={<RiFilter3Line />}
              aria-controls={openAnchor ? 'documents-filter-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openAnchor ? 'true' : undefined}
              sx={{ width: buttonWidth }}
            >
              All filters
            </MenuButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openAnchor}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    width: buttonWidth,
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      // left: 'calc(75% - 5px)',
                      width: 10,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem component={Link} to={'/'}>
                Reference
              </MenuItem>
              <MenuItem component={Link} to={'/policies'}>
                Policies
              </MenuItem>
              <MenuItem component={Link} to={'/'}>
                Induction
              </MenuItem>
            </Menu>
            <Button
              variant="outlined"
              startIcon={<RiSearchLine />}
              size={'small'}
              onClick={() => alert('Search')}
              sx={{ '& .MuiButton-startIcon': { mx: 0 } }}
            />
          </Stack>
        </Hidden>
 */

/* <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Stack
                direction={'row'}
                spacing={3}
                alignItems={'center'}
                sx={{
                  backgroundColor: theme => theme.palette.grey[100],
                  pr: 2,
                  border: `3px solid ${theme.palette.grey[300]}`,
                }}
              >
                <Box sx={{ height: 80, width: 80 }}>
                  <CardMedia
                    component={'img'}
                    src={VideoImage}
                    alt="Declarations video"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Stack spacing={1}>
                    <Typography variant="h5" color={'primary'}>
                      Managing your Documents
                    </Typography>
                    <Typography variant="body2">
                      Get to know the Documents section here
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                direction={'row'}
                spacing={3}
                alignItems={'center'}
                sx={{
                  backgroundColor: theme => theme.palette.grey[100],
                  pr: 2,
                  border: `3px solid ${theme.palette.grey[300]}`,
                }}
              >
                <Box sx={{ height: 80, width: 80 }}>
                  <CardMedia
                    component={'img'}
                    src={VideoImage}
                    alt="Declarations video"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Stack spacing={1}>
                    <Typography variant="h5" color={'primary'}>
                      Sorting, filtering and finding specific documents
                    </Typography>
                    <Typography variant="body2">
                      Find anything fast with our easy to use filters
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid> */
