import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { RiskLevelSelector } from '../../RiskLevelSelector';
import { IRiskRegisterItem } from 'types/types';
import { ReviewAndSubmitEditableRow } from 'app/components/ReviewAndSubmitEditableRow';

interface Props {
  changeStep: (step: string) => void;
}

export function ReviewAndSubmit(props: Props) {
  const { changeStep } = props;
  const [isEditableMode, setIsEditableMode] = useState<string>('');

  return (
    <>
      <Box>
        <Stack spacing={1}>
          <Typography variant="h1">Review and submit</Typography>
        </Stack>
      </Box>
      <Typography>
        Check that your wording is correct before you add this risk to the Risk
        Register.
      </Typography>

      <ReviewAndSubmitEditableRow
        title="What is the risk"
        name="name"
        onEdit={() => changeStep('risk-name')}
      />
      <ReviewAndSubmitEditableRow
        title="Impact"
        name="impact_text"
        onEdit={() => changeStep('risk-impact')}
      />
      <ReviewAndSubmitEditableRow
        title="Control measures"
        name="control_measures_text"
        onEdit={() => changeStep('control-measures')}
      />
      <ReviewAndSubmitEditableRow
        title="Risk owner"
        name="owner_name"
        onEdit={() => changeStep('risk-name')}
      />

      <Box>
        <Typography py={1} variant="h3">
          Likelihood
        </Typography>
        <RiskLevelSelector name="score_likelihood" fullWidth={true} />
      </Box>
      <Box>
        <Typography py={1} variant="h3">
          Impact before controls
        </Typography>

        <RiskLevelSelector name="score_impact" fullWidth={true} />
      </Box>

      <Box>
        <Stack spacing={2}>
          <Box>
            <Typography py={1} variant="h3">
              Impact after controls
            </Typography>
            <RiskLevelSelector name="score_impact_mitigated" fullWidth={true} />
          </Box>
        </Stack>
      </Box>
    </>
  );
}
