import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Button,
  Box,
  Typography,
} from '@mui/material';
import {
  RiArrowLeftCircleFill,
  RiInformationFill,
  RiShareForward2Line,
} from 'react-icons/ri';
import { RiskWizard } from 'app/components/RiskWizard';
import { Link, useLocation, useNavigation } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  IRiskArea,
  IRiskRegister,
  IRiskRegisterItem,
  IRiskSuggestion,
} from 'types/types';
import { useQuery } from 'utils/useQuery';

interface Props {
  risk_register: IRiskRegister;
  saving: boolean;
  onSave: (values: IRiskRegisterItem) => void;
  return_path?: string;
  openRiskAreaForm: (risk_area?: IRiskArea) => void;
  suggestions?: IRiskSuggestion[];
  memberNames?: string[];
  defaultMemberName?: string;
}

export function AddNewRisk(props: Props) {
  const {
    risk_register,
    saving,
    onSave,
    openRiskAreaForm,
    memberNames = [],
    defaultMemberName = '',
  } = props;

  const {
    query: { risk_area_code = '' },
  } = useQuery();

  const state = useLocation().state as { template?: IRiskRegisterItem };

  const template = state ? state.template : undefined;

  if (!risk_register) {
    return null;
  }

  return (
    <Stack>
      <Box>
        <Button
          startIcon={<RiArrowLeftCircleFill />}
          variant="text"
          component={Link}
          to={'../active'}
        >
          Back to risk register
        </Button>
      </Box>
      <RiskWizard
        openRiskAreaForm={openRiskAreaForm}
        onSave={onSave}
        saving={saving}
        template={template}
        risk_area_code={risk_area_code}
        risk_areas={risk_register.risk_areas}
        memberNames={memberNames}
        defaultMemberName={defaultMemberName}
      />
    </Stack>
  );
}
