/**
 *
 * Meeting Card
 *
 */
import React, { ReactNode, useMemo } from 'react';
import {
  Box,
  BoxProps,
  ButtonBase,
  Card,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IAbridgedMeeting } from 'types/types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

interface Props extends BoxProps {
  meeting: IAbridgedMeeting;
  subtitleType?: 'committee' | 'organisation';
  links?: ReactNode;
}

export function MeetingCard(props: Props) {
  const { meeting, links, sx } = props;

  const [colour, label] = useMemo(() => {
    const now = moment();
    const isOccurringNow = now.isBetween(meeting.date_start, meeting.date_end);
    const meetingDaysToGo = moment(meeting.date_end).diff(now, 'days');
    const withinTheWeek =
      (meetingDaysToGo <= 7 && meetingDaysToGo >= 0) || isOccurringNow;
    const isPast = moment(meeting.date_end).isBefore(now);
    const capitalisedLabel = (date: string) => {
      const fromNow = moment(date).fromNow();
      return fromNow.charAt(0).toUpperCase() + fromNow.slice(1);
    };

    if (isPast) {
      return ['common.brown', capitalisedLabel(meeting.date_end)];
    }

    if (isOccurringNow) {
      return ['error.main', 'Occurring now'];
    }

    const c = withinTheWeek
      ? 'error.main'
      : meetingDaysToGo <= 30
      ? 'warning.main'
      : 'success.main';
    return [c, capitalisedLabel(meeting.date_start)];
  }, [meeting.date_end, meeting.date_start]);

  const theme = useTheme();
  const cardHeight = 260;
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <ButtonBase
      component={Link}
      sx={{ width: '100%' }}
      to={`/organisation/${meeting.organisation_id}/meetings/${meeting.id}`}
    >
      <Card
        variant="outlined"
        square
        sx={{
          p: 2,
          width: '100%',
          height: cardHeight,
          ...sx,
        }}
      >
        <Stack height={'100%'} justifyContent={'space-between'}>
          <Stack spacing={1}>
            <Box>
              <Stack
                direction={'row'}
                spacing={1}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Chip
                  sx={{ backgroundColor: colour, color: 'common.white' }}
                  label={label}
                />
                {!meeting.published_at && (
                  <Chip color="warning" label="Draft" />
                )}
              </Stack>
            </Box>
            <Box>
              <Stack direction={{ xs: 'column', md: 'row' }}>
                <Typography sx={{ fontWeight: 600 }}>
                  {moment(meeting.date_start).format('ddd DD MMM YYYY')}
                </Typography>
                {isMd && (
                  <Typography
                    sx={{ fontWeight: 600, '&::before': { content: '"•"' } }}
                  />
                )}
                <Typography sx={{ fontWeight: 600 }}>
                  {moment(meeting.date_start).format('HH:mm')} -{' '}
                  {moment(meeting.date_end).format('HH:mm')}
                </Typography>
              </Stack>
            </Box>
            <Typography variant="h3">{meeting.name}</Typography>
            <Typography color={'primary.main'} variant="body2">
              See meeting details
            </Typography>
            {links}
          </Stack>
          <Box alignContent={'end'}>
            <Stack spacing={1}>
              <Typography>Committee</Typography>
              <Typography fontWeight={'bold'}>
                {meeting.committee.name}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Card>
    </ButtonBase>
  );
}
