/**
 *
 * Documents
 *
 */
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { DocumentsTable } from 'app/components/DocumentsTable';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentsSlice } from './slice/hook';
import { IOrganisation } from 'types/types';
import { selectDocuments } from './slice/selectors';
import { useEffectOnce } from 'utils/useEffectOnce';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Helmet } from 'react-helmet-async';
import { useHasPermission } from 'app/providers/AuthProvider/useHasPermission';
import { useConfirm } from 'utils/useConfirm';
import { useDocumentFormWidget } from 'app/widgets/DocumentFormWidget/useDocumentFormWidget';
import { RiAddCircleFill } from 'react-icons/ri';
import { useParams } from 'react-router';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';
import { useNavigate } from 'react-router-dom';
import { documentFormWidgetActions } from '../../widgets/DocumentFormWidget/slice';
import useHandleApiResponse from '../../../utils/useHandleApiResponse';
import { selectDocumentFormWidget } from '../../widgets/DocumentFormWidget/slice/selectors';
import { DocumentFormWidget } from '../../widgets/DocumentFormWidget';

interface Props {
  organisation: IOrganisation;
}

const MenuButton = ({ sx = null, ...rProps }) => (
  <Button
    fullWidth
    {...rProps}
    variant="outlined"
    sx={{
      ...(sx ? sx : {}),
    }}
  />
);

const categories = {
  reference: {
    title: 'Reference',
    description: `This section holds Reference Documents uploaded by your Organisation’s 
    Administrator.  Typical examples often include Articles of Association, Governing 
    Documents, Business Plan and more.  The choice is up to you and your 
    Administrator.`,
    codes: ['reference'],
  },
  policies: {
    title: 'Policies',
    description: `This section holds Policy Documents uploaded by your Organisation’s 
      Administrator.  Typical examples often include Safeguarding policies, Health & 
      Safety policies and key insurance policies.  The choice is up to you and your 
      Administrator.`,
    codes: ['policies'],
  },
  induction: {
    title: 'Induction',
    description: `This section holds Induction Documents uploaded by your Organisation’s 
      Administrator, i.e. the initial reading materials you provide to any new member 
      of your Board as part of their Induction.  The choice is up to you and your 
      Administrator.`,
    codes: ['induction'],
  },
};

export function Documents(props: Props) {
  const { organisation } = props;
  const { category } = useParams<{ category: string }>();
  const { loadDocuments, loadTemplates, loadDocumentCategories } =
    useSelector(selectDocuments);
  const { deleteDocument, saveDocument } = useSelector(
    selectDocumentFormWidget,
  );
  const { actions } = useDocumentsSlice();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasPermission } = useHasPermission();
  const { open, close } = useDocumentFormWidget();

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 20,
  });

  const canManage = hasPermission(
    'manage-organisation-documents',
    'organisation',
    organisation.id,
  );

  useEffect(() => {
    if (!categories[category]) {
      navigate(`/organisation/${organisation.id}/documents/reference`);
      return;
    }
  });

  useEffectOnce(() => {
    load();
    return () => {
      dispatch(actions.init());
    };
  });

  useEffectOnChange(() => {
    setPaginationModel({ ...paginationModel, page: 1 });
    load();
  }, category);

  useEffectOnChange(() => {
    load();
  }, paginationModel);

  const load = useCallback(() => {
    if (organisation && categories[category])
      dispatch(
        actions.loadDocumentsRequest({
          parent_entity_type: 'organisation',
          parent_entity_id: organisation.id,
          categories: category ? categories[category]?.codes : undefined,
          'page[size]': paginationModel.pageSize,
          'page[number]': paginationModel.page,
        }),
      );
  }, [organisation, category, paginationModel]);

  useHandleApiResponse(deleteDocument, null, {
    onSuccess: () => {
      load();
    },
  });

  useHandleApiResponse(saveDocument, null, {
    onSuccess: () => {
      load();
    },
  });

  if (!loadDocuments.data.length && loadDocuments.loading) {
    return <LoadingIndicator />;
  }

  const downloadable = hasPermission(
    'download-organisation-documents',
    'organisation',
    organisation.id,
  );

  return (
    <>
      <Helmet title={'Organisation documents'}>
        <meta name="description" content="Documents for the organisation" />
      </Helmet>

      <Stack mt={4} spacing={4}>
        <Typography variant="h3">{categories[category]?.title}</Typography>
        <Box>
          <Grid container alignItems={'center'}>
            <Grid item xs={12} md={canManage ? 6 : 12}>
              <Typography>{categories[category]?.description}</Typography>
            </Grid>
            {canManage && (
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    border: 2,
                    borderColor: 'grey.200',
                    borderStyle: 'dashed',
                    borderRadius: 3,
                    backgroundColor: 'common.beige',
                    p: 4,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    open({
                      parent_entity_id: organisation.id,
                      parent_entity_type: 'organisation',
                    })
                  }
                >
                  <Stack spacing={1} alignItems={'center'}>
                    <Button startIcon={<RiAddCircleFill />}>
                      Select a Document to upload
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box>
          <DocumentsTable
            meta={loadDocuments.meta}
            onPaginationModelChange={({ page, pageSize }) => {
              setPaginationModel({ page: page + 1, pageSize });
            }}
            maxHeight={'max(70vh, 300px)'}
            documents={loadDocuments.data}
            editable={canManage}
            downloadable={hasPermission(
              'download-organisation-documents',
              'organisation',
              organisation.id,
            )}
            onRemove={id => {
              confirm({
                title: 'Delete document',
                description: 'Are you sure you want to delete this document?',
              })
                .then(() =>
                  dispatch(
                    documentFormWidgetActions.deleteDocumentRequest({
                      id,
                    }),
                  ),
                )
                .catch(() => {});
            }}
            onEdit={id => {
              open({
                parent_entity_id: organisation.id,
                parent_entity_type: 'organisation',
                id,
              });
            }}
          />
        </Box>
      </Stack>
      <DocumentFormWidget
        categories={loadDocumentCategories.data}
        defaultCategory={category}
      />
    </>
  );
}

/*
<Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={2}>
            <Button variant="text" startIcon={<RiFile3Line />}>
              All
            </Button>
            <Button variant="text" startIcon={<RiBookOpenLine />}>
              Reference
            </Button>
            <Button
              variant="text"
              startIcon={<RiFileSearchLine />}
              component={Link}
              to="../policies"
            >
              Policies
            </Button>
            <Button variant="text" startIcon={<HandShakeIcon />}>
              Induction
            </Button>
          </Stack>
          <Hidden lgDown>
            <Stack direction={'row'} spacing={2}>
              <Button variant="text">Most recent</Button>
              <Button variant="text">Most Downloaded</Button>
              <Button variant="text">By Type</Button>
              <Button
                variant="outlined"
                startIcon={<RiSearchLine />}
                size={'small'}
                onClick={() => alert('Search')}
                sx={{ '& .MuiButton-startIcon': { mx: 0 } }}
              />
            </Stack>
          </Hidden>
        </Stack>
        <Hidden lgUp>
          <Stack direction={'row'}>
            <MenuButton
              onClick={handleClick}
              startIcon={<RiFilter3Line />}
              aria-controls={openAnchor ? 'documents-filter-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openAnchor ? 'true' : undefined}
              sx={{ width: buttonWidth }}
            >
              All filters
            </MenuButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openAnchor}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    width: buttonWidth,
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      // left: 'calc(75% - 5px)',
                      width: 10,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem component={Link} to={'/'}>
                Reference
              </MenuItem>
              <MenuItem component={Link} to={'/policies'}>
                Policies
              </MenuItem>
              <MenuItem component={Link} to={'/'}>
                Induction
              </MenuItem>
            </Menu>
            <Button
              variant="outlined"
              startIcon={<RiSearchLine />}
              size={'small'}
              onClick={() => alert('Search')}
              sx={{ '& .MuiButton-startIcon': { mx: 0 } }}
            />
          </Stack>
        </Hidden>
 */

/* <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Stack
                direction={'row'}
                spacing={3}
                alignItems={'center'}
                sx={{
                  backgroundColor: theme => theme.palette.grey[100],
                  pr: 2,
                  border: `3px solid ${theme.palette.grey[300]}`,
                }}
              >
                <Box sx={{ height: 80, width: 80 }}>
                  <CardMedia
                    component={'img'}
                    src={VideoImage}
                    alt="Declarations video"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Stack spacing={1}>
                    <Typography variant="h5" color={'primary'}>
                      Managing your Documents
                    </Typography>
                    <Typography variant="body2">
                      Get to know the Documents section here
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                direction={'row'}
                spacing={3}
                alignItems={'center'}
                sx={{
                  backgroundColor: theme => theme.palette.grey[100],
                  pr: 2,
                  border: `3px solid ${theme.palette.grey[300]}`,
                }}
              >
                <Box sx={{ height: 80, width: 80 }}>
                  <CardMedia
                    component={'img'}
                    src={VideoImage}
                    alt="Declarations video"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Stack spacing={1}>
                    <Typography variant="h5" color={'primary'}>
                      Sorting, filtering and finding specific documents
                    </Typography>
                    <Typography variant="body2">
                      Find anything fast with our easy to use filters
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid> */
