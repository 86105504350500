import { Box } from '@mui/material';
import React from 'react';

interface Props {
  colour: string;
  content: string | number;
  size?: number;
  type?: 'round' | 'long';
}

export function CustomBadge(props: Props) {
  const { content, colour, size = 28, type = 'round' } = props;

  const roundBadge = {
    width: size,
    height: size,
    fontSize: `calc(${size}px / 2)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  };
  const longBadge = {
    py: 1,
    px: 2,
    borderRadius: 3,
  };

  const badgeType = () => {
    switch (type) {
      case 'round':
        return roundBadge;
      case 'long':
        return longBadge;
    }
  };

  return (
    <Box
      component={'span'}
      fontWeight={'bold'}
      sx={{
        color: 'white',
        backgroundColor: colour,
        fontWeight: 700,
        ...badgeType(),
      }}
      typography={'body1'}
    >
      {content}
    </Box>
  );
}
