import { takeLatest } from 'redux-saga/effects';
import { actionActions as actions } from '.';
import { createRequestSaga } from '../../../../utils/saga';

const loadActionRegisterOptions = createRequestSaga({
  actionType: actions.loadActionRegisterOptionsRequest.type,
  url: 'action-register-items/options',
  successAction: actions.loadActionRegisterOptionsSuccess,
  failureAction: actions.loadActionRegisterOptionsFailed,
});

const loadActionRegisterItemList = createRequestSaga({
  actionType: actions.loadActionRegisterItemListRequest.type,
  url: p => `action-register-items`,

  successAction: actions.loadActionRegisterItemListSuccess,
  failureAction: actions.loadActionRegisterItemListFailed,
});

const loadActionRegisterItem = createRequestSaga({
  actionType: actions.loadActionRegisterItemRequest.type,
  url: p => `action-register-items/${p.id}`,
  successAction: actions.loadActionRegisterItemSuccess,
  failureAction: actions.loadActionRegisterItemFailed,
});

const saveActionRegisterItem = createRequestSaga({
  actionType: actions.saveActionRegisterItemRequest.type,
  method: d => (d.id ? 'put' : 'post'),
  url: d => (d.id ? `action-register-items/${d.id}` : 'action-register-items'),
  successAction: actions.saveActionRegisterItemSuccess,
  failureAction: actions.saveActionRegisterItemFailed,
});

const deleteActionRegisterItem = createRequestSaga({
  actionType: actions.deleteActionRegisterItemRequest.type,
  method: 'delete',
  url: p => `action-register-items/${p.id}`,
  successAction: actions.deleteActionRegisterItemSuccess,
  failureAction: actions.deleteActionRegisterItemFailed,
});

export function* actionRegisterSaga() {
  yield takeLatest(
    actions.loadActionRegisterOptionsRequest.type,
    loadActionRegisterOptions,
  );

  yield takeLatest(
    actions.loadActionRegisterItemListRequest.type,
    loadActionRegisterItemList,
  );
  yield takeLatest(
    actions.loadActionRegisterItemRequest.type,
    loadActionRegisterItem,
  );
  yield takeLatest(
    actions.saveActionRegisterItemRequest.type,
    saveActionRegisterItem,
  );
  yield takeLatest(
    actions.deleteActionRegisterItemRequest.type,
    deleteActionRegisterItem,
  );
}
