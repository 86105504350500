import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { MembersState } from './types';
import { apiCallInitialState, getApiCallInitialState } from 'types/ApiCall';
import { IInvitation, IMember } from 'types/types';
import { ResponseErrorPayload } from 'utils/request';

export const initialState: MembersState = {
  loadMembers: getApiCallInitialState<IMember[]>('list'),
  loadInvitations: getApiCallInitialState<IInvitation[]>('list'),
  removeInvitation: apiCallInitialState,
  resendInvitation: apiCallInitialState,
  deleteMember: apiCallInitialState,
};

export const slice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    loadMembersInit(state, action: PayloadAction<void>) {
      state.loadMembers = initialState.loadMembers;
    },
    loadMembersRequest(state, action: PayloadAction<{ [key: string]: any }>) {
      state.loadMembers.loading = true;
      state.loadMembers.error = undefined;
    },
    loadMembersSuccess(state, action: PayloadAction<{ data: IMember[] }>) {
      state.loadMembers.loading = false;
      state.loadMembers.error = undefined;
      state.loadMembers.data = action.payload.data;
    },
    loadMembersFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadMembers.loading = false;
      state.loadMembers.error = action.payload;
    },
    loadInvitationsInit(state, action: PayloadAction<void>) {
      state.loadInvitations = initialState.loadInvitations;
    },
    loadInvitationsRequest(
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) {
      state.loadInvitations.loading = true;
      state.loadInvitations.error = undefined;
    },
    loadInvitationsSuccess(
      state,
      action: PayloadAction<{ data: IInvitation[] }>,
    ) {
      state.loadInvitations.loading = false;
      state.loadInvitations.error = undefined;
      state.loadInvitations.data = action.payload.data;
    },
    loadInvitationsFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.loadInvitations.loading = false;
      state.loadInvitations.error = action.payload;
    },
    resendInvitationRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.resendInvitation.loading = true;
      state.resendInvitation.error = undefined;
    },
    resendInvitationSuccess(state, action: PayloadAction<any>) {
      state.resendInvitation.loading = false;
      state.resendInvitation.error = undefined;
      state.resendInvitation.data = true;
    },
    resendInvitationFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.resendInvitation.loading = false;
      state.resendInvitation.error = action.payload;
    },
    removeInvitationRequest(
      state,
      action: PayloadAction<{ id: number | string }>,
    ) {
      state.removeInvitation.loading = true;
      state.removeInvitation.error = undefined;
    },
    removeInvitationSuccess(state, action: PayloadAction<any>) {
      state.removeInvitation.loading = false;
      state.removeInvitation.error = undefined;
      state.removeInvitation.data = true;
    },
    removeInvitationFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.removeInvitation.loading = false;
      state.removeInvitation.error = action.payload;
    },
    deleteMemberRequest(state, action: PayloadAction<{ id: number }>) {
      state.deleteMember.loading = true;
      state.deleteMember.error = undefined;
    },
    deleteMemberSuccess(state, action: PayloadAction<{ data: IMember }>) {
      state.deleteMember.loading = false;
      state.deleteMember.error = undefined;
      state.deleteMember.data = action.payload.data;
    },
    deleteMemberFailed(state, action: PayloadAction<ResponseErrorPayload>) {
      state.deleteMember.loading = false;
      state.deleteMember.error = action.payload;
    },
  },
});

export const { actions: membersActions } = slice;
