import { Box, Stack } from '@mui/material';
import React from 'react';
import { RiskHeader } from '../RiskHeader';
import { AddNewButton } from '../AddNewButton';
import { IRiskArea, IRiskRegister, RiskAreaAction } from 'types/types';
import key from 'weak-key';
import { RiskRegisterItem } from '../RiskRegisterItem';
import { RiskSubheader } from '../RiskSubheader';

interface Props {
  riskArea: IRiskArea;
  saving?: boolean;
  openItems: number[];
  toggleOpen: (id: number) => void;
  onSave: (values: any) => void;
  onAddNew: () => void;
  onRiskAreaAction: (action: RiskAreaAction) => void;
  onRename: () => void;
  canManage?: boolean;
  memberNames?: string[];
  risk_areas?: IRiskArea[];
}

export function RiskAreaItem(props: Props) {
  const {
    toggleOpen,
    openItems = [],
    saving = false,
    riskArea: { name, icon, code, items = [] },
    onSave,
    onAddNew,
    onRiskAreaAction,
    onRename,
    canManage = false,
    memberNames = [],
    risk_areas = [],
  } = props;

  return (
    <Box>
      <Stack spacing={2}>
        <RiskHeader
          onRename={onRename}
          icon={icon}
          numberOfRisks={items.length}
          title={name}
          onRiskAreaAction={action => onRiskAreaAction({ ...action, code })}
          canManage={canManage}
        />

        {!!items.length && (
          <>
            <RiskSubheader subheader="active" />
            {items.map(item => (
              <RiskRegisterItem
                open={openItems.indexOf(item.id) > -1}
                toggleOpen={() => toggleOpen(item.id)}
                onSave={onSave}
                saving={saving}
                key={key(item)}
                item={item}
                canManage={canManage}
                memberNames={memberNames}
                risk_areas={risk_areas}
              />
            ))}
          </>
        )}
        {!!canManage && <AddNewButton onClick={onAddNew} />}
      </Stack>
    </Box>
  );
}
