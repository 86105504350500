import { CheckBox } from '@mui/icons-material';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment-timezone';
import React from 'react';
import { IOrganisation, OrganisationPicker } from 'types/types';

interface Props {
  previous: () => void;
  organisations?: OrganisationPicker[];
}

export function Confirm(props: Props) {
  const { previous, organisations } = props;
  const { values } = useFormikContext() as { values: any };

  return (
    <>
      <Typography fontWeight={'bold'}>
        Confirm your Declaration of Interest
      </Typography>
      <Box>
        <Stack>
          <Typography fontWeight={'bold'}>Declaration</Typography>
          <Typography>{values.description}</Typography>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Typography fontWeight={'bold'}>Selected organisations</Typography>
          {organisations
            .filter(o => values.organisation_ids.includes(o.id))
            .map((o, index) => (
              <Stack
                key={index}
                direction={'row'}
                spacing={1}
                alignItems={'center'}
              >
                <CheckBox color="primary" />
                <Typography>{o.name}</Typography>
              </Stack>
            ))}
        </Stack>
      </Box>

      <Box>
        <Stack>
          <Typography fontWeight={'bold'}>Interest start</Typography>
          <Typography>
            {moment(values.date_start).format('DD/MM/YYYY')}
          </Typography>
        </Stack>
      </Box>
      <Box>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              startIcon="<"
              variant="outlined"
              fullWidth
              onClick={previous}
            >
              Go back and make changes
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button fullWidth type="submit">
              I confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
