import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { HelpModal } from 'app/components/HelpModal';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { RiskLevelSelector } from 'app/components/RiskLevelSelector';

export function ControlMeasures() {
  return (
    <>
      <Box>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h1">Control measures</Typography>
          </Box>
          <Box>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography>What are control measures?</Typography>
              <HelpModal
                iconTitle="Find more about control measures"
                name="control_measures_text"
              />
            </Stack>
            <Typography variant="body2">
              Control measures are actions that can be taken to reduce the
              potential of exposure to the Risk. At this stage you should
              estimate your exposure to the Risk without you having deployed any
              solutions or mitigations to the Risk identified. This is sometimes
              known as ‘raw risk’.
            </Typography>
          </Box>
          <Box>
            <Field
              fullWidth
              label="Enter text here"
              component={TextField}
              name="control_measures_text"
              multiline
              rows={8}
            />
          </Box>
        </Stack>
      </Box>

      <Box>
        <Stack spacing={2}>
          <Typography variant={'h3'}>Impact after controls</Typography>

          <Typography variant="body2">
            In simplest form, risk mitigation means “making the impact of your
            identified risk less severe by preventing or reducing it through the
            controls you put in place”. Your aim by producing a Risk Register is
            to identify those key risks that affect your organisation,
            introducing controls to reduce the threat of them to your
            organisation (or ensuring existing controls work efficiently) and
            then ensuring that the controls you propose operate and keep your
            Organisation safe.
          </Typography>
          <RiskLevelSelector name="score_impact_mitigated" fullWidth={true} />
        </Stack>
      </Box>
    </>
  );
}
