import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { useField } from 'formik';
import {
  DateTimePicker,
  DateTimePickerProps,
  MobileDateTimePicker,
  PickersActionBarProps,
  MobileDateTimePickerProps,
} from '@mui/x-date-pickers-pro';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import { Button, Stack, useMediaQuery, useTheme } from '@mui/material';

const ActionList = (props: PickersActionBarProps) => {
  const { onAccept, onClear, onCancel } = props;
  return (
    <Stack
      p={2}
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Button
        variant={'text'}
        onClick={onClear}
        size="small"
        sx={{ pl: 0, justifyContent: 'start' }}
      >
        Clear
      </Button>
      <Stack direction={'row'} spacing={1}>
        <Button variant="outlined" onClick={onCancel} size="small">
          Cancel
        </Button>
        <Button onClick={onAccept} size="small">
          Ok
        </Button>
      </Stack>
    </Stack>
  );
};

export const DateTimePickerWrapper = (
  props: Partial<MobileDateTimePickerProps<any> & DateTimePickerProps<any>>,
) => {
  const { onChange, ...rest } = props;
  const [field, meta, helpers] = useField(props.name);
  const { value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  const momentValue = useMemo(() => {
    return value ? moment(value) : null;
  }, [value]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultProps = {
    skipDisabled: true,
    format: 'DD/MM/YYYY HH:mm',
    ampm: false,
    minutesStep: 15,
    value: momentValue,
    onChange: (date, context) => {
      setValue(date);
      onChange && onChange(date, context);
    },
    slots: {
      actionBar: ActionList,
    },
    slotProps: {
      layout: {
        sx: {
          [`.${pickersLayoutClasses.contentWrapper}`]: {
            gridColumn: 1,
            gridRow: 1,
          },
          [`.${pickersLayoutClasses.toolbar}`]: {
            gridColumn: 1,
            gridRow: 2,
          },
        },
      },
      textField: {
        error: !!error && touched,
        helperText: touched && error,
      },
    },
  };

  return (
    <>
      {!isXs ? (
        <DateTimePicker {...field} {...defaultProps} {...rest} />
      ) : (
        <MobileDateTimePicker {...field} {...defaultProps} {...rest} />
      )}
    </>
  );
};
