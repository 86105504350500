/**
 *
 * ForgotPasswordForm
 *
 */

import React, { useRef } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { TextField } from 'formik-mui';
import cn from 'clsx';
import Yup from 'utils/yup';
import { ResponseErrorPayload } from 'utils/request';

const schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('This field is required'),
});

interface Props {
  forgotPasswordRequest: Function;
  data: {
    loading: boolean;
    data?: any;
    error?: ResponseErrorPayload;
  };
}

export function ForgotPasswordForm(props: Props) {
  const { forgotPasswordRequest, data } = props;
  const ref = useRef<FormikProps<any>>(null);
  React.useEffect(() => {
    if (!data.loading && !!data.data) ref.current?.resetForm();
  }, [data.loading]);
  return (
    <Formik
      innerRef={ref}
      initialValues={{
        email: '',
      }}
      validationSchema={schema}
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        forgotPasswordRequest(values);
      }}
    >
      {({ submitForm, isSubmitting, setSubmitting }) => {
        if (isSubmitting !== data.loading) {
          setSubmitting(data.loading);
        }

        return (
          <Form>
            <Box>
              <Box sx={{ mb: 2 }}>
                <Field
                  component={TextField}
                  id="email"
                  name="email"
                  type="email"
                  label="Email / Username"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box>
                <Box>
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      Reset Password
                    </Button>
                  </Box>
                  <Box sx={{ mt: 3, textAlign: 'center' }}>
                    <Button
                      component={Link}
                      variant="text"
                      size="small"
                      className="align-right"
                      to="/auth/login"
                    >
                      Back to login page
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}
