import { createTheme, Theme } from '@mui/material/styles';
import {
  alpha,
  lighten,
  outlinedInputClasses,
  textFieldClasses,
} from '@mui/material';
import { gridClasses } from '@mui/x-data-grid';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D74D2', // was 4091F7
      light: '#d7e3f7',
      lighter: '#edf4fe',
      lightest: '#F3F5FA',
    },
    secondary: {
      main: '#E3005F',
      light: '#efc9c8',
    },
    error: {
      main: '#F04F4F',
      contrastText: '#fff',
    },
    warning: {
      main: '#F0B04F',
      light: '#F0B04F',
      contrastText: '#fff',
    },
    success: {
      main: '#3AD78C',
      contrastText: '#fff',
      light: '#E9FFE7',
    },
    text: {
      risk: {
        colours: {
          1: '#3AD78C',
          2: '#AED73A',
          3: '#F0B04F',
          4: '#F04F4F',
          5: '#A80A0A',
        },
      },
    },
    common: {
      midnight: '#343459',
      midnightGrey: '#B0ACBF',
      grey: '#D1CFCF',
      orange: '#E36D00',
      brown: '#AD9B6B',
      beige: '#FFFCEB',
      purple: {
        light: '#EDECFA',
        main: '#AAAAD3',
      },
      amber: '#C05100',
    },
  },

  shape: {
    borderRadius: 8,
  },

  typography: {
    allVariants: {
      lineHeight: 1.25,
    },
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 30,
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 22,
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 20,
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 18,
      fontWeight: 700,
      '& > b': {
        fontWeight: 700,
      },
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 16,
      fontWeight: 700,
    },
    body1: {
      fontSize: 16,
      //  fontWeight: 500,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      fontSize: 16,
      textTransform: 'none',
    },
  },

  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiDateTimePicker: {
      defaultProps: {},
    },
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        getRowClassName: params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd',
        getRowHeight: () => 'auto',
        getEstimatedRowHeight: () => 84,
      },
      styleOverrides: {
        root: (obj: any) => {
          // Not sure why this is needed only on this component!
          const theme: Omit<Theme, 'components'> = obj.theme;
          return theme.unstable_sx({
            border: 'none',
            [`& .${gridClasses.row}`]: {
              py: 3,
              alignItems: 'center',
              '&.even': {
                backgroundColor: 'common.white',
              },
              '&.odd': {
                backgroundColor: 'primary.lightest',
              },
            },
            [`& .${gridClasses['pinnedColumnHeaders']}`]: {
              color: theme.palette.primary.main,

              backgroundColor: theme.palette.common.white,
              boxShadow: 'none',
            },
            [`& .${gridClasses['pinnedColumns']}`]: {
              boxShadow: 'none',
            },
            [`& .${gridClasses.cell}`]: {
              borderBottom: 0,
            },
            [`& .${gridClasses.checkboxInput}`]: {
              color: theme.palette.grey.A700,
            },

            [`& .${gridClasses.menuIconButton}`]: {
              color: 'inherit',
            },

            [`& .${gridClasses.iconButtonContainer} > button`]: {
              color: 'inherit',
            },

            [`& .${gridClasses['columnHeader--sortable']}:not(.${gridClasses['columnHeader--sorted']}):hover .${gridClasses.sortIcon}`]:
              {
                opacity: 0.8,
              },

            [`& .${gridClasses.columnHeaders}`]: {
              [`& .${gridClasses.checkboxInput}`]: {
                color: theme.palette.common.white,
              },
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.common.white,
            },

            [`& .${gridClasses.columnHeaderTitleContainer}, & .${gridClasses.columnHeaderTitle}`]:
              {
                color: theme.palette.common.black,
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 700,
              },
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: 'none',
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: 'none',
              },
          });
        },
      },
    },
    MuiChip: {
      defaultProps: {},
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 0,
          minWidth: 0,
          opacity: 1,
          p: 0,
          '&:hover': {
            opacity: 0.9,
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {},
      styleOverrides: {
        container: ({ ownerState, theme }) => ({
          backgroundColor: `${alpha(theme.palette.common.white, 0.7)}`,
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          padding: `${theme.spacing(3, 0)} !important`,
          margin: `${theme.spacing(0, 3)} !important`,
        }),
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h2',
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            py: 3,
            px: 0,
            mx: 3,
            borderBottom: 1,
            borderColor: 'grey.300',
          }),
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        variant: 'body1',
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            py: 3,
            px: 0,
            mx: 3,
            borderTop: 1,
            borderColor: 'grey.300',
          }),
      },
    },
    MuiDivider: {
      defaultProps: {},
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            my: 4,
          }),
        wrapper: ({ theme }) =>
          theme.unstable_sx({
            color: 'grey.500',
            paddingLeft: 3,
            paddingRight: 3,
          }),
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiStack: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: { fontSize: 12 },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          backgroundColor: 'white',
        },

        notchedOutline: ({ ownerState, theme }) => ({
          borderWidth: 2,
        }),
        input: {
          '::placeholder': {},
        },
      },
    },
    MuiSelect: {
      defaultProps: {},
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.root}`]: {
            borderRadius: 8,
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: 2,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.root}`]: {
            borderRadius: 8,
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: 2,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) =>
          theme.unstable_sx({
            fontWeight: 600,
            boxShadow: 'none',
            py: 1,
            px: 2,
            ':hover': {
              boxShadow: 'none',
            },
          }),
        contained: ({ ownerState, theme }) => ({
          ':hover': {
            backgroundColor: theme.palette[ownerState.color]
              ? lighten(
                  theme.palette[ownerState.color].main ?? ownerState.color,
                  0.2,
                )
              : ownerState.color,
            opacity: !theme.palette[ownerState.color] ? 0.8 : 1,
          },
        }),
        outlined: ({ ownerState, theme }) => ({
          borderColor:
            (theme.palette[ownerState.color] || {}).main ?? ownerState.color,
          borderWidth: 2,
          ':hover': {
            borderWidth: 2,
          },
        }),
        text: ({ ownerState, theme }) => ({
          ':hover': {
            borderWidth: 2,
            backgroundColor: 'unset',
            color: theme.palette[ownerState.color]
              ? lighten(
                  theme.palette[ownerState.color].main ?? ownerState.color,
                  0.2,
                )
              : ownerState.color,
            opacity: !theme.palette[ownerState.color] ? 0.8 : 1,
          },
        }),
      },
    },
  },
});

export const useResetStyles = {
  'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video':
    {
      margin: '0',
      padding: '0',
      border: '0',
      fontSize: '100%',
      font: 'inherit',
      verticalAlign: 'baseline',
    },
  'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section':
    {
      display: 'block',
    },
};

export const useGlobalStyles = {
  body: {
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  img: {
    maxWidth: '100%',
    display: 'block',
  },
  a: {
    color: theme.palette.primary.main,
  },
};

declare module '@mui/material/styles' {
  interface CommonColors {
    midnight: string;
    midnightGrey: string;
    grey: string;
    orange: string;
    beige: string;
    brown: string;
    purple: {
      light: string;
      main: string;
    };
    amber: string;
  }
  interface TypeText {
    risk: {
      colours: {
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
      };
    };
  }
  interface PaletteColor {
    lighter?: string;
    lightest?: string;
    low?: string;
    high?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    lightest?: string;
  }
}

export const calculateDataTableHeight = (
  rows: number,
  showFooter: boolean,
  height: number = 84,
) => {
  return (showFooter ? 140 : 60) + rows * height;
};

export { theme };
