/**
 *
 * Reset Password
 *
 */

import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { AuthPageContainer } from 'app/components/AuthPageContainer';
import { authProviderActions } from 'app/providers/AuthProvider/slice';
import { ResetPasswordForm } from './Form';
import { selectResetPassword } from 'app/providers/AuthProvider/slice/selectors';
import { useSnackbar } from 'notistack';
import { Box, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { PasswordCriteriaInfo } from 'app/components/PasswordCriteriaInfo';

interface Props {}

export const ResetPassword = memo((props: Props) => {
  const data = useSelector(selectResetPassword);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [query, setQuery] = useSearchParams();

  const resetValues = {
    email: query.get('email'),
    token: query.get('token'),
  };

  React.useEffect(() => {
    if (data.error) {
      enqueueSnackbar(
        'An error occurred while trying to reset your password.',
        {
          variant: 'error',
        },
      );
    }

    if (data.data && !data.loading) {
      enqueueSnackbar('Password reset successfully.', {
        variant: 'success',
      });
    }
  }, [data, enqueueSnackbar]);

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
        <meta name="description" content="Description of reset password" />
      </Helmet>

      <AuthPageContainer>
        <>
          <Stack sx={{ textAlign: 'center' }} alignItems={'center'} spacing={3}>
            <Typography variant="h3" color="primary">
              Set new password
            </Typography>
            <Typography>
              Fill out the form below to set a new password for your account.
            </Typography>
            <Box textAlign={'left'}>
              <PasswordCriteriaInfo />
            </Box>

            <Box width={'100%'} pt={2}>
              <ResetPasswordForm
                data={{
                  ...data,
                }}
                resetPasswordRequest={data => {
                  dispatch(
                    authProviderActions.resetPasswordRequest({
                      ...resetValues,
                      ...data,
                    }),
                  );
                }}
              />
            </Box>
          </Stack>
        </>
      </AuthPageContainer>
    </>
  );
});
