import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { IRiskArea, IRiskRegisterItem, RiskHeaderIcon } from 'types/types';
import { RiskName } from './steps/RiskName';
import { ReviewAndSubmit } from './steps/ReviewAndSubmit';
import { Likelihood } from './steps/Likelihood';
import { ControlMeasures } from './steps/ControlMeasures';
import { RiskImpact } from './steps/RiskImpact';

interface Props {
  onSave: (payload: IRiskRegisterItem) => void;
  saving: boolean;
  template?: Partial<IRiskRegisterItem>;
  risk_area_code?: string;
  risk_areas?: Array<{ code: string; name: string; icon?: RiskHeaderIcon }>;
  openRiskAreaForm: (risk_area?: IRiskArea) => void;
  memberNames: string[];
  defaultMemberName: string;
}

const schema = [
  {
    name: Yup.string().required('This field is required'),
    risk_area_code: Yup.string()
      .typeError('This field is required')
      .required('This field is required'),
    owner_name: Yup.string()
      .typeError('This field is required')
      .required('This field is required'),
  },
  {
    score_likelihood: Yup.number()
      .typeError('This field is required')
      .required('This field is required'),
  },
  {
    impact_text: Yup.string().required('This field is required'),
    score_impact: Yup.number()
      .typeError('This field is required')
      .required('This field is required'),
  },
  {
    control_measures_text: Yup.string().required('This field is required'),
    score_impact_mitigated: Yup.number()
      .typeError('This field is required')
      .required('This field is required'),
  },
];

export function RiskWizard(props: Props) {
  const {
    onSave,
    saving,
    risk_area_code = '',
    risk_areas = [],
    openRiskAreaForm,
    memberNames,
    defaultMemberName,
    template = {},
  } = props;

  const formikRef = React.useRef(null);

  const stepperTabs = [
    {
      key: 'risk-name',
      label: "What's the risk?",
    },
    {
      key: 'likelihood',
      label: 'Likelihood',
    },
    {
      key: 'risk-impact',
      label: 'Impact',
    },
    {
      key: 'control-measures',
      label: 'Control measures & impact after controls',
    },
    {
      key: 'review-submit',
      label: 'Review & submit',
    },
  ];
  const [step, setStep] = useState<string>(stepperTabs[0].key);

  const indexTab = stepperTabs.findIndex(tab => tab.key === step);
  const currentValidationSchema = Yup.object().shape(
    Object.assign({}, ...schema.slice(0, indexTab + 1)),
  );

  const template_risk_area_code = risk_areas.find(
    ra => ra.code === template.risk_area_code,
  )
    ? template.risk_area_code
    : '';

  useEffect(() => {
    formikRef.current?.validateForm();
  }, [step]);

  const stepsContent = () => {
    switch (step) {
      case 'likelihood':
        return (
          <>
            <Likelihood />
          </>
        );
      case 'risk-impact':
        return (
          <>
            <RiskImpact />
          </>
        );
      case 'control-measures':
        return (
          <>
            <ControlMeasures />
          </>
        );
      case 'review-submit':
        return (
          <>
            <ReviewAndSubmit changeStep={setStep} />
          </>
        );
      default:
        return (
          <>
            <RiskName
              openRiskAreaForm={openRiskAreaForm}
              risk_areas={risk_areas}
              memberNames={memberNames}
            />
          </>
        );
    }
  };
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Box sx={{ backgroundColor: 'grey.200', p: 4, borderRadius: 3 }}>
        <Box sx={{ my: 2, mb: 4 }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'stretch'}
          >
            {isMd && (
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={indexTab} alternativeLabel>
                  {stepperTabs.map(step => (
                    <Step key={step.key}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            )}
          </Stack>
        </Box>
        <Stack spacing={4}>
          <Formik
            innerRef={formikRef}
            initialValues={
              {
                id: undefined,
                name: template.name || '',
                risk_area_code: template_risk_area_code || risk_area_code,
                impact_text: template.impact_text || '',
                score_impact: template.score_impact || 1,
                score_likelihood: template.score_likelihood || 1,
                score_impact_mitigated: template.score_impact_mitigated || 1,
                control_measures_text: template.control_measures_text || '',
                owner_name: defaultMemberName,
              } as IRiskRegisterItem
            }
            validationSchema={currentValidationSchema}
            validateOnChange
            validateOnMount
            validateOnBlur
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              onSave({ ...values });
            }}
          >
            {formik => {
              const {
                setSubmitting,
                dirty,
                isSubmitting,
                values,
                isValid,
                errors,
              } = formik;
              if (isSubmitting && !saving) setSubmitting(false);

              const handleNextStep = () => {
                if (currentValidationSchema) {
                  setStep(stepperTabs[indexTab + 1].key);
                }
              };

              const handlePreviousStep = () => {
                setStep(stepperTabs[indexTab - 1].key);
              };

              const handleStepChange = () => {
                if (step !== 'review-submit') {
                  handleNextStep();
                } else {
                  setSubmitting(true);
                  onSave({ ...values });
                }
              };

              return (
                <Form>
                  <Stack spacing={3}>
                    {stepsContent()}

                    <Box>
                      <Grid container justifyContent={'flex-end'}>
                        {step !== 'risk-name' && (
                          <Grid item xs={12} sm={6} lg={3}>
                            <Button
                              variant="outlined"
                              fullWidth
                              onClick={handlePreviousStep}
                            >
                              Previous
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6} lg={3}>
                          {step !== 'review-submit' ? (
                            <Button
                              disabled={!isValid}
                              onClick={handleStepChange}
                              type="button"
                              fullWidth
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              disabled={saving || isSubmitting || !isValid}
                              type="button"
                              fullWidth
                              onClick={() => {
                                formik.submitForm();
                              }}
                            >
                              Submit
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Stack>
      </Box>
    </>
  );
}
