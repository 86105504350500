/**
 * @deprecated
 * @module OnboardingStep
 * @desc
 * This file is deprecated and should not be used. It is kept for historical reasons and to prevent breaking changes. It should be removed in the future.
 */

/**
 *
 * ConfirmNewOrganisation
 *
 */
import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { IOrganisation, organisationTypes } from 'types/types';
import { TitledField } from 'app/components/TitledField';

interface Props {
  organisationDetails: Partial<IOrganisation>;
  planDetails: {
    planHash?: string;
    plan_id?: number;
    name?: string;
    partner_name?: string;
  };
  onConfirm: () => void;
  onDismiss: () => void;
  saving?: boolean;
}

export function ConfirmNewOrganisation(props: Props) {
  const {
    onDismiss,
    onConfirm,
    organisationDetails,
    planDetails,
    saving = false,
  } = props;
  const theme = useTheme();

  const fallbackMessage = field => (field ? field : 'Not set');

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.grey[200],
          borderRadius: 5,
          padding: 3,
        }}
      >
        <Stack spacing={5}>
          <Box>
            <Stack spacing={3}>
              <Box>
                <Typography variant={'h3'}>Organisation Details</Typography>
              </Box>

              <TitledField title="Name" direction="row">
                <Typography>{organisationDetails.name}</Typography>
              </TitledField>

              <TitledField title="Number" direction="row">
                <Typography>
                  {fallbackMessage(organisationDetails.number)}
                </Typography>
              </TitledField>
              <TitledField title="Type" direction="row">
                <Typography>
                  {fallbackMessage(organisationTypes[organisationDetails.type])}
                </Typography>
              </TitledField>

              <TitledField title="Address" direction="row">
                <Typography>
                  {organisationDetails.premises ||
                  organisationDetails.address_line_1 ||
                  organisationDetails.address_line_2 ||
                  organisationDetails.locality ||
                  organisationDetails.region ||
                  organisationDetails.country ||
                  organisationDetails.postal_code ? (
                    <>
                      {[
                        organisationDetails.premises,
                        organisationDetails.address_line_1,
                        organisationDetails.address_line_2,
                        organisationDetails.locality,
                        organisationDetails.region,
                        organisationDetails.country,
                        organisationDetails.postal_code,
                      ]
                        .filter(x => x !== undefined && x !== null && x !== '')
                        .map((line, ind, arr) => (
                          <React.Fragment key={`address-line-${ind}`}>
                            {line} {ind < arr.length - 1 ? <br /> : null}
                          </React.Fragment>
                        ))}
                    </>
                  ) : (
                    'Not set'
                  )}
                </Typography>
              </TitledField>
            </Stack>
          </Box>
          <Box>
            <Stack spacing={3}>
              <Typography variant={'h3'}>Plan Details</Typography>
              <TitledField title="Name" direction="row">
                <Typography>{planDetails.name}</Typography>
              </TitledField>
              <TitledField title="Partner" direction="row">
                <Typography>{planDetails.partner_name}</Typography>
              </TitledField>
            </Stack>
          </Box>

          <Divider />

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={'flex-end'}
          >
            <Button
              variant={'text'}
              size={'small'}
              onClick={() => onDismiss()}
              disabled={saving}
            >
              Back: select plan
            </Button>
            <Button
              onClick={() => onConfirm()}
              disabled={saving}
              startIcon={saving ? <CircularProgress size={20} /> : null}
            >
              Confirm details and create organisation
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
