/**
 *
 * DocumentsSection
 *
 */
import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import { SectionHeader } from 'app/components/SectionHeader';
import { SectionMenu, SectionMenuProps } from 'app/components/SectionMenu';
import { IOrganisation } from 'types/types';
import { Documents } from './Documents';
import { useDocumentsSlice } from './slice/hook';
import { useDispatch, useSelector } from 'react-redux';
import { useHasPermission } from '../../providers/AuthProvider/useHasPermission';
import { Templates } from './Templates';
import { Expiring } from './Expiring';
import { DocumentFormWidget } from '../../widgets/DocumentFormWidget';
import { selectDocuments } from './slice/selectors';

interface Props {
  organisation: IOrganisation;
}

export function DocumentsSection(props: Props) {
  const { organisation } = props;
  const { actions } = useDocumentsSlice();
  const { loadDocumentCategories } = useSelector(selectDocuments);
  const dispatch = useDispatch();

  const { hasPermission } = useHasPermission();

  useEffect(() => {
    dispatch(
      actions.loadDocumentCategoriesRequest({
        organisation_id: organisation.id,
      }),
    );
  }, [dispatch]);

  const location = useLocation();

  const sections: Omit<SectionMenuProps, 'isActive'>[] = [
    {
      title: 'Reference',
      path: `/organisation/${organisation.id}/documents/reference`,
    },
    {
      title: 'Policies',
      path: `/organisation/${organisation.id}/documents/policies`,
    },
    {
      title: 'Induction',
      path: `/organisation/${organisation.id}/documents/induction`,
    },
    {
      title: 'Templates',
      path: `/organisation/${organisation.id}/documents/templates`,
      hidden: !hasPermission(
        'view-organisation-plan-templates',
        'organisation',
        organisation.id,
      ),
    },
    {
      title: 'Expired/Expiring',
      path: `/organisation/${organisation.id}/documents/expiring`,
      hidden: !hasPermission(
        'view-expiring-documents',
        'organisation',
        organisation.id,
      ),
    },
  ];

  return (
    <>
      <SectionHeader
        title="Documents"
        sectionMenu={
          <>
            {sections.map((section, index) => (
              <SectionMenu
                key={index}
                {...section}
                isActive={location.pathname.includes(section.path)}
              />
            ))}
          </>
        }
      />
      <Container>
        <Routes>
          <Route
            path={'expiring'}
            element={
              <>
                <Expiring organisation={organisation} />
              </>
            }
          />
          <Route
            path={'templates'}
            element={
              <>
                <Templates organisation={organisation} />
              </>
            }
          />
          <Route
            path={':category'}
            element={
              <>
                <Documents organisation={organisation} />
              </>
            }
          />
        </Routes>
      </Container>
    </>
  );
}
